import React, { useState, useContext } from 'react'
import { SidePanelManager, ErrorBoundary, FetchData, SidePanelContext } from '../../index';
import { ContentContainer } from '../../index'
import { ExportDocumentsTable } from './ExportDocumentsTable'
import { Formik } from 'formik';
import { dataservice } from '../../00_global/service/dataservice';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';
import { TableLayout } from '../../04_organisms/tableLayout/TableLayout';
import { Button } from '../../02_atoms/buttons/Button';
import { ConfirmExport } from './ConfirmExport';
import { Announcement } from '../../03_molecules/announcement/Announcement';
import { StyledCheck } from '../../02_atoms/check/Check';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { PARAGRAPH_SIZE, PARAGRAPH_SPACING, CURRENT_PERIOD } from '../../constants';
import { Heading } from '../../02_atoms/headings/Heading';
import { PeriodSelectorBubble } from '../../03_molecules/periodSelector/PeriodSelector';

const ExportDocumentsForm = () => {

    const [selectedPeriod, setSelectedPeriod] = useState(CURRENT_PERIOD.value)
    const [selectedYear, setSelectedYear] = useState(CURRENT_PERIOD.year);
    const [displayValue, setDisplayValue] = useState(CURRENT_PERIOD.label)

    const [pristine, setPristine] = useState(false);

    const getPeriod = (label, value, displayValue, enabled = true) => {
        return {
            text: label,
            value: value,
            enabled: enabled,
            displayValue: displayValue
        }
    }

    const periods_2018 = [
        getPeriod('Q1', 201801, '2018 - Q1'),
        getPeriod('Q2', 201802, '2018 - Q2'),
        getPeriod('Q3', 201803, '2018 - Q3'),
        getPeriod('Q4', 201804, '2018 - Q4')
    ]

    const periods_2019 = [
        getPeriod('Q1', 201901, '2019 - Q1'),
        getPeriod('Q2', 201902, '2019 - Q2'),
        getPeriod('Q3', 201903, '2019 - Q3'),
        getPeriod('Q4', 201904, '2019 - Q4')
    ]

    const periods_2020 = [
        getPeriod('Q1', 202001, '2020 - Q1'),
        getPeriod('Q2', 202002, '2020 - Q2'),
        getPeriod('Q3', 202003, '2020 - Q3'),
        getPeriod('Q4', 202004, '2020 - Q4')
    ]

    const periods_2021 = [
        getPeriod('Q1', 202101, '2021 - Q1'),
        getPeriod('Q2', 202102, '2021 - Q2'),
        getPeriod('Q3', 202103, '2021 - Q3'),
        getPeriod('Q4', 202104, '2021 - Q4')
    ]

    const periods_2022 = [
        getPeriod('Q1', 202201, '2022 - Q1'),
        getPeriod('Q2', 202202, '2022 - Q2'),
        getPeriod('Q3', 202203, '2022 - Q3'),
        getPeriod('Q4', 202204, '2022 - Q4')
    ]

    const periods_2023 = [
        getPeriod('Q1', 202301, '2023 - Q1'),
        getPeriod('Q2', 202302, '2023 - Q2'),
        getPeriod('Q3', 202303, '2023 - Q3'),
        getPeriod('Q4', 202304, '2023 - Q4')
    ]

    const periods_2024 = [
        getPeriod('Q1', 202401, '2024 - Q1'),
        getPeriod('Q2', 202402, '2024 - Q2'),
        getPeriod('Q3', 202403, '2024 - Q3'),
        getPeriod('Q4', 202404, '2024 - Q4')
    ]

    const periodPages = [
        { text: '2018', value: 2018, periods: periods_2018 },
        { text: '2019', value: 2019, periods: periods_2019 },
        { text: '2020', value: 2020, periods: periods_2020 },
        { text: '2021', value: 2021, periods: periods_2021 },
        { text: '2022', value: 2022, periods: periods_2022 },
        { text: '2023', value: 2023, periods: periods_2023 },
        { text: '2024', value: 2024, periods: periods_2024 }
    ]

    const { add } = useContext(SidePanelContext);

    const body = {
        pristine: pristine,
        documentTypes: [2, 3, 4],
        period: selectedPeriod,
        selectedDocuments: []
    }

    const handleCheckChanged = (e) => {
        setPristine(!pristine)
    }

    const handlePeriodChanged = (period) => {
        setSelectedPeriod(period.value);
        setDisplayValue(period.displayValue);
    }



    return (
        <>
            <ErrorBoundary>
                <FetchData method={() => dataservice.getDocumentsForExport(body)} triggerValues={[selectedPeriod, pristine]}>
                    {({ loading, error, data }) => {
                        if (data && data.documents) {
                            const { documents } = data;
                            const selectedDocuments = documents.filter(doc => !doc.exportedOn).map(d => d.documentId);

                            return (
                                <Formik
                                    initialValues={{ selectedDocuments: selectedDocuments }}
                                    onSubmit={async (values, actions) => {
                                        //await onSubmit(values, actions);
                                        actions.setSubmitting(false);
                                    }}
                                >
                                    {
                                        ({ values }) => {
                                            return (<>
                                                <Announcement>

                                                    <table>
                                                        <tbody>
                                                        <tr>
                                                            <td> <Heading>Periode: {displayValue}</Heading></td>
                                                            <td><PeriodSelectorBubble
                                                                currentYear={selectedYear}
                                                                pages={periodPages}
                                                                selectedPeriod={selectedPeriod}
                                                                onPeriodSelected={(selectedPeriod) => handlePeriodChanged(selectedPeriod)}
                                                                onYearChanged={(selectedYear) => setSelectedYear(selectedYear)}>
                                                                <Paragraph narrow={false} spacing={PARAGRAPH_SPACING.medium}><StyledCheck id={'checkPristine'} name={'checkPristineName'} value={pristine} checked={pristine} label={'Toon enkel documenten die nog niet werden geëxporteerd'} onChange={() => handleCheckChanged()} /></Paragraph>
                                                            </PeriodSelectorBubble></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </Announcement>
                                                <hr></hr>
                                                <Paragraph narrow={true}>Er zijn in totaal {values.selectedDocuments.length} documenten geselecteerd.</Paragraph>
                                                <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.medium} color={'fc--gray-2'}>Klik op het tandwiel om de geselecteerde periode aan te passen</Paragraph>

                                                <TableLayout>
                                                    <ExportDocumentsTable documents={data.documents} />
                                                </TableLayout>
                                                <div>
                                                    <Button disabled={values.selectedDocuments.length === 0} onClick={() => add(<ConfirmExport selectedDocuments={values.selectedDocuments} />, true)}>Export</Button>
                                                </div>
                                            </>)
                                        }
                                    }
                                </Formik>
                            )
                        }
                        if (error) {
                            return (
                                <Bug error={error} />
                            )
                        }
                        return null;
                    }}
                </FetchData>
            </ErrorBoundary>
        </>
    )
}

const ExportDocumentsMain = () => {


    return (
        <>
            <ExportDocumentsForm />
        </>
    )
}


export const ExportDocuments = () => {
    const main = <ExportDocumentsMain />

    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}