import React from 'react';
import { SidePanelElement } from '../../04_organisms/sidePanel/SidePanel'
import { LimitContent } from '../../03_molecules/limitContent/LimitContent';
import { CreateCustomerPanel } from '../customers/CreateCustomerPanel';
import { CustomerOverview } from '../customers/CustomerOverview';

export const DevMode = () => {

    // const panel = <CreateCustomerPanel onCustomerCreated={(customer) => {console.log(customer)}} />

    // const newItem = {
    //     children: panel,
    //     closeProps: null,
    //     pinProps: null,
    //     isActive: true
    // };

    // return (
    //     <LimitContent width={'600px'} align={'center'}>
    //         <SidePanelElement
    //             debug={false}
    //             isOpen={true}
    //             onClose={() => { }}
    //             sidepanels={[newItem]}
    //             toggleIsOpen={() => { }}
    //             togglePinned={() => { }}
    //             visibility={'visible'}
    //             key={'devMode'}
    //         />
    //     </LimitContent>

    // )

    return <CustomerOverview />
}