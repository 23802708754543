import React from 'react';
import { Button, FormField, Field, Heading, Input, LimitContent, Paragraph } from "../../../components";
import { BUTTON_SIZE, COLOR, HEADING_SIZE, HEADING_SPACING, HEADING_WEIGHT, PARAGRAPH_SPACING } from "../../constants";
import { withFormik } from 'formik';

const AddAccountForm = () => {

    // render html
    return (
        <LimitContent width={580}>
            <div className="form">
                <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.small} color="fc--primary-1" weight={HEADING_WEIGHT.bold}>Nieuwe account</Heading>
                <Paragraph spacing={PARAGRAPH_SPACING.large}>Maak een nieuwe account lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam interdum sapien non magna aliquet pellentesque. Aliquam erat volutpat.</Paragraph>
                <FormField>
                    <div className="row">
                        <div className="col-md-4">
                            <Field header={'CODE'} id={'f1_1'} placeholder={'vb: XXXX'}>
                                <Input />
                            </Field>
                        </div>
                        <div className="col-md-8">
                            <Field header={'ONDERNEMING/DOSSIER'} id={'f1_2'} placeholder={'vb: BE XXXX XXX XXX'}>
                                <Input />
                            </Field>
                        </div>
                    </div>
                </FormField>
                <FormField>
                    <Field header={'VOORNAAM'} id={'f1_3'} placeholder={'Uw voornaam'}>
                        <Input />
                    </Field>
                </FormField>
                <FormField>
                    <Field header={'NAAM'} id={'f1_4'} placeholder={'Uw familienaam'}>
                        <Input />
                    </Field>
                </FormField>
                <FormField>
                    <Field header={'EMAIL'} id={'f1_5'} placeholder={'voornaam.naam@bedrijf.be'}>
                        <Input />
                    </Field>
                </FormField>
                <FormField>
                    <Button
                        type="submit"
                        color={COLOR.secondary_2}
                        size={BUTTON_SIZE.full}
                        disabled={false}
                        loading={false}
                    >
                        Account aanmaken
                    </Button>
                </FormField>
            </div>
        </LimitContent>
    );

}

export const AddAccount = withFormik({
    mapPropsToValues: () => ({}),
    //validationSchema: ,

    handleSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    },

    displayName: 'InputForm',
})(AddAccountForm);