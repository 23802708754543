import React from 'react';
import './styles/_btn.scss';
import cx from 'classnames';

import { BUTTON_SIZE, BUTTON_MODE, DISABLED, LOADER_SIZE } from "../../constants";
import { LoaderContainer } from '../../03_molecules/loaderContainer/LoaderContainer';
// import { isInDebug } from '../../00_global/isInDebug';

// const debug = isInDebug('Button');


export const Button = ({onClick = () => {}, children, disabled = false, inverted = false, rounded=false, color = '', size = BUTTON_SIZE.large, type='submit', href=undefined, additionalStyles=null, loading = false, stopPropagation = true}) => {

    const style = cx('btn',
        {[DISABLED]: disabled === true || loading },
        {[BUTTON_MODE.inverted]: inverted === true},
        {[color]: color !== ''},
        {'is-rounded': rounded === true},
        [size]
    );

    const handleClick = (e) => {
        if (onClick){
            onClick();
            stopPropagation && e.stopPropagation();
        };
        
    }

    const loader = <LoaderContainer size={LOADER_SIZE.xsmall} showLoader={loading === true} source="button">{children}</LoaderContainer>
    const content = loader;

    return href ? (
        <a href={href} className={style} onClick={onClick}>{children}</a>
    ) : (
        <button type={type} className={style} onClick={handleClick} disabled={disabled} style={additionalStyles}>
            <span className="btn__inner">{content}</span>
        </button>
    )
};