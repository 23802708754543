import React from 'react';

export const ShowObject = ({ value, background = '#f6f8fa', caption = 'Debug' }) => {

	const safeStringify = (o) => {
		var cache = [];
		const result = JSON.stringify(o, function (key, value) {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value) !== -1) {
					// Duplicate reference found
					try {
						// If this value does not reference a parent it can be deduped
						return JSON.parse(JSON.stringify(value, null, 2));
					} catch (error) {
						// discard key if value cannot be deduped
						return;
					}
				}
				// Store value in our collection
				cache.push(value);
			}
			return value;
		}, 2);

		cache = null; // Enable garbage collection
		return result;
	}


	return (<div style={{ margin: '1rem 0' }}>
		<h3 style={{ fontFamily: 'monospace' }}>{caption}</h3>
		<pre
			style={{
				background,
				fontSize: '10px',
				padding: '.5px',
			}}
		>
			{safeStringify(value)}
		</pre>
	</div>)
}