import React from 'react';
import './styles/_headings.scss';
import cx from 'classnames';

import {HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING} from "../../constants";

export const Heading = ({children, size=HEADING_SIZE.medium, weight=HEADING_WEIGHT.default, color='', spacing=HEADING_SPACING.default, classes=''}) => {

    const style = cx('heading',
        [size],
        [weight],
        [color],
        [spacing],
        [classes]
    );

    return (
        <h1 className={style}>{children}</h1>
    )
};