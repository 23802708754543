import React, { useState } from 'react';
import * as Yup from "yup";
import * as moment from 'moment';
import { addDays } from 'date-fns'
import { Formik } from 'formik';
import { Box, FormField, Field, Button, ShowObject } from '../../';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';
import { DateField } from '../../03_molecules/date/DateField';
import { SelectPaymentMethod } from '../inbox/_amo_';
import { dev } from '../../00_global/isInDebug';
import { dataservice } from '../../00_global/service/dataservice';
import { notNull } from '../../00_global';

const registerPaymentValidationSchema = Yup.object().shape({
    paymentDate: Yup.date()
        .required('Verplicht veld!'),
});

export const RegisterPayment = ({ document, onDocumentPaid }) => {

    const debug = dev();
    const [error, setError] = useState();

    const { documentId, totalAmount } = document;

    const initialValues = {
        documentId,
        amount: totalAmount || 0,
        paymentDate : null,
        paymentMethod: 'Nog niet betaald'
    }

    const registerPayment = async (values) => {

        const { documentId, paymentDate, paymentMethod, amount } = values;

        notNull(documentId, 'documentId');
        notNull(paymentDate, 'paymentDate');
        notNull(paymentMethod, 'paymentMethod');

        await dataservice.registerPayment(documentId, paymentDate, paymentMethod, amount);
        if (onDocumentPaid) {
            onDocumentPaid();
        }

    }

    return (
        <Formik
        initialValues={initialValues}
        validationSchema={registerPaymentValidationSchema}
        validateOnChange={true}
        isInitialValid={registerPaymentValidationSchema.isValidSync(initialValues)}
        //validateOnBlur={true}
        //validate={handleValidate}
        onSubmit={async (values, actions) => {
            try {
                await registerPayment(values);

            }
            catch (err) {
                setError(err);
            }
            finally {
                actions.setSubmitting(false);
            }
        }}>
        {
            (formikProps) => {
                const { isSubmitting, handleSubmit, isValid, setFieldValue } = formikProps;
                const onDocumentDateSelected = (date) =>{
                    const fieldName = 'paymentDate'
                    moment(date).isValid()
                    ? setFieldValue(fieldName, moment(date).format())
                    : setFieldValue(fieldName, null);
                };
                return (
                    <>
                        <Box noBorder color="bgc--gray-10">
                            {!error && <form className="form" onSubmit={handleSubmit}>
                            <FormField>
                                    <Field id={'paymentDate'} header={'Betaald op *'} infoMessage={''}>
                                        <DateField propertyName={'documentDateFormValue'} onSelect={onDocumentDateSelected} maxDate={addDays(new Date(), 60)} />
                                    </Field>
                                </FormField>
                                <FormField>
                                    <Field id={'paymentMethod'} header={'Betaalmethode'}>
                                        <SelectPaymentMethod id={'paymentMethodSelect'} />
                                    </Field>
                                </FormField>
                                <div style={{ 'marginTop': '16px' }}><Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button></div>
                            </form>}
                            {debug && <ShowObject value={formikProps} />}
                            {error && <Bug error={error} />}
                        </Box>
                    </>
                )
            }
        }
    </Formik>
    )
}