export const routeWithSlug = (location) => {
    

   
        let target = location;
        if (target.startsWith('/'))
            {
                target = target.substring(1)
            }

        return `/${target}`;
}