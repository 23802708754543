import React, { useState } from 'react';
import { Search, notNull, Bug, Heading, Paragraph } from '../../index'
import { Field, FormField } from '../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE } from "../../constants";
import { FetchData } from '../../00_global/FetchData';
import { isInDebug } from '../../00_global/isInDebug';
import { connect, getIn, FormikContext, FormikValues } from 'formik';
import { searchResultItemBuilder } from '../../03_molecules/search/helpers';
import { notUndefined, ShowObject } from '../../00_global';
import { SearchV2, SearchItem } from '../../03_molecules/search/SearchV2'
import { ErrorBoundary } from '../../00_global/ErrorBoundary';
const debug = isInDebug('Dropdown')

interface DropdownProps {
    formik: FormikContext<FormikValues>,
    id: string,
    header: string,
    placeholder?: string,
    infoMessage?: string,
    blockInput: boolean,
    onMapItem?(item: any): void, // to fix
    onItemSelected(item: SearchItem): void,
    onSearch?(searchQuery: string): any,
    results: SearchItem[],
    onBlur?(): void
}

export const DropdownElement = ({ formik, id, onItemSelected, header, placeholder, infoMessage, blockInput = false, results, onBlur }: DropdownProps) => {

    notNull(id, 'id');
    notNull(formik, 'formik');

    const { values, setFieldValue, setFieldError } = formik;

    notNull(values, 'values');

    const displayValue = values[id];
    //const valuePropertyName = getBackingFieldFor(id);
    //const fieldValue = values[valuePropertyName];

    // const handleItemSelected = (item: any) => {
    //     // todo: save as nested value
    //     setFieldValue(id, item.caption, true);
    //     setFieldValue(getBackingFieldFor(id), item.id, true);
    // }

    //const _onItemSelected = onItemSelected ? onItemSelected : handleItemSelected;

    //notUndefined(displayValue, `displayValue on '${id}'. Check the model`);
    //notUndefined(fieldValue, getBackingFieldFor(id))

    const handleChange = (e: { target: { value: any; }; }) => {

        // clear first
        // clearTimeout(searchTimer);

        const queryValue = e.target.value;

        !blockInput && setFieldValue(id, queryValue);
        //setFieldValue(valuePropertyName, ''); // vat => vatId

        debug && notNull(setFieldError, 'setFieldError');
        setFieldError(id, 'Maak een keuze uit de lijst');; // => push errors on selectedValueId to id so they get displayed on the Field

        // searchTimer = setTimeout(() => {
        //     setSearchQuery(queryValue);
        // }, 200)
    };

    const handleSelect = (selectedValue: SearchItem) => {
        onItemSelected(selectedValue);
    };

    const handleBlur = () => {
        if (onBlur)
            onBlur();
    }

    return (
        <>
            <ErrorBoundary>
                <FormField>
                    <Field header={header} id={id} placeholder={placeholder} infoMessage={infoMessage}>
                        <SearchV2
                            onChange={handleChange}
                            onItemSelected={handleSelect}
                            id={id}
                            value={values[id]}
                            results={results}
                            key={id}
                            whenBlurred={() => handleBlur()} // don't use onBlur, not working
                        />
                    </Field>
                </FormField>
            </ErrorBoundary>
        </>
    )
}

export const Dropdown = connect(DropdownElement);

interface FetchDataProps {
    data: any;
    loading: boolean;
    error: Error
}