import React, { Fragment, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { notNull } from './notNull'
import { isInDebug } from './isInDebug'
import { Paragraph } from '../02_atoms/paragraph/Paragraph';
import { Bug } from '../03_molecules/visualFeedback/VisualFeedback';

export const WhenData = ({data, loading, error, noData, children, noDataContent=null}) => {

    const ndc = noDataContent ? noDataContent : <Paragraph>Geen gegevens gevonden...</Paragraph>

    if (loading)
        return <Paragraph>Even geduld... de gegevens worden opgehaald</Paragraph>

    if (noData)
return <>{ndc}</>
    if (data){
        return <>{children()}</>
    }
    if (error)
        return <Bug error={error} ></Bug>

    return null
}

export const FetchData = ({ children, method, triggerValues = [], showLoader=true }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState();
    const debug = isInDebug('FetchData');

    notNull('children', children);

    useEffect(() => {
        let didCancel = false;
 
        const forceSync = async () => {

            try {
                
                if (method) {
                    !didCancel && setIsLoading(true);
                    const response = await method();
                    !didCancel && setIsLoading(false);
                    debug && console.log('done waiting')
                    !didCancel && setData(response);
                    !didCancel && setError(null);
                }
                else {
                    console.warn('FetchData was used without an api method. Did you forget to specify one?');
                }                
            }
            catch (error) {
                debug && console.error('something went wrong', error);
    
                if (error.response) {
                    // Request made and server responded
                    debug && console.log(error.response.data);
                    debug && console.log(error.response.status);
                    debug && console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received, happens in case of CORS issues (fe)
                    debug && console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    debug && console.log('Error', error.message);
                }    
    
                !didCancel && setError(error);
                !didCancel && setIsLoading(false);
                !didCancel && setData(null);
            }
    
        }

        forceSync();

        return () => {
            didCancel = true;
          };

    }, triggerValues);

    if (error && error.response && error.response.status === 401){
        const {config} = error.response;

        const {baseURL, url} = config;
        const route = url.replace(baseURL, '');
                
        console.log(route);
        
        if (route.startsWith('/api/portal')){
            return <Redirect to={`/portal/login`} />
        }
        else {
            return <Redirect to={`/login`} />
        }

        
    }

    return (<Fragment>
        {children({ loading: isLoading, error, data })}
    </Fragment>)
}