import React, { useContext } from 'react';
import * as Yup from 'yup';
import * as moment from 'moment';
import { addDays } from 'date-fns'
import { Formik } from 'formik';
import { SupplierField, SelectPaymentMethod } from './_amo_/index'
import { Input, Field, FormField, Button, SidePanelContext } from "../..";
import { BUTTON_SIZE, CONTROL_SIZE } from "../../constants";

import { purchaseInvoiceDefaultState } from "./_amo_/initialdata";
import { DateField } from "../../03_molecules/date/DateField";
import { isInDebug } from '../../00_global/isInDebug';
import { ShowObject } from '../../00_global/ShowObject';
import { notNull, Control } from '../..'

import { dataservice } from '../../00_global/service/dataservice';
import { withRouter } from "react-router-dom";
import { routeWithSlug } from '../../00_global/'
import { DateTime } from '../../02_atoms/date/DateTime';
import { CurrencyConversionPanel } from './CurrencyConversionPanel'

const debug = isInDebug('Inbox');

const formValidationSchema = Yup.object().shape({
    supplier_searchQuery: Yup.string()
        .required('Verplicht veld!'),
    documentDate: Yup.string()
        .required('Verplicht veld!'),
    documentAmount: Yup.string()
        .required('Verplicht veld!')
        .test("canBeParsed", "Dit lijkt geen geldig bedrag", val => {
            if (val) {
                const safeAmount = val.replace(',', '.').replace(' ', '');
                if (isNaN(safeAmount))
                    return false;

                const splitted = safeAmount.split('.');
                if (splitted.length > 2) {
                    return false;
                }

                return true;
            }
            return true;
        }),
    documentVat: Yup.string()
        .test("canBeParsed", "Dit lijkt geen geldig bedrag", val => {
            if (val) {
                const safeAmount = val.replace(',', '.').replace(' ', '');
                if (isNaN(safeAmount))
                    return false;

                const splitted = safeAmount.split('.');
                if (splitted.length > 2) {
                    return false;
                }
                return true;
            }
            return true;
        })
    
});


const AnnotateExpenseElement = ({ documentId, document, history }) => {

    notNull(documentId, 'documentId');
    notNull(history, 'history')   

    const { addedOn } = document;

    const onSubmit = async (values, actions) => {
        console.log('submitting')
        try {
            await dataservice.annotatePurchaseInvoice({ ...values, documentId: documentId })
            history.push(routeWithSlug('inbox'));
        }
        finally {
            actions.setSubmitting(false);
        }
    }

    const handleValidate = (values) => {
        const { supplierId, paymentMethod, dueDate } = values;

        let errors = {};

        if (!supplierId) {
            errors['supplier_searchQuery'] = 'Kies een leverancier';
        }

        if (paymentMethod === 'Nog niet betaald' && !dueDate) {
            errors['dueDate'] = 'Vervaldatum is verplicht';
        }
        return errors;
    }

    const sidepanelCtx = useContext(SidePanelContext);
    const { add } = sidepanelCtx;

    const onCurrencySettingsChanged = (values) => {
        console.log('CC changed', values);
    }

    const showCCPanel = (values) => {
        console.log('values', values);
        const documentDate = values['documentDate'] ? values['documentDate'] : addedOn;
        const addSupplier = <CurrencyConversionPanel amount={values['documentAmount']} currency={values['currency']} documentDate={documentDate} onSettingsChanged={onCurrencySettingsChanged} />
        add(addSupplier, true);
    }

    return (
        <Formik
            initialValues={purchaseInvoiceDefaultState}
            validationSchema={formValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            validate={handleValidate}
            onSubmit={(values, actions) => {
                onSubmit(values, actions);
            }}>
            {
                (formikProps) => {

                    const { isSubmitting, setFieldValue, handleSubmit, errors, values} = formikProps;
                    const selectedSupplier = values['supplierId'];
                    const selectedPaymentMethod = values['paymentMethod'];
                    const disablePaymentFields = selectedPaymentMethod !== 'Nog niet betaald'

                    const onDocumentDateSelected = (date) => {
                        const fieldName = 'documentDate'
                        moment(date).isValid()
                            ? setFieldValue(fieldName, moment(date).format())
                            : setFieldValue(fieldName, null);
                    };

                    const onDueDateSelected = (date) => {
                        const fieldName = 'dueDate'
                        moment(date).isValid()
                            ? setFieldValue(fieldName, moment(date).format())
                            : setFieldValue(fieldName, null);
                    };

                    const parseAmount = (amount, fieldName) => {
                        const safeAmount = amount.replace(',', '.').replace(' ', '');
                        debug && console.log('safe amount', safeAmount);
                        setFieldValue(fieldName, safeAmount);
                    }

                    return (
                        <form className="form" onSubmit={handleSubmit}>
                            <input type="hidden" value="disable_autocomplete" />
                            <FormField>
                                <Field id={'supplier_searchQuery'} infoMessage={!selectedSupplier && 'Typ om te zoeken'} header={'Leverancier *'} errorMessage={errors['supplierId']}>
                                    <SupplierField propertyName={'supplier_searchQuery'} selectedValueId={'supplierId'} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'documentDate'} header={'Factuur datum *'} infoMessage={<>Doorgestuurd op <DateTime dateValue={addedOn} /></>}>
                                    <DateField placeholder={'factuur datum'} propertyName={'documentDateFormValue'} onSelect={onDocumentDateSelected} maxDate={addDays(new Date(), 15)} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'paymentMethod'} header={'Betaalmethode'}>
                                    <SelectPaymentMethod id={'paymentMethodSelect'} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'dueDate'} header={'Verval datum'} infoMessage={disablePaymentFields && 'OPTIONEEL'} disabled={disablePaymentFields}>
                                    <DateField placeholder={'Verval datum'} disabled={disablePaymentFields} propertyName={'dueDateFormValue'} onSelect={onDueDateSelected} maxDate={addDays(new Date(), 60)} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'paymentReference'} header={'Mededeling'} infoMessage={disablePaymentFields && 'OPTIONEEL'}>
                                    <Input />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field onChange={(e) => parseAmount(e.target.value, 'documentAmount')} id={'documentAmount'} header={'Totaal *'} infoMessage={'incl. btw in EUR'}>
                                    <Input control={false && <Control size={CONTROL_SIZE.small} onClick={() => showCCPanel(values)}>EUR</Control>} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field onChange={(e) => parseAmount(e.target.value, 'documentVat')} id={'documentVat'} header={'BTW bedrag'} infoMessage={'aangerekende BTW'}>
                                    <Input />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'documentReference'} header={'Referentie'} infoMessage={'factuur nr, contract nr, ...'}>
                                    <Input />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'description'} header={'Info'} infoMessage={'eigen notities'}>
                                    <Input />
                                </Field>
                            </FormField>

                            <FormField>
                                <Button
                                    type="submit"
                                    size={BUTTON_SIZE.full}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >Verzenden</Button>
                            </FormField>
                            {debug && <ShowObject value={formikProps} />}
                        </form>
                    )
                }
            }
        </Formik>
    )
};

export const AnnotateExpense = withRouter(AnnotateExpenseElement);