import React, { useState, useContext } from 'react';
import { withRouter } from "react-router-dom";
import { Box, BoxHeader, SidePanelContext, Bug, Input, Field, FormField, Button, Checkbox } from '../../';
import { isInDebug } from '../../00_global/isInDebug';
import { Formik } from 'formik';
import { Heading } from '../../02_atoms/headings/Heading';
import { HEADING_SIZE, PARAGRAPH_SIZE, PARAGRAPH_SPACING } from '../../constants';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { FetchData } from '../../00_global/FetchData';
import { dataservice } from '../../00_global/service/dataservice';
import { notNull, routeWithSlug } from '../../00_global';

const debug = isInDebug('Inbox') && isInDebug('SupplierField');
const buttonText = 'Exporteren';

const ExportInfo = ({ notificationAddress }) => {
    return (
        <Box noBorder color="bgc--gray-10">
                                    <Heading size={HEADING_SIZE.small}>Hoe werkt het?</Heading>
                                    <Paragraph spacing={PARAGRAPH_SPACING.small}>
                                    </Paragraph>
                                    <Paragraph color={'fc--gray-4'} spacing={PARAGRAPH_SPACING.xs} size={PARAGRAPH_SIZE.small}>
                                        Nadat je op [{buttonText}] klikt maken we voor de geselecteerde bestanden een .zip bestand. We voegen aan elk
                                        document een extra pagina toe met de informatie die jij tijdens het importeren hebt opgegeven.
                                    </Paragraph>
                                    <Paragraph color={'fc--gray-4'} spacing={PARAGRAPH_SPACING.xs} size={PARAGRAPH_SIZE.small}>
                                        Het samenstellen van dit archief kan enkele minuten duren. We sturen je een mailtje op <b>{notificationAddress}</b> met een link naar je archief.
                                    </Paragraph>
                                </Box>
    )
}

const ConfirmExportElement = ({ selectedDocuments, history }) => {

    notNull(history, 'history', 'history not available, make sure your component is wrapped in a withRouter')
    notNull(selectedDocuments, 'selectedDocuments');

    const ctx = useContext(SidePanelContext);
    debug && console.log(ctx);
    const [error, setError] = useState('');

    const onSubmit = async (values, actions) => {
        const { description, enableEnrichDocument } = values;

        debug && console.log(description);

        try {
            setError(null);
            const response = await dataservice.createArchive(selectedDocuments, description, enableEnrichDocument);
            const { archiveId } = response;
            if (archiveId) {
                history.push(routeWithSlug('export/history'));
                ctx.close();
            }

        }
        catch (err) {
            setError(err);
        }
        finally {
            actions.setSubmitting(false);
        }
    }



    return (
        <>
            <Box plain={true} header={
                <BoxHeader
                    title={`Je hebt ${selectedDocuments.length} documenten geselecteerd`}
                    subTitle={'Je kan hieronder een omschrijving van deze export opgeven.'}
                />}>
                <Formik
                    initialValues={{ description: '', enableEnrichDocument: true }}
                    //validationSchema={formValidationSchema}
                    //validateOnChange={true}
                    //validateOnBlur={true}
                    //validate={handleValidate}
                    onSubmit={async (values, actions) => {
                        await onSubmit(values, actions);
                        actions.setSubmitting(false);
                    }}>
                    {
                        (formikProps) => {
                            const { isSubmitting, handleSubmit } = formikProps;
                            return (
                                <>
                                    {!error &&
                                        <>
                                            <form className="form" onSubmit={handleSubmit}>
                                                <FormField>
                                                    <Field id={'description'} infoMessage={'Optioneel'} header={'Omschrijving'}>
                                                        <Input />
                                                    </Field>
                                                </FormField>
                                                <FormField>
                                                    <Field id={'enableEnrichDocument'} infoMessage={''} header={'Info pagina'}>
                                                        <Checkbox label='Eigen gegevens toevoegen als bijlage?' />
                                                    </Field>
                                                </FormField>
                                                <FormField><Button type={'submit'} disabled={isSubmitting || selectedDocuments.length === 0}>{buttonText}</Button></FormField>
                                            </form>
                                        </>
                                    }
                                    {error && <Bug error={error} />}
                                </>
                            )
                        }
                    }
                </Formik>
            </Box>
            <FetchData method={dataservice.getUserProfile}>
                {
                    ({ data }) => {
                        if (data) {
                            return (<ExportInfo notificationAddress={data.notificationAddress} />)
                        }

                    }
                }
            </FetchData>

        </>
    )
}

export const ConfirmExport = withRouter(ConfirmExportElement);