import React from 'react';
import '../../03_molecules/table/styles/_table.scss'
import cx from 'classnames';
import { SidePanelManager, ContentContainer, Heading } from '../../index';
import { Ellipsis, DateTime } from '../../index';
import { notNull } from '../../00_global/notNull';
import { FetchData } from '../../00_global/FetchData';
import { ColumnHeader } from './TableElements';
import { dataservice } from '../../00_global/service/dataservice';
import { Bug, ArchiveEmpty } from '../../03_molecules/visualFeedback/VisualFeedback';
import { HEADING_SIZE, HEADING_SPACING } from '../../constants';

const columnDefinitions = [
    { property: 'requestedOn', headerText: 'Aangemaakt op', sortable: false, large: 'size-large-10' },
    { property: 'status', headerText: 'Status', sortable: false, large: 'size-large-10' },
    { property: 'description', headerText: 'Omschrijving', sortable: false, large: 'size-large-40' },
    { property: 'validUntil', headerText: 'Geldig tot', sortable: false, large: 'size-large-10' },
    { property: 'downloadLink', headerText: 'Link', sortable: false, large: 'size-large-30', hideOnMedium: false }
]

export const ExportOverview = () => {
    const main = <ExportOverviewMain />
    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}

const ExportOverviewMain = () => {

    const style = cx('table')

    return (
        <FetchData method={dataservice.getActiveArchives}>
            {
                ({ data, error, loading }) => {

                    if (data && data.length > 0) {

                        const trs = data.map(archive => <TableRow key={archive.archiveId} archive={archive} />)

                        return (
                            <>
                                <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.medium}>
                                    Historiek
                                </Heading>
                                <div className={style}>
                                    <div className="table__inner">
                                        <TableHeader />
                                        <div className="table__body">
                                            {trs}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    else if (data && data.length === 0){
                        return <ArchiveEmpty />
                    }
                    else if (error) {
                        return <Bug error={error} />
                    }

                }
            }
        </FetchData>
    )
}

const TableHeader = () => {
    const columns = columnDefinitions.map(cd => <ColumnHeader key={cd.headerText} text={cd.headerText} sortable={cd.sortable} medium={cd.medium} large={cd.large} hideOnMedium={cd.hideOnMedium} />)

    return (
        <div className="table__head">
            <div className="table__row">
                {columns}
            </div>
        </div>
    )
}

const TableRow = ({ archive }) => {

    const rowStyle = cx('table__row',
        { 'is-clickable': false },
        { 'is-selected': false }
    )

    const {
        //archiveName,
        description,
        status,
        validUntil,
        requestedOn,
        downloadLink,
    } = archive;

    const fod = (propertyName) => {
        const first = columnDefinitions.filter(def => def.property === propertyName)[0];
        notNull(first, 'item', `item with property ${propertyName} not found in definitions`);
        return first;
    }

    const getStatus = (statusType) => {
        switch (statusType) {
            case 1: return 'wordt verwerkt'
            case 2: return 'klaar'
            default: return ''
        }
    }

    return (
        <div className={rowStyle}>
            <TableCell definition={fod('requestedOn')}>
                <Ellipsis text={<DateTime dateValue={requestedOn} />} />
            </TableCell>
            <TableCell definition={fod('status')}>
                <Ellipsis text={getStatus(status)} />
            </TableCell>
            <TableCell definition={fod('description')}>
                <Ellipsis text={description} />
            </TableCell>
            <TableCell definition={fod('validUntil')}>
                <Ellipsis text={<DateTime dateValue={validUntil} />} />
            </TableCell>
            <TableCell definition={fod('downloadLink')}>
                {downloadLink && <a href={downloadLink}><Ellipsis text={'download'} /></a>}
            </TableCell>
        </div>
    )
}

export const TableCell = ({ definition, children }) => {

    const { hideOnMedium, medium, large, property, align } = definition;

    const style = cx(
        'table__cell',
        { 'hide-medium': hideOnMedium === true },
        { 'align-right': align === 'right' },
        [medium],
        [large]
    )
    return (
        <div data-field={property} className={style}>
            {children}
        </div>
    )
}