import React, { useContext, useState } from 'react';
import './_invoice-v2.scss';
import { FormField, Heading, Paragraph } from '../../index';
import { HEADING_SIZE, HEADING_WEIGHT, PARAGRAPH_SIZE, PARAGRAPH_WEIGHT } from "../../constants";
import { notNull } from '../../00_global';
import { InvoiceDataContext } from '.';
import { Amount } from '../../02_atoms/amount/Amount';
import { InvoiceActions } from './InvoiceActions';

interface InvoiceTotalsProps {
	pinned?: boolean,
	discount?: boolean,
	showLoader: boolean,
	onDeleteInvoice(): void,
	onShowPreview():void,
	onSendInvoice(): void,
	onDownload(): void,
}

export const InvoiceTotals = ({ pinned = true, discount = false, onDeleteInvoice, onShowPreview, onSendInvoice, showLoader, onDownload }: InvoiceTotalsProps) => {

	const dataContext = useContext(InvoiceDataContext);

	notNull(dataContext, 'dataContext @ AddInvoiceForm');
	const { invoiceData } = dataContext;

	if (!invoiceData)
		throw Error('invoice missing');

	let totalGross = 0;
	let totalNet = 0;
	let totalVat = 0;

	if (invoiceData.totals) {
		totalGross = invoiceData.totals.totalGross;
		totalNet = invoiceData.totals.totalNet;
		totalVat = invoiceData.totals.totalVat;
	}

	const columnSizesDefault = (discount) ? [3, 3, 3, 3] : [3, 0, 4, 5];
	const columnSizesPinned = (discount) ? [3, 3, 3, 3] : [4, 0, 4, 4];
	const alterLayout = (pinned || discount);
	const columnSizes = (alterLayout) ? columnSizesPinned : columnSizesDefault;

	const disabledActions = (!totalGross || totalGross === 0);

	return (
		<FormField largeGap={true}>
			<div className="invoice__totals">
				<div className="invoice__totalRow row">
					<div className={`col-lg-${(alterLayout) ? 12 : 7} col-xl-7`}>
						<div className="row">
							<div className={`col-md-${columnSizes[0]}`}>
								<Paragraph size={PARAGRAPH_SIZE.large} weight={PARAGRAPH_WEIGHT.bold}>
									<Amount value={totalNet} />
									<button className="invoice__infoBtn">
										<i className="material-icons">help_outline</i>
									</button>
								</Paragraph>
								<Paragraph size={PARAGRAPH_SIZE.small} weight={PARAGRAPH_WEIGHT.normal}>
									Subtotaal
								</Paragraph>
							</div>
							{discount &&
								<div className={`col-md-${columnSizes[1]}`}>
									<Paragraph size={PARAGRAPH_SIZE.large} weight={PARAGRAPH_WEIGHT.bold}>
										<Amount value={totalNet} />
										<button className="invoice__infoBtn">
											<i className="material-icons">help_outline</i>
										</button>
									</Paragraph>
									<Paragraph size={PARAGRAPH_SIZE.small} weight={PARAGRAPH_WEIGHT.normal}>
										Korting
									</Paragraph>
								</div>
							}
							<div className={`col-md-${columnSizes[2]}`}>
								<Paragraph size={PARAGRAPH_SIZE.large} weight={PARAGRAPH_WEIGHT.bold}>
									<Amount value={totalVat} />
									<button className="invoice__infoBtn">
										<i className="material-icons">help_outline</i>
									</button>
								</Paragraph>
								<Paragraph size={PARAGRAPH_SIZE.small} weight={PARAGRAPH_WEIGHT.normal}>
									BTW
								</Paragraph>
							</div>
							<div className={`col-md-${columnSizes[3]} invoice__totalsTotal`}>
								<Heading size={HEADING_SIZE.large} weight={HEADING_WEIGHT.bold} color="fc--primary-1">
									<Amount value={totalGross} />
								</Heading>
								<Paragraph size={PARAGRAPH_SIZE.small} weight={PARAGRAPH_WEIGHT.normal}>
									Eindtotaal
								</Paragraph>
							</div>
						</div>
					</div>
					<div className={`col-lg-${(alterLayout) ? 12 : 5} col-xl-5 text-right`}>
						<InvoiceActions invoice={invoiceData}
							deleteAction={{ isDisabled: invoiceData.invoiceStatus > 1, onClick: onDeleteInvoice }}
							preview={{ isLoading: showLoader, isDisabled: disabledActions, onClick: onShowPreview}}
							send={{ isDisabled: disabledActions, onClick: onSendInvoice }}
							scheduleSend={{ isDisabled: disabledActions, onClick: () => { } }}
							downloadAction={{isDisabled: disabledActions, onClick: onDownload}}
						/>
					</div>
				</div>
			</div>
		</FormField>
	)
};