import React, { Fragment, useContext, useState, useRef, useEffect } from 'react';
import { SidePanelContext, Button } from '../../'
import { SearchResultListItem } from './SearchResultListItem';
import { FetchData } from '../../00_global/FetchData';
import { dataservice } from '../../00_global/service/dataservice';
import { ShowObject } from '../../00_global/ShowObject';
import { BoxedSearchResult } from '../../04_organisms/boxedSearchResult/BoxedSearchResult';
import { SearchDocumentDetail } from './SearchDocumentDetail';
import { Bug, NoData2 } from '../../03_molecules/visualFeedback/VisualFeedback';
import { isInDebug } from '../../00_global/isInDebug';
import { withRouter } from "react-router-dom";
//import { LoaderContainer } from '../../03_molecules/loaderContainer/LoaderContainer'; // bug: hides content
import { SearchBar } from './SearchBar';

const getSearchQueryFromUrl = (location) => {
	// todo: improve
	return decodeURIComponent(location.search.replace('?q=', ''));
}

const ArchiveListElement = ({ history, location }) => {
	const debug = isInDebug('Search');

	//const [currentDetail, setCurrentDetail] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [forceUpdate, setForceUpdate] = useState(0);

	// use ref for detecting external change to url param
	const ref = useRef({ search: '', searchBarKey: 'initialSearchBarKey' });

	const { add, close } = useContext(SidePanelContext);

	useEffect(() => {
		if (ref.current.search !== location.search) {
			ref.current.searchBarKey = (new Date()).getTime();
			search(getSearchQueryFromUrl(location));
		}
	})

	const search = (query) => {
		// reset page
		setCurrentPage(1);
		setSearchQuery(query);

		// update url querystring (if needed)
		const search = query ? '?q=' + encodeURIComponent(query) : '';
		if (location.search !== search) {
			history.push({ search });
		}

		// update ref to latest search command
		ref.current = Object.assign({}, ref.current, { search });
	}

	const handleDocumentDeleted = () => {
		// fetch data
		setForceUpdate(forceUpdate +1);

		// close detail
		close();
	}

	const onShowDetail = (documentId) => {
		const sp = <SearchDocumentDetail documentId={documentId} onDocumentDeleted={handleDocumentDeleted} />
			add(sp, true); // blocking, because bug
			//setCurrentDetail(documentId);
	}

	return <Fragment>
		<FetchData method={() => dataservice.searchDocuments(searchQuery, currentPage)} triggerValues={[searchQuery, currentPage, forceUpdate]} >{
			({ error, data, loading }) => {
				if (error) {
					return <Bug error={error} />
				}
				if (data) {
					const srm = data.documents.length > 0
						? data.documents.map((doc) => <SearchResultListItem key={doc.documentId} document={doc} onShowDetail={() => onShowDetail(doc.documentId)} />)
						: null;

					const nextAvailable = data.pages > currentPage;
					const previousAvailable = currentPage > 1;

					const userFeedback = searchQuery.length > 0
						? <NoData2 title={`Geen resultaten gevonden voor '${searchQuery}'`} subTitle={'Pas je zoekwoorden aan'} />
						: <NoData2 title={'Geen documenten gevonden'} subTitle={'Zodra je documenten hebt doorgestuurd kan je ze hier terugvinden'} />

					return (<Fragment>
						{ /* We use the key on searchbar to force a new instance and so accept new initial value */ }
						<SearchBar onSearchQueryChanged={(query) => search(query)} initialValue={searchQuery} key={'searchbar_' + ref.current.searchBarKey} />

						{ srm &&
						<BoxedSearchResult>
						<div className="row">
							{srm}
						</div>
						</BoxedSearchResult>
						}

						{srm && <div className="row">
							<div className="col-sm-12 col-md-12 col-lg-12" style={{'textAlign': 'center'}}>
							<Button onClick={() => setCurrentPage(currentPage-1)} disabled={!previousAvailable}>Vorige</Button> &nbsp;
							<Button onClick={() => setCurrentPage(currentPage+1)} disabled={!nextAvailable}>Volgende</Button>
					 		</div>

						</div>}
						{!srm && userFeedback}
						{debug && <ShowObject value={data} />}
					</Fragment>)
				}


			}
		}</FetchData>
	</Fragment>
}

export const ArchiveList = withRouter(ArchiveListElement)