import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from './components/05_buckets/login/Login';
import { ResetPwd } from './components/05_buckets/login/ResetPwd';
import { PortalLogin } from './components/05_buckets/login/PortalLogin';
import { SluggedPrivateRoutes } from './SluggedPrivateRoutes';
import { Onboarding } from './components';
import { InvoicePDF } from './components/06_features/invoice_module_v1/pdf/_InvoicePdf.tsx';
import { AccountancyPortalRoutes } from './components/00_global/AccountancyPortalRoutes';

class App extends Component{ 
  render() {
    return (
      <Switch>
        <Route path="/invoices/print/:invoiceId" exact component={InvoicePDF} />
        <Route path="/login" exact component={Login} />
        <Route path="/reset-password" exact component={ResetPwd} />
        <Route path="/create-account" exact component={Onboarding} />
        <Route path="/portal/login" exact component={PortalLogin} />
        <Route path="/portal/" component={AccountancyPortalRoutes} />
        <PrivateRoute path="/" component={SluggedPrivateRoutes} />
      </Switch>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      sessionStorage.getItem('token') ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default App;
