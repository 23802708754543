import React, { useState, useContext } from 'react';
import { SidePanelManager, ContentContainer, Control, SidePanelContext, Heading, Button, FetchDataState } from "../../.."
import { FetchData } from '../../../00_global';
import { productService, getListResponse } from '../invoiceService';
import { InvoiceTemplateDto } from '../models/invoiceSettings';
import { WhenData } from '../../../00_global/FetchData';
import { Table, TableHeader, TableBody } from '../../export/TableHeader';
import { getColumnDefinition } from '../../export/getColumnDefinition';
import { TableCell } from '../../export/ExportOverview';
import { TableRow, TextCell } from '../../../03_molecules/table/TableRow';
import { invoiceTemplateFormFields, CreateInvoiceTemplate } from './CreateTemplateForm';
import { CONTROL_SIZE, HEADING_SIZE, HEADING_SPACING, COLOR } from '../../../constants';
import { ConfirmationV2 } from '../../../03_molecules/confirmation/ConfirmationV2';
import { defaultPinProps, defaultCloseProps } from '../CreateInvoice';
import { ISidePanelContext } from '../../../08_invoices/InvoiceModule';
import { UpdateInvoiceTemplate } from './UpdateInvoiceTemplate';
import { NoData1 } from '../../../03_molecules/visualFeedback/VisualFeedback';

const widths = [10, 10, 10, 10, 10]

const rem = 100 - widths.reduce((prev, curr) => prev + curr);

const fields = invoiceTemplateFormFields;

if (rem < 10)
    throw Error('not enough space');

const idDef = getColumnDefinition(fields.templateName, 'Template', widths[0]);
const companyNameDef = getColumnDefinition(fields.companyName, 'Bedrijf', widths[1]);
const vatDef = getColumnDefinition(fields.taxIdentificationNumber, 'BTW nr', widths[2]);
const addressDef = getColumnDefinition('address', 'Adres', rem);
const emailDef = getColumnDefinition(fields.emailAddress, 'Email', widths[3]);
const actionsDef = getColumnDefinition('_actions_', '', widths[4], 'right')

const columnDefinitions = [
    idDef,
    companyNameDef,
    vatDef,
    addressDef,
    emailDef,
]

const InvoiceTemplateOverviewElement = () => {

    const [version, setVersion] = useState(0);
    const panelContext = useContext<ISidePanelContext>(SidePanelContext);
    const { add, close } = panelContext;

    const showSidepanel = (content: any) => {
        add(content, false, defaultPinProps, defaultCloseProps);
    }

    const handleEditTemplate = (id: string) => {
        // open panel
        const panel = <UpdateInvoiceTemplate templateId={id} onUpdateTemplate={handleUpdateTemplate} />
        showSidepanel(panel);
    }

    const refresh = () => {
        setVersion(version + 1);
    }

    const deleteTemplate = async (id: string) => {
        await productService.deleteTemplate(id);
        console.log('deleted!')
        refresh();
    }

    const handleUpdateTemplate = async (item: InvoiceTemplateDto) => {
        const result = await productService.updateTemplateAsync(item);
        if (result.status === 0) {
            close();
            refresh();
        }
    }

    const handleCreateTemplate = async (item: InvoiceTemplateDto) => {
        await productService.createInvoiceTemplateAsync(item);
        close();
        refresh();
    }

    const handleCreateButtonClicked = () => {
        // create new template
        const panel = <CreateInvoiceTemplate onSaveTemplate={handleCreateTemplate} />
        showSidepanel(panel);
    }

    return (
        <>
            <div className="moduleHeader">
                <div className="row" style={{ alignItems: 'flex-end' }}>
                    <div className="col-md-7">
                        <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.small}>
                            Factuur instellingen
                        </Heading>
                        <Button inverted color={COLOR.secondary_2} onClick={handleCreateButtonClicked}>
                            <i className="material-icons">add</i>
                            Nieuwe template
                        </Button>
                    </div>
                </div>
            </div>
            <FetchData method={productService.getTemplatesAsync} triggerValues={[version]}>
                {
                    (fdp: FetchDataState<getListResponse<InvoiceTemplateDto>>) => {
                        const { data, error, loading } = fdp;
                        return <WhenData
                            data={data}
                            error={error}
                            loading={loading}
                            noData={data && data.items && data.items.length === 0}
                            noDataContent={
                                <NoData1 title={'Er zijn nog geen templates'} subTitle={'Je moet eerst een sjabloon aanmaken voordat we een geldige factuur kunnen opstellen. Geen zorgen, binnen 3 minuten maak je je eerste factuur!'}
                                    ctaButton={<Button inverted color={COLOR.secondary_2} onClick={handleCreateButtonClicked}>
                                        <i className="material-icons">add</i>
                                    Nieuwe template
                            </Button>} />}
                        >
                            {() =>
                                <Table>
                                    <TableHeader columnDefinitions={columnDefinitions} />
                                    <TableBody>
                                        {data.items.map(x =>
                                            <InvoiceTemplateRow
                                                key={x.id}
                                                template={x}
                                                onDeleteConfirmed={deleteTemplate}
                                                onEditClicked={handleEditTemplate}
                                            />)}
                                    </TableBody>
                                </Table>
                            }
                        </WhenData>
                    }
                }
            </FetchData>
        </>
    )
}

export const InvoiceTemplateOverview = () => {
    return (
        <SidePanelManager>
            <ContentContainer main={<InvoiceTemplateOverviewElement />} />
        </SidePanelManager>
    )
}

interface InvoiceTemplateRowProps {
    template: InvoiceTemplateDto,
    onDeleteConfirmed(templateId: string): void
    onEditClicked(templateId: string): void
}

export const InvoiceTemplateRow = ({ template, onEditClicked, onDeleteConfirmed }: InvoiceTemplateRowProps) => {

    if (!template.id)
        throw Error('id missing');

    const templateId = template.id;
    const address = template.from.address.map((a, i) => <span key={i}>{a} &nbsp;</span>)

    return (
        <TableRow key={templateId}>
            <TextCell definition={idDef} caption={template.name} />
            <TextCell definition={companyNameDef} caption={template.from.name} />
            <TextCell key={vatDef.property} definition={vatDef} caption={template.from.taxIdentificationNumber} />
            <TextCell key={addressDef.property} definition={addressDef} caption={address} />
            <TextCell key={emailDef.property} definition={emailDef} caption={template.from.emailAddress} />
            <TableCell key={actionsDef.property} definition={actionsDef}>
                <Control size={CONTROL_SIZE.small} isGhost onClick={() => onEditClicked(templateId)}>
                    <i key={'edit'} className="material-icons">edit</i>
                </Control>
                &nbsp;
                <ConfirmationV2 message={'Je kan deze actie niet ongedaan maken. Deze template kan je hierna niet meer gebruiken om nieuwe facturen op te stellen'}
                    align="right"
                    valign="middle"
                    onDeleteConfirmed={() => onDeleteConfirmed(templateId)}
                />
            </TableCell>
        </TableRow>

    )
}

