import React, { Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { NotFound } from "../../components";
import { UpdateAccount, ListAccounts, AccountantInbox, SingleSignOn } from '../07_portal-features';
import { AccountancyPortal } from '../01_fundaments/app/TriveApp';
import { Messages } from '../07_portal-features/messages/MessagesSoon';

const Routes = (props) => {

    const { history } = props;

    return (
        <AccountancyPortal>
            <Fragment>
                <Switch>
                    {/* <Route path={`/portal/`} exact component={ShowContent} /> */}
                    <Route path={'/portal/overview'} exact component={ListAccounts} />
                    <Route path={'/portal/account/:accountId/settings'} exact component={UpdateAccount} />
                    <Route path={'/portal/account/:accountId/inbox/'} exact component={AccountantInbox} />
                    <Route path={'/portal/account/:accountId/queue/:documentType'} exact component={AccountantInbox} />
                    <Route path={'/portal/account/:accountId/inbox/:documentId'} exact component={AccountantInbox} />
                    <Route path={'/portal/account/:accountId/sso'} exact component={SingleSignOn} />  
                    <Route path={'/portal/messages/'} component={Messages} />
                    <Route path={`**`} render={() => (<NotFound onNavigateBack={history.goBack} />)} />
                </Switch>
            </Fragment>
        </AccountancyPortal>
    );
};

export const AccountancyPortalRoutes = withRouter(Routes);


