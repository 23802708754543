import React from 'react';
import './styles/_notification.scss';
import {Control} from "../../";
import {CONTROL_SIZE} from "../../constants";

import cx from "classnames";
import {CloseIcon} from "../../01_fundaments/icons";

export const Notification = ({ caption, onClose, success, error}) => {

    const style = cx('notification',
        {'bgc--success': success},
        {'bgc--error': error},
    );

    return (
    <div className={style}>
        <div className="notification__inner">
            <div className="notification__content">
                {caption}
            </div>
                <div className="notification__control">
                    <Control isPlain size={CONTROL_SIZE.small} onClick={onClose}>
                        <CloseIcon />
                    </Control>
                </div>
            </div>
    </div>)
};

