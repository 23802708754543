import React from 'react';
import { InboxEmpty } from "../..";
import { Redirect } from "react-router-dom";
import { dataservice } from "../../00_global/service/dataservice";
import { FetchData } from '../../00_global/FetchData';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';

export const InboxQueue = () => (<FetchData method={dataservice.getInboxQueue}>
	{({ error, data, loading }) => {
		if (data) {
			
			const list = data;

			if (!list.length) {
				return (<InboxEmpty />);
			}

			return (
				<Redirect to={`/inbox/${list[0].value}`} />
			);
		}
		if (error) {
			return (
				<Bug error={error} />
			)
		}
	}}
</FetchData>);