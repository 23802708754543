import React from 'react';
import { ShowObject, Box, Heading, Ellipsis, FormField, Button } from '../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING } from '../../constants';
import { Formik, FormikProps } from 'formik';
import { SearchItem } from '../../03_molecules/search/SearchV2';
import { Dropdown } from '../invoice_module_v1/Dropdown';
import { isInDebug } from '../../00_global/isInDebug';
import { InputField } from '../invoice_module_v1/InputField';
import { productService } from '../invoice_module_v1/invoiceService';
import { invoiceFeatures } from '../invoice_module_v1/features';
import { CustomerDetailDto, CreateCustomerDto } from '../invoice_module_v1/models/api';


interface CreateCustomerPanelProps {
    onCustomerCreated(customer: CustomerDetailDto): void
    customerDetails?: CustomerDetailDto
}

export const CreateCustomerPanel = (props: CreateCustomerPanelProps) => {

    const fields = {
        companyName: 'companyName', //text
        addressLine1: 'addressLine1', //text
        addressLine2: 'addressLine2',//text
        countryCode: 'countryCode',
        taxIdentificationNumber: 'taxIdentificationNumber',
        searchByTaxId: 'searchByTaxId',
        generalEmail: 'generalEmail',
        contactFirstName: 'contactFirstName',
        contactSurName: 'contactSurName',
        defaultPaymentTermCaption: 'defaultPaymentTermCaption',
        defaultPaymentTerm: 'defaultPaymentTerm'
    }

    const {customerDetails} = props;

    const initialValues = {
        [fields.companyName]: customerDetails ? customerDetails.party.business.name : '',
        [fields.addressLine1]: customerDetails ? customerDetails.party.business.address[0] :'',
        [fields.addressLine2]: customerDetails ? customerDetails.party.business.address[1] : '',
        [fields.countryCode]: customerDetails ? customerDetails.party.business.countryCode : '',
        [fields.searchByTaxId]: customerDetails ? customerDetails.party.business.taxIdentificationNumber :'',
        [fields.taxIdentificationNumber]: customerDetails ? customerDetails.party.business.taxIdentificationNumber :'',
        [fields.generalEmail]:  customerDetails ? customerDetails.party.business.email : '',
        [fields.contactFirstName]: customerDetails ? customerDetails.toTheAttentionOf.firstName : '',
        [fields.contactSurName]:  customerDetails ? customerDetails.toTheAttentionOf.firstName : '',
    }

    const onSubmit = async (values: any, actions: any) => {
        // todo: create correct message, send to service
        const customer: CreateCustomerDto = {
            id: customerDetails ? customerDetails.id : undefined,
            invoiceDefaults: {
                paymentTerm: '30d'
            },
            party: {
                business: {
                    address: [
                        values[fields.addressLine1],
                        values[fields.addressLine2],
                    ],
                    countryCode: values[fields.countryCode],
                    email: values[fields.generalEmail],
                    name: values[fields.companyName],
                    taxIdentificationNumber: values[fields.taxIdentificationNumber]
                },
            },
            toTheAttentionOf: {
                firstName: values[fields.contactFirstName],
                surName: values[fields.contactSurName]
            }
        }

        actions.isSubmitting = false;

        if (props.customerDetails){
            // existing customer
            const result = await productService.updateCustomerAsync(customer);
            if (result.status === 0){
                props.onCustomerCreated(result.item);
            }
        }
        else {
            const result = await productService.createCustomerAsync(customer);
            if (result.status === 0){
                props.onCustomerCreated(result.item);
            }
        }
        // close panel
    }

    const noVatCaption = 'Niet van toepassing';

    const getItems = (input: string): SearchItem[] => {

        const addItemWhenSearching = false;
        const showEmptyWhenSearching = false;
        const vatSearchContainsInput = input !== '';

        const nvt = {
            id: 'void',
            value: '-',
            caption: noVatCaption,
            note: 'Indien het bedrijf geen BTW nummer heeft'
        }

        let list: SearchItem[] = []

        if (addItemWhenSearching && vatSearchContainsInput) {
            list.push({
                id: 'search',
                value: input,
                caption: input,
                note: 'Klik om te bevestigen'
            });
        }

        if (!vatSearchContainsInput) {
            list.push(nvt);
        }
        else if (showEmptyWhenSearching) {
            list.push(nvt);
        }

        return list;
    }

    const countryCodes: SearchItem[] = [
        { id: 'BE', value: 'BE', caption: 'Belgie', note: '' },
        { id: 'NL', value: 'NL', caption: 'Nederland', note: '' },
    ]

    const paymentTerms: SearchItem[]
        = [
            { id: 'immediately', value: 'immediately', caption: 'Bij ontvangst', note: '' },
            { id: '7d', value: '7d', caption: '7 dagen', note: '' },
            { id: '14d', value: '14d', caption: '14 dagen', note: '' },
            { id: '30d', value: '30d', caption: '30 dagen', note: '' },
        ]
    const debugInfo = isInDebug('InvoiceModule');

    const title = customerDetails ? 'Klant aanpassen' : 'Nieuwe klant';

    return (
        <>
                <Formik initialValues={initialValues}
                    validationSchema={undefined}
                    validateOnChange={true}
                    validateOnBlur={true}
                    // validate={handleValidate}
                    onSubmit={(values, actions) => {
                        onSubmit(values, actions);
                    }}>
                    {
                        (formikProps: FormikProps<any>) => {
                            const { handleSubmit, values, setFieldValue, isValid, isSubmitting } = formikProps

                            const setSelectedTaxId = (selected: SearchItem) => {
                                const { caption, value } = selected;
                                console.log('vat selected', selected);
                                if (value) {
                                    setFieldValue(fields.taxIdentificationNumber, selected.value);
                                }
                                else {
                                    setFieldValue(fields.taxIdentificationNumber, '')
                                }

                                setFieldValue(fields.searchByTaxId, caption);
                            }



                            const setTaxIdByTypedValue = () => {
                                // field is blurred, use entered text as value
                                const enteredValue = values[fields.searchByTaxId];

                                if (enteredValue !== noVatCaption)
                                    setFieldValue(fields.taxIdentificationNumber, enteredValue);
                            }

                            const searchByTaxIdResults = getItems(values[fields.searchByTaxId]);

                            return (
                                <form className="form" onSubmit={handleSubmit}>
                                    <Box id={'select_customer'} noBorder color="bgc--white">
                                        <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.xxs}>
                                            <Ellipsis>{title}</Ellipsis>
                                        </Heading>

                                        <InputField id={fields.companyName} header={'Naam klant'} formik={formikProps} />
                                        {!invoiceFeatures.canSearchKBO && <InputField id={fields.taxIdentificationNumber} header={'Ondernemingsnummer'} formik={formikProps} infoMessage={'Verplicht voor bedrijven'} /> }
                                        {invoiceFeatures.canSearchKBO && <Dropdown header={'BTW nummer'} id={'searchByTaxId'} results={searchByTaxIdResults} onBlur={() => setTaxIdByTypedValue()} onSearch={(q) => { }} onItemSelected={setSelectedTaxId} formik={formikProps} blockInput={false} infoMessage={'9 of 10 cijfers voor Belgische bedrijven'} />}
                                        <InputField id={fields.addressLine1} header={'Straat + nr'} formik={formikProps} />
                                        <InputField id={fields.addressLine2} header={'Postcode + woonplaats'} formik={formikProps} />
                                        <InputField id={fields.countryCode} header={'Land'} formik={formikProps} />
                                        {/* {invoiceFeatures.canSelectCountries && <Dropdown header={'Land'} id={fields.countryCode} results={countryCodes} onSearch={(q) => { console.log('searching', q) }} onItemSelected={setSelectedCountry} formik={formikProps} blockInput={true} />} */}
                                        <InputField id={fields.generalEmail} header={'Email'} formik={formikProps} />
                                    </Box>
                                    <Box id={'select_customer'} noBorder color="bgc--gray-10">
                                        <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.xxs}>
                                            <Ellipsis>{'Ter attentie van (optioneel)'}</Ellipsis>
                                        </Heading>
                                        <InputField id={fields.contactFirstName} header={'Voornaam'} formik={formikProps} />
                                        <InputField id={fields.contactSurName} header={'Naam'} formik={formikProps} />

                                        <FormField>
                                            <Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button>
                                        </FormField>
                                        {debugInfo && <ShowObject value={{ initialValues, values }} />}
                                    </Box>
                                </form>
                            )
                        }
                    }
                </Formik>

        </>
    )
}