import React from 'react'
import { COLOR_VALUE } from '../../constants';

export const Amount = ({ value = 0.00, color = COLOR_VALUE.inherit, empty = '', privacyMode=false}) => {

    const style = {
        color: color
    }

    if (privacyMode === true)
        style.filter = 'blur(4px)';

    const valueAsText = value !== null 
        ? value.toLocaleString('nl-BE', {style: 'currency', currency: 'EUR'})
        : empty

    return (
        <span style={style}>{valueAsText}</span>
    )
}