import React from 'react';
import './styles/_form.scss';
import cx from "classnames";

export const FormField = ({children, smallGap = false, largeGap = false, bottomGap=''}) => {

	const style = cx(
		'form__field',
		{'gap-small': smallGap === true},
		{'gap-large': largeGap === true},
		[bottomGap]
	);

	return (
		<div className={style}>
			{children}
		</div>
	)
};

export const Form = ({children}) => {

	const style = cx(
		'form'
	);

	return (
		<div className={style}>
			{children}
		</div>
	)
};