import React from 'react';
import { SidePanelManager, VisualFeedback } from '../../index';
import { ContentContainer } from '../../index';
import { dataservice } from '../../00_global/service/dataservice';
import { Button } from '../../02_atoms/buttons/Button';
import { withRouter } from "react-router-dom";
import { getAppVersion } from '../../00_global/appMode';
import { BUTTON_SIZE } from '../../constants';
import image from './undraw_finance.png';

const ShowConfirmation = ({ accountId, history }) => {

    const go = async () => {
        const version = getAppVersion();
        const result = await dataservice.portal_loginToAccount(accountId, version);
        console.log('result', result);
        history.push('/');
    }
    return (
        <>
            <VisualFeedback
                image={image}
                title={'Aanmelden als gebruiker'}
                subTitle={'Je verlaat het accountancy portaal in deze browser tab en start een sessie in het dossier van deze klant.'}
                ctaButton={<Button size={BUTTON_SIZE.medium} rounded={false} inverted={true} onClick={go}>Aanmelden</Button>}
            >
            </VisualFeedback>
        </>
    )
}


const SingleSignOnElement = ({ match, history }) => {

    const { accountId } = match.params;
    const main = <ShowConfirmation accountId={accountId} history={history} />

    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}

export const SingleSignOn = withRouter(SingleSignOnElement)