import React, { Fragment, useContext, useState, useEffect } from 'react';
import '../../03_molecules/table/styles/_table.scss'
import cx from 'classnames';
import { connect, getIn } from 'formik';
import { Ellipsis, DateTime, Amount, FromNow, isInDebug, Control } from '../../index';
import { notNull } from '../../00_global/notNull';
import { ShowObject } from '../../00_global/ShowObject';
import { StyledCheck } from '../../02_atoms/check/Check';
import { TableHeader } from './TableHeader';
import { DOCUMENTTYPE_ICONS } from '../../constants';
import { CONTROL_SIZE } from "../../constants";
import { SidePanelContext } from '../../';
import { SearchDocumentDetail } from '../archive/SearchDocumentDetail';

const debug = isInDebug('ExportDocuments');

const getIconFor = (documentTypeId) => {
    const selectedIconSetting = DOCUMENTTYPE_ICONS.filter(dt => dt.key === documentTypeId);
    const selectedIcon = selectedIconSetting.length > 0 ? selectedIconSetting[0].value : false;
    return selectedIcon;
}

const ExportDocumentsTableElement = ({ documents = [], formik }) => {

    const style = cx(
        'table',
    );

    const formikProps = getIn(formik);

    notNull(formikProps, 'formikProps', 'failed to get into props.formik')
    notNull(documents, 'documents not initialized')

    const { setFieldValue, values } = formikProps;
    const selectedDocuments = values['selectedDocuments'];

    const { add } = useContext(SidePanelContext);

    const allSelectedOnPage = (documents, selectedDocuments) =>{ 

        notNull(documents, 'documents');
        notNull(selectedDocuments, 'selectedDocuments');

        if (documents.length === 0)
            return false;

        const _allSelected =  documents.every(doc => {
            const indexOf = selectedDocuments.indexOf(doc.documentId);
            const isCurrentlySelected = indexOf > -1;
            return isCurrentlySelected;
        });

        return _allSelected;
    }

    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        const _as = allSelectedOnPage(documents, selectedDocuments);
        setAllSelected(_as);
      });

    const handleRowClicked = (documentId) => {
        const detail = <SearchDocumentDetail documentId={documentId} readonly />
        add(detail, true);     
    }

    const handleCheckboxValueChanged = (documentId) => {
        const indexOf = selectedDocuments.indexOf(documentId);
        const isCurrentlySelected = indexOf > -1;

        if (isCurrentlySelected) { // deselect
            selectedDocuments.splice(indexOf, 1);
        }
        else {
            selectedDocuments.push(documentId);
        }

        setFieldValue('selectedDocuments', selectedDocuments);
        const _as = allSelectedOnPage(documents, selectedDocuments);
        setAllSelected(_as);
    }

    // todo: debug with Oli: why you not work?
    const SelectAllCheckbox = () => {
        return (
            <>
            <input type="checkbox" name={'selectAll'} id={'said'} checked={allSelected} onChange={handleSelectAll} />
            {/* <StyledCheck id={`cb_${'all'}`} checked={allSelected} onChange={handleSelectAll} /> */}
            </>
        )
    }

    const columnDefinitions = [
        { property: 'checked', headerText: 'check all', sortable: false, medium: 'size-medium-5', large: 'size-large-5', children: <SelectAllCheckbox /> },
        { property: 'documentDate', headerText: 'Datum', sortable: false, medium: 'size-medium-18', large: 'size-large-12' },
        { property: 'documentName', headerText: 'Document', sortable: false, medium: 'size-medium-29', large: 'size-large-20' },
        { property: 'totalAmount', headerText: 'Totaal', sortable: false, medium: 'size-medium-15', large: 'size-large-15' },
        { property: 'description', headerText: 'Omschrijving', sortable: false, medium: 'size-medium-27', large: 'size-large-38', hideOnMedium: true },
        { property: 'exportedOn', headerText: 'Laatste export', sortable: false, medium: 'size-medium-18', large: 'size-large-12' }
    ]   

    const trs = documents.map(d => <TableRow key={d.documentId} columnDefinitions={columnDefinitions} document={d} onClick={() => handleRowClicked(d.documentId)} onChecked={() => handleCheckboxValueChanged(d.documentId)} checked={selectedDocuments.indexOf(d.documentId) > -1} />)

    const handleSelectAll = (e) => {
        
       if (allSelected) {
            // deselect all items on current page

            documents.forEach(doc => {
                const indexOf = selectedDocuments.indexOf(doc.documentId);
                const isCurrentlySelected = indexOf > -1;

                if (isCurrentlySelected) { // deselect
                    selectedDocuments.splice(indexOf, 1);
                }
            });

            
        }
        else {
            // select all on page
            documents.forEach(doc => {
                const indexOf = selectedDocuments.indexOf(doc.documentId);
                const isCurrentlySelected = indexOf > -1;

                if (!isCurrentlySelected) { // select
                    selectedDocuments.push(doc.documentId);
                }
            });
        }
       
        setFieldValue('selectedDocuments', selectedDocuments);
        const asp = allSelectedOnPage(documents, selectedDocuments);
        setAllSelected(asp);
    }  

    return (<Fragment>
        <div className={style}>
            <div className="table__inner">
                <TableHeader columnDefinitions={columnDefinitions} />
                <div className="table__body">
                    {trs}
                </div>
            </div>
        </div>
        {debug && <ShowObject value={formikProps} />}
    </Fragment>
    )
};

const TableRow = ({ document, onClick, onChecked, checked, columnDefinitions }) => {

    const rowStyle = cx('table__row',
        { 'is-clickable': onClick !== null },
        { 'is-selected': checked }
    )

    const {
        documentName = '',
        documentType = 0,
        documentId = '',
        documentDate = '',
        description = '',
        totalAmount = 0,
        exportedOn = '',
    } = document;

    const fod = (propertyName) => {
        const first = columnDefinitions.filter(def => def.property === propertyName)[0];
        notNull(first, 'item', `item with property ${propertyName} not found in definitions`);
        return first;
    }

    const selectedIcon = getIconFor(documentType);
    const icon = selectedIcon && <Control skipTab isPlain={true} size={CONTROL_SIZE.xsmall}><i className="material-icons">{selectedIcon}</i></Control>

    return (
        <div className={rowStyle} onClick={onClick}>
            <TableCell definition={fod('checked')} onClick={(e) => e.stopPropagation()}>
                <StyledCheck id={`cb_${documentId}`} checked={checked} onChange={(e) => onChecked(documentId)} />
            </TableCell>
            <TableCell definition={fod('documentDate')}>
                <Ellipsis text={<DateTime dateValue={documentDate} />} />
            </TableCell>
            <TableCell definition={fod('documentName')}>
                { icon } &nbsp; <Ellipsis text={documentName} />
            </TableCell>
            <TableCell definition={fod('totalAmount')}>
                <Amount value={totalAmount} />
            </TableCell>
            <TableCell definition={fod('description')}>
                <Ellipsis text={description} />
            </TableCell>
            <TableCell definition={fod('exportedOn')}>
                <Ellipsis text={<FromNow dateValue={exportedOn} />} />
            </TableCell>
        </div>
    )
}

const TableCell = ({ definition, children, onClick }) => {

    const { hideOnMedium, medium, large, property } = definition;

    const style = cx(
        'table__cell',
        { 'hide-medium': hideOnMedium === true },
        [medium],
        [large]
    )
    return (
        <div data-field={property} className={style} onClick={onClick}>
            {children}
        </div>
    )
}

export const ExportDocumentsTable = connect(ExportDocumentsTableElement);