import React from 'react'

export const StructuredMessage = ({ value }) => {
    var p1 = value.substring(0, 3);
    var p2 = value.substring(3, 7);
    var p3 = value.substring(7, 12);

    return (
        <span>***{p1}/{p2}/{p3}***</span>
    )
}