import React from 'react';
import './styles/_controlBar.scss';
import cx from 'classnames';

import { Control } from "../..";
import { CONTROL_SIZE} from "../../constants";

export const ControlBar = ({
	title = '',
	spacing = false,
	currentPage = -1,
	totalPages = -1,
	onlyActions = false,
	handleNext = ()=> {console.log('next')},
	handlePrevious = ()=> { console.log('previous') },
	handleDelete = () => { console.log('delete') },
	handleOpen = () => { console.log('open') },
	handleSave = () => { console.log('save') },
	}) => {

	const style = cx(
		'controlBar',
		{ 'spacing-t' : spacing === true },
		{ 'only-actions' : onlyActions }
	);

	return (
		<div className={style}>
			<div className="controlBar__inner">
				{ !onlyActions &&
					<div className="controlBar__name">
						<i className="material-icons">description</i>
						{title}
						{currentPage > 0 && totalPages > 0 && <span>(pagina {currentPage}/{totalPages})</span>}
					</div>
				}
				<div className="controlBar__controls">
					<ul>
						<li>
							<Control size={CONTROL_SIZE.small} isPlain onClick={handlePrevious}>
								<i className="material-icons">chevron_left</i>
							</Control>
						</li>
						<li>
							<Control size={CONTROL_SIZE.small} isPlain onClick={handleNext}>
								<i className="material-icons">chevron_right</i>
							</Control>
						</li>
						<li>
							<Control size={CONTROL_SIZE.small} onClick={handleDelete} isGhost color="color-error" >
								<i className="material-icons">delete_outline</i>
							</Control>
						</li>
						<li>
							<Control size={CONTROL_SIZE.small} isGhost  onClick={handleSave}>
								<i className="material-icons">save_alt</i>
							</Control>
						</li>
						<li>
							<Control size={CONTROL_SIZE.small} isGhost  onClick={handleOpen}>
								<i className="material-icons">input</i>
							</Control>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
};