import React from 'react';
import '../../03_molecules/table/styles/_table.scss'
import cx from 'classnames';
import { Ellipsis } from '../../index';

export const ColumnHeader = ({ text, medium = 'size-medium-18', large = 'size-large-12', hideOnMedium = false, children }) => {

    const style = cx(
        'table__cell',
        //{'is-sortable': sortable === true},
        { 'hide-medium': hideOnMedium === true },
        [medium],
        [large]
    )

    const content = children ? children : <Ellipsis text={text} />

    return (
        <div className={style}>
            {content}
            {/* {sortable && <SortToggle />} */}
        </div>)
}