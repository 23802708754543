import React, { useState } from 'react';
import './styles/_periodSelector.scss';
import cx from "classnames";

import { BUTTON_SIZE, CONTROL_SIZE } from "../../constants";
import { Button, Control, Bubble } from "../../";
import { ArrowLIcon, ArrowRIcon } from "../../01_fundaments/icons";
import { notNull } from '../../00_global/notNull';
import { isInDebug } from '../../00_global/isInDebug';
import { Manager, Reference, Popper } from 'react-popper';

export const PeriodSelectorBubble = ({ currentYear, pages, selectedPeriod, onPeriodSelected, onYearChanged, children }) => {

	const [_isOpen, setOpen] = useState(false);

	const _onPeriodSelected = (period) => {
		setOpen(false);
		onPeriodSelected(period);
	}

	return <Manager>
    <Reference>
      {({ ref }) => (
       <span className={'element-space'} ref={ref}><Control onClick={() => setOpen(!_isOpen)}><i className="material-icons">settings</i></Control></span>
      )}
    </Reference>
    {_isOpen && <Popper placement="bottom">
      {({ ref, style, placement, arrowProps }) => (
        <div ref={ref} style={{...style, 'zIndex': 1}} data-placement={placement}>
            <Bubble position="top">
				<PeriodSelector 
					currentYear={currentYear}
					 pages={pages}
					 selectedPeriod={selectedPeriod}
					 onPeriodSelected={_onPeriodSelected}
					 onYearChanged={onYearChanged} />
					 {children}
            </Bubble>
          <div ref={arrowProps.ref} style={arrowProps.style} />
        </div>
      )}
    </Popper>}
  </Manager>
}


export const PeriodSelector = ({ currentYear, pages, selectedPeriod, onPeriodSelected, onYearChanged }) => {

	const debug = isInDebug('PeriodSelector');

	notNull(currentYear, 'currentYear');
	notNull(pages, 'pages');
	notNull(onPeriodSelected, 'onPeriodSelected');
	notNull(onYearChanged, 'onYearChanged');

	const style = cx(
		'periodSelector'
	);

	const currentPage = pages.filter(page => page.value === currentYear)[0];
	const indexOfCurrent = pages.indexOf(currentPage);

	const previousEnabled = indexOfCurrent > 0;
	const nextEnabled = pages.length > indexOfCurrent + 1;

	if (!currentPage)
	{
		if (debug) {
			notNull(currentPage, 'currentPage', 'no current page found. Makes sure 1 of the period pages is selected');		
		}
		else {
			console.warn('no current page found. Makes sure 1 of the period pages is selected');
			return null;
		}
	}

	const handlePreviousYear = () => {
		const previousPage = pages[indexOfCurrent -1];
		const { value } = previousPage;
		onYearChanged(value);
	}

	const handleNextYear = () => {
		const nextPage = pages[indexOfCurrent +1];
		const { value } = nextPage;
		onYearChanged(value);
	}
	
	const availablePeriods = currentPage.periods.map(ap => {return {...ap, selected: ap.value=== selectedPeriod}});
	const periods = availablePeriods.map(p => <Period key={p.value} period={p} onClick={onPeriodSelected} />)

	return (
		<div className={style}>
			<div className="periodSelector__inner">
				<div className="periodSelector__header">
					<div className="periodSelector__control">
						{previousEnabled && <Control isPlain size={CONTROL_SIZE.small} onClick={handlePreviousYear}>
							<ArrowLIcon />
						</Control>}
					</div>
					<div className="periodSelector__heading">{currentPage.text}</div>
					<div className="periodSelector__control">
						{nextEnabled && <Control isPlain size={CONTROL_SIZE.small} onClick={handleNextYear}>
							<ArrowRIcon />
						</Control>}
					</div>
				</div>
				<div className="periodSelector__list">
					<ul>
						{periods}
					</ul>
				</div>
			</div>
		</div>
	)
};

const Period = ({ period, onClick }) => {

	const { text, selected, value } = period;
	notNull(value, 'value');

	return (
		<li>
			<Button size={BUTTON_SIZE.full} rounded={false} inverted={!selected} active={selected} onClick={() => onClick({...period, selected: true})}>
				{text}
			</Button>
		</li>
	)
}