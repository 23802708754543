import React from 'react';
import './styles/_chain.scss';
import cx from "classnames";

export const ChainItem = ({ 
	heading = '',
	caption = '',
	material_icon = '',

	incomplete = false,
	disabled = false,
	inProgress = false, 
	error = false, 
	warning = false, 
	done = false}) => {

	const style = cx(
		'chain__item',
		{ 'is-incomplete': incomplete === true },
		{ 'color-disabled': disabled === true || incomplete === true },
		{ 'is-progress': inProgress === true },
		{ 'color-warning': warning === true },
		{ 'color-error': error === true },
		{ 'is-done': done === true}
	);

	const iconOrLoader = material_icon === '' 
		? <span className="chain__loader"></span> // no icon => show loader
		: <i className="material-icons">{material_icon}</i>; 

	return (<div className={style}>
		<div className="chain__checkpoint">
			<div className="chain__point">
				<span className="chain__circle">
					{iconOrLoader}
				</span>
			</div>
			<div className="chain__content">
				<h3 className="chain__heading heading weight-500">
					{heading}
				</h3>
				<p>{caption}</p>
			</div>
		</div>
	</div>
	)
}