import React, { useContext, useState } from 'react';
import { SidePanelManager, ContentContainer, Heading, SidePanelContext, FetchDataState } from '../..';
import { HEADING_SIZE, HEADING_SPACING, COLOR, CONTROL_SIZE, LABEL_SIZE, PARAGRAPH_SIZE } from '../../constants';
import { TableHeader, Table, TableBody } from '../export/TableHeader';
import cx from 'classnames';
import '../../03_molecules/moduleHeader/styles/_moduleHeader.scss';
import { TableCell } from '../export/ExportOverview';
import { FetchData } from '../../00_global';
import { Button } from '../../02_atoms/buttons/Button';
import { productService, getListResponse } from '../invoice_module_v1/invoiceService';
import { defaultPinProps, defaultCloseProps } from '../invoice_module_v1/CreateInvoice';
import { CreateCustomerPanel } from './CreateCustomerPanel';
import { ISidePanelContext } from '../../08_invoices/InvoiceModule';
import { Control } from '../../02_atoms/control/Control';
import { getColumnDefinition } from '../export/getColumnDefinition';
import { Label } from '../../02_atoms/label/Label';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { Ellipsis } from '../../02_atoms/ellipsis/Ellipsis';
import { WhenData } from '../../00_global/FetchData';
import { ConfirmationV2 } from '../../03_molecules/confirmation/ConfirmationV2';
import { CustomerDetailDto } from '../invoice_module_v1/models/api';

const widths = [
    10, 16, 14, 28, 20
]

const rem = 100 - widths.reduce((prev, curr) => prev + curr);

const columnDefinitions = [
    getColumnDefinition('name', 'Naam', widths[1]),
    getColumnDefinition('taxId', 'BTW nummer', widths[2]),
    getColumnDefinition('address', 'Adres', widths[3]),
    getColumnDefinition('email', 'Email', widths[4]),
    getColumnDefinition('status', 'Status', widths[0]),
    getColumnDefinition('_actions_', '', rem, 'right'),
]

interface CustomerRowProps {
    customer: CustomerDetailDto,
    onDelete(id: string): void,
    onEdit(customer:CustomerDetailDto): void 
}

const CustomerRow = ({ customer, onDelete, onEdit }: CustomerRowProps) => {

    const rowStyle = cx('table__row',
        { 'is-clickable': false },
        { 'is-selected': false }
    )

    const handleDelete = async (id:string) => {
        onDelete(id);
    }

    if (!customer.id)
        throw Error('invalid customer');

    const customerId: string = customer.id;

    // @STIJN TODO customer status
    return (
        <div className={rowStyle}>
            <TableCell definition={columnDefinitions[0]}>
                <Ellipsis>{customer.party.business.name}</Ellipsis>
            </TableCell>
            <TableCell definition={columnDefinitions[1]}>
                <div style={{ overflow: 'hidden' }}>
                    <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-4">
                        <Ellipsis>{customer.party.business.taxIdentificationNumber}</Ellipsis>
                    </Paragraph>
                </div>
            </TableCell>
            <TableCell definition={columnDefinitions[2]}>
                <Ellipsis>{customer.party.business.address.map((line, i) => <span key={i}>{line}&nbsp;</span>)} {customer.party.business.countryCode}</Ellipsis>
            </TableCell>
            <TableCell definition={columnDefinitions[3]}>
                {customer.party.business.email &&
                    <a href={`mailto:${customer.party.business.email}`}>
                        <Ellipsis>{customer.party.business.email}</Ellipsis>
                    </a>
                }
            </TableCell>
            <TableCell definition={columnDefinitions[4]}>
                {(() => {
                    // @STIJN TODO
                    switch (customer.id) {
                        case '9eb2b11b-677b-4fda-9bbf-303db4c34f4a': return <Label size={LABEL_SIZE.small} color="warning"><i className="material-icons">warning</i> Onvolledig</Label>;
                        default: return <></>;
                    }
                })()}
            </TableCell>
            <TableCell definition={columnDefinitions[5]}>
                <Control size={CONTROL_SIZE.small} isGhost>
                    <i key={'edit_invoice'} className="material-icons" onClick={() => onEdit(customer)}>edit</i>
                </Control>
                &nbsp;
                <ConfirmationV2 
                    onDeleteConfirmed={() => handleDelete(customerId)}
                     message={'Deze actie kan niet ongedaan gemaakt worden. Reeds opgestelde facturen voor deze klant blijven wel behouden'} />
            </TableCell>
        </div>
    )
}

const CustomerOverviewContainer = () => {

    const [version, setVersion] = useState(0);
    const panelContext = useContext<ISidePanelContext>(SidePanelContext);
    const { add, close } = panelContext;

    const showSidepanel = (title = '', content: any) => {
        add(content, false, defaultPinProps, defaultCloseProps);
    }

    const closeSidepanel = () => {
        close();
    }

    const handleCreateCustomerClicked = () => {
        // open panel
        const panel = <CreateCustomerPanel onCustomerCreated={(customer) => { handleCustomerCreated(customer) }} />
        showSidepanel('Nieuwe klant', panel);
    }

    const handleCustomerCreated = (customer: CustomerDetailDto) => {
        console.log('customer created', customer)
        closeSidepanel();

        setVersion(version + 1);
    }

    const deleteCustomer = async (customerId: string) => {
        const result = await productService.deleteCustomer(customerId);
        if (result.status === 0)
            setVersion(version + 1);
    }

    const showEditPanel = (customer: CustomerDetailDto) => {
        const panel = <CreateCustomerPanel customerDetails={customer} onCustomerCreated={(customer) => { handleCustomerCreated(customer) }} />
        showSidepanel('Klant aanpassen', panel);
    }

    return (
        <>
            <div className="moduleHeader">
                <div className="row" style={{ alignItems: 'flex-end' }}>
                    <div className="col-md-7">
                        <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.small}>
                            Overzicht klanten
                        </Heading>
                        <Button inverted color={COLOR.secondary_2} onClick={handleCreateCustomerClicked}>
                            <i className="material-icons">add</i>
                            Nieuwe klant...
                        </Button>
                    </div>
                </div>
            </div>

            <FetchData method={productService.getCustomersAsync} triggerValues={[version]}>
                {
                    ({ data, error, loading }: FetchDataState<getListResponse<CustomerDetailDto>>) => {
                        return <WhenData data={data} error={error} loading={loading} noData={data && data.items && data.items.length === 0} >
                                {
                                    () => 
                                    <Table>
                                    <TableHeader columnDefinitions={columnDefinitions} />
                                    <TableBody>
                                        {data.items.map(x => <CustomerRow key={x.id} customer={x} onEdit={showEditPanel} onDelete={deleteCustomer} />)}
                                    </TableBody>
                                </Table>
                                }
                        </WhenData>
                    }
                }
            </FetchData>
        </>
    )
}


export const CustomerOverview = () => {
    return (
        <SidePanelManager >
            <ContentContainer main={<CustomerOverviewContainer />} />
        </SidePanelManager>
    )
}