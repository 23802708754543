import React, { Fragment } from 'react';
import './_search.scss';
import cx from "classnames";
import { CloseIcon } from "../../01_fundaments/icons";
import { SearchIcon } from "../../01_fundaments/icons";
import { TagIcon } from "../../01_fundaments/icons";
import { Control, Loader } from "../..";
import { LOADER_SIZE } from "../../constants";
import { isInDebug } from '../../00_global/isInDebug';

const debug = true && isInDebug('Search');

export class Search extends React.Component {

    constructor(props) {
        super(props);

        console.log('searchProps', props);

        this.state = {
            focused: false,
            highlightedIndex: null,
            isOpen: false
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {

        this.setState({
            isOpen: false,
            highlightedIndex: null
        }, () => {
            debug && console.log('search handleClick');

            this.setIgnoreBlur(false);
        })
    }

    // todo: fix - https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
    UNSAFE_componentWillMount() {
        // this.refs is frozen, so we need to assign a new object to it
        this.refs = {}
        this._ignoreBlur = false
        this._ignoreFocus = false
        //this._scrollOffset = null
        //this._scrollTimer = null
    }

    handleKeyDown(event) {
        if (Search.keyDownHandlers[event.key])
            Search.keyDownHandlers[event.key].call(this, event)
        else if (!this.isOpen()) {
            this.setState({
                isOpen: true
            })
        }
    }

    isOpen() {
        return 'open' in this.props ? this.props.open : this.state.isOpen
    }

    getFilteredItems(props) {
        if (this.props.results.length > 0) {
            //debug && console.log('getFilteredItems_results', JSON.stringify(props.results));
            return this.props.results;
        }
        return [];
    }

    setIgnoreBlur(ignore) {
        this._ignoreBlur = ignore
    }

    // triggered when the typed in the field
    handleChange(event) {
        debug && console.log('handleChange', event);
        this.props.onSearch(event, event.target.value);
        this.setState({ highlightedIndex: -1 }); // remove highlighted item
        this.setIgnoreBlur(true);
    }

    static keyDownHandlers = {
        ArrowDown(event) {
            event.preventDefault();
            const items = this.getFilteredItems(this.props); // returns all results atm
            if (!items.length) return; // quit if there are no results
            const { highlightedIndex } = this.state;
            let index = highlightedIndex === null ? -1 : highlightedIndex; // extra check to see if it's a valid value

            for (let i = 0; i < items.length; i++) {
                const p = (index + i + 1) % items.length;
                index = p
                break;
            }
            if (index > -1 && index !== highlightedIndex) {
                this.setState({
                    highlightedIndex: index,
                    isOpen: true,
                })
            }
        },

        ArrowUp(event) {
            event.preventDefault();
            const items = this.getFilteredItems(this.props);
            if (!items.length) return;
            const { highlightedIndex } = this.state;
            let index = highlightedIndex === null ? items.length : highlightedIndex
            for (let i = 0; i < items.length; i++) {
                const p = (index - (1 + i) + items.length) % items.length
                index = p;
                break
            }
            if (index !== items.length) {
                this.setState({
                    highlightedIndex: index,
                    isOpen: true,
                })
            }
        },

        Enter(event) {
            // Key code 229 is used for selecting items from character selectors (Pinyin, Kana, etc)
            if (event.keyCode !== 13) return;
            // In case the user is currently hovering over the menu
            this.setIgnoreBlur(false);
            if (!this.isOpen()) {
                // menu is closed so there is no selection to accept -> do nothing
                debug && console.log('doNothing', event);
                return;
            }
            else if (this.state.highlightedIndex == null) {
                // input has focus but no menu item is selected + enter is hit -> close the menu, highlight whatever's in input
                debug && console.log('doNothing', event);
                this.setState({
                    isOpen: false
                }, () => {
                    this.refs.input.select()
                });
            }
            else {
                // text entered + menu item has been highlighted + enter is hit -> update value to that of selected menu item, close the menu

                event.preventDefault();
                const item = this.getFilteredItems(this.props)[this.state.highlightedIndex]

                this.setState({
                    isOpen: false,
                    highlightedIndex: null
                }, () => {
                    //this.refs.input.focus() // TODO: file issue
                    // this.refs.input.setSelectionRange(
                    //     value.length,
                    //     value.length
                    // )
                    debug && console.log('selected a value from keyhandler', item)
                    if (item) {
                        item.props.onSelect();
                    }
                })
            }
        },

        Escape() {
            // In case the user is currently hovering over the menu
            this.setIgnoreBlur(false)
            this.setState({
                highlightedIndex: null,
                isOpen: false
            })
        },

        Tab() {
            // In case the user is currently hovering over the menu
            this.setIgnoreBlur(false)
        },
    };

    render() {
        const { results = [], tags = false, value } = this.props;

        //notNull(id, 'id');

        const { highlightedIndex, focused, isOpen } = this.state;

        const style = cx(
            'search',
            { 'has-tags': tags },
            { 'type-select' : (this.props.style === 'select') }
        );
        const onFocus = () => {
            this.setState({
                focused: true,
                isOpen: this.props.openOnFocus === true
            })
        };
        const onBlur = (e) => {
            debug && console.log('blurred', e);
            if (!this._ignoreBlur)
                this.setState({ focused: false })
        };

        const showResults = focused && isOpen && (results.length > 0);
        const { loading } = this.props;

        // invoke render function on items
        const itemsToRender = results.filter(item => item && item.props && item.props.render).map((item, i) => {

            if (item && item.props && item.props.render) {

                const renderedChild = item.props.render({ key: i, isHighlighted: i === highlightedIndex })
                return <span key={i} onClick={this.handleClick}>{renderedChild}</span>
            }
            else {
                console.warn('render function missing!');
                return null;
            }
        });

        itemsToRender.length !== results.length && console.warn(`Passed ${results.length} items but only ${itemsToRender.length} have a render function!`)

        return (
            <>
                <div className={style}>
                    {tags &&
                        <div className="search__icon">
                            <TagIcon />
                        </div>
                    }

                    {!tags &&
                        // todo Oli: support disableInput (zie https://trello.com/c/xiXBuAe4/163-input-van-search-moet-readonly-kunnen-staan)
                        <input
                            id={this.props.id | 'fallback'}
                            type="text"
                            className="search__input is-plain"
                            placeholder=""
                            autoComplete={'off'}
                            value={value}
                            onChange={this.handleChange}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={this.handleKeyDown}
                        />
                    }

                    {tags &&
                        <div className="search__tagList">
                            <ul>
                                <li>
                                    <span className="search_tagLabel">
                                        <CloseIcon />
                            HTML5
                        </span>
                                </li>
                                <li>
                                    <span className="search_tagLabel">
                                        <CloseIcon />
                            JavaScript
                        </span>
                                </li>
                                <li>
                                    <input type="text" />
                                </li>
                            </ul>
                        </div>
                    }
                    <span className="search__label"></span>
                    <div className="search__controls">
                        <Control isPlain={true} skipTab={true}>
                            {loading && <Loader size={LOADER_SIZE.xsmall} color="on-white" />}
                            {!loading && (
                                <>
                                { this.props.style === 'select' ? (
                                    <>
                                        <i className="material-icons">keyboard_arrow_down</i>
                                    </>
                                ) : (
                                    <SearchIcon />
                                )}
                                </>
                            )}
                        </Control>
                    </div>

                    {showResults &&
                        <Fragment>
                            <div className="search__results">
                                {itemsToRender}
                            </div>
                        </Fragment>
                    }
                </div>
            </>

        )
    }
}


