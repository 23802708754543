import React, { useState } from 'react';

import { Box, Heading, Ellipsis, FormField, Button } from "../..";
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING } from "../../constants";
import { Formik } from "formik";
import { InputField } from './InputField';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { MessageSend } from '../../03_molecules/visualFeedback/VisualFeedback';
import { Loader } from '../../02_atoms/loader/Loader';
import { LoaderContainer } from '../../03_molecules/loaderContainer/LoaderContainer';

export interface SendInvoiceResponse {
    success: boolean
}

interface SendInvoicePanelProps {
    toEmail: string,
    message: string,
    onSend(toEmail: string, message: string, carbonCopy: string): Promise<SendInvoiceResponse>
    onClose(): void
}

export const SendInvoicePanel = (props: SendInvoicePanelProps) => {

    const [statemachine, setStatemachine] = useState(0)

    const { toEmail, message, onSend, onClose } = props;

    const fields = {
        toEmail: 'toEmail',
        message: 'message',
        carbonCopy: 'cc',
    }

    const initialValues = {
        toEmail: toEmail,
        message: message
    }

    const whenSubmitted = async (values: any) => {
        setStatemachine(1);
        const toEmail = values[fields.toEmail];
        const message = values[fields.message];
        const carbonCopy = values[fields.carbonCopy];

        const response = await onSend(toEmail, message, carbonCopy);
        if (response.success)
            setStatemachine(2);
    }

    if (statemachine === 1) {
        return (<Box id={'select_customer'} noBorder color="bgc--white">
            <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.small}>
                <Ellipsis>{'Even geduld'}</Ellipsis>
                <br />
                <br />
                <LoaderContainer>
                </LoaderContainer>
            </Heading>
        </Box>)
    }
    else if (statemachine === 2) {
        return (<Box id={'select_customer'} noBorder color="bgc--white">
                <MessageSend title={'Bon Voyage!'} subTitle={'Je factuur is verzonden'} ctaButton={<Button onClick={() => onClose()    }>Sluiten</Button>} />
        </Box>)
    }
    else return (
        <Box id={'select_customer'} noBorder color="bgc--white">
            <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.small}>
                <Ellipsis>{'Factuur versturen'}</Ellipsis>
            </Heading>
            <Formik
                initialValues={initialValues}
                isInitialValid={initialValues.toEmail.length > 0}
                validationSchema={undefined}
                validateOnChange={true}
                validateOnBlur={true}
                // validate={handleValidate}
                onSubmit={whenSubmitted}
            >
                {
                    // render func
                    (formikProps) => {
                        const { handleSubmit, errors, values, setFieldValue, isValid, isSubmitting } = formikProps

                        return (
                            <form className="form" onSubmit={handleSubmit}>
                                {/* <Dropdown header={''} id={fields.templateName} results={_templates} onSearch={() => { }} onItemSelected={onTemplateSelected} key={'template_dd'} formik={formikProps} blockInput={true} /> */}
                                <InputField formik={formikProps} id={fields.toEmail} header={'Email'} infoMessage={`De ontvanger van je bericht`} />
                                {/* <InputField formik={formikProps} id={fields.carbonCopy} header={'CC'} infoMessage={`Eventuele ontvangers in kopie`} />
                                <InputField formik={formikProps} id={fields.message} header={'Bericht'} infoMessage={`Een boodschap die we toevoegen aan je mailtje`} multiline={true} /> */}

                                {/* <InputField formik={formikProps} id={fields.fiscalYear} header={'Boekjaar'} infoMessage={'Staat in principe goed...'} />
                            <InputField formik={formikProps} id={fields.paymentReference} header={'Referentie betaling'} infoMessage={''} />
                            
                            <FormField>
                                <Field id={fields.dueDate} header={'Vervaldatum'} infoMessage={'Uiterste datum waarop de klant mag betalen'}>
                                    <DateField formik={formikProps} placeholder={'factuur datum'} propertyName={fields.dueDate} onSelect={onDueDateSelected} />
                                </Field>
                            </FormField>
                            <InputField formik={formikProps} id={fields.footerNote} header={'Notitie'} infoMessage={'Wordt onderaan de factuur afgedrukt'} multiline={true} /> */}
                                <FormField>
                                    <Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button>
                                </FormField>
                            </form>
                        )
                    }
                }
            </Formik>
        </Box>
    )


}