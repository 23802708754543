import React from 'react';
import { InvoiceTemplateDto } from '../models/invoiceSettings';
import { FetchDataState } from '../../..';
import { invoiceTemplateFormFields } from './CreateTemplateForm';
import { InvoiceTemplateForm } from './InvoiceTemplateForm';
import { FetchData } from '../../../00_global';
import { productService, getListResponse } from '../invoiceService';

interface UpdateInvoiceTemplateProps {
    templateId: string,
    onUpdateTemplate(dto: InvoiceTemplateDto): void
}



export const UpdateInvoiceTemplate = (props: UpdateInvoiceTemplateProps) => {

    const handleSubmit = async (item: InvoiceTemplateDto) => {
        await props.onUpdateTemplate(item);
    }

    const fields = invoiceTemplateFormFields;

    const emptyValues = {
        [fields.templateName]: '',
        [fields.companyName]: '',
        [fields.addressLine1]: '',
        [fields.addressLine2]: '',
        [fields.country]: '',
        [fields.phoneNumber]: '',
        [fields.emailAddress]: '',
        [fields.iban]: '',
        [fields.taxIdentificationNumber]: '',
        [fields.website]: '',
        [fields.footerMessage]: '',
        [fields.defaultPaymentTermInDays]: 30
    }


    const mapToForm = (template: InvoiceTemplateDto) => {

        const { name, address, emailAddress, phoneNumber, country, taxIdentificationNumber, bankAccount } = template.from;

        return {
            ...emptyValues,
            [fields.templateName]: template.from.name,
            [fields.companyName]: name,
            [fields.addressLine1]: address.length > 0 ? address[0] : '',
            [fields.addressLine2]: address.length > 1 ? address[1] : '',
            [fields.country]: country,
            [fields.phoneNumber]: phoneNumber,
            [fields.emailAddress]: emailAddress,
            [fields.iban]: bankAccount.iban,
            [fields.taxIdentificationNumber]: taxIdentificationNumber,
            [fields.website]: template.website,
            [fields.footerMessage]: template.footerMessage,
            [fields.defaultPaymentTermInDays]: template.defaultPaymentTermInDays
        }
    }

    return (
        <FetchData method={productService.getTemplatesAsync}>
            {
                (fdp: FetchDataState<getListResponse<InvoiceTemplateDto>>) => {
                    if (fdp.data && fdp.data.items) {
                        const template = fdp.data.items[0];
                        const initial = mapToForm(template);
                        return (
                            <>
                                <InvoiceTemplateForm template={template} initialValues={initial} onSubmit={handleSubmit} />                                
                            </>)
                    }
                }
            }
        </FetchData>
    )
}

// export const UpdateInvoiceTemplate = (props: RouteComponentProps<UpdateInvoiceTemplateProps>) => {

//     const { templateId } = props.match.params;

//     return (
//         <SidePanelManager>
//             <ContentContainer main={<UpdateInvoiceTemplateElement templateId={templateId} />} />
//         </SidePanelManager>
//     )
// }