import React from 'react';
import './styles/_keyValue.scss';
import cx from "classnames";

import {CONTROL_SIZE} from "../../constants";
import {Control} from "../../";
import { Ellipsis } from '../../02_atoms/ellipsis/Ellipsis';

const KeyValueRow = ({title, value, canEdit = false, ellipsis}) => {
	return (
		<div className="keyValue__row">
			<div className="keyValue__label">
				<div className="keyValue__cell">
					<strong>
						{ ellipsis ? (
							<Ellipsis text={title} />
						) : (
							<>{title}</>
						)}
					</strong>
				</div>
			</div>
			<div className="keyValue__value">
				<div className="keyValue__cell">{value}</div>
				{canEdit && <div className="keyValue__edit">
					<Control size={CONTROL_SIZE.small} isPlain={true}>
						<i className="material-icons">edit</i>
					</Control>
				</div>}
			</div>
		</div>
	)
}

export const KeyValue = ({ values, compact = false, ellipsis = false, canEdit = false, labelSize }) => {

	const style = cx(
		'keyValue',
		{ 'is-compact': compact },
		{ 'label-l': labelSize === 'large' }
	);

	const items = values.map(item => <KeyValueRow key={item.title} title={item.title} canEdit={canEdit} value={item.value} ellipsis={ellipsis} />)

	return (
		<div className={style}>
			<div className="keyValue__inner">
				{items}
			</div>
		</div>
	)
};