import React, { Fragment } from 'react';
 
export class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { 
          hasError: true,
          errorMessage: error.message
      };
    }
  
    componentDidCatch(error, info) {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, info);
      console.error(error);
      console.log(info);
    }

   
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <Fragment>
          <h1>Oeps, er doet zich een fout voor:</h1>
          <div>{this.state.errorMessage}</div>
          <div>Neem contact op via support@trive.io als het probleem zich blijft voordoen</div>
        </Fragment>;
      }
  
      return this.props.children; 
    }
}