import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "./styles/react-datepicker.css";
import './styles/_datefield.scss';
import cx from "classnames";
import * as moment from 'moment';
import { isInDebug } from '../../00_global/isInDebug';
import { connect, getIn } from 'formik';
import { differenceInDays } from 'date-fns'

const debug = isInDebug('Inbox') && isInDebug('DateField');

const DateFieldElement = ({ onSelect, maxDate = null, formik, placeholder = '', invalid = false, propertyName, dateFormat = ["dd/MM/yyyy", "yyyy-MM-dd"] }) => {

    moment.locale('nl-be');
    const formikProps = getIn(formik);
    const { values, onBlur } = formikProps;

    const value = values[propertyName];
    const initialValue = value ? moment(value).toDate() : null;
    const [parsedValue, setParsedValue] = useState(initialValue);

    const style = cx('input',
        { 'is-invalid': invalid === true },
    );

    const handleSelect = (selected) => {
        const selectedDate = moment(selected).toDate();
        const parsedResult = validateDateDiff(selectedDate);
        setParsedValue(parsedResult)
        onSelect(parsedResult);

    }

    const handleChange = (e) => {

    }

    const handleChangeRaw = (e) => {
        const input = e.target.value;

        const parsedResult = validateSelectedDate(input);
        setParsedValue(parsedResult)
        onSelect(parsedResult);

        //const parsed = inputValue && moment(inputValue).isValid() ? moment(inputValue).toDate() : null;

    }

    const validateDateDiff = (dateInput) => {
        if (moment(dateInput).isValid()) {
            const di = moment(dateInput).toDate();
            var result = differenceInDays(
                new Date(di),
                new Date()
            )
            if (maxDate) {
                const upper = differenceInDays(maxDate, new Date())
                if (result <= upper && result > -700)
                    return di;
            }
            else {
                return di;
            }

        }
        return null;
    }

    const validateSelectedDate = (input) => {
        if (input.length > 4) {
            const parsed = moment(input, ["DD-MM-YYYY", "YYYY-MM-DD"]);
            if (parsed && parsed.isValid()) {
                return validateDateDiff(parsed);
            }
        }
    }

    //debug && console.log(`DateFieldElement ${propertyName} SELECTED`, selectedDate);
    debug && console.log(`DateFieldElement ${propertyName} VALUE`, value);

    return (
        <div className={style}>
            <div className="input__element">
                <DatePicker
                    //id={propertyName}
                    selected={parsedValue}
                    //dropdownMode={'scroll'}
                    placeholder={placeholder}
                    dateFormat={dateFormat}
                    onBlur={onBlur}
                    maxDate={maxDate}
                    todayButton='vandaag'
                    onChange={handleChange}
                    onChangeRaw={handleChangeRaw}
                    onSelect={handleSelect}
                />
            </div>
        </div>
    );
};

export const DateField = connect(DateFieldElement);