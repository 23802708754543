import React, { version } from 'react';
import ReactMarkDown from 'react-markdown';
import './_pdfStyle.scss';
import { productService, getInvoiceResponse } from '../invoiceService';
import { FetchData, notNull } from '../../../00_global';
import { Amount, FetchDataState } from '../../..';
import { RouteComponentProps } from 'react-router-dom';
import { Invoice, InvoiceLineItem } from '../models/api';
import { DateTime } from '../../../02_atoms/date/DateTime';
import queryString from 'query-string';
import { Paragraph } from '../../../02_atoms/paragraph/Paragraph';

interface InvoiceIdRoutInfo {
	invoiceId: string
}

interface QueryProps {
	token: string
}

const withLogo = false;

// https://medium.com/@christopher.talke/using-node-puppeteer-with-docker-without-wanting-to-smash-your-keyboard-ed78e9529a8b
// https://github.com/christopher-talke/node-express-puppeteer-pdf-example
// http://{print-pdf-container}:3000/generate-pdf?url=http://preview.trive.io/invoices/print/{id}?token={token}

interface InvoiceLineItemPage {
	pageNumber: number,
	totalPages?: number,
	invoiceLineItems: InvoiceLineItem[]
}

export const InvoicePDF = (props: RouteComponentProps<InvoiceIdRoutInfo>) => {

	const { invoiceId } = props.match.params;

	const q = queryString.parse(location.search)
	if (!q || !q.token) {
		return <div>Not authorized</div>
	}

	const token: any = q.token;

	const parameters = {
		token: token
	};

	if (!invoiceId)
		throw Error('Invoice id missing!')

	const estimatePrintedLineCount = (value: string): number => {

		const chars = value.length;

		if (chars === 0)
			return 0
		else if (chars <= CHARS_ON_PAGE)
			return 1;
		else {
			const rows = chars / CHARS_ON_PAGE
			return Math.ceil(rows)
		}
	}

	const MAX_LINES_ON_PAGE = 24;
	const CHARS_ON_PAGE = 67; // on average

	const splitInPages = (lineItems: InvoiceLineItem[]): InvoiceLineItemPage[] => {

		let currentLineCount = 0;
		let pagesWithLines: InvoiceLineItemPage[] = [];
		let linesOnPage: InvoiceLineItem[] = [];

		for (let currentLineIndex = 0; currentLineIndex < lineItems.length; currentLineIndex++) {

			const currentLineItem = lineItems[currentLineIndex];
			const hasHeader = pagesWithLines.length === 0; // first page
			const maxLinesOnCurrentPage = MAX_LINES_ON_PAGE - (hasHeader? 10:0)

			const printedLinesForCurrentItem = estimatePrintedLineCount(currentLineItem.productOrTask.productName) + estimatePrintedLineCount(currentLineItem.productOrTask.description);

			if (currentLineCount + printedLinesForCurrentItem <= maxLinesOnCurrentPage) {
				// still fits on one page
				currentLineCount += printedLinesForCurrentItem;
				linesOnPage.push(currentLineItem);
			}
			else {
				// does not fit. Push the current page and start a new one
				pagesWithLines.push({
					invoiceLineItems: linesOnPage,
					pageNumber: pagesWithLines.length + 1,
				})

				linesOnPage = [];// new index
				linesOnPage.push(currentLineItem);
				currentLineCount = printedLinesForCurrentItem;
			}

			if (currentLineIndex === lineItems.length - 1) {
				// last line processsed.

				pagesWithLines.push({
					invoiceLineItems: linesOnPage,
					pageNumber: pagesWithLines.length + 1,
				})


				const maxItemsOnLastPage = (MAX_LINES_ON_PAGE - 13);

				if (linesOnPage.length > maxItemsOnLastPage){
					pagesWithLines.push({
						invoiceLineItems: [], // empty, just the totals
						pageNumber: pagesWithLines.length + 1,
					})
				}
			}
		}

		return pagesWithLines.map(p => {
			return {...p,totalPages: pagesWithLines.length}
		});
	}
	// render html
	return (
		<div className="invoicePDF">
			<FetchData method={() => productService.getInvoiceForPrintAsync(invoiceId, parameters.token)} triggerValues={[version]} >
				{
					({ data, error }: FetchDataState<getInvoiceResponse>) => {
						if (data && data.status === 0) {
							notNull(data.item, 'invoice');
							const pages = splitInPages(data.item.lineItems)
							return (
								<>
									{/* <InvoicePage lines={lines} /> */}
									{pages.map((page) => <InvoicePage key={page.pageNumber} invoice={data.item} pageInfo={page} />)}
								</>
							)
						}
						if (error) {
							return <Paragraph>{error.message}</Paragraph>
						}
					}
				}
			</FetchData>
		</div>
	);

}

const Text = (props: any) => {
	const { value } = props;
	if (value)
		return <>{value}<br /></>

	return null;
}


const InvoiceHeader = (props: InvoiceHeaderProps) => {

	const { invoice } = props;

	// render view
	return (
		<>
			<div className="invoicePDF__header">
				<div className="invoicePDF__basics">
					<div className="column">
						<div className="invoicePDF__brand">
							{/* <img src={logo} alt={'logo'} /> */}
							<strong style={{ fontSize: '26px' }}>{invoice.fromParty.name}</strong><br />
						</div>
						<div>
							{invoice.fromParty.address.map((a,i) => <Text key={i} value={a} />)}
							<Text value={invoice.fromParty.country} />
							<Text value={`BTW nr. ${invoice.fromParty.taxIdentificationNumber}`} />
							{invoice.fromParty.bankAccount.iban && <Text value={`Bank: ${invoice.fromParty.bankAccount.iban}`} />}
							<Text value={invoice.fromParty.phoneNumber} />
							<Text value={invoice.fromParty.emailAddress} />
						</div>
					</div>
					<div className="column text-right">
						<h1>{'Factuur'}</h1>
					</div>
					{withLogo &&
						<div className="column text-right small-text">
							<strong>{invoice.fromParty.name}</strong><br />
							{invoice.fromParty.address.map((a,i) => <Text key={i} value={a} />)}
							{invoice.fromParty.taxIdentificationNumber}<br />
							{invoice.fromParty.bankAccount.iban} <br />
							{invoice.fromParty.emailAddress} <br />
							{invoice.fromParty.phoneNumber} <br />
						</div>
					}
				</div>
				<div className="invoicePDF__basics">
					<div className="column">
						<strong>{invoice.toParty.business.name}</strong><br />
						{invoice.toParty.business.address.map((a,i) => <Text key={i} value={a} />)}
						{invoice.toParty.business.taxIdentificationNumber}
					</div>
				</div>

				<div className="invoicePDF__essentials">
					<div className="column">
						<strong>Factuur datum</strong><br />
						<DateTime dateValue={invoice.invoiceDate} />
					</div>
					<div className="column">
						<strong>Verval datum</strong><br />
						<DateTime dateValue={invoice.invoiceDue.dueOnDate} />
					</div>
					<div className="column">
						<strong>Referentie</strong><br />
						{invoice.paymentReference}
					</div>
					<div className="column">
						<strong>Factuur nummer</strong><br />
						{invoice.invoiceNumber}
					</div>
				</div>
			</div>
		</>
	)
};

interface InvoicePageProps {
	invoice: Invoice;
	pageInfo: InvoiceLineItemPage
}

const InvoicePage = ({ invoice, pageInfo }: InvoicePageProps) => {

	if (!pageInfo.totalPages)
		throw Error('pageInfo.totalPages missing')

	const invoiceHeader = pageInfo.pageNumber === 1
		? <InvoiceHeader invoice={invoice} />
		: <div className="invoicePDF__header">
			<div className="invoicePDF__pageIndication">
				Factuur {invoice.invoiceNumber} - Pagina {pageInfo.pageNumber}
			</div>
		</div>

	const invoiceFooter = pageInfo.pageNumber === pageInfo.totalPages
		? <InvoiceFooterWithTotals invoice={invoice} pageNumber={pageInfo.pageNumber} totalPages={pageInfo.totalPages}  />
		: <InvoiceFooter invoice={invoice} pageNumber={pageInfo.pageNumber} totalPages={pageInfo.totalPages} invoiceNumber={invoice.invoiceNumber} />


	return (<div className="invoicePDF__page">
		<div className="invoicePDF__inner">
			{invoiceHeader}
			<InvoiceLines lineItems={pageInfo.invoiceLineItems} />
			{invoiceFooter}
		</div>
	</div>)
};

interface InvoiceLinesProps {
	lineItems: InvoiceLineItem[]
}

const InvoiceLines = ({ lineItems }: InvoiceLinesProps) => {
	return (
		<div className="invoicePDF__lines">
			<table>
				<thead>
					<tr>
						<th>Omschrijving</th>
						<th>aantal</th>
						<th>prijs</th>
						<th>excl. BTW</th>
						<th>BTW</th>
						<th >Totaal</th>
					</tr>
				</thead>
				<tbody>
					{lineItems.map((item, index) => (
						<tr key={index}>
							<td valign="top">
								{item.productOrTask.productName}
								{item.productOrTask.description && <> <br /><em>{item.productOrTask.description}</em></>}
							</td>
							<td valign="top">
								{item.productOrTask.quantity}
							</td>
							<td valign="top">
								<Amount value={item.productOrTask.unitPrice} />
							</td>
							<td valign="top">
								{item.productOrTask.totals ? <Amount value={item.productOrTask.totals.totalNet} /> : '-'}
							</td>
							<td valign="top">
								{item.productOrTask.totals ? <Amount value={item.productOrTask.totals.totalVat} /> : '-'}
							</td>
							<td valign="top">
								{item.productOrTask.totals ? <Amount value={item.productOrTask.totals.totalGross} /> : '-'}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{/* <table>
					<tbody>
						<tr>
							<td><strong>Getrouwheidskorting</strong></td>
							<td></td>
							<td><strong>-10%</strong></td>
							<td><strong>€ 1.250</strong></td>
						</tr>
					</tbody>
				</table> */}
		</div>
	)
}

interface InvoiceHeaderProps {
	invoice: Invoice,
	pageNumber?: number,
	totalPages?: number,
}

const InvoiceFooterWithTotals = (props: InvoiceHeaderProps) => (
	<div className="invoicePDF__totals">
		<div className="invoicePDF__totalsNote">
			<strong><ReactMarkDown source={props.invoice.footerNote}></ReactMarkDown></strong>
		</div>
		<div className="invoicePDF__totalsColumns">
			<div className="column">
				<div>

					We ontvangen uw betaling graag <br />
					voor <strong><DateTime dateValue={props.invoice.invoiceDue.dueOnDate} /></strong> <br />
					op rekening <strong>{props.invoice.fromParty.bankAccount.iban}</strong><br />
					met mededeling <strong>{props.invoice.paymentReference}</strong>
				</div>
			</div>
			<div className="column has-color">
				<ul>
					<li>
						<strong>Subtotaal</strong>
						<span><Amount value={props.invoice.totals.totalNet}></Amount></span>
					</li>
					{/* <li>
						<strong>Korting</strong>
						<span>- € 10,00</span>
					</li> */}
					<li>
						<strong>Btw</strong>
						<span><Amount value={props.invoice.totals.totalVat}></Amount></span>
					</li>
					<li>
						<strong>Totaal</strong>
						<span><Amount value={props.invoice.totals.totalGross}></Amount></span>
					</li>
				</ul>
			</div>
		</div>
		<div className="invoicePDF__pageNumber position-fixed">
			pagina {props.pageNumber}/{props.totalPages}
		</div>
	</div>
);

interface InvoiceFooterProps {
	pageNumber: number,
	totalPages: number,
	invoiceNumber: string,
	invoice: Invoice
}


const InvoiceFooter = (props: InvoiceFooterProps) => (
	<div className="invoicePDF__totals type-compact">
		{/* <div className="column">
			<div>
				<strong>Pagina {props.pageNumber} van {props.totalPages}</strong>
			</div>
			<div>
				<br /><br />
				<strong>Betaling</strong><br />
				We ontvangen uw betaling graag <br />
			</div>
		</div> */}
		<div className="invoicePDF__compactFooter">
			<div className="invoicePDF__compactFooterInfo">
				<div className="invoicePDF__compactFooterInfoRow">
					<div><strong>{props.invoice.fromParty.name}</strong></div>
					<div>{props.invoice.fromParty.address.join(', ')}</div>
					<div>{`BTW nr. ${props.invoice.fromParty.taxIdentificationNumber}`}</div>
				</div>

				{ (props.invoice.fromParty.websiteAddress || props.invoice.fromParty.emailAddress || props.invoice.fromParty.phoneNumber) &&
				<div className="invoicePDF__compactFooterInfoRow">
					{props.invoice.fromParty.websiteAddress && <div>| {props.invoice.fromParty.websiteAddress}</div>}
					{props.invoice.fromParty.emailAddress && <div>| {props.invoice.fromParty.emailAddress}</div>}
					{props.invoice.fromParty.phoneNumber && <div>| {props.invoice.fromParty.phoneNumber}</div>}
				</div>
				}
			</div>
			<div className="invoicePDF__pageNumber">
				pagina {props.pageNumber}/{props.totalPages}
			</div>
		</div>
	</div>
);