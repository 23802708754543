import React, { useState, useContext } from 'react';
import { Box, BoxHeader, SidePanelContext, Bug } from '../../index';
//import { dataservice } from "../../00_global/service/dataservice";
import { Search, Heading, Paragraph } from '../../index'
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE } from "../../constants";
//import { isInDebug } from '../../00_global/isInDebug';
import { Formik } from 'formik';
import { Input, Field, FormField, Button } from "../../";
import * as Yup from "yup";
import { notNull } from '../../00_global';
import { searchResultItemBuilder } from '../../03_molecules/search/helpers';
import { DateField } from '../../03_molecules/date/DateField';
import * as moment from 'moment';
import { addDays } from 'date-fns'

//const debug = isInDebug('Inbox') && isInDebug('SupplierField');

const formValidationSchema = Yup.object().shape({
    amount: Yup.string()
        .required('Verplicht veld!'),
});

export const CurrencyConversionPanel = ({ amount = '', currency, baseValue = '', documentDate, onSettingsChanged, exchangeRate=1 }) => {

    notNull(onSettingsChanged, 'onSettingsChanged');
    console.log('amount', amount);
    
    const [error, setError] = useState('');
    
    const ctx = useContext(SidePanelContext);
    
    const onSubmit = async (values, actions) => {
    const { amount, currency, baseValue } = values;

        try {
            setError(null);
            //const response = await dataservice.createSupplier(supplierName);
            //const {supplierId} = response;
            const parameters = {
                amount, currency, baseValue
            }
            onSettingsChanged && onSettingsChanged(parameters)
            ctx.close();
        }
        catch (err) {
            setError(err);
        }
        finally {
            actions.setSubmitting(false);
        }
    }

    const searchResults = [
        {isoCode: 'EUR', name: 'euro', exchangeRate: 1},
        {isoCode: 'USD', name: 'dollar', exchangeRate: 0.8},
    ]

    const mappedResults = searchResults.map((sr) => {
        const subTitle = <span>{sr.name}</span>;
        const children = (
        <>
            <Heading size={HEADING_SIZE.xxs} weight={HEADING_WEIGHT.bold} spacing={HEADING_SPACING.zero} color="fc--primary-1">{sr.isoCode}</Heading>
            <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-4">{subTitle}</Paragraph>
        </>)
        return searchResultItemBuilder(sr.key, () => console.log('selected', sr), children);
    })

    return (
        <Box plain={true} header={<BoxHeader title={'Valuta aanpassen'} />}>
            <Formik
                initialValues={{ amount, currency, baseValue, documentDate, exchangeRate }}
                validationSchema={formValidationSchema}
                validateOnChange={true}
                
                //validateOnBlur={true}
                //validate={handleValidate}
                onSubmit={async (values, actions) => {
                    await onSubmit(values, actions);
                    actions.setSubmitting(false);
                }}>
                {
                    (formikProps) => {
                        const { isSubmitting, handleSubmit, setFieldValue } = formikProps;
                        const onDocumentDateSelected = (date) =>{
                            const fieldName = 'documentDate'
                            moment(date).isValid() 
                            ? setFieldValue(fieldName, moment(date).format())
                            : setFieldValue(fieldName, null);
                        };
                        return (
                            <>
                                {!error && <form className="form" onSubmit={handleSubmit}>
                                    <FormField>
                                        <Field id={'amount'} infoMessage={'Het bedrag op de factuur'} header={'Bedrag'}>
                                            <Input />
                                        </Field>
                                        <Field id={'currency'} infoMessage={'Valuta waarin het document is opgesteld'} header={'Munt'}>
                                            <Search
                                                onSearch={() => {}}
                                                onBlur={() => {}}
                                                openOnFocus={true}
                                                searchQuery={''}
                                                results={mappedResults}
                                                onSelect={(value) => console.log(value)}
                                                value={currency}
                                            />
                                        </Field>
                                        <Field id={'documentDate'} infoMessage={'Wordt gebruikt om de koers op te halen'} header={'Koersdatum'}>
                                            <DateField placeholder={'Document datum'} disabled={false} propertyName={'documentDateFormValue'} onSelect={onDocumentDateSelected} maxDate={addDays(new Date(), 60)} />
                                        </Field>
                                        <Field id={'exchangeRate'} infoMessage={'Wisselkoers naar EURO'} header={'Koers'}>
                                            <Input />
                                        </Field>
                                        <div style={{ 'marginTop': '16px' }}><Button type={'submit'} disabled={isSubmitting}>Opslaan</Button></div>
                                    </FormField>
                                </form>}
                                {error && <Bug error={error} />}
                            </>
                        )
                    }
                }
            </Formik>
        </Box>
    )
}