import React, { useState, useContext } from 'react';
import { SidePanelManager, ContentContainer, Redirectable, FetchDataState, Button, SidePanelContext } from '../..';
import { FetchData, ShowObject } from '../../00_global';
import { getProductsResponse, productService } from '../invoice_module_v1/invoiceService';
import { CreateProductPanel } from './CreateProductPanel';
import { ISidePanelContext } from '../../08_invoices/InvoiceModule';
import { defaultPinProps, defaultCloseProps } from '../invoice_module_v1/CreateInvoice';
import { Heading } from '../../02_atoms/headings/Heading';
import { HEADING_SIZE, HEADING_SPACING, COLOR } from '../../constants';

const ProductOverviewElement = () => {

    const [redirect, setRedirect] = useState('');
    const [version, setVersion] = useState(0);

    const panelContext = useContext<ISidePanelContext>(SidePanelContext);
    const { add, close } = panelContext;

    const showSidepanel = (title = '', content: any) => {
        //(panel, blocking = false, pinProps = defaultPinProps, closeProps = defaultCloseProps)
        add(content, false, defaultPinProps, defaultCloseProps);
    }

    const closeSidepanel = () => {
        close();
    }

    const handleNewProductClicked = () => {
        // open panel
        const panel = <CreateProductPanel  />
        showSidepanel('Nieuw product', panel);
    }

    return (
        <Redirectable to={redirect}>
            <FetchData method={productService.getProductsAsync} triggerValues={[version]}>
                {
                    ({ data, loading, error }: FetchDataState<getProductsResponse>) => {
                        if (data && data.products && data.products.length === 0) {
                            return <>Er zijn nog geen producten</>
                        }
                        if (data) {
                            return (
                               <>
                                <div className="moduleHeader">
                                    <div className="row" style={{ alignItems: 'flex-end' }}>
                                        <div className="col-md-7">
                                            <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.small}>
                                                Overzicht producten
                                            </Heading>
                                            <Button type={'button'} inverted color={COLOR.secondary_2} onClick={() => handleNewProductClicked()}>
                                                <i className="material-icons">add</i>
                                                Nieuw product
                                            </Button>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="moduleHeader__search">
                                                <Button type={'button'} inverted color={COLOR.secondary_2} onClick={() => setVersion(version+1)}>
                                                    <i className="material-icons">add</i>
                                                    Refresh
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               <ShowObject value={{
                                   data,loading,error
                               }} />
                               </>
                            )
                        }
                        return null;
                    }
                }
            </FetchData>
        </Redirectable>
    )
}

export const ProductOverview = () => {
    return (
        <SidePanelManager >
            <ContentContainer main={<ProductOverviewElement />} />
        </SidePanelManager>
    )
}