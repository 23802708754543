export const notNull = (value, propertyName, message = '') => {
    if (!value) {
      throw new Error('value for [' + propertyName + '] is missing. ' + message);
    }
  };

  export const notUndefined = (value, propertyName, message = '', warnOnly = false) => {
    if (value === undefined) {
      const errorMessage = 'value for [' + propertyName + '] is undefined. ' + message;
      console.warn(errorMessage);
      if (!warnOnly) {
        throw new Error(errorMessage);
      }
    }
  };