import React, { useState } from 'react';
import './_invoice-v2.scss';
import { Paragraph, DateTime, Amount, isInDebug } from '../../index';
import { PARAGRAPH_SIZE } from "../../constants";
import { ShowObject } from '../../00_global';
import { VatAmount } from './';
import { RowActions } from './InvoiceComponents';
import { InvoiceLineItem } from './models/api';
import cx from 'classnames';
import { ToPeriod } from './models/api';
import { TotalAmount } from './VatAmount';

  interface TaskOrProductLineProps {
	line: InvoiceLineItem;
	onDelete(line: InvoiceLineItem) : any,
	onEdit(line: InvoiceLineItem) : void,
	setStayOpen?: any
  }

   export interface UnitTranslation {
      key: string;
      value: string;
      plural: string;
  }

  const units: UnitTranslation[] = [
    {key: 'd', value: 'dag', plural: 'dagen'},
    {key: 'y', value: 'jaar', plural: 'jaren'},
	{key: 'pce', value: 'stuk', plural: 'stuks'},
	{key: 'kg', value: 'kilo', plural: 'kilo'}
  ]

interface PlaceHolderProps {
	for: string
}

const Placeholder = (props: PlaceHolderProps) => {

	const debug = isInDebug('dev');

	return (
		<>
		{debug && <span>{props.for}</span> }
		<div className="invoice__placeholder"></div>
		</>
	)
}

interface PeriodProps {
	value:ToPeriod
}
const Period = ( {value}: PeriodProps) => {
	return (
		<>
			<DateTime dateValue={value.fromDate} /> - <DateTime dateValue={value.toDate} />
		</>
	)
}

export const TaskOrProductLine = ({ line, onDelete, onEdit }: TaskOrProductLineProps) => {
	if (!line || ! line.productOrTask)
		return null;

	const pt = line.productOrTask;

	const { lineItemId, productName, description, note, unit, unitPrice, taxPercentage, quantity, relates } = pt;
	const period = relates && relates.toPeriod;

	const isActive = lineItemId === 'todo'; // todo: retrieve from props or status on line?
	const [stayOpen, setStayOpen] = useState(false);

	const lineStyle = cx(
		'invoice__line',
		{'stay-open': stayOpen}
	);

	const rowStyle = cx('row invoice__lineRow invoice__lineItem',
        //{[iconSize]: iconSize !== false},
		{'is-active': isActive !== false},
		{'stay-open': stayOpen}
	);

	const spacer = (description && note) ? ' - ' : '';
	const debug = isInDebug('invoiceLines');

	return (
		<>
		{debug && <ShowObject value={{...pt}} />}
		<div className={lineStyle}>
			<div className={rowStyle}>
				<div className={'col-md-5'}>
					<Paragraph size={PARAGRAPH_SIZE.medium}>
						<span className="invoice__lineLabel">
							{ productName ? productName : (<div className="invoice__placeholder"></div>)}

							{productName ? (
								<span className={'invoice__lineNote is-description'}>{description}{spacer}{note}</span>
							) : (
								<div className="invoice__placeholder size-s"></div>
							)}
						</span>
					</Paragraph>
				</div>
				<div className={'col-md-1'}>
					<Paragraph size={PARAGRAPH_SIZE.medium}>
						<span className="invoice__lineLabel">
							{ quantity ? (
								<>
									{quantity} {unit}
									{period && (<span className={'invoice__lineNote'}><Period value={period} /></span>)}
								</>
							) : (
								<Placeholder for={'qty and unit'} />
							)}
						</span>
					</Paragraph>
				</div>
				<div className={'col-md-2'}>
					<Paragraph size={PARAGRAPH_SIZE.medium}>
						<span className="invoice__lineLabel">
							{ unitPrice ? (
								<>
									<Amount value={unitPrice} />
									<span className={'invoice__lineNote'}>{'Eenheidsprijs (excl. BTW)'}</span>
								</>
							): (
								<Placeholder for={'unit amount'} />
							)}
						</span>
					</Paragraph>
				</div>
				<div className={'col-md-1'}>
					<Paragraph size={PARAGRAPH_SIZE.medium}>
						<span className="invoice__lineLabel">
							{ (quantity && unitPrice) ? (
								<>
									<Amount value={quantity * unitPrice} />
									<span className={'invoice__lineNote'}>{'excl. btw'}</span>
								</>
							) : (
								<Placeholder for={'total amount'} />
							)}
						</span>
					</Paragraph>
				</div>
				<div className={'col-md-1 text-center'}>
					<Paragraph size={PARAGRAPH_SIZE.medium}>
					<span className="invoice__lineLabel">
							{ line.productOrTask.totals	 ? (
								<VatAmount totals={line.productOrTask.totals} />
							) : <Placeholder for={'BTW'} />}
						</span>
					</Paragraph>
				</div>
				<div className={'col-md-2 text-right'}>
					<Paragraph size={PARAGRAPH_SIZE.medium}>
						<span className="invoice__lineLabel">
							{ line.productOrTask.totals	 ? (
								<TotalAmount totals={line.productOrTask.totals} />
							) : <Placeholder for={'Totaal'} />}
						</span>
					</Paragraph>
				</div>
				<RowActions onEdit={() => onEdit(line)} canEdit={true} onDelete={() => onDelete(line)} setStayOpen={setStayOpen} />
			</div>
		</div>
		</>
	)
}