import React from 'react';
import cx from 'classnames';
import './styles/_confirmation.scss';

export const Confirmation = ({ children, message, open = false, align="right", valign="top", ...props }) => {

    // create classes
    const styles = cx(
        'confirmation',
        { 'is-open' : open},
        `align-${align}`,
        `valign-${valign}`
    );

    // render html
    return (
        <div className={styles}>
            <div className="confirmation__inner">
                <div className="confirmation__trigger">
                    {children}
                </div>
                <div className="confirmation__message">
                    {message}
                </div>
            </div>
        </div>
    );

};