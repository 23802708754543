import React from 'react';
import { Formik } from 'formik';
import { Input, Field, FormField } from "../../";
import { notNull } from '../../00_global';

export const SearchBar = ({ onSearchQueryChanged, initialValue = '' }) => {

	notNull(onSearchQueryChanged, 'onSearchQueryChanged');

	const fieldName = 'searchQuery';
	return (
		<div className="row">
			<div className="col-sm-12 col-md-12 col-lg-12">
				<Formik
					initialValues={{ searchQuery: initialValue }}
					validateOnChange={true}
					onSubmit={(values, actions) => {
						const fieldValue = values[fieldName];
						try {
							console.log('submitting', values);
							onSearchQueryChanged(fieldValue);
						}
						finally {
							actions.setSubmitting(false);
						}
					}}
				>
					{
						(formikProps) => {
							const { handleSubmit } = formikProps;
							return (
								<>
									{<form className="form" onSubmit={handleSubmit}>
										<FormField bottomGap="spacing-b-s">
											<Field id={fieldName} infoMessage={'Geef je zoektermen in om de resultaten te filteren'} header={'Doorzoek je archief'}>
												<Input />
											</Field>
										</FormField>
									</form>}
								</>
							)
						}
					}
				</Formik>
			</div>
		</div>
	)
}