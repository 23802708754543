import React, { useState } from 'react';
import { Box, Heading, Ellipsis, FormField, Button, DateTime, DateField, ShowObject, Field } from '../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING } from '../../constants';
import { Formik } from 'formik';
import { InputField } from './InputField';
import { Invoice } from './models/api';
import moment from 'moment';
import { Dropdown } from './Dropdown';
import { SearchItem } from '../../03_molecules/search/SearchV2';
import { InvoiceTemplateDto } from './models/invoiceSettings';
import { translateInvoiceStatusEnum } from './_translateInvoiceStatus';
import { ERROR_REQUIRED_FIELD } from './addInvoiceLineValidationSchema';

interface UpdateInvoiceSettingsPanelProps {
    invoice: Invoice,
    templates: InvoiceTemplateDto[],
    updateDraft(invoice: Invoice): void,
    onTemplateChanged(templateId: string): Promise<void>,
}

const fields = {
    templateId: '_templateId',
    templateName: '_templateName',
    invoiceState: 'invoiceState',
    invoiceStateId: 'invoiceStateId',
    invoiceDate: 'invoiceDate',
    dueDate: 'dueDate',
    invoiceNumber: 'invoiceNumber',
    paymentReference: 'paymentReference',
    footerNote: 'footerNote',
    fiscalYearBasedSequenceNumber: 'fiscalYearBasedSequenceNumber',
    fiscalYear: 'fiscalYear',
    sendOn: 'sendOn'
}

export const UpdateInvoiceSettingsPanel = (props: UpdateInvoiceSettingsPanelProps) => {

    const { invoice, templates } = props;
    const selectedSetting = templates.find(item => item.id && item.id === invoice.templateId);

    const _invoiceStates: SearchItem[] = [
        { id: '1', caption: translateInvoiceStatusEnum(1), note: 'Factuur wordt opgemaakt', value: 1 },
        { id: '2', caption: translateInvoiceStatusEnum(2), note: 'Verzonden naar de klant', value: 2 },
        { id: '3', caption: translateInvoiceStatusEnum(3), note: 'Factuur is betaald', value: 3 },
    ]

    const selectedState = _invoiceStates.find(item => item.value === invoice.invoiceStatus);

    const initialValues = {
        [fields.templateId]: invoice.templateId,
        [fields.templateName]: selectedSetting ? selectedSetting.name : '',
        [fields.invoiceStateId]: selectedState ? selectedState.value : '-',
        [fields.invoiceState]: selectedState ? selectedState.caption : '-',
        [fields.fiscalYear]: invoice.fiscalYear,
        [fields.fiscalYearBasedSequenceNumber]: invoice.fiscalYearBasedSequenceNumber,
        [fields.invoiceDate]: invoice.invoiceDate,
        [fields.dueDate]: invoice.invoiceDue.dueOnDate,
        [fields.invoiceNumber]: invoice.invoiceNumber,
        [fields.paymentReference]: invoice.paymentReference ? invoice.paymentReference : '',
        [fields.footerNote]: invoice.footerNote,
        [fields.sendOn]: invoice.sendOn ? invoice.sendOn : undefined,
    }

    const handleValidate = (values: any) => {
        
        let errors: any = {}
        
        if (values[fields.invoiceStateId] === 2) {
            if (!values[fields.sendOn]){
                errors[fields.sendOn] = ERROR_REQUIRED_FIELD
            }
        }
        return errors;
    }

    const updateInvoice = (values: any, errors: any) => {

        const updated: Invoice = {
            ...invoice,
            templateId: values[fields.templateId],
            invoiceDate: values[fields.invoiceDate],
            invoiceDue: {
                dueOnDate: values[fields.dueDate],
                dueOnReceipt: false
            },
            footerNote: values[fields.footerNote],
            invoiceNumber: values[fields.invoiceNumber],
            paymentReference: values[fields.paymentReference],
            fiscalYear: +values[fields.fiscalYear],
            fiscalYearBasedSequenceNumber: +values[fields.fiscalYearBasedSequenceNumber],
            invoiceStatus: values[fields.invoiceStateId],
            sendOn: values[fields.sendOn]
        }

        props.updateDraft(updated);
    }

    const _templates: SearchItem[] = props.templates.map(t => {
        return {
            id: t.id ? t.id : '',
            caption: t.name,
            note: t.name !== t.from.name ? t.from.name : '',
            value: t.id
        }
    });

    return (
        <Box id={'update_invoice_settings'} noBorder color="bgc--white">
            <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.small}>
                <Ellipsis>{'Factuur instellingen'}</Ellipsis>
            </Heading>
            <Formik
                initialValues={initialValues}
                validationSchema={undefined}
                validateOnChange={true}
                validateOnBlur={true}
                validate={handleValidate}
                onSubmit={updateInvoice}
            >
                {
                    // render func
                    (formikProps) => {
                        const { handleSubmit, errors, values, setFieldValue, isValid, isSubmitting } = formikProps

                        const onDateSelected = (fieldName: string, date: string) => {
                            moment(date).isValid()
                                ? setFieldValue(fieldName, moment(date).format())
                                : setFieldValue(fieldName, null);
                        };

                        const onTemplateSelected = async (item: SearchItem) => {
                           const selectedSetting = templates.find(x => x.id && x.id === item.id);
                       
                           setFieldValue(fields.templateId, item.id);
                           setFieldValue(fields.templateName, item.caption);

                          // refresh
                          // await props.onTemplateChanged(item.id);

                          setFieldValue(fields.footerNote, selectedSetting ? selectedSetting.footerMessage : '')
                        }

                        const onInvoiceStateChanged = (item: SearchItem) => {
                            setFieldValue(fields.invoiceStateId, item.value);
                            setFieldValue(fields.invoiceState, item.caption);
                        }

                        const selectedState = values[fields.invoiceStateId]

                        return (
                            <form className="form" onSubmit={handleSubmit}>
                                <Dropdown header={'Status'} id={fields.invoiceState} infoMessage={''} results={_invoiceStates} onSearch={() => { }} onItemSelected={onInvoiceStateChanged} key={'invoiceState_dd'} formik={formikProps} blockInput={true} />
                                {selectedState === 2 && <FormField>
                                    <Field id={fields.sendOn} header={'Verzonden op *'} infoMessage={''}>
                                        <DateField formik={formikProps} placeholder={''} propertyName={fields.sendOn} onSelect={(dte: string) => onDateSelected(fields.sendOn, dte)} />
                                    </Field>
                                </FormField>}
                                <Dropdown header={'Sjabloon'} id={fields.templateName} results={_templates} onSearch={() => { }} onItemSelected={onTemplateSelected} key={'template_dd'} formik={formikProps} blockInput={true} />
                                <FormField>
                                    <Field id={fields.invoiceDate} header={'Factuur datum *'} infoMessage={''}>
                                        <DateField formik={formikProps} placeholder={'factuur datum'} propertyName={fields.invoiceDate} onSelect={(dte: string) => onDateSelected(fields.invoiceDate, dte)} />
                                    </Field>
                                </FormField>
                                <FormField>
                                    <Field id={fields.dueDate} header={'Vervaldatum'} infoMessage={'Uiterste datum waarop de klant mag betalen'}>
                                        <DateField formik={formikProps} placeholder={'factuur datum'} propertyName={fields.dueDate} onSelect={(dte: string) => onDateSelected(fields.dueDate, dte)} />
                                    </Field>
                                </FormField>
                                <InputField formik={formikProps} id={fields.fiscalYearBasedSequenceNumber} header={'Volgnummer'} infoMessage={`Verplicht oplopend nummer per boekjaar`} />
                                <InputField formik={formikProps} id={fields.fiscalYear} header={'Boekjaar'} infoMessage={''} />
                                <InputField formik={formikProps} id={fields.paymentReference} header={'Mededeling betaling'} infoMessage={''} />
                                <InputField formik={formikProps} id={fields.footerNote} header={'Notitie'} infoMessage={'Wordt onderaan de factuur afgedrukt'} multiline={true} />
                                <FormField>
                                    <Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button>
                                </FormField>
                            </form>
                        )
                    }
                }
            </Formik>
        </Box>
    )
}