import React from 'react';
import { Redirect } from "react-router-dom";

interface RedirectableProps {
    children: any,
    to?:string
}

export const Redirectable = ({ to, children }: RedirectableProps) => {
    if (to)
        return <Redirect to={to}></Redirect>
    else {
        return children
    }
}