import React from 'react';
import cx from 'classnames';
import './styles/_table.scss';
import { TableCell } from '../../06_features/export/ExportOverview';
import { Ellipsis } from '../..';

interface TableRowProps {
    children: any,
    isClickable?: boolean,
    isSelected?: boolean
}

export const TableRow = ({children, isClickable= false, isSelected = false } : TableRowProps) => {

    const rowStyle = cx('table__row',
    { 'is-clickable': isClickable },
    { 'is-selected': isSelected }
)

    return (
        <div className={rowStyle}>
            {children}
        </div>
    )
}

export const TextCell = (props: any) => {
    const {caption, definition} = props
    return (
        <TableCell key={definition.property} definition={definition}>
                <Ellipsis>{caption}</Ellipsis>
        </TableCell>
    )
}