import React, { useContext, useState } from 'react';
import { RouteComponentProps } from "react-router-dom";
import { FetchData, SidePanelManager, ContentContainer, SidePanelContext, Redirectable, FetchDataState } from '../..';
import { Invoice, CustomerDetailDto } from './models/api';
import { InvoiceDataProvider, ManageSidepanelState, ISidePanelContext, InvoiceDataProviderProps } from '../../08_invoices/InvoiceModule';
import { productService, getInvoiceResponse, invoiceApiResponse } from './invoiceService';
import { SelectCustomer } from '../customers/SelectCustomerPanel';
import { UpdateInvoiceSettingsPanel } from './UpdateInvoiceSettingsPanel';

export const defaultPinProps = {
    toolTip: '',
    canPin: true,
    pinned: false
}

export const defaultCloseProps = {
    canClose: false,
    onClose: () => true
};

interface CreateInvoiceRouteInfo {
    invoiceId: string
}

interface CreateInvoiceElementProps {
    invoice: Invoice,
    refresh(): void,
    saveInvoice(invoice: Invoice) : Promise<invoiceApiResponse>
}

const CreateInvoiceModule = (props: CreateInvoiceElementProps) => {

    // must be wrapped in a panel context!
    const { invoice, refresh, saveInvoice } = props;

    const panelContext = useContext<ISidePanelContext>(SidePanelContext);
    const { add, close } = panelContext;

    const showSidepanel = (title = '', content: any) => {
        //(panel, blocking = false, pinProps = defaultPinProps, closeProps = defaultCloseProps)
        add(content, false, defaultPinProps, defaultCloseProps);
    }

    const closeSidepanel = () => {
        close();
    }

    const handlePartySelected = async (customer: CustomerDetailDto) => {

        console.log(customer);
        
        const draft: Invoice = { ...invoice, toParty: customer.party }
        const response: invoiceApiResponse = await saveInvoice(draft);

        if (response.status  === 0) {
            refresh();
            closeSidepanel();
        }

        
    }

    const openCustomerPanel = () => {
        showSidepanel('Kies de klant?', <SelectCustomer onPartySelected={handlePartySelected} onCreatePartyClicked={openCreateCustomerPanel} />)
    }

    const updateDraft = async (invoice: Invoice) => {
        const result = await productService.saveDraftInvoice({
            draft: invoice
        });
        if (result.status === 0)
        {
            refresh();
            closeSidepanel();
        }
    }

    const getTemplates = async () => {
        const list = await (await productService.getTemplatesAsync()).items;
        return list;
    }

    const handleTemplateChanged = async (templateId: string) => {
        const result = await productService.applyTemplateToInvoice(invoice.id, templateId);
        
        if (result.status === 0)
        {
            
            
        }
        refresh();
    }

    const showTemplatePanel = async () => {
        const list = await getTemplates();
        showSidepanel('', <UpdateInvoiceSettingsPanel onTemplateChanged={templateId => handleTemplateChanged(templateId)} invoice={invoice} updateDraft={updateDraft} templates={list} />)
    }

    // const handlePartyCreated = (customer: Customer) => {
    //     console.log('customer created', customer);
    // }

    const openCreateCustomerPanel = () => {
        //showSidepanel('Kies de klant', <CreateCustomerPanel onCustomerCreated={handlePartyCreated} />)
        redirectTo('/customers/overview'); // temp workaround (easy fix)
    }

    const [redirect, redirectTo] = useState('');

    const ctxValue: InvoiceDataProviderProps = {
        debug: true,
        invoiceData: invoice,
        deleteInvoice: async (id: string) => {
            const response = await productService.removeInvoice(id);
            if (response.status === 0)
                redirectTo('/invoices/overview')
        },
        refresh: refresh,
        openCustomerPanel: () => openCustomerPanel(),
        openCreateCustomerPanel: openCreateCustomerPanel,
        showTemplatePanel: showTemplatePanel,
        saveInvoice: async (invoice: Invoice) => {
            const response: invoiceApiResponse = await productService.saveDraftInvoice({
                draft: invoice
            });
            return response
        }
    }

    return (

        <Redirectable to={redirect}>
            <>
                <SidePanelManager >
                    <InvoiceDataProvider value={{ ...ctxValue, invoiceData: invoice }} >
                        <ContentContainer main={<ManageSidepanelState />} />
                    </InvoiceDataProvider>
                </SidePanelManager>
            </>
        </Redirectable>
    )
}

export const LoadInitialInvoice = (props: CreateInvoiceRouteInfo) => {

    const [version, setVersion] = useState(0);
    const saveDraft = (invoice: Invoice) => {
        return productService.saveDraftInvoice({
            draft: invoice
        })
    }

    return (<FetchData method={() => productService.getInvoiceAsync(props.invoiceId)} triggerValues={[version]} >
        {
            ({ data }: FetchDataState<getInvoiceResponse>) => {
                if (data && data.status === 0) {
                    return (<CreateInvoiceModule saveInvoice={saveDraft} invoice={data.item} refresh={() => setVersion(version+1)} />)
                }
            }
        }
    </FetchData>)
}

export const CreateInvoice = (props: RouteComponentProps<CreateInvoiceRouteInfo>) => {

    const { invoiceId } = props.match.params;

    return (
        <SidePanelManager >
            <ContentContainer main={<LoadInitialInvoice invoiceId={invoiceId} />} />
        </SidePanelManager>
    )

}