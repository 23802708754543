import { Formik, FormikProps } from "formik";import { Control, Button } from "../..";import { CONTROL_SIZE } from "../../constants";import { InputField } from "./InputField";
import { useState } from "react";



interface SearchInvoicesProps {
    enabled: boolean
}

 export const SearchInvoices = (props: SearchInvoicesProps) => {

    const {enabled} = props;

    if (!enabled)
        return null;

    const [isFilteringInvoices, setIsFilteringInvoices] = useState(false);

     return <div className="moduleHeader__search">
                {isFilteringInvoices ? (
                    <Formik initialValues={{}}
                        validationSchema={undefined}
                        validateOnChange={true}
                        validateOnBlur={true}
                        // validate={handleValidate}
                        onSubmit={() => {
                        }}>
                        {
                            (formikProps: FormikProps<any>) => (
                                <form>
                                    <div className="moduleHeader__searchClose">
                                        <Control size={CONTROL_SIZE.xsmall} isPlain onClick={() => { setIsFilteringInvoices(false); }}>
                                            <i className="material-icons">close</i>
                                        </Control>
                                    </div>
                                    <InputField
                                        id={'searchInvoiceStuff'}
                                        header={'DOORZOEK JE FACTUREN'}
                                        infoMessage={'Geef je zoektermen in om de resultaten te filteren'}
                                        formik={formikProps}
                                        placeholder="Vb. klantnaam"
                                        inputControl={<Control onClick={() => { alert('perform search'); }}><i className="material-icons">search</i></Control>}
                                    />
                                </form>
                            )
                        }
                    </Formik>
                ) : (
                        <>
                            <Button inverted onClick={() => { setIsFilteringInvoices(true); }}><i className="material-icons">search</i>Doorzoek je facturen</Button>
                        </>
                    )}
            </div>
 }