import React from 'react';
import './styles/_sticker.scss';
import cx from 'classnames';

export const Sticker = ({ children }) => {

	const style = cx(
		'sticker'
	);

	return (
		<div className={style}>
			{children}
		</div>
	)
};