import React from 'react';
import { Box, Paragraph, Heading, Ellipsis, FormField, Button } from '../../';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE, PARAGRAPH_SPACING } from '../../../components/constants';
import { Formik } from "formik";
import { ShowObject, notNull } from '../../00_global';
import { updateSyncSettingValidation } from './updateSyncSettingValidation';
import { Dropdown } from '../../06_features/invoice_module_v1/Dropdown';
import { getDocumentType, getSyncDirections } from './UpdateAccount';
import { ErrorBoundary } from '../../00_global/ErrorBoundary';
import { isInDebug } from '../../00_global/isInDebug';
import { InputField } from '../../06_features/invoice_module_v1/InputField.tsx';

export const UpdateSyncSetting = ({ folderConfig, onUpdate }) => {

    notNull(onUpdate, 'onUpdate event handler not specified');
    
    const handleValidate = (values, errors) => {
        
    }

    const syncDirections = getSyncDirections();

    const settingsToForm = () => {

        const { documentType, syncDirection } = folderConfig
        const selectedDirection = syncDirections.find(item => item.id === syncDirection);

        return {
            ...folderConfig,
            syncDirectionId: syncDirection,
            syncDirection: selectedDirection.caption,
            documentType: getDocumentType(documentType),
            documentTypeId: documentType,
            
        }
    }

    const formToDto = (values) => {
        return {
            ...folderConfig, 
            path: values['path']
    }
        
    }

    const onSubmit = async (values, actions) => {
        actions.isSubmitting = true;
        const updatedItem = formToDto(values);
        console.log('onSubmit', { ...values, updatedItem })
        await onUpdate(updatedItem);
        actions.isSubmitting = false;

    }

    const getDocumenttypes = () => {
        return [
            { id: 2, caption: getDocumentType(2), note: "documenten voor het verkoop dagboek" },
            { id: 3, caption: getDocumentType(3), note: "documenten voor het aankoop dagboek" },
            { id: 4, caption: getDocumentType(4), note: "BTW uittrekels, fiscale fiches, ..." }
        ]
    }

    const debug = isInDebug('dev');
    const formValues = settingsToForm(folderConfig)

    return (
        <>
            <ErrorBoundary>
                <Box noBorder color="bgc--white">
                    <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.normal} spacing={HEADING_SPACING.xxs}>
                        <Ellipsis text={'Synchronisatie - aanpassen'}></Ellipsis>
                    </Heading>
                    <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.zero} color="fc--gray-4">
                        <div>Vul in waar deze bestanden gedownload worden bij syncronisatie van dit dossier</div>
                    </Paragraph>
                    <Formik initialValues={formValues}
                        isInitialValid={true}
                        validationSchema={updateSyncSettingValidation}
                        validateOnChange={true}
                        validateOnBlur={true}
                        validate={handleValidate}
                        onSubmit={(values, actions) => {
                            onSubmit(values, actions);
                        }}>
                        {
                            (formik) => {

                                const { handleSubmit, errors, values, setFieldValue, isValid, isSubmitting } = formik;

                                const onDocumentTypeSelected = (item) => {
                                    setFieldValue('documentTypeId', item.id);
                                    setFieldValue('documentType', item.caption);
                                }

                                return (
                                    <>{debug && <ShowObject value={{values, errors, isValid:isValid }} />}
                                        <form className="form" onSubmit={handleSubmit}>
                                            <Dropdown header={'Type document'} id={'documentType'} placeholder={''} infoMessage={''} onSearch={getDocumenttypes} onItemSelected={onDocumentTypeSelected} />
                                            <Dropdown header={'Richting'} id={'syncDirection'} placeholder={''} infoMessage={''} onSearch={getSyncDirections} />
                                            <InputField formik={formik} header={'Pad'} id={'path'} placeholder={''} infoMessage={'Bestemming op de server'} multiline={folderConfig.path.length > 40} />
                                            <FormField>
                                                <Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button>
                                            </FormField>
                                        </form>
                                    </>

                                )
                            }
                        }
                    </Formik>
                </Box>
            </ErrorBoundary>
        </>
    )
}