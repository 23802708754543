import React, { useState } from 'react';
import { Button, Control } from '../..';
import { BUTTON_SIZE, COLOR, CONTROL_SIZE, HEADING_SIZE, HEADING_WEIGHT, PARAGRAPH_SIZE, PARAGRAPH_SPACING, LOADER_SIZE } from '../../constants';
import { Invoice } from './models/api';
import { Confirmation } from '../../03_molecules/confirmation/Confirmation';
import { Box } from '../../03_molecules/box/Box';
import { Heading } from '../../02_atoms/headings/Heading';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { invoiceFeatures } from './features';
import { Loader } from '../../02_atoms/loader/Loader';

interface InvoiceActionsProps {
    invoice: Invoice,
    send: InvoiceAction,
    scheduleSend: InvoiceAction,
    preview: InvoiceAction,
    deleteAction: InvoiceAction,
    downloadAction: InvoiceAction
}

interface InvoiceAction {
    isDisabled: boolean,
    isLoading?: boolean,
    onClick: any,
}

export const InvoiceActions = (props: InvoiceActionsProps) => {

    const { send, preview, deleteAction, downloadAction } = props;

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const handleAccept = () => {
        setDeleteConfirmationOpen(false);
        deleteAction.onClick();
    }

    const handleDecline = () => {
        setDeleteConfirmationOpen(false);
    }

 

    return (
        <Confirmation open={deleteConfirmationOpen} message={<InvoiceDeleteConfirmation handleAccept={handleAccept} handleDecline={handleDecline} />} align="right" valign="middle">
            <div className="invoice__totalsActions">
                <Button size={BUTTON_SIZE.full} color={COLOR.secondary_2} disabled={send.isDisabled} onClick={send.onClick}>
                    <i className="material-icons">near_me</i>Verzend nu</Button>
                {invoiceFeatures.scheduleSendInvoice &&
                    <Control size={CONTROL_SIZE.medium} isGhost disabled={send.isDisabled} onClick={send.onClick}>
                        <i className="material-icons">query_builder</i>
                    </Control>
                }
                {
                    preview.isLoading 
                    ? <Control size={CONTROL_SIZE.medium} isGhost disabled={true} onClick={() => {}}>
                        <i className="material-icons">watch_later</i>
                    </Control>
                    : <Control size={CONTROL_SIZE.medium} isGhost disabled={preview.isDisabled} onClick={preview.onClick}>
                            <i className="material-icons">visibility</i>
                        </Control>
                }
                
                <Control size={CONTROL_SIZE.medium} isGhost disabled={downloadAction.isDisabled} onClick={downloadAction.onClick}> <i className="material-icons">save_alt</i></Control>
                
                <Control size={CONTROL_SIZE.medium} isGhost color="color-error" disabled={deleteAction.isDisabled} onClick={() => { setDeleteConfirmationOpen(true); }}>
                    <i className="material-icons">delete</i>
                </Control>
            </div>
        </Confirmation>
    )

}

interface InvoiceDeleteConfirmationProps {
    handleAccept: any,
    handleDecline: any
}

const InvoiceDeleteConfirmation = ({ handleAccept, handleDecline, ...props }: InvoiceDeleteConfirmationProps) => {
    return (
        <Box border={true}>
            <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Ben je zeker?</Heading>
            <Paragraph size={PARAGRAPH_SIZE.small} spacing={PARAGRAPH_SPACING.xs}>Deze factuur wordt definitief verwijderd.</Paragraph>
            <div className="row no-pad-v">
                <div className="col-md-6">
                    <Button size={BUTTON_SIZE.full} color={COLOR.secondary_2} onClick={handleAccept}>Ja</Button>
                </div>
                <div className="col-md-6">
                    <Button size={BUTTON_SIZE.full} color="color-error" onClick={handleDecline}>Nee</Button>
                </div>
            </div>
        </Box>
    )
}