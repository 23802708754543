import React from 'react';
import './_invoice-v2.scss';
import { CONTROL_SIZE, PARAGRAPH_SIZE, HEADING_SIZE, HEADING_WEIGHT , HEADING_SPACING } from "../../constants";
import { Control, Heading, Paragraph } from '../../index';
import { RowActions } from './InvoiceComponents.tsx';

export const DiscountInvoiceLine = ({ ...props }) => (
	<div className="row invoice__lineRow">
		<div className="col-md-8">
			<Paragraph size={PARAGRAPH_SIZE.medium}>
				<span className="invoice__lineLabel">
					<strong>Getrouwheidskorting</strong>
				</span>
			</Paragraph>
		</div>
		<div className="col-md-4 text-right">
			<Paragraph size={PARAGRAPH_SIZE.medium}>
				<strong>-&euro;&nbsp;121,00</strong>
			</Paragraph>
		</div>

		<div className="invoice__rowActions">
			<Control size={CONTROL_SIZE.small} isGhost>
				<i className="material-icons">edit</i>
			</Control>
			<Control size={CONTROL_SIZE.small} color="color-error">
				<i className="material-icons">delete</i>
			</Control>
		</div>
		<RowActions />
	</div>
);

export const SpacerInvoiceLine = ({ ...props }) => (
	<div className="row invoice__lineRow">
		<div className="col-md-12"></div>
		<RowActions canEdit={false} />
	</div>
);

export const HeaderLine = () => {
	return (
		<div className="invoice__lineHeading row">
			<div className={`col-md-6`}>
				<Heading size={HEADING_SIZE.xs} spacing={HEADING_SPACING.zero} color="fc--primary-1" weight={HEADING_WEIGHT.bold}>
					<span className="invoice__lineLabel">
						Beschrijving
					</span>
				</Heading>
			</div>
			<div className={`col-md-6 text-right`}>
				<Heading size={HEADING_SIZE.xs} spacing={HEADING_SPACING.zero} color="fc--primary-1" weight={HEADING_WEIGHT.bold}>
					Totaal
				</Heading>
			</div>
		</div>
	)
}