import React, { useState } from 'react'
import './_periodSelection.scss'
import { Announcement } from '../../03_molecules/announcement/Announcement';
import { Heading } from '../../02_atoms/headings/Heading';
import { PeriodSelectorBubble } from '../../03_molecules/periodSelector/PeriodSelector';
import { CURRENT_PERIOD } from '../../constants';

export const PeriodSelection = ({children, selectedPeriod, handlePeriodChanged}) => {
   
    const [displayValue, setDisplayValue] = useState(CURRENT_PERIOD.label) // todo: retrieve from API
    const [selectedYear, setSelectedYear] = useState(2024);
    
    const getPeriod = (label, value, displayValue, enabled = true) => {
        return {
            text: label,
            value: value,
            enabled: enabled,
            displayValue: displayValue
        }
    }
    
    const periods_2017 = [
        getPeriod('Q1', 201701, '2017 - Q1'),
        getPeriod('Q2', 201702, '2017 - Q2'),
        getPeriod('Q3', 201703, '2017 - Q3'),
        getPeriod('Q4', 201704, '2017 - Q4')
    ]

    const periods_2018 = [
        getPeriod('Q1', 201801, '2018 - Q1'),
        getPeriod('Q2', 201802, '2018 - Q2'),
        getPeriod('Q3', 201803, '2018 - Q3'),
        getPeriod('Q4', 201804, '2018 - Q4')
    ]

    const periods_2019 = [
        getPeriod('Q1', 201901, '2019 - Q1'),
        getPeriod('Q2', 201902, '2019 - Q2'),
        getPeriod('Q3', 201903, '2019 - Q3'),
        getPeriod('Q4', 201904, '2019 - Q4')
    ]

    const periods_2020 = [
        getPeriod('Q1', 202001, '2020 - Q1'),
        getPeriod('Q2', 202002, '2020 - Q2'),
        getPeriod('Q3', 202003, '2020 - Q3'),
        getPeriod('Q4', 202004, '2020 - Q4')
    ]

    const periods_2021 = [
        getPeriod('Q1', 202101, '2021 - Q1'),
        getPeriod('Q2', 202102, '2021 - Q2'),
        getPeriod('Q3', 202103, '2021 - Q3'),
        getPeriod('Q4', 202104, '2021 - Q4')
    ]

    const periods_2022 = [
        getPeriod('Q1', 202201, '2022 - Q1'),
        getPeriod('Q2', 202202, '2022 - Q2'),
        getPeriod('Q3', 202203, '2022 - Q3'),
        getPeriod('Q4', 202204, '2022 - Q4')
    ]

    const periods_2023 = [
        getPeriod('Q1', 202301, '2023 - Q1'),
        getPeriod('Q2', 202302, '2023 - Q2'),
        getPeriod('Q3', 202303, '2023 - Q3'),
        getPeriod('Q4', 202304, '2023 - Q4')
    ]

    const periods_2024 = [
        getPeriod('Q1', 202401, '2024 - Q1'),
        getPeriod('Q2', 202402, '2024 - Q2'),
        getPeriod('Q3', 202403, '2024 - Q3'),
        getPeriod('Q4', 202404, '2024 - Q4')
    ]
    
    const periodPages = [
        { text: '2017', value: 2017, periods: periods_2017},
        { text: '2018', value: 2018, periods: periods_2018},
        { text: '2019', value: 2019, periods: periods_2019},
        { text: '2020', value: 2020, periods: periods_2020},
        { text: '2021', value: 2021, periods: periods_2021},
        { text: '2022', value: 2022, periods: periods_2022},
        { text: '2023', value: 2023, periods: periods_2023},
        { text: '2024', value: 2024, periods: periods_2024},
    ]

    const _handlePeriodChanged = (period) => {
        setDisplayValue(period.displayValue);
        handlePeriodChanged(period);
    }

    return (
        <div className={'periodSelection'}>
            <Announcement>
            <table>
                <tbody>
                    <tr>
                        <td> <Heading>Periode: {displayValue}</Heading></td>
                        <td><PeriodSelectorBubble
                            currentYear={selectedYear}
                            pages={periodPages}
                            selectedPeriod={selectedPeriod}
                            onPeriodSelected={(selectedPeriod) => _handlePeriodChanged(selectedPeriod)}
                            onYearChanged={(selectedYear) => setSelectedYear(selectedYear)}>
                            {children}
                        </PeriodSelectorBubble></td>
                    </tr>
                </tbody>
            </table>
        </Announcement>
        </div>
    )
}