import React from 'react';
import './styles/_searchResultItem.scss';
import cx from 'classnames';

export const SearchResultItem = ({ children, tinted=false, hover=false, id, onClick = () => null }) => {

	const style = cx(
		'searchResultItem',
		{ 'is-tinted': tinted === true },
		{ 'can-hover': hover === true },
	);

	return (
		<div className={style} onClick={() => onClick(id)}>
			<div className="searchResultItem__inner">
				{children}
			</div>
		</div>
	)
};

export const SearchResultItemV2 = ({ children, tinted=false, hover=false, id}) => {

	const style = cx(
		'searchResultItem',
		{ 'is-tinted': tinted === true },
		{ 'can-hover': hover === true },
	);

	return (
		<div className={style}>
			<div className="searchResultItem__inner">
				{children}
			</div>
		</div>
	)
};

