import React from 'react';
import './styles/_check.scss';
import { ReactComponent as CheckIcon } from './check.svg';
import { connect, getIn } from 'formik';
import { notNull } from '../../06_features/reconciliation';

const CheckboxElement = ({label, id, formik }) => {

    notNull(id, 'id');
    
    const formikProps = getIn(formik);

    notNull(formikProps, 'formikProps', 'failed to get into props.formik')

    const {name, value, values, onBlur, setFieldValue} = formikProps;
    const checked = values[id];

    const handleChange = (e) => {
        const isChecked = e.target.checked;
        setFieldValue(id, isChecked)
    }
    
    return (<div>
        <StyledCheck 
            name={name} 
            id={id}
            label={label}
            value={value}
            checked={checked}
            onChange={handleChange}
            onBlur={onBlur}
        />
    </div>)
}

export const StyledCheck = ({name, id, value, checked, onChange, onBlur, label}) => {
    notNull(onChange, 'onChange');
    
    return (<div className="check">
    <input type="checkbox" name={name} id={id} value={value} checked={checked} onChange={onChange} onBlur={onBlur} />
    <label htmlFor={id}>
        {label}
        <div className="check__icon">
            <CheckIcon />
        </div>
    </label>
</div>)
}

export const Checkbox = connect(CheckboxElement);