import React from 'react';
import './styles/_announcement.scss';
import cx from 'classnames';
import { Control } from "../../";
import {CONTROL_SIZE} from "../../constants";
import {CloseIcon} from "../../01_fundaments/icons";

export const Announcement = ({id, content, onClose, value, imageUrl='', transparent=false, error=false, children}) => {

	const style = cx(
		'announcement',
        {'is-transparent': transparent === true},
        {'fc--error': error === true}
    );

	return (
		<div className={style}>
			<div className="announcement__inner">

				{imageUrl !== '' &&
				<div className="announcement__icon">
					<img src={imageUrl} alt={content} />
				</div>
				}

				<div className="announcement__content">
					{content || children}
				</div>
			</div>
			<div className="announcement__control">
                {onClose && <Control isPlain size={CONTROL_SIZE.small} onClick={() => onClose(id)}>
                    <CloseIcon />
                </Control>}
			</div>
		</div>
	)

};