
export const defaultBarChartColor = 'rgba(143,183,218,1)';
export const highlightedBarChartColor = 'rgba(15,113,183,1)';

export const donutBackgroundColors = [
  'rgba(15,113,183,1)',
  'rgba(15,113,183,0.5)',
];

export const defaultColor = 'rgba(15,113,183,1)';

export const barChartOptions = {
  //maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
      display: true,
      gridLines: {
        display: false,
      },
      ticks : {
        display: false,
      },
    }],
    yAxes: [{
      display: false,
      stacked: true,
      ticks : {
        display: false,
      },
    }],
  },
};

export const donutOptions = {
  legend: {
    display: false,
  },
  cutoutPercentage: 60,
};
