import React from 'react';
import cx from 'classnames';
import { LogoIconColor } from "../../01_fundaments/logo";
import './styles/_brand.scss';

export const Brand = ({ size, ...props }) => {

    // define classes
    const style = cx(
        'brand',
        [size]
    );

    // render html
    return (
        <div className={ style }>
            <LogoIconColor />
        </div>
    );

};