import React from 'react';
import './styles/_box.scss';
import cx from 'classnames';

export const Box = ({ children, id='', plain = false, border = false, header = '', hover = false, noBorder = false, isActive = false, color='', onClick = () => null , fullHeight= false}) => {

	const style = cx(
		'box',
		{ 'has-border': border === true },
		{ 'is-plain': plain === true },
		{ 'can-hover': hover === true },
		{ 'is-active': isActive === true },
		{ 'is-fullHeight': fullHeight === true },
		{ 'no-border': noBorder === true },
		[color],
	);

	return (
		<div className={style} onClick={() => onClick(id)}>
			{header && header}
			<div className="box__body">
				{children && <div className="box__inner">
					{children}
				</div>}
			</div>
		</div>
	)
};