import React from 'react';
import './styles/_ellipsis.scss';
import cx from "classnames";

export const Ellipsis = ({text='', children = null}) => {

	const style = cx(
		'ellipsis'
	);

	return (
		<span className={style}>
			<span className="ellipsis__element">
				{children || text}
			</span>
		</span>
	)
};