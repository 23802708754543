import React, { useContext } from 'react';
import './_invoice-v2.scss';
import { Heading, Paragraph, Button, KeyValue, DateTime, Label } from '../../index';
import { HEADING_SIZE, HEADING_SPACING, HEADING_WEIGHT, PARAGRAPH_SPACING, BUTTON_SIZE, COLOR, LABEL_SIZE } from "../../constants";
import { notNull } from '../../00_global';
import { CustomerDetails, InvoiceDataContext } from '.';
import { InvoiceEditZone } from './InvoiceEditZone';
import { translateInvoiceStatusEnum } from './_translateInvoiceStatus';
import { invoiceService } from './invoiceService';


interface InvoiceStatusProps {
	invoiceStatusEnum: number
}

export const InvoiceStatus = (props: InvoiceStatusProps): any => {

	const status = props.invoiceStatusEnum;
	const translation = translateInvoiceStatusEnum(status);

	switch (status) {
		case 1:   return <Label size={LABEL_SIZE.small}>{translation}</Label>;
		case 2: return <Label size={LABEL_SIZE.small} color="gray-7">{translation}</Label>;
		case 3:  return <Label size={LABEL_SIZE.small} color="error">{translation}</Label>;
	case 4:  return <Label size={LABEL_SIZE.small} color="success">{translation}</Label>;
		default:      return '';
	}
}

export const InvoiceHeader = () => {

	const dataContext = useContext(InvoiceDataContext);
	notNull(dataContext, 'dataContext');

	const { invoiceData, openCustomerPanel, showTemplatePanel } = dataContext;

	notNull(invoiceData, 'invoiceData');

	if (!invoiceData) {
		return (
			<div>Invoice empty</div>
		)
	}

	const { invoiceDate, invoiceDue, toParty, paymentReference, invoiceNumber, fromParty, invoiceStatus } = invoiceData;
	const name = fromParty ? fromParty.name : <div>Selecteer afzender</div>
	const iban = fromParty ? fromParty.bankAccount.iban : ''

	return (
		<div className="invoice__defaults">
			<div className="row">
				<div className="invoice__defaultsCol col-md-12 col-lg-6">
					<InvoiceEditZone key={'from-details'} onClick={showTemplatePanel}>
						<Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.xxs} color="fc--primary-1" weight={HEADING_WEIGHT.bold}>
							Factuur {invoiceNumber}
						</Heading>
						<KeyValue compact labelSize="large" ellipsis canEdit values={[
							{ title: 'Van', value: name },
							{ title: 'Rekeningnummer', value: iban },
							{ title: 'Datum', value: <DateTime dateValue={invoiceDate} /> },
							{ title: 'Vervaldatum', value: <DateTime dateValue={invoiceDue.dueOnDate} /> },
							{ title: 'Mededeling', value: paymentReference },
							{ title: 'Status', value: <InvoiceStatus invoiceStatusEnum={invoiceStatus} /> },
						]} />
					</InvoiceEditZone>
				</div>
				<div className="invoice__defaultsCol col-md-12 col-lg-6">
					<InvoiceEditZone key={'company-customer-details'} onClick={openCustomerPanel}>
						<Heading size={HEADING_SIZE.xs} spacing={HEADING_SPACING.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">
							Aan:
						</Heading>
						{
							toParty ?
								(<Paragraph spacing={PARAGRAPH_SPACING.xs}>
									<CustomerDetails inSidebar={false} party={invoiceData.toParty} validation={{ success: true }} />
								</Paragraph>)
								:
								(<Button size={BUTTON_SIZE.medium} color={COLOR.secondary_2} onClick={openCustomerPanel} key={'selectCustomer'} >
									<div>Selecteer klant</div>
								</Button>)
						}
					</InvoiceEditZone>
				</div>
			</div>
		</div>
	);
}