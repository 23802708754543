import React, { useState, useContext } from 'react';
import '../styles/_portalmanager.scss';
import cx from 'classnames';
import { sleep } from '../../helpers';
import { NotificationManager, SidePanelManager } from "../..";
import { NotificationContext } from '../../00_global/context/NotificationContext';
import { Grid, GridColumn, GridRow, ConfirmDelete } from '../../index';
import { withRouter } from "react-router-dom";
import { FetchData } from '../../00_global/FetchData';
import { Bug, VisualFeedback } from '../../03_molecules/visualFeedback/VisualFeedback';
import PleasWaitImage from '../../06_features/inbox/download-undraw.png';
import { InboxDocumentViewer } from '../../06_features/inbox/_amo_';
import { ShowObject } from '../../00_global';
import { Ellipsis } from '../../02_atoms/ellipsis/Ellipsis';
import { notNull } from '../../06_features/reconciliation';
import { isInDebug } from '../../00_global/isInDebug';
import { getPortalService } from '../'

import { PARAGRAPH_SIZE, PARAGRAPH_WEIGHT, PARAGRAPH_SPACING } from '../../constants';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { DateTime } from '../../02_atoms/date/DateTime';

const LimitedViewer = ({ detail, onDeleteDocument }) => {

    const { documentId, pdfContent, contentHash } = detail;

    notNull(documentId, 'documentId');
    notNull(pdfContent, 'pdfContent');
    notNull(contentHash, 'contentHash')

    return (

        <InboxDocumentViewer
            key={contentHash}
            documentId={documentId}
            title={contentHash}
            content={pdfContent}
            onDelete={() => onDeleteDocument(detail.documentId)}
        />
    )
}


const AnnotateDocumentView = ({ accountId, document, onDeleteDocument }) => {

    const { documentId } = document;
    const debug = isInDebug('accountancyPortal');

    const svc = getPortalService();

    return (
        <FetchData method={() => svc.portal_getDocumentContent(accountId, documentId)} triggerValues={[documentId]}>
            {({ loading, error, data }) => {
                if (data) {
                    const { content, contentHash } = data;

                    const docWithContent = { ...document, pdfContent: content, contentHash }

                    return (
                        <>
                            {/* {data && <InboxFormViewer detail={docWithContent} />} } */}
                            {data && <LimitedViewer key={document.documentId} detail={docWithContent} onDeleteDocument={onDeleteDocument} />}
                            {data && debug && <ShowObject value={{ ...docWithContent, origin: 'AnnotateDocumentView' }} />}
                        </>
                    )
                }

                return (
                    <>

                        {error && error.response && error.response.status === 404}
                        {error && <Bug error={error} />}
                        {loading && <VisualFeedback title={'Even geduld'} subTitle={'Je bestand wordt gedownload'} image={PleasWaitImage} delay={100}></VisualFeedback>}
                    </>
                )
            }}
        </FetchData>
    )
};

const QueuedItem = ({ accountId, document, selectedDocument, onItemSelected }) => {

    // define variables
    const isActive = (selectedDocument && selectedDocument.documentId === document.documentId);

    // define classes
    const style = cx(
        'portal__box',
        { 'is-active': isActive }
    );

    return (
        <div className={style}>
            <div className="portal__boxContent" onClick={onItemSelected}>
                <Paragraph size={PARAGRAPH_SIZE.medium} weight={PARAGRAPH_WEIGHT.bold} color="fc--primary-1" nopadding>
                    <Ellipsis text={document.documentName} />
                </Paragraph>
                <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.zero} color="fc--gray-4">
                    <Ellipsis>Ontvangen op: <DateTime dateValue={document.receivedOn} /></Ellipsis>
                </Paragraph>
            </div>
            <div className="portal__boxTrigger" onClick={onItemSelected}>
                <i className="material-icons">list_alt</i>
            </div>
        </div>
    )
}

const ShowQueue = ({ accountId, documentList, selectedDocument, onItemSelected = (item) => { } }) => {

    notNull(accountId, 'accountId');
    const items = documentList.map(item => <QueuedItem accountId={accountId} key={item.documentId} document={item} selectedDocument={selectedDocument} onItemSelected={() => onItemSelected(item)} />)

    return (
        <>
            {items}
        </>
    )
}

const LoadQueue = (props) => {

    const { accountId, documentType } = props.match.params;
    const [selectedDocument, setSelectedDocument] = useState();
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const [reload, setReload] = useState(0);
    const { showNotificationToast, showErrorToast } = useContext(NotificationContext);
    
    const svc = getPortalService();

    const handleDeleteConfirmed = (documentId) => { 
        try {
            svc.portal_deleteDocument(accountId, documentId);
            sleep(3000); // wait until BE finished processing
            showNotificationToast('Document verwijderd');
        } catch (error) {
            showErrorToast(`Oeps... er ging iets mis: ${error}`);
        }
        
        setReload(reload + 1);
        setDocumentToDelete(null);
        setSelectedDocument(null);
    }

    const handleShowDelete = (documentId) => {
        setDocumentToDelete(documentId);
    }    

    const docTypeToFetch = documentType ? documentType : 1; // inbox by default

    return (
        <FetchData method={() => svc.portal_getDocumentQueue(accountId, docTypeToFetch)} triggerValues={[reload]}>
            {({ loading, error, data }) => {

                if (data && data.documentList.length > 0 && !selectedDocument){
                    const first = data.documentList[0];
                        setSelectedDocument(first);
                }

                return (
                    <div className="section">
                        <div className="section__inner">
                            <Grid>
                                <GridRow>
                                    <GridColumn size='col-5 col-lg-3'>
                                        {documentToDelete && <ConfirmDelete key={documentToDelete} detail={{documentToDelete}} onCancelled={() => setDocumentToDelete(null)} onConfirmed={() => handleDeleteConfirmed(documentToDelete)} />}
                                        {data && !documentToDelete && <ShowQueue accountId={accountId} documentList={data.documentList} onItemSelected={setSelectedDocument} selectedDocument={selectedDocument} />}
                                    </GridColumn>

                                    <GridColumn size={'col-7 col-lg-9'}>
                                        {selectedDocument && <AnnotateDocumentView accountId={accountId} document={selectedDocument} onDeleteDocument={handleShowDelete} />}
                                        {error && error.response && error.response.status === 404 && setReload(reload+1)}
                                        {error && <Bug error={error} />}
                                        {loading && <VisualFeedback title={'Even geduld'} subTitle={'Je bestand wordt gedownload'} image={PleasWaitImage} delay={500}></VisualFeedback>}
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </div>
                    </div>
                )
            }}
        </FetchData>
    )

}

const GetDocumentIdFromRoute = (props) => {

    return (
        <NotificationManager>
            <SidePanelManager>
                <LoadQueue {...props} />
            </SidePanelManager>
        </NotificationManager>
    )
}

export const AccountantInbox = withRouter(GetDocumentIdFromRoute);

// QueueViewer ->
//  loadQueue + showQueue (grid-3)
//  loadPdfContent -> documentViewer (grid-9)