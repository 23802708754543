import React from 'react';
import './styles/_label.scss';
import cx from "classnames";

import {LABEL_SIZE} from "../../constants";

export const Label = ({children, text='', size = LABEL_SIZE.default, color='primary-1'}) => {

    const style = cx(
    	'label',
    	[size],
    	'bgc--' + [color]
    );

    const content = children ? children : text;

    return (
        <div className={style}>
        	{content}
       	</div>
    )
};