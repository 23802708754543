import React from 'react';
import { withRouter } from "react-router-dom";
import './styles/_user.scss';
import cx from "classnames";

import {Ellipsis} from "../../";
import {DotsIcon} from "../../01_fundaments/icons";
import { routeWithSlug } from '../../00_global/routeWithSlug';

const UserElement = ({userName, companyName, limited = true, actions = [], history }) => {

	//notNull(settings, 'settings');
	//const { firstName, lastName, accountName } = settings;

	const style = cx(
		'user',
		{'is-limited': limited === true},
	);	

	return (
		<button className={style} onClick={() => history.push(routeWithSlug('settings'))}>
			<span className="user__icon">
				<i className="material-icons">person_outline</i>
			</span>
			<span className="user__content">
				<span className="user__name"><Ellipsis text={userName} /></span>
				<span className="user__account"><Ellipsis text={companyName} /></span>
			</span>
			{actions.length > 0 && // todo: action buttons in menu
					<span className="user__action">
					<DotsIcon />
				</span>
			}
		</button>
	)
};

export const User = withRouter(UserElement)