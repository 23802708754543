import React, {useState} from 'react'
import { SidePanelManager, ErrorBoundary, NotificationManager, ShowObject } from '../../index';
import { ContentContainer } from '../../index'
import { dataservice } from '../../00_global/service/dataservice';
import { isInDebug } from '../../00_global/isInDebug';
import { FetchData } from '../../00_global/FetchData';

import { Expenses, Revenue } from './Components';
import { PeriodSelection } from '../01_general/PeriodSelection';
import { CURRENT_PERIOD } from '../../constants';

const debug = isInDebug('Dashboard');

const DashboardMain = () => {

    const [selectedPeriod, setSelectedPeriod] = useState(CURRENT_PERIOD.value)// todo: retrieve value from settings - API

    const _handlePeriodChanged = (period) => {
        setSelectedPeriod(period.value);
    }

    return (
        <ErrorBoundary>
            <>
                <PeriodSelection selectedPeriod={selectedPeriod} handlePeriodChanged={_handlePeriodChanged} />
                <FetchData method={() => dataservice.getDashboard(selectedPeriod)} triggerValues={[selectedPeriod]} >
                    {
                        ({ data, error, loading }) => {
                            if (data) {
                                return (
                                    <>
                                        <Expenses data={data} />
                                        <Revenue data={data} />
                                    </>
                                )
                            }

                            return (
                                <>
                                    {debug && <ShowObject value={{ data, error, loading }} />}
                                </>
                            )
                        }
                    }
                </FetchData>

            </>
        </ErrorBoundary>
    )
}

export const Dashboard = () => {
    const main = <DashboardMain />

    return (
        <SidePanelManager>
            <NotificationManager>
                <ContentContainer
                    main={main}
                />
            </NotificationManager>
        </SidePanelManager>
    )
}