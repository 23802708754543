import React from 'react';
import './styles/_select.scss';
import cx from 'classnames';

import {ArrowDIcon} from "../../01_fundaments/icons";

export const Select = ({id, value, invalid=false, options=[], ...props}) => {
	
	const style = cx(
		'select',
        {'is-invalid': invalid === true}
	);
	
	const _ = options.map(o => (<option key={o} value={o}>{o}</option>))

    return (
    	<div className={style}>
			<select id={id}	value={value} className="select__element" {...props}>{_}</select>
			<div className="select__icon">
				<ArrowDIcon/>
			</div>
		</div>
    )
};