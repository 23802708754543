import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { FetchData, SidePanelManager, ContentContainer, SidePanelContext, Redirectable, FetchDataState } from '../..';
import { RouteComponentProps } from "react-router-dom";

import image from './samples/combell@2x.png';
import settings from './samples/combell.json';
import { Box } from '../../03_molecules/box/Box';

import './styles/_AnnotateModule.scss';
import { Heading } from '../../02_atoms/headings/Heading';
import { BUTTON_SIZE, HEADING_SIZE, HEADING_SPACING, HEADING_WEIGHT } from '../../constants';
import { Button } from '../../02_atoms/buttons/Button';
import { Control } from '../../02_atoms/control/Control';
import { Sticker } from '../../02_atoms/sticker/Sticker';

interface CreateInvoiceRouteInfo {
    documentId: string
}

const AnnotateCombell = () => {

    // define variables
    const page = settings.pages[0];
    const words = page.words;

    return (
        <div className="row">
            <div className="col-4">
                <Sticker>
                    <AnnotateAside />
                </Sticker>
            </div>
            <div className="col-8">
                <AnnotateDocument words={words} annotatedFields={settings.annotatedFields} />
            </div>
        </div>
    );

}

const AnnotateAside = () => {

    // check progress
    const documentProgress = Math.floor(75);

    // render html
    return (
        <Box>
            <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.xxs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Gevonden velden</Heading>
            <AnnotateProgressBar value={documentProgress} />
            <div className="annFields">
                <div className="annFields__list">
                    <div className="annFields__item">
                        <AnnotateField type="success" />
                    </div>
                    <div className="annFields__item">
                        <AnnotateField type="success" />
                    </div>
                    <div className="annFields__item">
                        <AnnotateField type="success" />
                    </div>
                    <div className="annFields__item">
                        <AnnotateField type="warning" />
                    </div>
                </div>
                <div className="annFields__actions">
                    <Button size={BUTTON_SIZE.full} color="color-secondary-2" disabled={documentProgress < 100}>
                        {documentProgress < 100 ? (
                            <span>Gelieve alle velden in te vullen</span>
                        ) : (
                            <span>Bevestigen</span>
                        )}
                    </Button>
                </div>
            </div>
        </Box>
    )
}

interface AnnFieldProps {
    type?: any
}

const AnnotateField = (props: AnnFieldProps) => {

    // define class names
    const classnames = cx(
        'annField',
        { 'type-warning': props.type === 'warning'}
    )

    return (
        <div className={classnames}>
            <div className="field">
                <div className="field__intro">
                    <label className="field__label" htmlFor="fld3_1">HEADER</label>
                    <div className="field__note">Small note</div>
                </div>
                <div className="field__element">
                    <div className="input has-control">
                        <div className="input__element">
                            <input id="f2_1" type="text" placeholder="field with intro and text control" value="" onChange={() => {}} />
                        </div>
                        <div className="input__control">
                            <Control color={`color-${props.type}`} onClick={() => { alert('Start linking'); }}>
                                <i className="material-icons">read_more</i>
                            </Control>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ProgressBarProps {
    value: any
}

const AnnotateProgressBar = (props: ProgressBarProps) => {

    // define classname
    const classnames = cx(
        'progressbar',
        { 'type-almost': props.value < 100 },
        { 'type-warning': props.value < 75 },
        { 'type-error': props.value < 50 }
    );

    // render labels
    return (
        <div className={classnames} data-progress={ props.value }>
            <div className="progressbar__bar"></div>
            <div className="progressbar__label">{ props.value }%</div>
        </div>
    );

}


interface DocumentProps {
    words: any,
    annotatedFields: any
}

const AnnotateDocument = (props: DocumentProps) => {

    const words = props.words;
    const annotatedFields = props.annotatedFields;

    return (
        <div className="document">
            <div className="document__labels">
                { words.map((word: any, index: any) => {

                    // get correct dimensions
                    const bounding = getBoudings(word.box);
                    const annotation = annotatedFields.filter((field: any) => {

                        if(!field.tokens[0]) { return false; }
                        return field.tokens[0].id === word.id;

                    })[0];

                    const dummyType = (index === 51) ? 'warning' : '';

                    return <AnnotateAnnotation annotation={annotation} bouding={bounding} key={index} type={dummyType} />

                })}

            </div>
            <div className="document__visual">
                <img src={image} />
            </div>
        </div>
    );

}

interface AnnotationProps {
    annotation: any,
    bouding: any,
    type?: any
}

const AnnotateAnnotation = (props: AnnotationProps) => {

    const annotation = props.annotation;
    const bounding = props.bouding;
    const showIcon = true;

    const classnames = cx(
        'annotation',
        { 'is-annotated' : annotation },
        { 'has-icon' : annotation && showIcon },
        { 'color-warning' : props.type === 'warning' }
    );

    // render labels
    return (
        <div className={classnames} style={{
            width: bounding.width,
            height: bounding.height,
            left: bounding.x,
            top: bounding.y
        }}>
            <div className="annotation__button">
                <button></button>
            </div>
            { annotation &&
                <>
                    <div className="annotation__label">
                        <span>{annotation.key}</span>

                        { props.type === 'warning' &&
                            <button onClick={() => { alert('Confirm annotation'); }}>
                                <i className="material-icons">check</i>
                            </button>
                        }

                        <button onClick={() => { alert('Remove annotation'); }}>
                            <i className="material-icons">delete</i>
                        </button>
                    </div>
                    {showIcon &&
                        <div className="annotation__icon">
                            <i className="material-icons">check</i>
                        </div>
                    }
                </>
            }
        </div>
    );

}

export const AnnotateModule = (props: RouteComponentProps<CreateInvoiceRouteInfo>) => {

    const { documentId } = props.match.params;

    return (
        <SidePanelManager >
            <ContentContainer main={<AnnotateCombell  />} />
        </SidePanelManager>
    )

}


/*
 *
 * Utility */
const getBoudings = (coordinates: any) => {

    // define variables
    const papersize = { w: 612, h: 792 };

    // re-calculate
    const width = coordinates.urx - coordinates.llx;
    const height = coordinates.lly - coordinates.ury;

    // return coordinates
    return {
        width: ((width / papersize.w) * 100) +'%',
        height: ((height / papersize.h) * 100) +'%',
        x: ((coordinates.llx / papersize.w) * 100) +'%',
        y: ((coordinates.ury / papersize.h) * 100) +'%'
    };

}