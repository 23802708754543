import React, { useState } from 'react';
import { ErrorBoundary, Amount, StructuredMessage, Box, Ellipsis, Heading, Paragraph, Control, Input, Field, FormField, Button, DateField } from '../../index';
import { HEADING_SIZE, HEADING_WEIGHT, PARAGRAPH_SIZE, PARAGRAPH_SPACING, HEADING_SPACING, PARAGRAPH_WEIGHT, COLOR_VALUE, CONTROL_COLOR } from "../../constants";
import { Trivie } from "../../index";
import { Formik } from 'formik';
import * as moment from 'moment';
import { DateTime } from '../../02_atoms/date/DateTime';
import { FetchData } from '../../00_global/FetchData';
import { notNull } from '../reconciliation';
import { ShowObject } from '../../00_global';

export const RECONCILIATION_TAB_SEARCH = 3;
export const RECONCILIATION_TAB_TRANSACTION_DETAILS = 1;
export const RECONCILIATION_TAB_LINKED_DOCUMENTS = 2;
export const RECONCILIATION_TAB_UPDATE_PARAMETERS = 4;

const KeyValue = ({caption,children, success = false}) => {
    if (!children)
        return null;

    return (
        <>
        <strong className={success ? 'fc--success' : undefined}>{caption}</strong>: <span className={success ? 'fc--success': undefined}>{children}</span><br />
        </>
    )
}

const MatchedDocument = ({ document, linkDocumentToTransaction, transaction }) => {

    notNull(linkDocumentToTransaction, 'linkDocumentToTransaction');

    const { partyName, documentDate, amount, score, description, documentId, reconciled, dateDiff, documentReferenceId, documentName, matchedQueries, paymentReference } = document
    let { thumbnailLink } = document;

    let reconciliationState = -1; // not initialized

    if (!reconciled)
    {
        reconciliationState = 1; // not reconciled
    }
    else {
        
        const {reconciliationDetails} = document;

        notNull(reconciliationDetails, 'reconciliation details are missing');

        reconciliationState = reconciliationDetails.transactionId === transaction.transactionId ? 2 : 3
    }

    if (!thumbnailLink)
        thumbnailLink = "http://placehold.it/200x300";

    const detachDocumentFromTransaction = () => {
         alert('detached!');
    }

    const partyMatch = matchedQueries.includes('PartyId');
    const amountMatch = matchedQueries.includes('amount');
    const referenceMatch = matchedQueries.includes('ReferenceId');
    const paymentReferenceMatch = matchedQueries.includes('PaymentReference');

    return (
        <>
       {false &&  <ShowObject value={document} />}
        <div className="RCDocument">
            <div className="RCDocument__info">
                <div className="RCDocument__visual">
                    <img alt={documentName} src={thumbnailLink} />
                </div>
                <div className="RCDocument__content">
                    <Paragraph size={PARAGRAPH_SIZE.medium} weight={PARAGRAPH_WEIGHT.bold} color={partyMatch ? "fc--success" : COLOR_VALUE.gray1}>{partyName}</Paragraph>
                    <Paragraph size={PARAGRAPH_SIZE.xs} color={COLOR_VALUE.gray2}>
                    <KeyValue caption={'Datum'} success={false}><DateTime dateValue={documentDate} /> ({dateDiff} d)</KeyValue>
                    <KeyValue caption={'Mededeling'} success={paymentReferenceMatch}>{paymentReference}</KeyValue>
                    <KeyValue caption={'Kenmerk'} success={referenceMatch}>{documentReferenceId}</KeyValue>
                    <KeyValue caption={'Bedrag'} success={amountMatch}><Amount value={amount} /></KeyValue>
                        <Ellipsis text={description}></Ellipsis>
                        
                    </Paragraph>
                </div>
            </div>
            <div className="RCDocument__actions">
                <Control color={score > 80 ? CONTROL_COLOR.success : CONTROL_COLOR.warning } isGhost>
                    {reconciliationState === 1 && <i onClick={() => linkDocumentToTransaction(documentId)} className="material-icons">done</i>}
                    {reconciliationState === 2 && <i onClick={() => detachDocumentFromTransaction(documentId)} className="material-icons">link_off</i>}
                    {reconciliationState === 3 && <i onClick={() => detachDocumentFromTransaction(documentId)} className="material-icons">eject</i>}
                </Control>
                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5"><Trivie /> Trive score: {score/10}/10</Paragraph>
            </div>
        </div>
        </>
    )
}

const LinkedDocument = ({ linkedDocumentInfo }) => {
    const { documentName, linkedOn, linkedBy, linkId, thumbnailLink } = linkedDocumentInfo;
    return (
        <div className="RCDocument">
            <div className="RCDocument__visual">
                <img alt={documentName} src={thumbnailLink} />
            </div>
            <div className="RCDocument__content">
                <Paragraph size={PARAGRAPH_SIZE.medium} weight={PARAGRAPH_WEIGHT.bold} color="fc--gray-1">{documentName}</Paragraph>
                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-2">
                    <strong>Gekoppeld op:</strong> <DateTime dateValue={linkedOn} /><br />
                    <strong>Gekoppeld door:</strong> {linkedBy}<br />
                    <Ellipsis text="+++ 111/222333444555666777888999888777666555444333222/111+++"></Ellipsis>
                </Paragraph>
            </div>
            <div className="RCDocument__actions">
                <Control>
                    <i onClick={() => alert(linkId)} className="material-icons">link_off</i>
                </Control>
            </div>
        </div>
    )
}

export const MatchedDocumentList = ({ matchedDocuments, handleDocumentLinked, transaction }) => {

    notNull(matchedDocuments, 'matchedDocuments');

    const searchResults = matchedDocuments.map(doc => <MatchedDocument key={doc.documentId} document={doc} linkDocumentToTransaction={handleDocumentLinked} transaction={transaction} />)
    const counter = matchedDocuments.length;

    return (
        <>
            <Box noBorder color="bgc--gray-10">
                <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Zoekresultaat</Heading>
                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-2">
                    <strong className="fc--gray-1">{counter} document(en)</strong> gevonden
            </Paragraph>
            </Box>
            <div className="RCDocuments">
                {searchResults}
            </div>
        </>
    )
}

const LinkedDocumentsTab = ({ linkedDocuments }) => {

    const linkedDocumentInfo = {
        documentName: 'Sample document',
        linkedOn: '2019-11-10',
        linkedBy: 'Stijn Volders',
        linkId: 'abc',
        thumbnailLink: 'https://s3.eu-west-1.amazonaws.com/trive.storage.thumbnails/8d768134e94b642_9462fc10037b5bce907971b072b469fc.jpg?AWSAccessKeyId=AKIAIMHTACEBRZO3WXHQ&Expires=1889249246&Signature=P25HwyE8gYFNfNivuDEAn7vTJ78%3D'
    }

    return (
        <>
            <Box noBorder color="bgc--gray-10">
                <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Gekoppelde documenten</Heading>
            </Box>
            <div className="RCDocuments">
                <LinkedDocument linkedDocumentInfo={linkedDocumentInfo} />
            </div>
            <Box noBorder color="bgc--white">
                <Button inverted>Extra document koppelen</Button>
            </Box>
        </>
    )
}

const SearchFilter = ({ field, value, handleDelete }) => {

    let displayValue = null;

    switch (field) {
        case 'transactionDate':
            displayValue = <DateTime dateValue={value} />
            break;
        case 'amount':
            displayValue = <Amount value={value} />
            break;
        default:
            displayValue = value;
    }

    // todo: enable delete
    if (value) {
        return (

            <button className="RC__filter">
                <span className="RC__filterText">{displayValue}</span>
                <span className="RC__filterIcon">
                    <i className="material-icons" onClick={() => handleDelete(field)}>delete_forever</i>
                </span>
            </button>
        )
    }
    else {
        return null;
    }

}

const SearchMatchesFilters = ({ filters, onEditFiltersClicked, onRemoveFilter  }) => {

        return (
        <Box noBorder>
            <Heading size={HEADING_SIZE.xs} spacing={HEADING_SPACING.xs} weight={HEADING_WEIGHT.bold}>Gekozen parameters</Heading>
            <div className="RC__filters">
                {
                    filters.map((item, i) => { return <SearchFilter key={item.field} field={item.field} value={item.value} handleDelete={onRemoveFilter} /> })
                }
            </div>
            <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-2">
                <a href={'#edit_search'} onClick={onEditFiltersClicked} className="fc--gray-2">Parameters wijzigen?</a>
            </Paragraph>
        </Box>
    )
}

const SearchMatchesForTransaction = ({ dataservice, transaction, searchParameters, onUpdateSearchButtonClicked }) => {

    notNull(dataservice, 'dataservice')

    const { dateRange, updatedOn } = searchParameters;
    const { transactionId } = transaction;

    const requestBody = {
        dateRange,
        updatedOn,
        filters: searchParameters.filters,
        transactionId: transactionId,
    }

    const fetchMethod = () => {
        return dataservice.findMatches(requestBody);
    }

    const handleDocumentLinked = async (documentId, transactionId) => {
        const response = await dataservice.linkTransaction(documentId, transactionId);
        console.log('response', response);
    }

    return (
        <>
            <FetchData method={fetchMethod} triggerValues={[transaction, requestBody]}>
                {({ loading, error, data }) => {
                    const isReady = data;
                    if (!isReady)
                        return null;

                    return (
                        <ErrorBoundary>
                            <MatchedDocumentList transaction={transaction} matchedDocuments={data.documents} handleDocumentLinked={(documentId) => handleDocumentLinked(documentId, transactionId)} />
                            <Box noBorder color="bgc--gray-10">
                                <Heading size={HEADING_SIZE.xxs} spacing={HEADING_SPACING.xs} weight={HEADING_WEIGHT.bold}>Document niet gevonden?</Heading>
                                <Button onClick={onUpdateSearchButtonClicked} inverted>Verfijn parameters</Button>
                            </Box>
                        </ErrorBoundary>
                    )
                }}
            </FetchData>

        </>
    )
}

const TransactionDetailItem = ({ caption, children }) => {
    if (!children)
        return null;

    return (
        <>
            <Paragraph size={PARAGRAPH_SIZE.xs} weight={PARAGRAPH_WEIGHT.bold} upper={true} spacing={PARAGRAPH_SPACING.zero} color="fc--gray-1">{caption}</Paragraph>
            <Paragraph size={PARAGRAPH_SIZE.s} spacing={PARAGRAPH_SPACING.xs} color="fc--gray-2">{children}</Paragraph>
            <hr />
        </>
    )
}

// contains details of the transaction (first tab)
const TransactionDetails = ({ transaction }) => {
    const { otherAccountName, otherAccountNumber, amount, transactionDate, valutaDate, clientReference, info } = transaction;
    return (
        <div className="RCDetails">
            <div className="RCDetails__inner">
                <Box noBorder color="bgc--white">
                    <Heading size={HEADING_SIZE.xs} spacing={HEADING_SPACING.small} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Details</Heading>
                    <TransactionDetailItem caption={'Naam tegenpartij'}>{otherAccountName}</TransactionDetailItem>
                    <TransactionDetailItem caption={'Rekeninnummer'}>{otherAccountNumber}</TransactionDetailItem>
                    <TransactionDetailItem caption={'Bedrag'}><Amount value={amount} /></TransactionDetailItem>
                    <TransactionDetailItem caption={'Verrichtingsdatum'}><DateTime dateValue={transactionDate} /></TransactionDetailItem>
                    <TransactionDetailItem caption={'Valutadatum'}><DateTime dateValue={valutaDate} /></TransactionDetailItem>
                    <TransactionDetailItem caption={'Persoonlijke info'}>{clientReference}</TransactionDetailItem>
                    <TransactionDetailItem caption='Omschrijving'>{info}</TransactionDetailItem>
                </Box>
            </div>
        </div>
    )
}

const SearchTransactionForm = ({ searchParameters, onUpdate }) => {
    const { amount, structuredMessage, clientReference } = searchParameters;

    const onSubmit = (values) => {
        onUpdate(values);
    }

    return (
        <div className="RCForm">
            <div className="RCForm__inner">
                <Box noBorder color="bgc--white">
                    <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">[TAB] Search</Heading>
                    <Paragraph size={PARAGRAPH_SIZE.s} color="fc--gray-2">Er wordt gezocht met de volgende gegevens:</Paragraph>
                    <Paragraph size={PARAGRAPH_SIZE.s} spacing={PARAGRAPH_SPACING.small} color="fc--gray-2">Bedrag: <Amount value={amount} /></Paragraph>

                    <Formik
                        initialValues={searchParameters}
                        onSubmit={(values, actions) => {
                            onSubmit(values);
                            actions.setSubmitting(false);
                        }}
                    >
                        {
                            (formikProps) => {
                                const { isSubmitting, handleSubmit, setFieldValue } = formikProps;

                                const onDateSelected = (date, fieldName) => {

                                    moment(date).isValid()
                                        ? setFieldValue(fieldName, moment(date).format())
                                        : setFieldValue(fieldName, null);
                                };

                                return (
                                    <>
                                        <form className="form" onSubmit={handleSubmit}>
                                            <FormField>
                                                <Field id={'transactionDate'} header={'Datum verrichting *'} infoMessage={''}>
                                                    <DateField propertyName={'transactionDate_fv'} onSelect={(value) => { onDateSelected(value, 'transactionDate') }} />
                                                </Field>
                                            </FormField>

                                            <FormField>
                                                <Field id={'dateRange'} infoMessage={'Periode in acht te nemen'} header={'Aantal dagen'}>
                                                    <Input />
                                                </Field>
                                            </FormField>
                                            <FormField>
                                                <Field id={'query'} infoMessage={'Zoektermen'} header={'Verfijn de zoekopdracht'}>
                                                    <Input />
                                                </Field>
                                            </FormField>
                                            <FormField>
                                                <Field id={'counterPart'} infoMessage={'Klant of leverancier v/h document'} header={'Tegenpartij'}>
                                                    <Input />
                                                </Field>
                                            </FormField>
                                            <FormField>
                                                <div style={{ 'marginTop': '16px' }}><Button type={'submit'} disabled={isSubmitting}>Zoek</Button></div>
                                            </FormField>
                                        </form>
                                    </>
                                )
                            }
                        }
                    </Formik>

                    {structuredMessage && <div>Mededeling: <StructuredMessage value={structuredMessage} /></div>}
                    {clientReference && <div>Referentie: {clientReference}</div>}
                </Box>
            </div>
        </div>
    )
}

const TabView = ({ tabIndex = -1, activeTabIndex = -1, children }) => {
    if (activeTabIndex === tabIndex) {
        return children;
    }
    return null;
}

const TabHeader = ({ tabIndex, activeTabIndex, caption, onClick, iconName }) => {
    // todo Oli: replace anchor with something else
    return (
        <li className={activeTabIndex === tabIndex ? 'is-active' : ''} onClick={onClick}>
            <button>
                {iconName && <i className="material-icons">{iconName}</i>}
                {caption}
            </button>
        </li>
    )
}

const TabHeaderCollection = ({ children }) => {
    return (
        <div className="RCTabs">
            <div className="RCTabs__inner">
                <div className="RCTabs__list">
                    <ul>
                        {children}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export const MatchTransactionSidePanel = ({ dataservice, transaction, tabIndex = RECONCILIATION_TAB_SEARCH }) => {

    notNull(dataservice, 'dataservice');

    const { transactionDate, amount, structuredMessage, clientReference, otherAccountName } = transaction;
    
    const defaultSearchParams = {
        dateRange: 60,
        query: '',
        updatedOn: new Date(),
        filters: [
            { field: 'transactionDate', value: transactionDate },
            { field: 'counterPart', value: otherAccountName },
            { field: 'amount', value: amount },
            { field: 'remittanceInfo', value: structuredMessage },
            { field: 'documentReferenceId', value: clientReference}
        ]
    } 

    const [activeTab, setActiveTab] = useState(tabIndex);

    const [searchParameters, updateSearchParameters] = useState(defaultSearchParams);

    const handleFilterRemoved = (filter) => {
        const { filters } = searchParameters
        const filtered = filters.filter(item => item.field !== filter);
        const updatedParams = {
            ...searchParameters,
            filters: filtered
        }

        updateSearchParameters(updatedParams);
    }

    return (
        <>
            <TabHeaderCollection>
                <TabHeader tabIndex={RECONCILIATION_TAB_TRANSACTION_DETAILS} activeTabIndex={activeTab} caption={'Details'} onClick={() => setActiveTab(RECONCILIATION_TAB_TRANSACTION_DETAILS)} iconName={'notes'} />
                <TabHeader tabIndex={RECONCILIATION_TAB_SEARCH} activeTabIndex={activeTab} caption={'Zoeken'} onClick={() => setActiveTab(RECONCILIATION_TAB_SEARCH)} iconName={'search'} />
                {/* <TabHeader tabIndex={2} activeTabIndex={activeTab} caption={'Documenten'} onClick={() => setActiveTab(2)} iconName={'attach_file'} /> */}
            </TabHeaderCollection>
            <TabView tabIndex={1} activeTabIndex={activeTab}>
                <TransactionDetails transaction={transaction} />
            </TabView>
            <TabView tabIndex={2} activeTabIndex={activeTab}>
                <div className="RCResults">
                    <div className="RCResults__inner">
                        <LinkedDocumentsTab linkedDocuments={[]} />
                    </div>
                </div>
            </TabView>
            <TabView tabIndex={3} activeTabIndex={activeTab}>
                <div className="RCParams">
                    <div className="RCParams__inner">
                        <SearchMatchesFilters filters={searchParameters.filters} onEditFiltersClicked={() => setActiveTab(4)} onRemoveFilter={handleFilterRemoved} />
                    </div>
                    <div className="RCResults__inner">
                        <SearchMatchesForTransaction 
                            dataservice={dataservice}
                            searchParameters={searchParameters} 
                            transaction={transaction} 
                            onUpdateSearchButtonClicked={() => setActiveTab(4)} />
                    </div>
                </div>
            </TabView>
            <TabView tabIndex={4} activeTabIndex={activeTab}>
                <SearchTransactionForm searchParameters={searchParameters} onUpdate={(values) => updateSearchParameters(values)} />
            </TabView>
        </>
    )
}