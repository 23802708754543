import React, { Fragment, useState, useContext } from 'react'
import { SidePanelContext, SidePanelManager, ShowObject, ErrorBoundary, FetchData, Control, Heading, Paragraph, ContentContainer, DateTime, Amount, Ellipsis } from '../../index';
import { HEADING_SIZE, HEADING_WEIGHT, PARAGRAPH_SIZE, CONTROL_STATUS, CONTROL_SIZE } from "../../constants";
import { Trivie } from "../../index";
import cx from 'classnames';
import { notNull } from '../../00_global';
import { MatchTransactionSidePanel, RECONCILIATION_TAB_SEARCH } from './MatchTransactionSidePanel'

import './styles/_reconciliationv2.scss';

const ActionInfo = ({ dataservice, transaction, handleLinkTransactionButtonClicked }) => {

    // temp hack
    if (!transaction.priceState) {
        console.debug('priceState not set, fallback')
        transaction.priceState = 'todo';
    }

    notNull(transaction.priceState, "transaction.priceState")

    const getIconName = (state) => {
        switch (state) {
            case 'success':
                return 'done';
            case 'warning':
                return 'notification_important';
            case 'todo':
                return 'attach_file';
            default:
                return '';
        }
    }

    const getColorStyle = (state) => {
        switch (state) {
            case 'success':
            case 'warning':
            case 'todo':
                return `fc--${transaction.priceState}`;
            default:
                return ""
        }
    }

    return (
        <div className="reconciliation__actionInfo">
            <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color={getColorStyle(transaction.priceState)}><Amount value={transaction.amount} /></Heading>
            <Control color={`color-${transaction.priceState}`} onClick={() => handleLinkTransactionButtonClicked(transaction)}>
                {transaction.noHands
                    ? (<Trivie />)
                    : <i className="material-icons">{getIconName(transaction.priceState)}</i>
                }
            </Control>
        </div>
    )
}

const AttachedDocumentsControl = ({ count = -1 }) => {
    const featureActive = true;

    if (count < 1 || !featureActive)
        return null;

    const label = 'gekoppelde document(en)';
    const showLabel = false;

    return (
        <div className="reconciliation__transactionAttribute">
            <Control isPlain size={CONTROL_SIZE.xsmall} status={CONTROL_STATUS.success} count={count}>
                <i className="material-icons">attach_file</i>
            </Control>
            <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-2">{showLabel && label}</Paragraph>
        </div>
    )
}

const ActionFeedback = ({ state, warning = '' }) => {

    const getFeedback = () => {
        switch (state) {
            case 'success':
                return 'wijzigen?';
            case 'warning':
                return warning;
            case 'todo':
                return 'document(en) koppelen?';
            default:
                return 'invalid input:' + state; // or throw?
        }
    }

    const feedback = getFeedback();

    return (
        <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5"><a href="?fool-linter">{feedback}</a></Paragraph>
    )
}

const AttachedNotesControl = ({ notes }) => {

    const ticketAttributeStyle = cx(
        'reconciliation__transactionAttribute',
        { 'is-disabled': (notes && notes > 0) === false }
    )

    const notesFeatureEnabled = true;
   

    return (<>
        {notesFeatureEnabled &&
            <div className={ticketAttributeStyle}>
                <Control isPlain size={CONTROL_SIZE.xsmall} count={notes}>
                    <i className="material-icons">notes</i>
                </Control>
                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-2">{}</Paragraph>
            </div>
        }</>
    )
}

const StatementDiff = ({ statement }) => {

    var diff = statement.finalBalance - statement.initialBalance;
    var color = diff > 0 ? 'fc--success' : '';

    return (
        <div className="reconciliation__statementBalanceItem">
            <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5">verschil</Paragraph>
            <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color={color}><Amount value={diff} /></Heading>
        </div>
    )
}

const RCTransaction = ({ dataservice, transaction }) => {

    const { transactionDate, clientReference, otherAccountName, description, info } = transaction;
    const notesCount = 0;
    const linkedDocuments = 0;

    const { add } = useContext(SidePanelContext);
    const [isActive, setIsActive] = useState(false);

    const showPanel = (t) => {
        setIsActive(true);
        
        const panelContent = <MatchTransactionSidePanel dataservice={dataservice} transaction={t} activeTab={RECONCILIATION_TAB_SEARCH} />
        const closeProps = {
            canClose: true,
            onClose: () => { 
                setIsActive(false);
                return true; // false to block close operation
             }
        }
        // API: component, blocking = false, pinProps = defaultPinProps, closeProps = defaultCloseProps
        add(panelContent, false, null, closeProps)
    }

    const rcStyle = cx('reconciliation__transaction',
        {'is-active': isActive === true}
    );
    

    return (
        <div className={rcStyle}>
            <div className="reconciliation__transactionDefaults">
                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5"><DateTime dateValue={transactionDate} /></Paragraph>
                <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold}>{description} - {otherAccountName}</Heading>
                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5">
                    <span className="reconciliation__transactionLine">
                        <strong>Mededeling:</strong> <Ellipsis text={info}></Ellipsis>
                    </span>
                    {clientReference && <span className="reconciliation__transactionLine">
                        <strong>Persoonlijke info:</strong> <Ellipsis text={clientReference}></Ellipsis>
                    </span>}
                </Paragraph>
            </div>
            <div className="reconciliation__transactionAttributes">
                <AttachedNotesControl notes={notesCount} />
                <AttachedDocumentsControl count={linkedDocuments} />
            </div>
            <div className="reconciliation__transactionActions">
                <ActionInfo dataservice={dataservice} transaction={transaction} handleLinkTransactionButtonClicked={showPanel} />
                <ActionFeedback state={'todo'} warning={'gedeeltelijk gekoppeld!'} />
            </div>
        </div>
    );
}

const TransactionList = ({ dataservice, transactions }) => {
    return (
        <>
            {transactions && (<div className="reconciliation__statementList">{transactions.map(t => <RCTransaction key={t.transactionId} dataservice={dataservice} transaction={t} />)}</div>)}
        </>)
}

const RCStatement = ({ dataservice, statement }) => {

    const [isOpen, updateIsOpen] = useState(true);
    const toggleTicketState = () => { updateIsOpen(!isOpen); }

    const ticketStyle = cx('reconciliation__statement', { 'is-open': isOpen === true });
    const ticketProgress = Math.floor(42);

    return (
        <div className={ticketStyle}>
            <div className="reconciliation__statementHeader">
                <div className="reconciliation__statementBasics">
                    <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.bold}>Rekening: {statement.accountNumber}</Heading>
                    <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5">
                        <strong>{ticketProgress}% afgepunt</strong> van afschrift: {statement.statementNumber} van <DateTime dateValue={statement.statementDate} />
                    </Paragraph>
                    <div className="reconciliation__statementProgress" data-progress={ticketProgress}></div>
                </div>
                <div className="reconciliation__statementBalance">
                    <div className="reconciliation__statementBalanceItem">
                        <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5">begin saldo</Paragraph>
                        <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold}><Amount value={statement.initialBalance} privacyMode={false} /></Heading>
                    </div>
                    <div className="reconciliation__statementBalanceItem">
                        <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-5">eind saldo</Paragraph>
                        <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold}><Amount value={statement.finalBalance} privacyMode={false} /></Heading>
                    </div>
                    <StatementDiff statement={statement} />
                    <div className="reconciliation__statementBalanceItem">
                        <Control isPlain onClick={toggleTicketState}>
                            <i className="material-icons">
                                {(isOpen) ? "expand_less" : "expand_more"}
                            </i>
                        </Control>
                    </div>
                </div>
            </div>
            <TransactionList dataservice={dataservice} transactions={statement.transactions} />
        </div>
    );
}

export const RCStatementList = ({ dataservice, statements }) => {

    notNull(dataservice, 'dataservice');

    const style = cx(
        'reconciliation'
    );
    return (
        <div className={style}>
            <div className="reconciliation__inner">
                {statements && statements.map(statement => <RCStatement key={statement.statementId} dataservice={dataservice} statement={statement} />)}
            </div>
        </div>
    )
}

export const ReconciliationCore = ({ service }) => {

    notNull(service, 'service', 'dataservice not specified');
    const debug = false;

    return (
        <Fragment>
            <ErrorBoundary>
                <FetchData method={service.getStatements}>
                    {({ loading, error, data }) => {
                        return <Fragment>
                            {data && <RCStatementList dataservice={service} statements={data} />}
                            {(error || debug) && <ShowObject value={{ data }} background={'#BFDADE'} />}
                        </Fragment>
                    }}
                </FetchData>
            </ErrorBoundary>
        </Fragment>
    )
}
export const ReconciliationV2 = ({ds}) => {

    return (
        <SidePanelManager>
            <ContentContainer main={<ReconciliationCore service={ds} />} />
        </SidePanelManager>
    )
}