import React, {useState} from 'react';
import { Button } from '../../02_atoms/buttons/Button';
import { Fill } from '../../02_atoms/fill/Fill';
import { Box } from '../../03_molecules/box/Box';
import { BoxHeader } from '../../03_molecules/box/BoxHeader';

export const ConfirmDelete = ({ onConfirmed, onCancelled, isSubmitting }) => {

    const additionalStyles = {
        'width': '100%',
        'margin': '5px',
    }

    const warningButtonStyle = {
        ...additionalStyles,
        'backgroundColor': '#D8393C',
        'borderColor': '#D8393C'
    }

    const spaceStyle = {
        'height': '20px'
    }

    const [isDeleting, setDeleting] = useState(false);

    const handleConfirm = () => {
        try {
            setDeleting(true);
            onConfirmed();
        }
        finally{
            setDeleting(false);
        }
        

    }

    return (
        <>
            <div style={spaceStyle}></div>
            <Box header={<BoxHeader title={'Ben je zeker?'} subTitle={'Deze actie kan je niet ongedaan maken'} />} >
                {isDeleting && 'deleting... just a sec!'}
                <Fill><Button additionalStyles={warningButtonStyle} onClick={handleConfirm} loading={isDeleting || isSubmitting}>Ik ben zeker</Button></Fill>
                <Fill><Button additionalStyles={additionalStyles} onClick={onCancelled}>Toch maar bijhouden</Button></Fill>
            </Box>
        </>
    )
}