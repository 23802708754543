import React from 'react';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { PARAGRAPH_WEIGHT, PARAGRAPH_SIZE, PARAGRAPH_SPACING, HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, COLOR } from '../../constants';
import { Party, Business, Validation } from '../invoice_module_v1/models/api';
import { Box } from '../../03_molecules/box/Box';
import { Heading } from '../../02_atoms/headings/Heading';
import { Message } from '../../03_molecules/message/Message';
import { Button } from '../../02_atoms/buttons/Button';

interface CustomerValidationProps {
	inSidebar: boolean,
	validation: Validation
}

export const CustomerValidation = (props: CustomerValidationProps) => {

	const { inSidebar, validation } = props;

	if (validation.success)
		return null;

	else {
		return <Message compact orientation={inSidebar ? 'vertical' : ''} color="color-warning">
			<Heading size={HEADING_SIZE.xxs} weight={HEADING_WEIGHT.bold} spacing={HEADING_SPACING.xxs}>Opgelet!</Heading>
			<Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.xxs} nopadding={true}>
				Er ontbreken nog enkele gegevens!
			</Paragraph>
			<ul>
				{validation.errors && validation.errors.map(err => <li>{err.message}</li>)}
			</ul>
			{<Button color={COLOR.white} type={'button'} onClick={() => { alert('Edit customer details'); }}>Vul gegegevens aan</Button>
			}
		</Message>
	}
}

interface CustomerDetailProps {
	party: Party,
	inSidebar: boolean,
	validation: Validation
}

export const CustomerDetails = ({ party, inSidebar, validation }: CustomerDetailProps) => {

	if (!party) {
		console.warn('party null')
		return null;
	}

	if (party.business) {
		return (
			<div className="invoice__customerDetails">
				{validation && <CustomerValidation inSidebar={inSidebar} validation={validation} />}
				{inSidebar ? (
					<Box color="bgc--gray-10">
						<CustomerDetailsInfo business={party.business} />
					</Box>
				) : (
						<CustomerDetailsInfo business={party.business} />
					)}
			</div>
		)
	}
	else if (party.private) {
		return <>todo: person info</>
	}

	return null;
}

interface CustomerDetailInfoProps {
	business: Business
}

const CustomerDetailsInfo = ({ business }: CustomerDetailInfoProps) => {

	const { name, taxIdentificationNumber, address, email, countryCode } = business;

	return (
		<>
			<Paragraph size={PARAGRAPH_SIZE.large} spacing={PARAGRAPH_SPACING.xxs} weight={PARAGRAPH_WEIGHT.bold}>{name}</Paragraph>
			{address.map((addressLine: string, i: number) => <Paragraph key={`address-${i}`} size={PARAGRAPH_SIZE.small} nopadding={true}>{addressLine}</Paragraph>)}
			<Paragraph size={PARAGRAPH_SIZE.small} nopadding={true}>{countryCode}</Paragraph>
			<Paragraph size={PARAGRAPH_SIZE.small} nopadding={true}>{taxIdentificationNumber}</Paragraph>
			<Paragraph size={PARAGRAPH_SIZE.small} nopadding={true}>{email}</Paragraph>
		</>
	)
}