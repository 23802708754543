import React, { useContext } from 'react';
import * as Yup from "yup";
import { SearchParty } from './SearchParty'
import { Input, Field, FormField, Button, SidePanelContext } from "../..";
import { BUTTON_SIZE } from "../../constants";
import { Formik } from "formik";
import { DateField } from "../../03_molecules/date/DateField";
import * as moment from 'moment';
import { isInDebug } from '../../00_global/isInDebug';
import { ShowObject } from '../../00_global/ShowObject';
import { notNull } from '../..'
import { dataservice } from '../../00_global/service/dataservice';
import { withRouter } from "react-router-dom";
import { routeWithSlug } from '../../00_global/'
import { differenceInDays } from 'date-fns'
import { CreateCustomer } from './_amo_/CreateCustomer';
import { sleep } from '../../helpers';

const debug = isInDebug('Inbox');
const requiredErrorMessage = 'Verplicht veld!';

const annotateSalesInvoiceFormSchema = Yup.object().shape({
    party_searchQuery: Yup.string()
        .required(requiredErrorMessage),
    documentDate: Yup.string()
        .required(requiredErrorMessage),
});

const annotateSalesInvoiceDefaultState = {
    party_searchQuery: '',
    documentDate: '',
    description: ''
}

const mapValuesToRequest = (documentId, values) => {
    const request = {
        description: values['description'],
        documentDate: values['documentDate'],
        documentId: documentId,
        documentType: 2,
        party: {
            name: values['party_searchQuery'],
            partyId: values['partyId']
        },
        paymentMethod: null,
    }

    return request;
}

const AnnotateSalesInvoiceElement = ({ documentId, history }) => {

    notNull(documentId, 'documentId');
    notNull(history, 'history')

    const { add, close, sidepanels } = useContext(SidePanelContext);

    const onSubmit = async (values, actions) => {
        try {
            const request = mapValuesToRequest(documentId, values);
            await dataservice.addMetadata(request);
            sleep(1000);
            history.push(routeWithSlug('inbox'));
        }
        finally {
            actions.setSubmitting(false);
        }
    }

    const handleValidate = (values) => {

        let errors = {};

        const { partyId, documentDate, dueDate } = values;

        if (!partyId) {
            errors['party_searchQuery'] = 'Verplicht veld!';
        }

        if (documentDate && dueDate) {
            var result = differenceInDays(dueDate, documentDate)
            if (result < 0) {
                errors['documentDate'] = `Moet voor de verval datum zijn`;

            }
        }

        return errors;
    }

    return (
        <Formik
            initialValues={annotateSalesInvoiceDefaultState}
            validationSchema={annotateSalesInvoiceFormSchema}
            validateOnChange={true}
            validateOnBlur={false}
            validate={handleValidate}
            onSubmit={(values, actions) => {
                onSubmit(values, actions);
            }}>
            {
                (formikProps) => {

                    const { isSubmitting, setFieldValue, handleSubmit, errors, values, setFieldError } = formikProps;

                    const handleSelect = (selectedValue) => {
                        if (!selectedValue) return; // 2019-04-06 bug while navigating + enter with keyboard
                        setFieldValue('party_searchQuery', selectedValue.displayValue);
                        setFieldValue('partyId', selectedValue.key);
                        setFieldError('party_searchQuery', '');; // => reset errors
                    };

                    const handleNoResults = (value) => {
                        debug && console.log('no result for', value);
                        const panel = <CreateCustomer userInput={value} onCustomerCreated={handleCustomerCreated} />
                        add(panel, true);
                    }

                    const handleCustomerCreated = (id, name) => {

                        const selectedValue = {
                            key: id,
                            displayValue: name
                        }

                        handleSelect(selectedValue);
                        setFieldValue('party_searchQuery', name); //update search inputfield

                        if (sidepanels) {
                            close();
                        }
                        else {
                            debug && console.warn('no sidepanels found')
                        }
                    }

                    const selectedParty = values['party'];

                    const onDocumentDateSelected = (date) => {
                        const fieldName = 'documentDate'
                        moment(date).isValid()
                            ? setFieldValue(fieldName, moment(date).format())
                            : setFieldValue(fieldName, null);
                    };

                    return (
                        <form className="form" onSubmit={handleSubmit}>
                            <FormField>
                                <Field id={'party_searchQuery'} infoMessage={!selectedParty && 'Typ om te zoeken'} header={'Klant *'} errorMessage={errors['partyId']}>
                                    <SearchParty
                                        propertyName={'party_searchQuery'}
                                        selectedValueId={'partyId'}
                                        onSearch={(searchQuery) => dataservice.searchCustomer(searchQuery)}
                                        onNoResultsFound={handleNoResults} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'documentDate'} header={'Factuur datum *'} infoMessage={''}>
                                    <DateField placeholder={'factuur datum'} propertyName={'documentDateFormValue'} onSelect={onDocumentDateSelected} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'description'} header={'Info'} infoMessage={'eigen notities'}>
                                    <Input />
                                </Field>
                            </FormField>

                            <FormField>
                                <Button
                                    type="submit"
                                    size={BUTTON_SIZE.full}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >Verzenden</Button>
                            </FormField>
                            {debug && <ShowObject value={formikProps} />}
                        </form>
                    )
                }
            }
        </Formik>
    )
};

export const AnnotateSalesInvoiceLtd = withRouter(AnnotateSalesInvoiceElement);