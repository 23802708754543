import React from 'react';
import './_showContent.scss';
import { SidePanelManager, FetchData } from '../../index';
import { ContentContainer } from '../../index';
import { dataservice } from '../../00_global/service/dataservice';
import ReactMarkDown from 'react-markdown';
import { Heading } from '../../02_atoms/headings/Heading';
import { HEADING_SPACING } from '../../constants';

export const ShowSlug = ({slug}) => {
    return (
    <FetchData method={() => dataservice.getPage(slug)}>
    {
        ({loading, error, data}) => {
            
            const md = data ? data.content.body[1].content : '';
            const title = data ? data.content.body[0].content : '';

            return (
                <>
                    <div className={'showContent'}>
                        <div className={'showContent__inner'}>
                            {data && <Heading spacing={HEADING_SPACING.medium}>{title}</Heading>}
                            {data && <ReactMarkDown source={md} />}
                        </div>
                    </div>
                </>
            )
        }
    }
    </FetchData>)
}

const ShowContentMain = ({match}) => {
    const { slug } = match.params;

    return(
       <ShowSlug slug={slug} />
    )
}

export const ShowContent = ({match}) => {
    const main = <ShowContentMain match={match} />

    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}