import React from 'react';
import { SearchResultContainer } from "./SearchResultContainer";
import { notNull, SearchResultItem, Heading, Paragraph } from '../../index';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE } from "../../constants";

export const noResultsItemBuilder = (onSelect, title, subTitle) => {
    const key = 'no_result';
    notNull(onSelect, 'onSelect', 'Required prop is missing!');

    return <SearchResultContainer key={key} onSelect={onSelect} render={
        ({ isHighlighted }) => {
            return (
                <SearchResultItem 
                    onClick={onSelect} 
                    tinted={!isHighlighted} 
                >
                    <Heading size={HEADING_SIZE.xxs} weight={HEADING_WEIGHT.bold} spacing={HEADING_SPACING.zero} color="fc--primary-1">
                        {title}
                    </Heading>
                    <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-4"><span>{subTitle}</span></Paragraph>
                </SearchResultItem>
            )
        }
    }
    />
}

export const searchResultItemBuilder = (key, onSelect, children) => {
    return (
        <SearchResultContainer key={key} onSelect={onSelect} render={
            ({ key, isHighlighted }) => {
                return (
                    <SearchResultItem key={key} 
                        onClick={onSelect} 
                        tinted={!isHighlighted} 
                    >
                        { children }
                    </SearchResultItem>
                )
            }
        }
        />
    )
}