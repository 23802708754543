import React from 'react';
import '../../03_molecules/table/styles/_table.scss'
import { ColumnHeader } from './TableElements'


export const TableHeader = ({ columnDefinitions }) => {
    const columns = columnDefinitions.map(cd => <ColumnHeader key={cd.headerText} text={cd.headerText} sortable={cd.sortable} medium={cd.medium} large={cd.large} hideOnMedium={cd.hideOnMedium}>{cd.children}</ColumnHeader>)

    return (
        <div className="table__head">
            <div className="table__row">
                {columns}
            </div>
        </div>
    )
}

export const Table = ({ children }) => {
    return (
        <div className={'table'}>
        <div className="table__inner">
            {children}
        </div>
    </div>
    )
}

export const TableBody = ({children}) => {
    return (<div className="table__body">{children}</div>)
}