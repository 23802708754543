import React from 'react';
import { SidePanelManager, VisualFeedback } from '../../index';
import { ContentContainer } from '../../index';
import { Button } from '../../02_atoms/buttons/Button';
import { BUTTON_SIZE } from '../../constants';
import image from './undraw_developer.png';

const ShowMessage = () => {

    const go = () => {
        window.open('https://trive.be/contacteer-ons', '_blank');
    }

    return (<VisualFeedback
        image={image}
        title={'Communicatie met uw klanten'}
        subTitle={'Samen werken, snel schakelen, vlot overleggen, heldere afspraken maken en opvolgen... Dat kan binnenkort met onze nieuwe module!'}
        ctaButton={<Button size={BUTTON_SIZE.medium} rounded={false} inverted={true} onClick={go}>Meer weten</Button>}
    >
    </VisualFeedback>)
}

export const Messages = () => {

    const main = <ShowMessage />

    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}