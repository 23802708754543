import React from 'react';
import './styles/_loaderContainer.scss';
import cx from 'classnames';

import {Loader} from "../..";
import { LOADER_SIZE } from '../../constants';

export const LoaderContainer = ({showLoader = true, source = false, size = LOADER_SIZE.default, children}) => {

	const style = cx(
        'loaderContainer',
        {'is-loading': showLoader},
        {'source-button': source === 'button'}
	);

    return (
        <div className={style}>
            <div className="loaderContainer__content">
            	{children}
            </div>
            <div className="loaderContainer__loader">
            	<Loader size={size} />
            </div>
        </div>
    )

}