import React, { useContext, FC } from 'react';
import { SidePanelContext } from '../00_global/context/SidePanelContext';
import { notNull }from '../00_global';
import { Invoice } from '../06_features/invoice_module_v1/models/api';
import { AddInvoiceForm, ISidePanelService } from '../06_features/invoice_module_v1/InvoiceComponents';
import { ErrorBoundary } from '../00_global/ErrorBoundary';
import { invoiceApiResponse } from '../06_features/invoice_module_v1/invoiceService';

// export function createCtx<ContextType>() {
// 	const ctx = React.createContext<ContextType | undefined>(undefined);
// 	function useCtx() {
// 	  const c = React.useContext(ctx);
// 	  if (!c)
// 		throw new Error(
// 		  "useCtx must be inside a Provider with a value"
// 		);
// 	  return c;
// 	}
// 	return [useCtx, ctx.Provider] as const;
//   }

export type InvoiceDataProviderProps = {
	invoiceData: Invoice,
	refresh(): void,
	deleteInvoice(id:string) : void
	debug: boolean,
	openCustomerPanel(): void,
	openCreateCustomerPanel(): void,
	showTemplatePanel(): void,
	saveInvoice(invoice:Invoice): Promise<invoiceApiResponse>
  }

  export const InvoiceDataContext = React.createContext<Partial<InvoiceDataProviderProps>>({});

export const InvoiceDataProvider: FC<{children: any, value: InvoiceDataProviderProps}> = ({ children, value }) => {
	
	notNull(value, 'value is a required prop!')
	
	return (
		<>
			<InvoiceDataContext.Provider value={value}>
				{children}
			</InvoiceDataContext.Provider>
		</>
	)
}

export interface ISidePanelContext {
	add(panel: any, blocking: boolean, pinProps: any, closeProps: any): void
	close(): void
}

export const ManageSidepanelState = () => {
	// component to manage the sidepanel state for this module

	const panelContext = useContext<ISidePanelContext>(SidePanelContext);
	const { add, close } = panelContext;

	const defaultPinProps = {
		toolTip: '',
		canPin: true,
		pinned: false
	}

	const defaultCloseProps = {
		canClose: false,
		onClose: () => true
	};

	const showSidepanel = (title = '', content: any) => {
		//(panel, blocking = false, pinProps = defaultPinProps, closeProps = defaultCloseProps)
		add(content, false, defaultPinProps, defaultCloseProps);
	}

	const closeSidepanel = () => {
		close();
	}

	const panelService: ISidePanelService = {
		showSidepanel,
		close: closeSidepanel
	}

	return (
		<>
			<ErrorBoundary>
				<AddInvoiceForm panelService={panelService} />
			</ErrorBoundary>
		</>
	)
}

