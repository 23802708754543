export const purchaseInvoiceDefaultState = {
  supplier_searchQuery: '',
  documentDate: '',
  dueDate: '',
  documentAmount: '',
  documentVat: '',
  description: '',
  paymentReference: '',
  paymentMethod: 'Nog niet betaald',
  documentReference: '',
  supplierId: '',
  currency: 'EUR',
  exchangeRate: 1
};

