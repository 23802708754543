import React from 'react';

export const Fill = ({children, backgroundColor='', color='', justifyContent= 'center'}) => {
    const divStyle = {
        display: 'flex',
        justifyContent: justifyContent,
        width: '100%',        
        background: backgroundColor,
        color: color,
    };

    return (
        <div style={divStyle}>{children}</div>
    )
}