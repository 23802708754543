import React, { Fragment, useState, SyntheticEvent } from 'react';
import './_search.scss';
import cx from "classnames";
import { CloseIcon } from "../../01_fundaments/icons";
import { SearchIcon } from "../../01_fundaments/icons";
import { TagIcon } from "../../01_fundaments/icons";
import { Control, Loader, Heading, Paragraph } from "../..";
import { LOADER_SIZE, HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE } from "../../constants";
import { isInDebug } from '../../00_global/isInDebug';
import { ShowObject } from '../../00_global';
import { searchResultItemBuilder } from './helpers';
import { SearchResultItemV2 } from '../searchResultItem/SearchResultItem';
import { modules } from '../../00_global/debugInfo';

const debug = true && isInDebug('Search');

interface SearchState {
    hover: boolean,
    selected: boolean
}

interface SearchResultItemProps {
    children: any,
    id: string,
    hover?: boolean,
    tinted?: boolean,
    onClick?(id: string): void,
    searchState: SearchState
}



export const ShowSearchResult = ({ children, tinted = false, hover = false, id, onClick = () => null }: SearchResultItemProps) => {

    const style = cx(
        'searchResultItem',
        { 'is-tinted': tinted === true },
        { 'can-hover': hover === true },
    );

    return (
        <div className={style} onClick={() => onClick(id)}>
            <div className="searchResultItem__inner">
                {children}
            </div>
        </div>
    )
};



export interface SearchItem {
    id: string,
    caption: any,
    note: any,
    value: any
    // onSelect(item: SearchItem): void
}

interface SearchProps {
    value: string,
    id: string,
    results: SearchItem[],
    onChange: any,
    onItemSelected?(item: SearchItem): void,
    whenBlurred? (): void
}

interface TrackerProps {
    children(state: SearchState): void
    onClick(): void
}

const Tracker = (props: TrackerProps) => {

    const [highlight, setHighlighted] = useState(false);

    // important: should be triggered by onMouseDown, as onClick won't fire if the item is blurred
    // https://www.eventbrite.com/engineering/a-story-of-a-react-re-rendering-bug/
    const handleClick = (e: any) => {
        props.onClick(); // bubble
    }

    const debug = isInDebug(modules.TRACKER);

    return (
        <>
            {debug && <ShowObject value={{ hover: highlight, selected: false }} />}
            <div onMouseEnter={() => setHighlighted(true)} onMouseLeave={() => setHighlighted(false)} onMouseDown={handleClick}>
                <>
                    {props.children({ hover: highlight, selected: false })}
                </>
            </div>
        </>
    )
}

export const SearchV2 = (props: SearchProps) => {
    const [focused, setFocused] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [ignoreBlur, setIgnoreBlur] = useState(false);
    const [ignoreFocus, setIgnoreFocus] = useState(false);
    const [selectedItem, setSelectedItem] = useState<SearchItem>();

    const handleKeyDown = (e: SyntheticEvent, isOpen: boolean) => {
        // if (Search.keyDownHandlers[event.key])
        // Search.keyDownHandlers[event.key].call(this, event)
        //else if (!this.isOpen()) {
        // this.setState({
        //     isOpen: true
        // })

        // todo: add support for key handlers
        if (!isOpen)
            setIsOpen(true);
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        props.onChange(e);
    }

    const handleItemSelected = (item: SearchItem) => {
        
        setSelectedItem(item);

        if (props.onItemSelected){
            props.onItemSelected(item);
        }
    }

    const handleClick = (item: SearchItem) => {
        setIsOpen(false);
        setHighlightedIndex(null);
        setIgnoreBlur(false); //originally invoked in the callback
        handleItemSelected(item);
    }

    const handleFocus = () => {
        setFocused(true);
        setIsOpen(true);
    }

    const handleBlur = () => {
        debug && console.log('blurring');
        if (props.whenBlurred) {
            debug && console.log('onBlur set')
            props.whenBlurred();
        }
        else{
            debug && console.log('onBlur not set')
        }
        setFocused(false);
        
    }

    const showResults = focused && isOpen && (props.results.length > 0);

    const style = cx(
        'search',
        //{ 'has-tags': tags },
        //{ 'type-select': (this.props.style === 'select') }
    );

    return (
        <>
            <div className={style}>
                <input
                    id={props.id}
                    type="text"
                    className="search__input is-plain"
                    placeholder=""
                    autoComplete={'off'}
                    value={props.value}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                //onKeyDown={this.handleKeyDown}
                />
                <span className="search__label"></span>
                    <div className="search__controls">
                        <Control isPlain={true} skipTab={true}>
                                <>
                                { props.results && props.results.length > 0 ? (
                                    <>
                                        <i className="material-icons">keyboard_arrow_down</i>
                                    </>
                                ) : (
                                    <SearchIcon />
                                )}
                                </>
                        </Control>
                    </div>
                {showResults &&
                    <>
                        <div className="search__results">
                            {
                                props.results.map((item) =>
                                    <Tracker key={item.id} onClick={() => handleClick(item)}>
                                        {state => <> 
                                        <SearchResultItemV2 id={item.id} hover={state.hover}>
                                            <Heading size={HEADING_SIZE.xxs} weight={HEADING_WEIGHT.bold} spacing={HEADING_SPACING.zero} color="fc--primary-1">{item.caption}</Heading>
                                            <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-4">{item.note}</Paragraph>
                                        </SearchResultItemV2> </>}
                                    </Tracker>
                                )
                            }
                        </div>
                    </>
                }
            </div>
        </>
    )
}