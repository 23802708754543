import React, { useContext, useState } from 'react';
import cx from 'classnames';
import './_invoice-v2.scss';
import { Control, LimitContent, ShowObject, Button, Amount } from '../../index';
import { CONTROL_SIZE, COLOR, HEADING_SIZE, HEADING_WEIGHT, PARAGRAPH_SIZE, PARAGRAPH_SPACING, BUTTON_SIZE } from "../../constants";
import { notNull } from '../../00_global';
import { InvoiceEditZone, AddLinePanel, InvoiceDataContext, InvoiceRemarks, InvoiceHeader } from '.';
import { HeaderLine } from './InvoiceLines';
import { TaskOrProductLine } from './TaskOrProductLine';
import { InvoiceLineItem, ProductDto } from './models/api';
import { InvoiceTotals } from './InvoiceTotals'
import { productService, addInvoiceLineResponse, invoiceApiResponse } from './invoiceService';
import { Box } from '../../03_molecules/box/Box';
import { Heading } from '../../02_atoms/headings/Heading';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { Confirmation } from '../../03_molecules/confirmation/Confirmation';
import { Overlay } from '../../04_organisms/overlay/Overlay';
import { SendInvoicePanel, SendInvoiceResponse } from './SendInvoicePanel';
import { UpdateLinePanel } from './UpdateLinePanel';
import PdfViewer from '../inbox/_amo_/PdfViewer';
import { download } from '../../helpers';

// interface PendingLineProps {
// 	productName: string,
// 	description: string
// }

// export const PendingLine = ({productName, description} : PendingLineProps)  => {
// 	return (
// 		<TaskOrProductLine key="_pending" line={{

// 			productName: productName,
// 			description: description,
// 			//unitId: false,
// 			//unitPrice: false,
// 			vatPct: 21,
// 			qty: 1
// 		}} />
// 	)
// }

export interface ISidePanelService {
	showSidepanel(tooltip: string, content: any): void;
	close(): void;
}

export interface AddInvoiceFormProps {
	panelService: ISidePanelService
}

export const AddInvoiceForm: any = (props: AddInvoiceFormProps) => {

	const { panelService } = props;
	notNull(panelService, 'panelService @ AddInvoiceForm');

	const dataContext = useContext(InvoiceDataContext);
	notNull(dataContext, 'dataContext @ AddInvoiceForm');

	const { invoiceData, deleteInvoice, showTemplatePanel } = dataContext;
	const invoice = invoiceData && invoiceData;

	notNull(invoice, 'invoiceData');

	const [showOverlay, setShowOverlay] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [pdfData, setPdfData] = useState('');

	if (!deleteInvoice)
		throw Error('deleteInvoice handler missing');

	const remarks = invoice ? invoice.footerNote : '';

	const handleDelete = () => {
		if (invoice)
			deleteInvoice(invoice.id);
	}

	const refresh = () => {
		if (dataContext.refresh) {
			dataContext.refresh();
		}
		else {
			throw Error('refresh missing')
		}
	}

	if (invoice === undefined)
		return;

	const getPdf = async (addPrefix: boolean) => {
		const data = await productService.getPdfAsync(invoice.id);

		if (data.base64Content) {
			if (addPrefix)
				return `data:application/pdf;base64,${data.base64Content}`
				else{
					return data.base64Content;
				}
		}
	}

	const showPdf = async () => {
		
		setShowLoader(true);

		await loadPdf();
		setShowOverlay(true);
		setShowLoader(false);
	}

	const loadPdf = async () => {
		const base64Data = await getPdf(false);
	
		if (base64Data) {
			setPdfData(base64Data)
		}
	}

	const sendInvoice = async (toEmail: string, message: string, carbonCopy: string): Promise<SendInvoiceResponse> => {
		const response = await productService.sendInvoiceAsync(invoice.id, toEmail, message, carbonCopy)
		if (response.status === 0) {
			refresh();
		}

		return {
			success: response.status === 0
		}
	}

	const openSendInvoicePanel = () => {
		
		const toEmail = invoice.toParty.business.email ? invoice.toParty.business.email : '';
		const panelContent = <SendInvoicePanel onClose={panelService.close} toEmail={toEmail} message={''} onSend={sendInvoice} />
		panelService.showSidepanel('Send invoice', panelContent);
	}

	const handleLineUpdated = (result: invoiceApiResponse) => {
		if (result.status === 0) {
			panelService.close();
			refresh();
		}
		else {
			console.warn(result);
			throw Error('unexpected result')
		}
	}

	const handleEditInvoiceLine = (item: InvoiceLineItem) => {
		const panelContent = <UpdateLinePanel invoiceId={invoice.id} item={item} onInvoiceLineSaved={handleLineUpdated} defaultVat={'0.21'} />
		panelService.showSidepanel('', panelContent);
	}

	const onPDFLoaded = (totalPages: any) => {
        console.log(totalPages)
	};
	
	const handleDownload = async () => {
		const base64Data = await getPdf(false);

		if (base64Data)
			download(base64Data, invoice.invoiceNumber, 'application/pdf');
		else
			throw Error('PDF not loaded')
	}

	return (
		<>
			<div className="invoice">
				<div className="invoice__inner">
					<LimitContent width={'1200px'} align='left'>
						<InvoiceHeader />
						<InvoiceEditZone>
							<InvoiceLines panelService={panelService} onEdit={handleEditInvoiceLine} />
						</InvoiceEditZone>

						<InvoiceEditZone>
							<InvoiceTotals onShowPreview={() => showPdf()} onDeleteInvoice={handleDelete} onSendInvoice={openSendInvoicePanel} showLoader={showLoader} onDownload={handleDownload} />
						</InvoiceEditZone>

						<InvoiceEditZone onClick={showTemplatePanel}>
							<InvoiceRemarks remarks={remarks} />
						</InvoiceEditZone>

					</LimitContent>
				</div>
			</div>

			{showOverlay &&

				<Overlay onClose={() => setShowOverlay(false)} type="iframe">
					 {pdfData && <PdfViewer content={pdfData} page={1} fillWidth fillHeight scale={1} onDocumentComplete={onPDFLoaded} />}
					 {!pdfData && <div>Just a sec...</div>}
				</Overlay>
			}
		</>
	);
}

interface InvoiceLinesProps {
	panelService: ISidePanelService,
	onEdit(item: InvoiceLineItem): void,
}

export const InvoiceLines = ({ panelService, onEdit }: InvoiceLinesProps) => {

	const dataContext = useContext(InvoiceDataContext);
	const { invoiceData, refresh } = dataContext;

	if (invoiceData === undefined || refresh === undefined)
		return null;

	const { lineItems } = invoiceData;
	notNull(lineItems, 'lineItems');

	const confirmDelete = async (line: InvoiceLineItem) => {

		console.log('todo: request confirmation', line);

		//todo: show panel

		const result = await productService.removeInvoiceLine(invoiceData.id, line.productOrTask.lineItemId);
		refresh();
	};

	const invoiceLines = lineItems.map(l => <TaskOrProductLine key={l.productOrTask.lineItemId} line={l} onDelete={confirmDelete} onEdit={onEdit} />)

	const handleLineAdded = (result: addInvoiceLineResponse) => {

		if (result.status === 0) {
			panelService.close();
			refresh();
		}

		// todo: show notification if something failed
	}

	const handleNewInvoiceLine = () => {
		const panelContent = <AddLinePanel invoiceId={invoiceData.id} onInvoiceLineSaved={handleLineAdded} defaultVat={'0.21'} />
		panelService.showSidepanel('Nieuwe lijn', panelContent);
	}

	return (
		<>
			<div className="invoice__lines">
				<HeaderLine />
				{invoiceLines}
				{/* <PendingLine /> */}
				{/* <SpacerInvoiceLine />
				<DiscountInvoiceLine /> */}
			</div>
			<Button onClick={handleNewInvoiceLine} color={COLOR.secondary_2}>Voeg nieuwe regel toe</Button>
		</>
	)
}

interface RowActionsProps {
	canEdit: boolean;
	onDelete(): void,
	setStayOpen: any,
	onEdit(): void
}

export const RowActions = ({ canEdit = true, onDelete, setStayOpen, onEdit }: RowActionsProps) => {

	const [lineDeleteConfirmationOpen, setLineDeleteConfirmationOpen] = useState(false);

	const handleDeleteConfirmed = () => {
		setLineDeleteConfirmationOpen(false);
		onDelete();
		setStayOpen(false);
	}

	const handleToggle = () => {
		setStayOpen(true);
		setLineDeleteConfirmationOpen(true);
	}

	const handleDeclined = () => {
		setLineDeleteConfirmationOpen(false);
		setStayOpen(false);
	}

	const styles = cx(
		'invoice__rowActions',
		{ 'stay-open': lineDeleteConfirmationOpen }
	);

	return (
		<div className={styles}>
			{canEdit &&
				<Control size={CONTROL_SIZE.small} isGhost onClick={onEdit}>
					<i className="material-icons">edit</i>
				</Control>
			}
			<Confirmation open={lineDeleteConfirmationOpen} message={<InvoiceLineDeleteConfirmation handleAccept={handleDeleteConfirmed} handleDecline={handleDeclined} />} align="right" valign="middle">
				<Control size={CONTROL_SIZE.small} color="color-error" onClick={handleToggle}>
					<i className="material-icons">delete</i>
				</Control>
			</Confirmation>
		</div>
	);
};

interface UnitPriceProps {
	price: number,
	unit: string
}

export const UnitPrice = ({ price, unit }: UnitPriceProps) => {

	return (
		<><Amount value={price} /> per {unit}</>
	)
}

interface ProductNoteProps {
	product: ProductDto
}

export const ProductNote = ({ product }: ProductNoteProps) => {
	return (
		<>
			{product.description}
		</>
	)
}

interface InvoiceLineDeleteConfirmationProps {
	handleAccept: any,
	handleDecline: any
}

const InvoiceLineDeleteConfirmation = ({ handleAccept, handleDecline, ...props }: InvoiceLineDeleteConfirmationProps) => {
	return (
		<Box border={true}>
			<Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Lijn verwijderen</Heading>
			<Paragraph size={PARAGRAPH_SIZE.small} spacing={PARAGRAPH_SPACING.xs}>Ben je zeker? Deze wordt definitief verwijderd</Paragraph>
			<div className="row no-pad-v">
				<div className="col-md-6">
					<Button size={BUTTON_SIZE.full} color={COLOR.secondary_2} onClick={handleAccept}>Ja</Button>
				</div>
				<div className="col-md-6">
					<Button size={BUTTON_SIZE.full} color="color-error" onClick={handleDecline}>Nee</Button>
				</div>
			</div>
		</Box>
	)
}