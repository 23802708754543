import React from 'react';
import './styles/_chain.scss';
import cx from "classnames";
import { ChainItem } from './ChainItem';

export const Chain = ({ items= [] }) => {

	const style = cx(
		'chain'
	);

	// remark: render items in reverse order
	const children = items.reverse().map((ci, i) => {
		const { heading, caption, material_icon } = ci
		return (<ChainItem key={i} heading={heading} caption={caption} material_icon={material_icon} />)
	})

	return (
		<div className={style}>
			<div className="chain__inner">
				<div className="chain__list">
					{children}
				</div>
			</div>
		</div>
	)
};