import React from 'react';
import './styles/_status.scss';
import cx from 'classnames';

import {STATUS_TYPE} from "../../constants";

export const Status = ({type=STATUS_TYPE.default, value='', dot=false}) => {

    const style = cx('status',
        [type],
        {'is-dot': dot === true},
    );

    return (
        <span className={style}>{value}</span>
    )
};