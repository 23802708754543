import { dev } from './isInDebug';

export const appMode = () => {
    
    const mode = parseInt(sessionStorage.getItem('mode'));
    let result = 2;

    if (mode) {
        result = mode
    }

    return result;
}

export const getAppVersion = () => {
    try {
        const buildNumber = dev() ? 'dev' : process.env.REACT_APP_BITBUCKET_BUILD_NUMBER
        const appVersion = `trive_atomic_react-2_1_${buildNumber}`
        return appVersion;    
    } catch (error) {
        return "error";
    }
    
}