import React from 'react';
import './styles/_overlay.scss';
import cx from "classnames";
import { Control } from '../../02_atoms/control/Control';
import { CONTROL_SIZE } from '../../constants';

export const Overlay = ({ children, open = false, type = '', onClose }) => {

	const style = cx(
		'overlay',
		{ 'is-open' : open },
		{ 'type-iframe' : type === 'iframe' }
	);

	return (
		<div className={style}>
			<div className="overlay__inner" onClick={() => { console.log('click'); onClose(); }}>
                <div className="overlay__holder">
					<div class="overlay__content">
						{ children }
					</div>
					<div class="overlay__close">
						<Control size={CONTROL_SIZE.medium}>
							<i className="material-icons">close</i>
						</Control>
					</div>
                </div>
			</div>
		</div>
	);

};