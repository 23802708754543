import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { isInDebug } from '../../00_global/isInDebug';
import { Invoice, InvoiceLineItem, ProductDto, UnitDto, GetVatPercentageDto, CustomerDetailDto, CreateCustomerDto, InvoiceSettingsDto } from './models/api';
import { modules } from '../../00_global/debugInfo';
import { InvoiceListItemDto } from './models/dtos';
import { InvoiceTemplateDto } from './models/invoiceSettings';

const { hostname, protocol } = window.location;

const runLocal = false; //debug on localhost

const getEndpoint = (hostname: string) => {
    switch (hostname) {
        case 'preview.trive.io': return 'https://app.trive.io/'
        //case 'localhost': return runLocal ? 'http://0.0.0.0:8080/https://cloud.trive.io/' : 'https://test.app.trive.io'
        case 'localhost': return runLocal ? 'http://localhost:5000/' : 'https://test.app.trive.io'
        default: return `${protocol}//${hostname}`
    }
}

const endpoint = getEndpoint(hostname);

const instance = axios.create({
    baseURL: endpoint,
    withCredentials: false,
});

const debug = isInDebug(modules.INVOICE_SERVICE);
const token = () => 'Bearer ' + sessionStorage.getItem('token');

const _get = async (route: string) => {

    const jwt = token();
    const authorization = jwt? jwt : null

    try {
        const response = await instance.get(route, {
            headers: {
                authorization
            }
        });

        const { data } = response;
        debug && console.log('response received', response)

        return data;
    }
    catch (error) {
        debug && console.log('error received', error)
        debug && console.log('error response', error.response);

        if (error && error.response && error.response.status && error.response.status === 401) {
            console.warn('user not authenticated');
        }

        throw error;
    }
}

const _rawPost = async (route: string, token:string) => {

    const options = {}
    const body = {
        token: token
    }

    const response = await instance.post(route, body, options);
    const { data } = response;

    debug && console.log(`POST to ${route} result`, response);
    return data;

}

const _post = async (route: string, body: any) => {

    const options = {
        headers: {
            Authorization: token(),
        }
    }

    const response = await instance.post(route, body, options);
    const { data } = response;

    debug && console.log(`POST to ${route} result`, response);
    return data;
}

const _delete = async (route: string) => {
    const response = await instance.delete(route, {
        headers: {
            Authorization: token(),
        }
    });

    const { data } = response;
    debug && console.log(`DELETE to ${route} result`, response);
    return data;
}

// ###########################################################################

export interface getProductsResponse {
    products: ProductDto[]
}

export interface createDraftResponse {
    draft: Invoice,
    status: number
}

export interface getInvoiceResponse {
    item: Invoice,
    status: number
}

export interface saveDraftInvoiceRequest {
    draft: Invoice
}

export interface getInitialValuesResponse {
    products: ProductDto[],
    units: UnitDto[],
    vat: GetVatPercentageDto[]
}

export interface getListResponse<TDto> {
    items: TDto[],
    status: number
}

export interface getDetailResponse<TDto> {
    item: TDto,
    status: number
}

export interface updateRequest<TDto> {
    item: TDto
}

const getProducts = async (): Promise<getProductsResponse> => {
    const data = await _get(`api/products`);
        return {
            products: data.products
        };
}

const getCustomersAsync = async (): Promise<getListResponse<CustomerDetailDto>> => {

    const data = await _get(`api/customers`);
    return data;
}

const getCustomerDetailsAsync = async (id: string): Promise<getDetailResponse<CustomerDetailDto>> => {

    if (!id) throw Error('customerId not specified!');

    const data = await _get(`api/customers/${id}`);

    return {
        item: data.item,
        status: data.status
    };
}

const getInvoicesAsync = async (): Promise<getListResponse<InvoiceListItemDto>> => {
    const data = await _get(`api/invoices`);
    return data;
}

const getTemplatesAsync = async (): Promise<getListResponse<InvoiceTemplateDto>> => {
        const data: getListResponse<InvoiceTemplateDto> = await _get(`api/invoices/templates`);
        return data;
}

const getInitialValuesAsync = async (): Promise<getInitialValuesResponse> => {

    const getProductsResponse = await getProducts();
    const getUnitsResponse = getUnits();

    return {
        products: getProductsResponse.products,
        units: getUnitsResponse,
        vat: getVatPercentages().items
    }
}

const getDraftAsync = async (): Promise<createDraftResponse> => {
    const data = await _get(`api/invoices/draft`);
    const response = {
        draft: <Invoice>data.draft,
        status: <number>data.status
    }
    return response;
}


const createCustomerAsync = async (customer: CreateCustomerDto): Promise<getDetailResponse<CustomerDetailDto>> => {
    console.log('Create customer');

    const data = await _post(`api/customers`, {
        item: customer
    });
    const response = {
        item: <CustomerDetailDto>data.item,
        status: <number>data.status
    }
    return response;
}

const updateCustomerAsync = async (customer: CreateCustomerDto): Promise<getDetailResponse<CustomerDetailDto>> => {
    console.log('Update customer');

    const data = await _post(`api/customers/${customer.id}`, {
        item: customer
    });
    const response = {
        item: <CustomerDetailDto>data.item,
        status: <number>data.status
    }
    return response;
}

const createProductAsync = async (product: ProductDto): Promise<getDetailResponse<ProductDto>> => {
    const data = await _post(`api/products`, {
        item: product
    });
    const response = {
        item: <ProductDto>data.item,
        status: <number>data.status
    }
    return response;
}

const saveDraftInvoice = async (request: saveDraftInvoiceRequest) : Promise<getInvoiceResponse> => {

    const data = await _post(`api/invoices/draft/${request.draft.id}`, request);

    const response = {
        item: <Invoice>data.draft,
        status: <number>data.status
    }

    return response;
}

const updateTemplateAsync = async (item: InvoiceTemplateDto) : Promise<invoiceApiResponse> => {
    
    const request: updateRequest<InvoiceTemplateDto> = {
        item: item
    }

    const data:invoiceApiResponse  = await _post(`api/invoices/templates/${request.item.id}`, request);
    return data;
}

const applyTemplateToInvoiceAsync = async (invoiceId: string, templateId: string) : Promise<getInvoiceResponse> => {

    const applyTemplateRequest = {
        invoiceId: invoiceId,
        templateId: templateId,
    }

    const data = await _post(`api/invoices/applyTemplate/`, applyTemplateRequest);

    const response = {
        item: <Invoice>data.item,
        status: <number>data.status
    }
    return response;
}

const activateInvoiceModuleAsync = async () : Promise<invoiceApiResponse> => {
    const request = {}

    const data:invoiceApiResponse  = await _post(`api/invoices/activate`, request);
    return data;
}

const getInvoiceModuleSettingsAsync = async () : Promise<getDetailResponse<InvoiceSettingsDto>> => {
    const request = {}

    const data  = await _get(`api/invoices/settings`);
    return data;
}

const sendInvoiceAsync = async (invoiceId: string, toEmail: string, message: string, carbonCopy: string) : Promise<invoiceApiResponse> => {
    const sendInvoiceRequest = {
        invoiceId,
        toEmail,
        message,
        carbonCopy
    }
    const data:invoiceApiResponse  = await _post(`api/invoices/send/${invoiceId}`, sendInvoiceRequest);
    return data;
}

const createInvoiceTemplateAsync = async (item: InvoiceTemplateDto) : Promise<invoiceApiResponse> => {
    
    const request: updateRequest<InvoiceTemplateDto> = {
        item: item
    }

    const data:invoiceApiResponse  = await _post(`api/invoices/templates/`, request);
    return data;
}

const getInvoiceAsync = async (documentId: string): Promise<getInvoiceResponse> => {
    const data = await _get(`api/invoices/${documentId}`);
    const response = {
        item: <Invoice>data.item,
        status: <number>data.status
    }
    return response;
}

const getPdfAsync = async (invoiceId: string): Promise<getPdfResponse> => {
    const data: getPdfResponse = await _get(`api/invoices/print/${invoiceId}`)
    return data;
}

const getInvoiceForPrintAsync = async (documentId: string, token:string): Promise<getInvoiceResponse> => {

    const data = await _rawPost(`api/invoices/private/${documentId}`, token);

    const response = {
        item: <Invoice>data.item,
        status: <number>data.status
    }
    return response;
}

const getVatPercentages = () => {
    var dtos = [
        { id: '0.21', value: 0.21, caption: '21%' },
        { id: '0.06', value: 0.06, caption: '6%' },
        { id: '0', value: 0, caption: '0%' },
    ]

    return {
        items: dtos,
        status: 0
    };
}

const getUnits = () => {
    return [
        { id: 'pce', caption: 'stuk' },
        { id: 'y', caption: 'jaar' },
        { id: 'm', caption: 'maand' },
        { id: 'd', caption: 'dag' },
    ]
}

export interface addInvoiceLineResponse {
    status: number,
    version: number
}

export interface getPdfResponse {
    base64Content: string,
    exception?: string
}

interface UpdateInvoiceLineRequest {
    item: InvoiceLineItem
}

const updateInvoiceLineAsync = async (invoiceId: string, invoiceLine: InvoiceLineItem): Promise<invoiceApiResponse> => {
    
    if (!invoiceLine.productOrTask.lineItemId)
        throw Error('lineId missing')

    const request : UpdateInvoiceLineRequest = {
        item: invoiceLine
    }

    const response = await _post(`api/invoices/${invoiceId}/line/${invoiceLine.productOrTask.lineItemId}`, request);

    return {
        status: response.status
    }

}

const addInvoiceLine = async (invoiceId: string, invoiceLine: InvoiceLineItem): Promise<addInvoiceLineResponse> => {

    invoiceLine.productOrTask.lineItemId = uuidv4();
    const request = {
        lineItem: invoiceLine,
        invoiceId: invoiceId
    }

    const response = await _post(`api/invoices/line`, request);

    return {
        status: response.status,
        version: response.version
    }
}

export interface invoiceApiResponse {
    status: number
}

const removeInvoiceLine = async (invoiceId: string, lineId: string): Promise<invoiceApiResponse> => {

    const response = await _delete(`api/invoices/${invoiceId}/line/${lineId}`);

    return response
}

const removeInvoice = async (invoiceId: string): Promise<invoiceApiResponse> => {

    const response = await _delete(`api/invoices/${invoiceId}`);
    return response
}

const deleteCustomer = async (customerId: string): Promise<invoiceApiResponse> => {
    
    const response = await _delete(`api/customers/${customerId}`);
    return response;
}

const deleteTemplate = async (templateId: string): Promise<invoiceApiResponse> => {
    const response: invoiceApiResponse = await _delete(`api/invoices/templates/${templateId}`);
    return response;
}

export interface IProductService {
    getProductsAsync(): Promise<getProductsResponse>;
    createProductAsync(product: ProductDto) : Promise<getDetailResponse<ProductDto>>;

    // invoice stuff
    addInvoiceLine(invoiceId: string, invoiceLine: InvoiceLineItem): Promise<addInvoiceLineResponse>;
    updateInvoiceLine(invoiceId: string, invoiceLine: InvoiceLineItem): Promise<invoiceApiResponse>;
    getInitialValuesAsync(): Promise<getInitialValuesResponse>;
    getInvoicesAsync(): Promise<getListResponse<InvoiceListItemDto>>,
    createDraftAsync(): Promise<createDraftResponse>,
    getInvoiceAsync(invoiceId: string): Promise<getInvoiceResponse>,
    getInvoiceForPrintAsync(invoiceId: string, token:string): Promise<getInvoiceResponse>,
    removeInvoiceLine(invoiceId: string, lineId: string): Promise<invoiceApiResponse>,
    removeInvoice(invoiceId: string): Promise<invoiceApiResponse>,
    deleteTemplate(templateId: string) : Promise<invoiceApiResponse>,
    saveDraftInvoice(request: saveDraftInvoiceRequest): Promise<invoiceApiResponse>,
    getTemplatesAsync(): Promise<getListResponse<InvoiceTemplateDto>>
    updateTemplateAsync(item: InvoiceTemplateDto) : Promise<invoiceApiResponse>
    createInvoiceTemplateAsync(item: InvoiceTemplateDto) : Promise<invoiceApiResponse>
    getPdfAsync(invoiceId: string) : Promise<getPdfResponse>
    sendInvoiceAsync(invoiceId: string, toEmail: string, message: string, carbonCopy: string): Promise<invoiceApiResponse>
    applyTemplateToInvoice(invoiceId: string, templateId: string) : Promise<getInvoiceResponse>
    activateInvoiceModuleAsync() : Promise<invoiceApiResponse>
    getInvoiceModuleSettingsAsync() : Promise<getDetailResponse<InvoiceSettingsDto>>

    // customers
    deleteCustomer(customerId: string): Promise<invoiceApiResponse>,
    getCustomerDetail(customerId: string) : Promise<getDetailResponse<CustomerDetailDto>>,
    createCustomerAsync(customer:CreateCustomerDto) : Promise<getDetailResponse<CustomerDetailDto>>
    updateCustomerAsync(customer:CreateCustomerDto) : Promise<getDetailResponse<CustomerDetailDto>>
    getCustomersAsync(): Promise<getListResponse<CustomerDetailDto>>;

    // masterdata
    getUnitsAsync(): UnitDto[];
    getVatPercentages(): getListResponse<GetVatPercentageDto>;

}

export const productService: IProductService = {
    getCustomersAsync: () => {return getCustomersAsync()},
    getProductsAsync: () => { return getProducts() },
    createProductAsync: createProductAsync,
    getVatPercentages: () => { return getVatPercentages() },
    addInvoiceLine: addInvoiceLine,
    getUnitsAsync: () => { return getUnits() },
    getInitialValuesAsync: () => { return getInitialValuesAsync() },
    getInvoicesAsync: getInvoicesAsync,
    createDraftAsync: getDraftAsync,
    getInvoiceAsync: getInvoiceAsync,
    getInvoiceForPrintAsync: getInvoiceForPrintAsync,
    removeInvoiceLine: removeInvoiceLine,
    removeInvoice: removeInvoice,
    deleteCustomer: deleteCustomer,
    deleteTemplate: deleteTemplate,
    saveDraftInvoice: saveDraftInvoice,
    getCustomerDetail: getCustomerDetailsAsync,
    createCustomerAsync: createCustomerAsync,
    updateCustomerAsync: updateCustomerAsync,
    getTemplatesAsync: getTemplatesAsync,
    updateTemplateAsync: updateTemplateAsync,
    createInvoiceTemplateAsync: createInvoiceTemplateAsync,
    getPdfAsync: getPdfAsync,
    sendInvoiceAsync: sendInvoiceAsync,
    updateInvoiceLine: updateInvoiceLineAsync,
    applyTemplateToInvoice: applyTemplateToInvoiceAsync,
    activateInvoiceModuleAsync: activateInvoiceModuleAsync,
    getInvoiceModuleSettingsAsync : getInvoiceModuleSettingsAsync
}

export const invoiceService = {
    //getDefaults: getDefaults,
    debug: true,
    getUnits,
    getVatPercentages,
}