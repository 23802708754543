import cx from 'classnames';
import React from 'react';
import { Paging } from "../../";
import './styles/_tableLayout.scss';

export const TableLayout = ({header, children, showPaging = false}) => {

	const style = cx(
		'tableLayout'
	);

	return (
		<div className={style}>
			<div className="tableLayout__inner">
				{header && <div className="tableLayout__options">
					{header}
				</div>
				}
				<div className="tableLayout__table">
					{children}
					
				</div>
				<div className="tableLayout__paging">
					{showPaging && <Paging />}
				</div>
			</div>
		</div>
	)
};