import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import cx from 'classnames';
import queryString from 'query-string';

import { Button, Experience, Field, FormField, Input, Wrapper, Box } from "../../";
import { BUTTON_SIZE, HEADING_SPACING, STATUS_TYPE } from "../../constants";
import { LogoPlain } from "../../01_fundaments/logo";
import { Formik } from 'formik';
import { ShowObject, FetchData, notNull } from '../../00_global';
import { Checkbox } from '../../02_atoms/check/Check';

import { dataservice } from '../../00_global/service/dataservice';
import { LoaderContainer } from '../../03_molecules/loaderContainer/LoaderContainer';
import { Heading } from '../../02_atoms/headings/Heading';
import { Status } from '../../02_atoms/status/Status';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';
import { dev } from '../../00_global/isInDebug';
import { Redirect } from 'react-router-dom';

const color = 'color-primary-1'; //'color-secondary-2';
const debug = false;

const OnboardingBox = ({ validatedDetails }) => {

	notNull(validatedDetails, 'validatedDetails');
	const [stateMachine, setStateMachine] = useState(-1);
	const [errors, setErrors] = useState([]);

	const initialValues = {
		accountName: '',
		password: '',
		conditionsAccepted: false,
	}

	const { email, firstName, lastName, signature, expires } = validatedDetails;

	const createAccount = async (values, actions) => {

		const request = {
			accountName: values['accountName'],
			password: values['password'],
			email: email,
			firstName: firstName,
			lastName: lastName,
			signature: signature,
			expires: expires,
			privacyConsent: true,
			tcConsent: values['conditionsAccepted'],
			dryRun: dev()
		}

		var response = await dataservice.createAccount(request);
		
		if (response.code === 200) {
			setStateMachine(1);
			setErrors([]);
		}
		else if (response.errors && response.errors.length > 0) {
			setStateMachine(0); // submitted, with errors
			setErrors(response.errors);
		}

		actions.setSubmitting(false);
	}

	const validationSchema = Yup.object().shape({
		accountName: Yup.string()
			.required('Verplicht veld!'),
		password: Yup.string()
			.min(6, 'Minstens 6 tekens')
			.required('Verplicht veld!'),
		conditionsAccepted: Yup.bool().oneOf([true], "Je moet de algemene voorwaarden aanvaarden")
	})

	const formikForm = <Formik
		initialValues={initialValues}
		validationSchema={validationSchema}
		validateOnChange={true}
		isInitialValid={validationSchema}
		validateOnBlur={true}
		onSubmit={createAccount}
	>
		{
			(formikProps) => {
				const { handleSubmit } = formikProps;
				return (
					<Fragment>
						<form className="form" onSubmit={handleSubmit}>
							<FormField>
								<Field id={'accountName'} header={'Dossiernaam'} infoMessage={'Handelsnaam/bedrijfsnaam'} color={color}>
									<Input placeholder={'De vennootschapsvorm (bijvoorbeeld bvba mag je weglaten)'} />
								</Field>
							</FormField>

							<FormField>
								<Field id={'password'} header={'Wachtwoord'} infoMessage={'Minstens 6 tekens'} color={color}>
									<Input type={'password'} placeholder={'Minstens 6 tekens'} />
								</Field>
							</FormField>
							<FormField>
								<Field id={'conditionsAccepted'} header={'Algemene voorwaarden'} infoMessage={'Goede afspraken, goede vrienden'} color={color}>
									<Checkbox label={<span>Ik aanvaard de <a href={'https://www.trive.be/algemene-voorwaarden'} target='_blank' rel="noopener noreferrer">algemene voorwaarden</a></span>}></Checkbox>
								</Field>
							</FormField>

							<FormField>
								<Field id={'button'} >
									<Button type={'submit'} color={color} size={BUTTON_SIZE.full}>Registreer</Button>
								</Field>
							</FormField>
							{debug && <ShowObject value={validatedDetails} />}
						</form>
					</Fragment>
				)
			}
		}
	</Formik>

	return (
		<Box header={false}>
			{stateMachine === -1 && <div>{formikForm}</div>}
			{stateMachine === 0 && 
			<>
				<Errors errors={errors} />
				<div>{formikForm}</div>
			</>
			}
			{stateMachine === 1 && <Redirect to='/content/welcome' />}

		</Box>
	);
}

const Errors = ({errors}) => {
	const items = errors.map(err => <li style={{'color':'red'}}>{err}</li>);
	return (
		<ul>
			{items}
		</ul>
	)
}

const CheckOnboardingDetails = ({ onValidated }) => {

	notNull(onValidated, 'onValidated');

	const q = queryString.parse(window.location.search);
	const parameters = {
		email: q.email,
		firstName: q.fn,
		lastName: q.ln,
		signature: q.sig,
		expires: q.exp,
	};

	return (<FetchData method={() => dataservice.validateOnboarding(parameters)} >
		{
			({ error, loading, data }) => {
				if (data && data.success) {
					onValidated({
						result: data,
						parameters: parameters
					}); // trigger state change
				}
				else if (data && data.errors && data.errors.length > 0) {
					return (
						<ValidationErrors errors={data.errors} />
					)
				}
				else if (loading) {
					return <LoaderContainer showLoader={true} />
				}
				else if (error) {
					return <Bug error={error} />
				}

			}
		}
	</FetchData>)
}

const ValidationErrors = ({ errors }) => {

	const showErrors = errors.map(err => <div><Status dot={true} type={STATUS_TYPE.error}></Status> {err}</div>)

	return (
		<>
			<Box header={false}>
				<Heading spacing={HEADING_SPACING.medium}>Oeps... er gaat iets mis!</Heading>
				{showErrors}
				<div><br />Heb je een probleem met je registratie? Dan kan je ons altijd <a href='https://www.trive.be/contacteer-ons'>contacteren</a>. We helpen je graag verder!</div>
			</Box>
		</>
	)
}

const OnboardingFooter = () => (
	<Fragment>
		<LogoPlain />
	</Fragment>
);

const OnboardingIntro = ({firstName}) => (
	<Fragment>
		<h1 className="heading size-l spacing-s weight-400">Howdy {firstName}!</h1>
		<p>Leuk dat je Trive wil uitproberen! We hebben nog een paar gegevens van jou nodig, na deze stap kan je onmiddellijk aan de slag.</p>
	</Fragment>
);

export const Onboarding = () => {

	const [stateMachine, setStateMachine] = useState(-1);
	const [validatedDetails, setValidatedDetails] = useState();

	const style = cx(
		'template--onboarding'
	);

	const checking = <CheckOnboardingDetails onValidated={(response) =>{
		if (response.result.success) {
			setValidatedDetails(response.parameters);
			setStateMachine(2);
		}  
	}} />

	return (
		<div className={style}>
			<Wrapper header={false} footer={false} compact={true}>
				{/* <Experience intro={<OnboardingIntro />} box={<OnboardingBox />} footer={<OnboardingFooter />} theme="color-secondary-2" /> */}

				{stateMachine === -1 && <Experience intro={null} box={checking} footer={<OnboardingFooter />} />}
				{stateMachine === 2 && <Experience intro={<OnboardingIntro firstName={validatedDetails.firstName} />} box={<OnboardingBox validatedDetails={validatedDetails} />} footer={<OnboardingFooter />} />}
			</Wrapper>
		</div>
	)
};