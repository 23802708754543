import React from 'react';
import cx from "classnames";

export const GridColumn = ({size = 'col-md-6', children}) => {

    const style = cx(size);

    return (<div className={style}>
        {children}
    </div>)


}