
import React, { useState } from 'react';
import cx from 'classnames';
import './styles/_confirmation.scss';
import { Control, Box, Heading, Paragraph, Button } from '../..';
import { CONTROL_SIZE, HEADING_SIZE, HEADING_WEIGHT, PARAGRAPH_SIZE, PARAGRAPH_SPACING, BUTTON_SIZE, COLOR } from '../../constants';

interface ConfirmationProps {
    //children: any,
    message: string,
    align?: string,
    valign?: string,
    onDeleteConfirmed(): void
}

export const ConfirmationV2 = ({align='right', valign='top', message, onDeleteConfirmed}: ConfirmationProps) => {

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    // create classes
    const styles = cx(
        'confirmation',
        { 'is-open' : deleteConfirmationOpen},
        `align-${align}`,
        `valign-${valign}`
    );

    const handleDeleteConfirmed = async () => {
        setSubmitting(true)
        onDeleteConfirmed();

        setDeleteConfirmationOpen(false);
        setSubmitting(false);
    }

    const handleDeleteCancelled = () => {
        setDeleteConfirmationOpen(false);
    }


    return (
        <>
        <div className={styles}>
            <div className="confirmation__inner">
                <div className="confirmation__trigger">
                    <Control size={CONTROL_SIZE.small} isGhost color="color-error" onClick={() => { setDeleteConfirmationOpen(true); }}>
                        <i key={'delete_invoice'} className="material-icons">delete</i>
                    </Control>
                </div>
                {deleteConfirmationOpen && 
                    <div className="confirmation__message">
                        <DeleteConfirmation handleConfirmed={handleDeleteConfirmed} handleCancelled={handleDeleteCancelled} message={submitting ? 'even geduld' : message} submitting={submitting} />
                    </div>
                }
            </div>
        </div>
        </>
    );

};

interface DeleteConfirmationProps {
    handleConfirmed: any,
    handleCancelled: any,
    message: string,
    submitting: boolean
}

const DeleteConfirmation = ({ handleConfirmed, handleCancelled, message, submitting }: DeleteConfirmationProps) => {
    return (
        <Box border={true}>
            <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Ben je zeker?</Heading>
    <Paragraph size={PARAGRAPH_SIZE.small} spacing={PARAGRAPH_SPACING.xs}>{message}</Paragraph>
            <div className="row no-pad-v">
                <div className="col-md-6">
                    <Button disabled={submitting} size={BUTTON_SIZE.full} color={COLOR.secondary_2} onClick={handleConfirmed}>Ja</Button>
                </div>
                <div className="col-md-6">
                    <Button disabled={submitting} size={BUTTON_SIZE.full} color="color-error" onClick={handleCancelled}>Nee</Button>
                </div>
            </div>
        </Box>
    )
}