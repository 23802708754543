import React, { Fragment } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { TriveApp, FetchData, NotFound, Bug, isInDebug } from "./components";
import { Tasks } from './components/06_features/tasks/Tasks';
import { Archive } from './components/06_features/archive/Archive';
import { dataservice } from './components/00_global/service/dataservice';
import { Upload, ExportDocuments, Dashboard, Inbox, InboxQueue, ReconciliationV2 } from './components/06_features';
import { notNull } from './components/00_global';
import { ExportOverview } from './components/06_features/export/ExportOverview';
import { UserProfile } from './components/06_features/userprofile/UserProfile';
import { DocumentViewer } from './components/06_features/documentViewer/DocumentViewer'
import { ShowContent } from './components/06_features/content/ShowContent';
import { DevMode } from './components/06_features/invoice_module_v1/_temp-panel'
import { InvoiceOverview } from './components/06_features/invoice_module_v1/InvoiceOverview';
import { CreateInvoice } from './components/06_features/invoice_module_v1/CreateInvoice';
import { CustomerOverview } from './components/06_features/customers/CustomerOverview';
import { ProductOverview } from './components/06_features/products/ProductOverview';
import { InvoiceTemplateOverview } from './components/06_features/invoice_module_v1/templates/InvoiceTemplateOverview';
import { EnableInvoiceModule, ActivateInvoiceModule, ModuleActivated } from './components/06_features/invoice_module_v1/onboarding/EnableInvoiceModule';
import { AnnotateModule } from './components/06_features/annotate/'

export const routes = {
    invoices: {
        templateOverview: '/invoices/templates/overview',
        invoiceOverview: '/invoices/overview'
    }
}

const Routes = (props) => {
    const { history } = props;

    notNull(history, 'history');

    // if (!slug) {
    //         return (<Redirect to={routeWithSlug('/dashboard')} />);
    // }

    const debug = isInDebug('dev');

    const redirect = <Redirect to={'/dashboard'} />

    return (
        <TriveApp>
            <FetchData method={dataservice.ping}>
                {
                    ({ data, error }) => {
                        return (
                            <Fragment>
                                {data && <Switch>
                                    <Route path={`/content/:slug`} exact component={ShowContent} />
                                    <Route path={`/dashboard`} exact component={Dashboard} />
                                    <Route path={`/inbox`} exact component={InboxQueue} />
                                    <Route path={`/tasks`} component={Tasks} />
                                    <Route path={`/search`} component={Archive} />
                                    <Route path={`/inbox/:documentId`} component={Inbox} />
                                    <Route path={`/reconciliation`} component={() => <ReconciliationV2 ds={dataservice} />} />
                                    <Route path={`/export`} exact component={ExportDocuments} />
                                    <Route path={`/export/history`} exact component={ExportOverview} />
                                    <Route path={`/upload`} component={Upload} />
                                    <Route path={`/settings`} component={UserProfile} />
                                    <Route path={`/document/:documentId`}  component={DocumentViewer} />
                                    <Route path={`/invoices/dev`} exact component={DevMode} />
                                    <Route path={`/invoices/new`} exact component={() => <>don't use this anymore</>} />
                                    <Route path={`/invoices/overview`} exact  component={InvoiceOverview} />
                                    <Route path={`/invoices/edit/:invoiceId`} component={CreateInvoice} />
                                    <Route path={`/invoices/detail/:invoiceId`} component={() => <div>show invoice</div>} />
                                    <Route path={`/invoices/templates/:templateId`} component={InvoiceTemplateOverview} />
                                    <Route path={routes.invoices.templateOverview} exact component={InvoiceTemplateOverview} />
                                    <Route path={`/customers/overview`} exact component={CustomerOverview} />
                                    <Route path={'/invoices/onboarding'} component={EnableInvoiceModule} />
                                    <Route path={'/invoices/activate'} component={ActivateInvoiceModule} />
                                    <Route path={'/invoices/activated'} component={ModuleActivated} />
                                    <Route path={`/products/overview`} exact  component={ProductOverview} />
                                    <Route path={`/annotate`} exact component={AnnotateModule} />
                                    
                                    {!debug &&  <Route path={`/`}  component={() => redirect} />}
                                    {!debug && <Route path={`**`} render={() => (<NotFound onNavigateBack={history.goBack} />)} />}
                                </Switch>}
                                {error &&
                                    // <Redirect to={`/login`} />
                                    <Bug error={error} />
                                }
                            </Fragment>
                        )
                    }
                }
            </FetchData>
        </TriveApp>
    );
};

export const SluggedPrivateRoutes = withRouter(Routes);