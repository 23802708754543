import React from 'react';
import './styles/_footer.scss';
import cx from "classnames";

import {LogoIconColor} from "../../01_fundaments/logo";

export const Footer = ({ alt, ...props }) => {

	const style = cx(
		'footer',
		'section',
		{ 'theme-primary' : alt }
	);

	return (
		<div className={style}>
			<div className="footer__inner section__inner">

				<div className="footer__brand">
					<LogoIconColor />
				</div>
				<div className="footer__disclaimer">Copyright Trive.io - Alle rechten voorbehouden</div>

			</div>
		</div>
	)
};