import React, { useState } from 'react';
import { Grid, GridColumn, GridRow } from '../../index';
import { AnnotateExpense } from './AnnotateExpense'
import { InboxDocumentViewer } from './_amo_/index'
import { notNull, routeWithSlug } from '../../00_global';
import { SelectDocumentType } from './SelectDocumentType';
import { ConfirmDelete } from '../../03_molecules/confirmDelete/ConfirmDelete'
import { dataservice } from '../../00_global/service/dataservice';
import { withRouter } from "react-router-dom";
import { isInDebug } from '../../00_global/isInDebug';
import { AnnotateSalesInvoice } from './AnnotateSalesInvoice';
import { AnnotateOther } from './AnnotateOther';
import { Sticker } from '../../02_atoms/sticker/Sticker';
import { AnnotateSalesInvoiceLtd } from './AnnotateSalesInvoiceLtd';
import { AnnotateExpenseLtd } from './AnnotateExpenseLtd'
import { appMode } from '../../00_global/appMode';

function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds){
        break;
      }
    }
  }

const AnnotateExpenseSwitcher = ({ document, ltd = false }) => {
    return (
        ltd 
            ? <AnnotateExpenseLtd documentId={document.documentId}  /> 
            : <AnnotateExpense documentId={document.documentId} document={document} />
    )
}

const InboxFormViewerElement = ({detail, history}) => {

    const debug = isInDebug('Inbox');

    notNull(detail, 'detail')

    const [docType, setDocType] = useState(1);

    const handleDeleteRequested = (documentId) => {
        debug && console.log('delete requested', documentId);
        setDocType(5); // temp hack
    }

    const handleDeleteConfirmed = async (documentId) => {
        debug && console.log('confirmed!', documentId)
        try {
            const response = await dataservice.deleteDocument(documentId);
            debug && console.log('delete response', response);
            sleep(1000); // wait until BE finished processing
            history.push(routeWithSlug('inbox'));

        }
        catch(err){
            console.error(err)
        }
    }

    const mode = appMode();
    const ltd = mode === 1;

    const salesInput = ltd ? <AnnotateSalesInvoiceLtd documentId={detail.documentId} /> : <AnnotateSalesInvoice documentId={detail.documentId} />
    const otherInput = <AnnotateOther detail={detail} />

    return (
        <Grid>
            <GridRow>
                <GridColumn size='col-3'>
                    <Sticker>
                    {docType === 1 && <SelectDocumentType duplicates={detail.duplicateResult.duplicates} onDocumentTypeSelected={(docType) => setDocType(docType)} />}
                    {docType === 2 && salesInput }
                    {docType === 3 && <AnnotateExpenseSwitcher document={detail} ltd={ltd} /> }
                    {docType === 4 && otherInput }
                    {docType === 5 && <ConfirmDelete detail={detail}
                        onConfirmed={() => handleDeleteConfirmed(detail.documentId)}
                        onCancelled={() => setDocType(1)}
                    />}
                    </Sticker>
                </GridColumn>
                <GridColumn size='col-9'>
                    <InboxDocumentViewer 
                        documentId={detail.documentId}
                        title={detail.name}
                        content={detail.pdfContent}
                        onDelete={() => handleDeleteRequested(detail.documentId)}
                    />
                </GridColumn>
            </GridRow>
        </Grid>
    );
};

export const InboxFormViewer = withRouter(InboxFormViewerElement);
