import React from 'react';
import './styles/_paragraph.scss';
import cx from 'classnames';

import {PARAGRAPH_SIZE, PARAGRAPH_WEIGHT, PARAGRAPH_ALIGNMENT} from "../../constants";

export const Paragraph = ({
    children,
    narrow=false,
    nopadding=false,
    upper=false,
    size=PARAGRAPH_SIZE.default,
    weight=PARAGRAPH_WEIGHT.normal,
    alignment=PARAGRAPH_ALIGNMENT.default,
    spacing= '',
    color=''}) => {

    const style = cx('paragraph',
        [size],
        [weight],
        [alignment],
        [spacing],
        [color],
        { 'is-narrow' : narrow === true },
        { 'no-padding' : nopadding === true },
        { 'text-upper' : upper === true }
    );

    return (
        <div className={style}>{children}</div>
    )
};