
export const translateInvoiceStatusEnum = (status:number): string => {
    // Unknown = 0,
    //     Draft = 1,
    //     Send = 2,
    //     Paid = 3,
    //     Cancelled = 4,
    //     Deleted = 5,

    switch (status) {
		case 1:   return 'Concept';
		case 2: return 'Verzonden';
		case 3: return 'Betaald';
        case 4: return 'Cancelled';
        case 5: return 'Verwijderd';
		default:      return '';
	}
}