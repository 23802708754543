import React from 'react';
import * as Yup from 'yup';
import { ShowObject, Box, Heading, Ellipsis, FormField, Button } from '../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING } from '../../constants';
import { Formik, FormikProps } from 'formik';
import { SearchItem } from '../../03_molecules/search/SearchV2';
import { isInDebug } from '../../00_global/isInDebug';
import { ProductDto } from '../invoice_module_v1/models/api';
import { productService } from '../invoice_module_v1/invoiceService';
import { InputField } from '../invoice_module_v1/InputField';
import { Checkbox } from '../../02_atoms/check/Check';
import { ERROR_REQUIRED_FIELD, ERROR_INVALID_NUMBER } from '../invoice_module_v1/addInvoiceLineValidationSchema';

interface CreateProductPanelProps {
    // onCustomerCreated(customer: Customer): void
}

export const CreateProductPanel = (props: CreateProductPanelProps) => {

    const fields = {
        name: 'name',
        description: 'description',
        remark: 'remark',
        defaultPrice: 'defaultPrice',
        showDebug: 'showDebug'
    }

    const createProductValidationSchema = Yup.object().shape({
        [fields.name]: Yup.string().required(ERROR_REQUIRED_FIELD),
        [fields.defaultPrice]: Yup.number().required(ERROR_REQUIRED_FIELD).typeError(ERROR_INVALID_NUMBER)
    });

    const initialValues = {
        name: '',
        description: '',
        remark: '',
        defaultPrice: '',
        showDebug: ''
    }

    const onSubmit = async (values: any, actions: any) => {
        const p: ProductDto = {
            id: '',
            defaultPrice: 0,
            description: '',
            name: '',
            unit: '',
        }

        actions.isSubmitting = false;
        const result = await productService.createProductAsync(p);
        if (result.status === 0){
            // what?
        }
    }

    const getItems = (input: string): SearchItem[] => {

        let list: SearchItem[] = []

        return list;
    }
    
    const debugInfo = isInDebug('dev');

    return (
        <>

                <Formik initialValues={initialValues}
                    validateOnChange={true}
                    validateOnBlur={true}
                    // validate={handleValidate}
                    validationSchema={createProductValidationSchema}
                    onSubmit={(values, actions) => {
                        onSubmit(values, actions);
                    }}>
                    {
                        (formikProps: FormikProps<any>) => {
                            const { handleSubmit, errors, values, setFieldValue, isValid, isSubmitting } = formikProps

                            
                            return (
                                <form className="form" onSubmit={handleSubmit}>
                                    <Box id={'select_customer'} noBorder color="bgc--white">
                                        <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.xxs}>
                                            <Ellipsis>{'Nieuw product'}</Ellipsis>
                                        </Heading>
                                        <InputField id={fields.name} header={'Naam product'} formik={formikProps} />
                                        <InputField id={fields.description} header={'Omschrijving'} formik={formikProps} />
                                        <InputField id={fields.remark} header={'Opmerking'} infoMessage={'Standaard notitie/opmerking'} formik={formikProps} />
                                        <InputField id={fields.defaultPrice} header={'Prijs'} infoMessage={'per stuk/eenheid'} formik={formikProps} />
                                        <FormField>
                                            <Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button>
                                        </FormField>
                                        <Checkbox id={fields.showDebug} formik={formikProps} label={'debug?'}  />
                                    </Box>
                                    <Box id={'select_customer'} noBorder color="bgc--gray-10">   
                                        {values[fields.showDebug] && <ShowObject value={{ initialValues, values }} />}
                                    </Box>
                                </form>
                            )
                        }
                    }
                </Formik>

        </>
    )
}