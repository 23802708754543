import React from 'react';
import cx from 'classnames';
import { Control } from '../../index';
import './_invoice-v2.scss';

export const InvoiceEditZone = ({ children, onClick = null }) => {

	const editable = onClick; // only editable if there's a callback registered

	const editStyles = cx(
		'invoice__editZone',
		{ 'is-editable': editable }
	);

	const onDivClicked = (e) => {
		onClick && onClick(); // invoke callback
	}

	return (
		<div className={editStyles} onClick={(e) => { if (editable) { onDivClicked(e) } }}>
			{children}

			{editable &&
				<div className="invoice__editIcon">
					<Control>
						<i className="material-icons">edit</i>
					</Control>
				</div>
			}
		</div>
	)
};