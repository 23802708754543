import React, { useState, Fragment, useContext } from 'react';
import { Search, SidePanelContext, notNull, Bug, Heading, Paragraph } from '../../../index';
import { dataservice } from "../../../00_global/service/dataservice";
import { FetchData } from '../../../00_global/FetchData';
import { isInDebug } from '../../../00_global/isInDebug';
import { connect, getIn } from 'formik';
import { CreateSupplier } from './CreateSupplier'

import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE } from "../../../constants";
import { DateTime } from '../../../02_atoms/date/DateTime';
import { noResultsItemBuilder, searchResultItemBuilder } from '../../../03_molecules/search/helpers';

const debug = isInDebug('Inbox') && isInDebug('SupplierField');

let searchTimer;

const SupplierFieldElement = ({ id, selectedValueId, formik }) => {

    const [searchQuery, setSearchQuery] = useState('');
    const { add, close, sidepanels } = useContext(SidePanelContext);

    const handleSupplierCreated = (supplierId, supplierName) => {

        const selectedValue = {
            key: supplierId,
            displayValue: supplierName
        }

        supplierSelected(selectedValue);
        setFieldValue(id, supplierName); //update search inputfield

        if (sidepanels)
            close();
    }

    const handleNoResults = (value) => {

        notNull(value, 'value', 'Expected a value for the user input. Check the origin of handleNoResults');
        const addSupplier = <CreateSupplier userInput={value} onSupplierCreated={handleSupplierCreated} />
        add(addSupplier, true);
    }

    const handleChange = (e) => {
        // clear first
        clearTimeout(searchTimer);

        //close();

        const search = e.target.value;

        setFieldValue(id, search);
        setFieldValue(selectedValueId, '');

        debug && notNull(setFieldError, 'setFieldError');
        setFieldError(id, 'Maak een keuze uit de lijst');; // => push errors on selectedValueId to id so they get displayed on the Field

        searchTimer = setTimeout(() => {
            setSearchQuery(search);
        }, 200)
    };

    const formikProps = getIn(formik);
    const { values, setFieldValue, handleBlur, setFieldError } = formikProps;

    const supplierSelected = (selectedValue) => {
        if (!selectedValue) return; // 2019-04-06 bug while navigating + enter with keyboard
        setFieldValue(id, selectedValue.displayValue);
        setFieldValue(selectedValueId, selectedValue.key);
        setFieldError(id, '');; // => reset errors
    };

    const textboxValue = values[id];
    const selectedSupplierId = values[selectedValueId]
    const sidepanelOpen = sidepanels && sidepanels.length > 0;

    return (
        <FetchData method={() => dataservice.searchSupplier(searchQuery)} triggerValues={[searchQuery]}>
            {
                ({ data, error, loading }) => {
                    if (data) {

                        const searchResults = data;
                        searchResults.sort((srA, srB) => srB.score - srA.score); // sort by score, most relevant first

                        const showNoResults = !sidepanelOpen // user is entering text
                            && textboxValue.length > 1 // at least 2 chars to use as input for the search
                            && !selectedSupplierId // supplier is not selected

                        const mappedResults = searchResults.map((sr) => {
                            const subTitle = sr.lastUsedOn && <span>Laatst gebruikt op <DateTime dateValue={sr.lastUsedOn} /></span>;
                            const children = (
                            <>
                                <Heading size={HEADING_SIZE.xxs} weight={HEADING_WEIGHT.bold} spacing={HEADING_SPACING.zero} color="fc--primary-1">{sr.displayValue}</Heading>
                                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-4">{subTitle}</Paragraph>
                            </>)
                            return searchResultItemBuilder(sr.key, () => supplierSelected(sr), children);
                        })

                        if (showNoResults) {
                            const message = searchResults.length > 0 ? 'Nieuwe leverancier?' : `'${searchQuery}' niet gevonden`
                            const noResultControl = noResultsItemBuilder(() => handleNoResults(searchQuery), message, 'Klik hier om een nieuwe leverancier aan te maken');
                            mappedResults.push(noResultControl)
                        }

                        return (
                            <Fragment>
                                <Search
                                    onSearch={handleChange}
                                    onBlur={handleBlur}
                                    searchQuery={searchQuery}
                                    results={mappedResults}
                                    //onSelect={handleSelect}
                                    loading={loading}
                                    value={textboxValue}
                                />
                            </Fragment>
                        )
                    }
                    else if (error) {
                        return <Bug error={error} />
                    }
                }
            }
        </FetchData>
    )
}

export const SupplierField = connect(SupplierFieldElement);