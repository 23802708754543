import React, {Component} from 'react';
import './styles/_carousel.scss';
import cx from "classnames";

export class Carousel extends Component {

	constructor(props) {
		super(props);

		// define state
		this.state = {
			current: 0
		};

		// define variables
		this.style = cx('carousel');

		// TODO DEFINE PARENT WIDTH
		this.iSlides = props.slides.length;
		this.divStyle = {width: props.width +'px'};

		// bind events
		this.slidePrev = this.slidePrev.bind(this);
		this.slideNext = this.slideNext.bind(this);

	}

	navigate(index) {
		
		// set state
		this.setState({current: index});

	}

	slidePrev() {

		// define variables
		let currentIndex = this.state.current;
		currentIndex--;

		// check if not last
		if(currentIndex < 0) { currentIndex = this.iSlides - 1; }

		// perform navigation
		this.navigate(currentIndex);

	}

	slideNext() {

		// define variables
		let currentIndex = this.state.current;
		currentIndex++;

		// check if not last
		if(currentIndex >= this.iSlides) { currentIndex = 0; }

		// perform navigation
		this.navigate(currentIndex);

	}

	render() {
		return (
			<div style={this.divStyle}>
				<div className={this.style}>
					<div className="carousel__inner">
						<div className="carousel__track" style={{transform: `translate(${this.state.current * -100}%, 0)`}}>
							{ this.props.slides.map((slide, index) => (
								<div className="carousel__slide" style={this.divStyle} key={index}>
									{slide}
								</div>
							))}
						</div>

						{ this.props.controls &&
							<div className="carousel__controls">
								{ (this.props.infinite || this.state.current > 0) && 
									<button className="carousel__control is-prev" onClick={this.slidePrev}><i className="material-icons">chevron_left</i></button>
								}
								{ (this.props.infinite || this.state.current < this.iSlides - 1) && 
									<button className="carousel__control is-next" onClick={this.slideNext}><i className="material-icons">chevron_right</i></button>
								}
							</div>
						}

						{ this.props.dots &&
							<div className="carousel__dots">
								{ this.props.slides.map((slide, index) => (
									<button
										className={cx({'is-active': index === this.state.current})} key={index}
										onClick={(e) => this.navigate(index)}
									>										
										{index}
									</button>
								))}
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
};


Carousel.defaultProps = {
	dots: true,
	controls: true,
	infinite: true,
	width: 800
};
