import React from 'react'
import { withRouter } from 'react-router-dom'
import { SidePanelManager, FetchData } from '../../index';
import { ContentContainer } from '../../index'
import { dataservice } from '../../00_global/service/dataservice';
import { KeyValue } from '../../03_molecules/keyValue/KeyValue';
import { Button } from '../../02_atoms/buttons/Button';

const UserProfileMainElement = ({ history }) => {

    const logout = () => {
        sessionStorage.removeItem('token');
        history.push('/bye')
    }

    const getUserProfile = () => {
        if (sessionStorage.getItem('mode') === 'accountant')
        {
            return {
                firstName: sessionStorage.getItem('userName'),
                lastName: '',
                accountName: '',
                emailAddress: '',
                mailboxAddress: ''
            }
        }
        else {
            return dataservice.getUserProfile();
        }
    }

    return (

        <FetchData method={getUserProfile}>
            {
                ({ data }) => {
                    if (data) {
                        const { firstName, lastName, accountName, emailAddress, mailboxAddress } = data;
                        const values = [
                            {title: 'Naam', value: `${firstName} ${lastName}`},
                            {title: 'email', value: emailAddress},
                            {title: 'Dossier', value: accountName},
                            {title: 'mailbox', value: mailboxAddress},
                            {title: 'afmelden', value: <Button onClick={logout}>Afmelden</Button>}
                        ]

                        return (
                            <>
                                <KeyValue values={values} />
                            </>
                        )
                    }
                }
            }
        </FetchData>
    )
}

const UserProfileMain = withRouter(UserProfileMainElement);

export const UserProfile = () => {
    const main = <UserProfileMain />

    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}