import React from 'react'
import { Bar } from 'react-chartjs-2';
import { barChartOptions } from './constants';

import { Amount } from '../../index';
import { isInDebug } from '../../00_global/isInDebug';
import { ShowObject } from '../../00_global/ShowObject';
import { defaultBarChartColor, highlightedBarChartColor } from './constants';
import { Box } from '../../03_molecules/box/Box';
import { BoxHeader } from '../../03_molecules/box/BoxHeader';
import { notNull } from '../../00_global/notNull';

const debug = isInDebug('Dashboard');

export const getBarChartData = (months, labels) => {

    const barColors = labels.map(l => l.inSelectedPeriod === true ? defaultBarChartColor : highlightedBarChartColor);

    const chartData = {
        labels: labels.map(l => l.label),
        datasets: [{ 
            backgroundColor: barColors,
            data: months.slice(0),
        }

        ],
    };
    return chartData;
}

export const BarChartWrapper = ({ total, subTitle, barData, labels }) => {

    notNull(barData, 'barData');
    notNull(labels, 'labels');

    const revenueData = getBarChartData(barData, labels);

    return (
        <>
            <Box header={<BoxHeader title={<Amount value={total} />} subTitle={subTitle} />}>
                <>
                <BarChart barData={revenueData} />
                </>
            </Box>
        </>
    )
}

/// values: array with floating point values
export const DonutWrapper = ({ total, subTitle, donutData }) => {

    return (
        <>
            <Box fullHeight={true} header={<BoxHeader title={<Amount value={total} />} subTitle={subTitle} />}>
                {donutData.map(d => <div key={d.label}>{d.label}: <Amount value={d.value} /></div>)}
            </Box>
        </>
    )
}

export const Revenue = ({ data }) => {

    const donutData = data.revenue.donutData;
    const receivable = 1.0 * donutData[0].value;

    debug && console.log('data for revenue', receivable);

    return (
        <>
            <div className={'row'}>
                <div className={'col-sm-12 col-md-4 col-lg-4'}>
                    <BarChartWrapper
                        total={data.revenue.total}
                        subTitle={'Totaal verkopen in het geselecteerde kwartaal (incl. BTW)'}
                        barData={data.revenue.months}
                        labels={data.labels}
                    />
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4'}>
                    <BarChartWrapper
                        total={data.vatPayable.vatAmount}
                        subTitle={'BTW op verkopen in het geselecteerde kwartaal'}
                        barData={data.vatPayable.months}
                        labels={data.labels}
                    />
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4'}>
                    <DonutWrapper total={receivable} subTitle={'Te ontvangen facturen'} donutData={donutData} />
                </div>
            </div>
            {debug && <div className={'row'}>
                <div className={'col-sm-12 col-md-12 col-lg-12'}>
                    <ShowObject value={data.revenue} />
                </div>
            </div>}
        </>
    )
}

export const Expenses = ({ data }) => {

    const donutData = data.expenses.donutData;
    const payable = data.expenses.totalDue;

    return (
        <>
            <div className={'row'}>
                <div className={'col-sm-12 col-md-4 col-lg-4'}>
                    <BarChartWrapper
                        total={data.expenses.total}
                        subTitle={'Totaal aankopen in het geselecteerde kwartaal (incl. BTW)'}
                        barData={data.expenses.months}
                        labels={data.labels}
                    />
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4'}>
                    <BarChartWrapper
                        total={data.vatReceivable.vatAmount}
                        subTitle={'BTW op aankopen in het geselecteerde kwartaal'}
                        barData={data.vatReceivable.months}
                        labels={data.labels}
                    />
                </div>
                <div className={'col-sm-12 col-md-4 col-lg-4'}>
                    <DonutWrapper total={payable} subTitle={'Te betalen facturen (over alle periode\'s)'} donutData={donutData} />
                </div>
            </div>
            {debug && <div className={'row'}>
                <div className={'col-sm-12 col-md-12 col-lg-12'}>
                    <ShowObject value={data.expenses} />
                </div>
            </div>}
        </>

    )
}

export const BarChart = ({barData, chartOptions = barChartOptions}) => {
    
    return (
      <>
        <Bar data={barData} options={chartOptions} width={350} height={270} />
      </>
    );
}