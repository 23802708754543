import React, { useContext, useState } from 'react';
import { Box, BoxHeader, Ellipsis, KeyValue, TableLayout, FetchData, VisualFeedback, SidePanelManager, ContentContainer, SidePanelContext, NotificationManager, NotificationContext } from "../../../components/index";
import { BUTTON_SIZE } from '../../constants';
import { DateTime } from '../../02_atoms/date/DateTime';
import { StyledCheck } from '../../02_atoms/check/Check';
import { notNull } from '../../00_global';
import { Brand } from '../../02_atoms/brand/Brand';
import { Paragraph } from '../../02_atoms/paragraph/Paragraph';
import { getPortalService } from '../'
import { Control } from '../../02_atoms/control/Control';
import { UpdateSyncSetting } from './UpdateSyncSetting';
import { EnableSyncPanel } from './EnableSyncPanel';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';
import { Button } from '../../02_atoms/buttons/Button';
import image from './undraw_server_cluster_jwwq.png';
import { dataservice } from '../../00_global/service/dataservice';

const AccountDetails = ({ settings, onUpdateSettingsClicked }) => {
  
    notNull(settings, 'settings');

    const { accountId, name, primaryContact, mailboxAddress, isActive, syncEnabled, notificationAddress, createdOn, updatedOn } = settings;
    notNull(accountId, 'accountId');

    const keyValue = (title, value) => {
        return {
            title: title,
            value: value
        }
    }

    const [onboardingStarted, setOnboardingStarted] = useState(false);

    const startOnboarding = () => {
        dataservice.portal_startOnboarding(accountId);
        setOnboardingStarted(true);
    }

    const userInfo = [
        keyValue('email', notificationAddress),
        keyValue('Aangemaakt op', <DateTime dateValue={createdOn} />),
        keyValue('Aangepast op', <DateTime dateValue={updatedOn} />),
        keyValue('mailbox', mailboxAddress),
        keyValue('Dossier actief', <StyledCheck id={'isActive'} checked={isActive} onChange={() => { }} />),
        keyValue('Synchronisatie actief', <><StyledCheck id={'syncEnabled'} checked={syncEnabled} onChange={() => { onUpdateSettingsClicked()}} /></>),
        keyValue('Wachtwoord en welkom', <Button disabled={onboardingStarted} onClick={startOnboarding}>{onboardingStarted ? 'verzonden!' : 'Versturen'}</Button>)
    ]

    return (
        <>
            <Box header={
                <BoxHeader
                    title={`Instellingen voor ${name}`}
                    subTitle={`Contactpersoon: ${primaryContact}`}
                />
            }>
                <KeyValue values={userInfo} />
            </Box>
        </>
    )
}

export const UpdateAccountV2 = ({ match }) => {

    const { accountId } = match.params;
    
    const { showNotificationToast, showErrorToast } = useContext(NotificationContext);
    const svc = getPortalService();

    const [version, setVersion] = useState(0);
    const { add, close } = useContext(SidePanelContext);

    const handleSaveUpdatedRow = async (config, updatedFolder) => {
        // find the item in the config

        const { folderConfigs } = config;

        if (folderConfigs){
            const index = folderConfigs.findIndex(item => item.folderConfigId === updatedFolder.folderConfigId)


            try {
                if (~index) {
                    folderConfigs[index] = updatedFolder;
                    const request = {
                        folders: folderConfigs,
                        accountId: accountId
                    }
                    const result = await svc.portal_updateOfflineSync(request);
                    console.log(result);
                    showNotificationToast('Instellingen werden bewaard');
                    setVersion(version + 1);
                }
                else {
                    throw Error('Deze map is niet gevonden');
                }
            } catch (error) {
                showErrorToast('Oeps, er ging iets mis bij het bewaren van de gegevens')
            }
        }
        else {
            console.warn('no config found!');
        }
    }   

     const handleEnableSync = async (syncSettings) => {
        
        const {rootFolder, path, accountId} = syncSettings;
        const request = {root: rootFolder, folder: path, accountId: accountId} 

        const result = await svc.portal_enableOfflineSync(request);
        close();
        console.log(result);
        showNotificationToast('Instellingen werden bewaard');
        setVersion(version + 1);
    }

    const showPanel = (settings) => {
        const panel = <EnableSyncPanel accountSettings={settings} onSubmit={handleEnableSync} />
        add(panel, true);
    }

    return (
        <FetchData method={() => svc.portal_getAccount(accountId)} triggerValues={[version]}>
            {
                ({ loading, error, data }) => {
                    return (
                        <>
                            {loading && <VisualFeedback title={'Even geduld'} subTitle={'We halen de gegevens op...'} delay={600}></VisualFeedback>}
                            {data &&
                                <>
                                    <AccountDetails settings={data} onUpdateSettingsClicked={() => showPanel(data)} afterSettingsUpdated={() => setVersion(version+1)} />
                                    <br />
                                    {(data.folderConfigs && data.folderConfigs.length > 0) &&
                                        <FileLocationTable folderConfigs={data.folderConfigs} onSaveFolderConfig={(updatedItem) => handleSaveUpdatedRow(data, updatedItem)} />
                                    }
                                    {(!data.folderConfigs || data.folderConfigs.length === 0) &&
                                        <VisualFeedback
                                        image={image}
                                        title={'Configureer de synchronisatie naar uw omgeving'}
                                        subTitle={"Klik op 'start' om dit dossier te configureren voor digitale uitwisseling met Trive"}
                                        ctaButton={<Button size={BUTTON_SIZE.medium} rounded={false} inverted={true} onClick={() => showPanel(data)}>Start</Button>}
                                    >
                                    </VisualFeedback>
                                        
                                    }
                                </>
                            }
                            {error && <Bug error={error} />}
                        </>
                    )
                }
            }
        </FetchData>
    )
}

export const UpdateAccount = ({ ...props }) => {

    const main = <>

        <UpdateAccountV2  {...props} />
    </>

    return (
        <NotificationManager>
            <SidePanelManager>
                <ContentContainer
                    main={main}
                />
            </SidePanelManager>
        </NotificationManager>
    )

}

const FileLocationTable = ({ folderConfigs, onSaveFolderConfig }) => {

    const headerConfig = [
        {
            value: 'Type document',
            widths: 'size-medium-17 size-large-17'
        },
        {
            value: 'richting',
            widths: 'size-medium-22 size-large-22'
        },
        {
            value: 'Map',
            widths: 'size-medium-61 size-large-61'
        }
    ]

    return (
        <TableLayout>
            <div className="table">
                <div className="table__inner">
                    <div className="table__head">
                        <UpdateAccountHead headerInfo={headerConfig} />
                    </div>

                    <div className="table__body">
                        <UpdateAccountBody folderConfigs={folderConfigs} onSaveFolderConfig={onSaveFolderConfig} />
                    </div>
                </div>
            </div>
        </TableLayout>
    )
}

const UpdateAccountHead = ({ headerInfo }) => (
    <div className="table__row">
        {headerInfo.map((item) => (
            <div key={item.value} className={`table__cell ${item.widths}`}>
                <Ellipsis text={item.value} />
            </div>
        ))}
    </div>
);

const UpdateAccountBody = ({ folderConfigs, onSaveFolderConfig }) => {
    notNull(folderConfigs, 'folderConfigs');
    notNull(onSaveFolderConfig, 'onSaveFolderConfig');
    return (
        <>
            {folderConfigs.map((item) => <FolderRow key={item.folderConfigId} config={item} onSaveFolderConfig={onSaveFolderConfig} />)}
        </>
    )
}

export const getDocumentType = (docType) => {
    switch (docType) {
        case 3:
            return 'Aankoop'
        case 2:
            return 'Verkoop'
        case 4: return 'Divers'
        default:
            break;
    }
}

export const getFirmName = () => {
    let { companyName } = JSON.parse(sessionStorage['userInfo']);

    if (companyName === undefined)
        companyName = 'kantoor'; //fallback

    return companyName;
}

export const getSyncDirections = () => {

    const companyName = getFirmName();

    return [
        { id: 1, caption: "Download", note: `download van Trive naar ${companyName}` },
        { id: 2, caption: "Upload", note: `upload van ${companyName} naar Trive` }
    ]
}



const FolderRow = ({ config, onSaveFolderConfig }) => {

    notNull(onSaveFolderConfig, 'save handler for config is missing!')
    
    const { add, close } = useContext(SidePanelContext);    
    const { documentType, syncDirection, path } = config;

    const handleUpdateFolder = async (folderConfig) => {
        await onSaveFolderConfig(folderConfig);
        await close(); 
    }

    const showEditPanel = (settings) => {

        const panel = <UpdateSyncSetting folderConfig={settings} key={settings.folderConfigId} onUpdate={handleUpdateFolder} />
        add(panel, true);
    }

    const firmName = getFirmName();

    const getDocumentDirection = (direction) => (
        <>
            {direction === 2 ? (
                <>
                    <Paragraph upper color="fc--primary-1" nopadding>{firmName}</Paragraph>
                    <i className="material-icons" style={{ color: '#a1a1a1', margin: '0 7px' }}>arrow_right_alt</i>
                    <Brand size="size-s" />
                </>
            ) : (
                    <>
                        <Brand size="size-s" />
                        <i className="material-icons" style={{ color: '#a1a1a1', margin: '0 7px' }}>arrow_right_alt</i>
                        <Paragraph upper color="fc--primary-1" nopadding>{firmName}</Paragraph>
                    </>
                )}
        </>
    )

    return (
        <div className="table__row">
            <div className={`table__cell size-medium-17 size-large-17`}>
                <Ellipsis text={getDocumentType(documentType)} />
            </div>
            <div className={`table__cell size-medium-22 size-large-22`}>
            {getDocumentDirection(syncDirection)}
            </div>
            <div className={`table__cell size-medium-58 size-large-58`}>
                <Ellipsis text={path} />
            </div>
            <div className={`table__cell size-medium-3 size-large-3`}>
                <Control isPlain={true} onClick={() => showEditPanel(config)}>
                    <i className="material-icons">settings</i>
                </Control>
            </div>
        </div>
    )
}
