import React from 'react';
import './styles/_paging.scss';
import cx from 'classnames';

export const Paging = ({id}) => {

	const style = cx(
		'paging'
	);

	return (
		<div className={style}>
			<div className="paging__inner">
				<ul>
					<li>
						<button className="paging__button is-disabled">
							<i className="material-icons">first_page</i>
						</button>
					</li>
					<li>
						<button className="paging__button is-disabled">
							<i className="material-icons">chevron_left</i>
						</button>
					</li>
					<li>
						<button className="paging__button is-active">
							1
						</button>
					</li>
					<li>
						<button className="paging__button">
							2
						</button>
					</li>
					<li>
						<button className="paging__button">
							3
						</button>
					</li>
					<li>
						<button className="paging__button">
							<i className="material-icons">chevron_right</i>
						</button>
					</li>
					<li>
						<button className="paging__button">
							<i className="material-icons">last_page</i>
						</button>
					</li>
				</ul>
			</div>
		</div>
	)

};