import React from 'react';
import './styles/_controlBar.scss';
import cx from 'classnames';
import { Control } from "../..";
import { CONTROL_SIZE} from "../../constants";

export const ControlBar2 = ({
	title = '',
    spacing = false,
    controls,
	isPlain = true,
	onlyActions = false,
	}) => {

	const style = cx(
		'controlBar',
		{ 'spacing-t' : spacing === true },
		{ 'only-actions' : onlyActions }
    );

    const controlItems = controls && controls.map(c =>
    <li key={c.materialIconName}>
        <Control size={CONTROL_SIZE.small} isPlain={ isPlain } onClick={c.onClick}>
            <i title={c.title} className="material-icons">{c.materialIconName}</i>
        </Control>
    </li>)

	return (
		<div className={style}>
			<div className="controlBar__inner">
				{ !onlyActions &&
					<div className="controlBar__name">
						{title}
					</div>
				}
				<div className="controlBar__controls">
					<ul>
						{ controlItems }
					</ul>
				</div>
			</div>
		</div>
	)
};