import React, { Fragment } from 'react';
import { Control, Heading, BoxedSearchResultItem, SearchResultItem } from "../..";

import { CONTROL_SIZE, CONTROL_STATUS, HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING } from "../../constants";
import { DateTime } from '../../02_atoms/date/DateTime';
import { notNull } from '../../00_global/notNull';
import { isInDebug } from '../../00_global/isInDebug';

export const SearchResultListItem = ({ document, onShowDetail }) => {

    const { id, title, documentDate, thumbnailLink, isActive, amount, documentTypeId } = document;
    const debug = isInDebug('Search');

    return (
        <div key={id} className="col-sm-12 col-md-6 col-lg-4">
            <BoxedSearchResultItem
                isActive={isActive}
                onClick={onShowDetail}
                id={id}
                hover={true}
            >
                <SearchResultItemVisual caption={title} documentTypeId={documentTypeId} thumbnailLink={thumbnailLink} />
                <div className="boxedSearchResultItem__content">
                    <SearchResultItem hover={false}>
                        <Heading size={HEADING_SIZE.xxs} weight={HEADING_WEIGHT.bold} spacing={HEADING_SPACING.zero}>
                            {title}
                        </Heading>
                        {documentDate && <p className="fc--gray-4">Document van: <DateTime dateValue={documentDate} /></p>}
                        {amount && <p className="fc--gray-1"><strong>{amount}</strong></p>}
                    </SearchResultItem>
                </div>
                <SearchResultItemNotification show={documentTypeId === 1} />
                {debug && <div className="boxedSearchResultItem__hover">
                    <SearchResultItemIconBar />
                </div>}
            </BoxedSearchResultItem>
        </div>
    )
}

const SearchResultItemNotification = ({ show = false }) => {
    return (
        <Fragment>
            {show && <div className="boxedSearchResultItem__notification">
                <Control isPlain={true} size={CONTROL_SIZE.small} status={CONTROL_STATUS.error}>
                    <i className="material-icons">inbox</i>
                </Control>
            </div>}
        </Fragment>
    )
}

const SearchResultItemVisual = ({ documentTypeId, thumbnailLink }) => {

    const documentTypeIcons = [
        { key: 1, value: 'inbox' },
        { key: 2, value: 'send' },
        { key: 3, value: 'move_to_inbox' },
        { key: 4, value: 'folder_shared' }
    ]

    notNull(documentTypeId, 'documentTypeId');
    const selectedIconSetting = documentTypeIcons.filter(dt => dt.key === documentTypeId);
    const selectedIcon = selectedIconSetting.length > 0 ? selectedIconSetting[0].value : false;

    return (
        <div className="boxedSearchResultItem__visual">
            <div className="boxedSearchResultItem__visualHolder">
                <img src={thumbnailLink} alt='' />
            </div>
            {selectedIcon && <div className="boxedSearchResultItem__type">
                <Control isPlain={true} size={CONTROL_SIZE.xsmall}>
                    <i className="material-icons">{selectedIcon}</i>
                </Control>
            </div>}
        </div>
    )
}

const SearchResultItemIconBar = () => {
    return (
        <div className="boxedSearchResultItem__icons">
            <ul>
                <li>
                    <Control isPlain={true} size={CONTROL_SIZE.xsmall}>
                        <i className="material-icons">link</i>
                    </Control>
                    1
                                </li>
                <li>
                    <Control isPlain={true} size={CONTROL_SIZE.xsmall}>
                        <i className="material-icons">alarm</i>
                    </Control>
                    4
                                </li>
                <li>
                    <Control isPlain={true} size={CONTROL_SIZE.xsmall}>
                        <i className="material-icons">attach_file</i>
                    </Control>
                    1
                                </li>
            </ul>
        </div>
    )
}