import React from 'react';
import * as Yup from 'yup';
import * as moment from 'moment';
import { Formik } from 'formik';
import { Input, Field, FormField, Button } from "../..";
import { BUTTON_SIZE } from "../../constants";

import { DateField } from "../../03_molecules/date/DateField";
import { isInDebug } from '../../00_global/isInDebug';
import { ShowObject } from '../../00_global/ShowObject';
import { notNull } from '../..'

import { dataservice } from '../../00_global/service/dataservice';
import { withRouter } from "react-router-dom";
import { routeWithSlug } from '../../00_global/'

const debug = isInDebug('dev');

const formValidationSchema = Yup.object().shape({
    documentDate: Yup.string()
        .required('Verplicht veld'),
    documentName: Yup.string()
        .required('Verplicht veld')
});

const AnnotateOtherElement = ({ detail, history }) => {

    notNull(detail, 'detail');
    notNull(history, 'history')

    const onSubmit = async (values, actions) => {

        const request = {
            documentId: detail.documentId,
            documentDate: values['documentDate'],
            documentName: values['documentName'],
            documentReference: values['documentReference'],
            description: values['description']
        }

        try {
            await dataservice.annotateReferenceMaterial(request)
            history.push(routeWithSlug('inbox'));
        }
        finally {
            actions.setSubmitting(false);
        }        
    }

    const splittedName = detail.name.split('.');
    const name = splittedName.length > 0 ? splittedName[0] : ''

    const defaultState = {
        documentDateFormValue: detail.addedOn,
        documentDate: detail.addedOn,
        documentName: name,
        documentReference: '',
        description: ''
        
    }

    return (
        <Formik
            initialValues={defaultState}
            validationSchema={formValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, actions) => {
                onSubmit(values, actions);
            }}>
            {
                (formikProps) => {

                    const { isSubmitting, setFieldValue, handleSubmit } = formikProps;

                    const onDocumentDateSelected = (date) =>{
                        const fieldName = 'documentDate'
                        moment(date).isValid() 
                        ? setFieldValue(fieldName, moment(date).format())
                        : setFieldValue(fieldName, null);
                    };

                    return (
                        <form className="form" onSubmit={handleSubmit}>
                            <FormField>
                                <Field id={'documentDate'} header={'Document datum *'} infoMessage={''}>
                                    <DateField placeholder={'factuur datum'} propertyName={'documentDateFormValue'} onSelect={onDocumentDateSelected} maxDate={new Date()} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'documentName'} header={'Naam *'} infoMessage={''}>
                                    <Input />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'documentReference'} header={'Referentie'} infoMessage={'factuur nr, contract nr, ...'}>
                                    <Input />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'description'} header={'Info'} infoMessage={'eigen notities'}>
                                    <Input />
                                </Field>
                            </FormField>

                            <FormField>
                                <Button
                                    type="submit"
                                    size={BUTTON_SIZE.full}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >Verzenden</Button>
                            </FormField>
                            {debug && <ShowObject value={formikProps} />}
                        </form>
                    )
                }
            }
        </Formik>
    )
};

export const AnnotateOther = withRouter(AnnotateOtherElement);