import * as Yup from "yup";
import { v4 as uuidv4 } from 'uuid';

const ERROR_REQUIRED_FIELD = 'Verplicht veld!';

// AllowCreate = true,
// DocumentType = DocumentTypeEnum.PurchaseInvoice,
// SyncDirection = SyncDirectionEnum.TriveToAgent,
// Path = new Uri(Path.Combine(RootFolder, folder, "AANKOOP")).LocalPath,
// FolderConfigId = Guid.NewGuid().ToDigits()

export const DOCUMENTTYPE = {
    //Inbox: 1,
    SalesInvoice: 2,
    PurchaseInvoice: 3,
    ReferenceMaterial: 4,
    //Coda: 5
}

export const defaultSyncSettings = {
    folderConfigId: uuidv4(),
    allowCreate: true,
    documentType : "",
    documentTypeId: null,
    syncDirectionId: 1,
    syncDirection: '',
    path: '',
}

export const updateSyncSettingValidation = Yup.object().shape({
    path: Yup.string().required(ERROR_REQUIRED_FIELD),
    folderConfigId: Yup.string().required(ERROR_REQUIRED_FIELD),
    documentType: Yup.string().required(ERROR_REQUIRED_FIELD),
    documentTypeId: Yup.number().required(ERROR_REQUIRED_FIELD).typeError("Dit is geen gekende waarde")
});