import React, { Fragment, useContext, useState } from 'react';
import { SidePanelContext, SIDEPANEL_VISIBILITY } from '../../'
//import './styles/_table.scss';
import cx from 'classnames';

import { CONTROL_SIZE, STATUS_TYPE } from "../../constants";
import { Control, Ellipsis, Status, DateTime } from "../../";
import { SortAsc, SortDesc, SortToggle } from "../../01_fundaments/icons";
import { Amount } from '../../02_atoms/amount/Amount';
import { SearchDocumentDetail } from '../archive/SearchDocumentDetail'
import { FetchData } from '../../00_global';
import { dataservice } from '../../00_global/service/dataservice';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';

const ActivityTableHeader = () => {
	return (
		<div className="table__head">
			<div className="table__row">
				<div className="table__cell size-medium-18 size-large-12 is-sortable">
					<Ellipsis text="Datum" />
					<SortToggle />
				</div>
				<div className="table__cell size-medium-29 size-large-20 is-sortable">
					<Ellipsis text="Klant/leverancier" />
					<SortToggle />
				</div>
				<div className="table__cell size-medium-15 size-large-15 is-sortable is-sorted">
					<Ellipsis text="Bedrag" />
					<SortAsc />
				</div>
				<div className="table__cell size-medium-27 size-large-29 hide-medium">
					<Ellipsis text="Info" />
					<SortAsc />
				</div>
				<div className="table__cell size-medium-18 size-large-12 is-sortable">
					<Ellipsis text="Vervaldatum" />
					<SortDesc />
				</div>
				<div className="table__cell size-medium-14 size-large-8 is-sortable">
					<Ellipsis text="# dagen" />
					<SortToggle />
				</div>
			</div>
		</div>
	)
}

const ActivityTableRow = ({ row, isClickable = true, showDetail = () => { } }) => {

	const {
		supplier = 'tegenpartij',
		daysTillDue = 0,
		//documentId = '',
		documentDate = '',
		//description = '',
		amount = 0,
		dueDate = '',
		paymentReference = '',
		//webContentLink = '' // api/content/...
	} = row;

	const status = daysTillDue > 3 ? STATUS_TYPE.success : daysTillDue <= 0 ? STATUS_TYPE.error : STATUS_TYPE.warning;

	const statusStyle = daysTillDue > 3 ? 'fc--success' : daysTillDue <= 0 ? 'fc--error' : 'fc--warning';

	const rowStyle = cx('table__row', { 'is-clickable': isClickable === true })

	return (
		<div className={rowStyle} onClick={showDetail}>
			<div className="table__cell size-medium-18 size-large-12">
				<Status type={status} dot={true} />
				<Ellipsis text={<DateTime dateValue={documentDate} />} />
			</div>
			<div className="table__cell size-medium-29 size-large-20">
				<span><Ellipsis text={supplier} /></span>
			</div>
			<div className="table__cell size-medium-15 size-large-15"><Amount value={amount} /></div>
			<div className="table__cell size-medium-27 size-large-29 hide-medium">
				<Ellipsis text={'mededeling: ' + paymentReference} />
			</div>
			<div className="table__cell size-medium-18 size-large-12">
				<Ellipsis text={<DateTime dateValue={dueDate} />} />
			</div>
			<div className="table__cell size-medium-14 size-large-8"><span className={statusStyle}>{daysTillDue}</span></div>
			<div className="table__cell size-medium-5 size-large-5">
				<Control isPlain size={CONTROL_SIZE.small}>

				</Control>
			</div>
		</div>
	)
}

export const ActivityTable = ({ activities = [] }) => {

	const [fetchCounter, setFetchCounter] = useState(0)
	const style = cx(
		'table'
	);

	const sidepanel = useContext(SidePanelContext);
	const [currentDetail, setCurrentDetail] = useState(null);

	const onRowClicked = (documentId) => {

		// case 1: clicked the same row twice while panel already open
		if (currentDetail === documentId && sidepanel.visibility === SIDEPANEL_VISIBILITY.open) {
			// cleanup and close sidepanel
			sidepanel.close();
			setCurrentDetail(null);
		}
		else {
			// case 2: nothing selected yet and sidepanel should be opened (shares some code with case 2)
			// or already opened and details should be updated to reflect newly selected document
			setCurrentDetail(documentId);
			sidepanel.add(<SearchDocumentDetail documentId={documentId} onDocumentDeleted={() => setFetchCounter(fetchCounter + 1)} key={documentId} />);
		}
	}

	return (<Fragment>
		<FetchData method={dataservice.getNotifications} triggerValues={[fetchCounter]} >
			{
				({ data, loading, error }) => {
					if (data) {

						const trs = activities.map(row => <ActivityTableRow key={row.documentId} row={row} showDetail={() => onRowClicked(row.documentId)} />)

						return (
							<div className={style}>
								<div className="table__inner">
									<ActivityTableHeader />
									<div className="table__body">
										{trs}
									</div>
								</div>
							</div>
						)
					}
					else if (error) {
						return <Bug error={error} />
					}
				}
			}
		</FetchData>

	</Fragment>
	)
};

