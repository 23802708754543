import React, { useContext } from 'react';
import './styles/_contentContainer.scss';
import cx from "classnames";
import { SidePanel, SidePanelContext, SIDEPANEL_VISIBILITY } from "../../";
import { notNull } from '../../00_global/notNull';
import { ShowObject } from '../../00_global/ShowObject';

export const ContentContainer = ({ main }) => {

	const ctx = useContext(SidePanelContext);
	notNull(ctx, 'SidePanelContext', 'Did you forget to wrap your component?');
	notNull(main, 'main component not specified');

	const ctxData = useContext(SidePanelContext);
	const { visibility, debug, blocked, size, pinned, sidepanels } = ctxData;

	if (debug){
		console.log('SidePanelContext', ctxData);
	}

	const isOpen = visibility === SIDEPANEL_VISIBILITY.open;
	const isActive = isOpen //|| visibility === SIDEPANEL_VISIBILITY.collapse;
	const mainPauzed = blocked && isOpen;
	const isPinned = pinned;

	const style = cx(
		'contentContainer',
		'section',
		{ 'is-active': isActive },
		{ 'is-open': isOpen },
		{ 'is-pauzed': mainPauzed },
		{ 'is-pinned': isPinned },
		[size]
		// @stijn todo: size opties: [geen = small], [size-m], [size-l]
	);

	//const isPinEnabled = true; //todo

	return (
		<div className={style}>
			<div className="contentContainer__inner section__inner">
				<div className="contentContainer__main">
					<div className="contentContainer__mainInner">
						{main}
						{debug && <ShowObject value={{
							style: style,
							visibility: visibility,
							check: visibility === SIDEPANEL_VISIBILITY.open,
							sidePanelCount: sidepanels.length
						}} />
						}
					</div>
					<div className="contentContainer__mainPauzer"></div>
				</div>
				<div className="contentContainer__aside">
					<SidePanel />
				</div>
			</div>
		</div>
	)
};