import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import './styles/_header.scss';
import cx from "classnames";

import { STATUS_TYPE, FEATURE_ANNOTATE_ENABLED } from "../../constants";
import { Status, User, useInterval } from "../../";
import { LogoTextPlain } from "../../01_fundaments/logo";
import { NavLink } from "react-router-dom";
import { FetchData } from '../../00_global/FetchData';
import { routeWithSlug, notNull } from '../../00_global/'
import { isInDebug } from '../../00_global/isInDebug';
import { InvoiceModuleHeader } from '../../06_features/invoice_module_v1/onboarding/EnableInvoiceModule'

export const Header = ({ alt, dataservice }) => {

	notNull(dataservice, 'dataservice', 'Inject a dataservice into Header');

	const [count, setCount] = useState(0);
	const [errorCounter, setErrorCounter] = useState(0);

	useInterval(() => {
		setCount(count + 1);
	}, 10000);

	return (
		<FetchData method={dataservice.ping} triggerValues={[count]} >
			{
				({ error, data }) => {

					if (error) {
						setErrorCounter(errorCounter + 1);
						console.warn(error)
						try {
							if (error && error.response && error.response.status === 401) {
								console.log('redirect');
							}
						}
						catch (err2) {
							console.warn(err2)
						}
					}

					const inboxCounter = data ? data.inboxCounter : 0;
					const notificationCounter = data ? data.paymentsCounter : 0;

					const shouldRedirect = errorCounter > 3;

					return (
						<FetchData method={dataservice.getUserProfile}>
							{
								({ data }) => {

									if (shouldRedirect) {
										return <Redirect to="/login" />
									}

									return (
										<>
											{data && <HeaderElement userProfile={data} inboxCounter={inboxCounter} notificationCounter={notificationCounter} />}
										</>)

									// if (shouldRedirect)
									// 	return
									// else if (data) {

									// 	}
									// }

								}
							}
						</FetchData>
					)
				}
			}
		</FetchData>
	)
};



const HeaderElement = ({ inboxCounter, notificationCounter, userProfile, alt }) => {

	const style = cx(
		'header',
		{ 'theme-primary': alt }
	);

	notNull(userProfile, 'userProfile');
	const inboxBulletType = STATUS_TYPE.default;
	const notificationBulletType = STATUS_TYPE.default;
	const { firstName, lastName, accountName, limited } = userProfile;

	const annotateEnabled = FEATURE_ANNOTATE_ENABLED;

	return (
		<div className={style}>
			<div className="section">
				<div className="header__inner section__inner">
					<div className="header__primary">
						<div className="header__brand">
							<LogoTextPlain />
						</div>
						<div className="header__navigation">
							<ul>
								{!limited &&
									<li>
										<NavLink to={routeWithSlug('dashboard')} activeClassName={`is-active`}>
											Dashboard
										</NavLink>
									</li>
								}
								<li>
									<NavLink to={routeWithSlug('inbox')} activeClassName={`is-active`}>
										Inbox
										{inboxCounter > 0 && <span className="header__status">
											<Status value={inboxCounter} type={inboxBulletType} />
										</span>}
									</NavLink>
								</li>
								{annotateEnabled &&
									<li>

										<NavLink to={routeWithSlug('annotate')} activeClassName={`is-active`}>
											Annotate
										</NavLink>

									</li>
								}
								<li>
									{!limited &&
										<NavLink to={routeWithSlug('tasks')} activeClassName={`is-active`}>
											Opvolgen
											{notificationCounter > 0 && <span className="header__status">
												<Status value={notificationCounter} type={notificationBulletType} />
											</span>
											}
										</NavLink>
									}
								</li>
								<li>
									<NavLink to={routeWithSlug('search')} activeClassName={`is-active`}>
										Archief
									</NavLink>
								</li>
								<li>
									<NavLink to={routeWithSlug('upload')} activeClassName={`is-active`}>
										Upload
									</NavLink>
								</li>
								<li>
									<NavLink to={routeWithSlug('export')} activeClassName={`is-active`}>
										Export
									</NavLink>
								</li>
								<InvoiceModuleHeader />
								{isInDebug('dev') &&
									<li>
										<NavLink to={routeWithSlug('reconciliation')} activeClassName={`is-active`}>
											Bank <span className="header__superscript">beta</span>
										</NavLink>
									</li>
								}
							</ul>
						</div>
					</div>
					<div className="header__user">
						<User userName={`${firstName} ${lastName}`} companyName={accountName} />
					</div>
				</div>
			</div>
		</div>
	)
}