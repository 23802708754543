import React, { Fragment } from 'react';
import cx from "classnames";
import { withFormik } from 'formik';
import { Box, Button, Experience, Field, FormField, Input, Paragraph, Toast, Wrapper } from "../..";
import { BUTTON_SIZE, COLOR, PARAGRAPH_SIZE, PARAGRAPH_ALIGNMENT } from "../../constants";

import { LogoPlain } from "../../01_fundaments/logo";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import { notNull } from '../../06_features/reconciliation';
import { dataservice } from "../../00_global/service/dataservice";
import { dev, isInDebug } from '../../00_global/isInDebug';

const debug = isInDebug('Login');

const LoginIntro = () => (
	<Fragment>
		<LogoPlain />
	</Fragment>
);

const formValidationSchema = Yup.object().shape({
	login: Yup.string()
		.email('Geen geldig e-mailadres')
		.required('Verplicht veld!'),
	password: Yup.string()
		.required('Verplicht veld!'),
});

class LoginBoxElement extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
		};

		sessionStorage.removeItem('token');
	}

	removeError = () => {
		this.props.setErrors({});
	};

	render() {
		const { errors, touched, handleSubmit, isSubmitting } = this.props;

		const formProps = {
			onChange: this.props.handleChange,
			onBlur: this.props.handleBlur,
			values: this.props.values
		};

		return (
			<Fragment>
				<Box header={false}>
					{errors.general && (
						<Toast
							onDismiss={this.removeError}
							items={[errors.general]}
						/>
					)}
					<form className="form" onSubmit={handleSubmit}>
						<FormField>
							<Field id={'login'} {...formProps} header={'E-mailadres / login'} errorMessage={touched['login'] && errors['login']} invalid={touched['login'] && errors['login']} color="color-secondary-2">
								<Input id={'login'} name="login" />
							</Field>
						</FormField>
						<FormField>
							<Field id={'password'}{...formProps} header={'Wachtwoord'} errorMessage={touched['password'] && errors['password']} invalid={touched['password'] && errors['password']} color="color-secondary-2">
								<Input id={'password'} type="password" name="password" />
							</Field>
						</FormField>
						<FormField>
							<Button
								type="submit"
								color={COLOR.secondary_2}
								size={BUTTON_SIZE.full}
								disabled={isSubmitting  }
								loading={isSubmitting}
							>Log in</Button>
						</FormField>
						<FormField smallGap={true}>
							<Paragraph size={PARAGRAPH_SIZE.xs} alignment={PARAGRAPH_ALIGNMENT.center}>
								{/* <a href={'/forgot-password'} className={'fc--gray-2'}>Wachtwoord vergeten?</a><br /> */}
								Heb je nog geen account? <a href="https://public.trive.io/gratis-proberen" className="fc--gray-2">Klik hier.</a><br />
							</Paragraph>
						</FormField>
					</form>
				</Box>
			</Fragment>
		);
	}
}

// add router data
const LoginWithFormik = withFormik({
	mapPropsToValues: () => {
		return {
			login: '',
			password: '',
		};
	},
	validationSchema: formValidationSchema,
	handleSubmit: async (values, { props, setErrors, resetForm, setSubmitting }) => {
		const { history } = props;
		notNull(history, 'history');
		
		try {
			const buildNumber = dev() ? 'dev' : process.env.REACT_APP_BITBUCKET_BUILD_NUMBER
			const appVersion = `trive_atomic_react-2_1_${buildNumber}`
			const { login, password } = values;

			await dataservice.portal_login(login, password, appVersion);
						
			props.history.push('/portal/overview');
		}
		catch (err) {
			setSubmitting(false);
			debug && console.log('error', err)

			let errorMessage = `Er ging iets mis: ${err.message}`

			if (err.response) {
				// Request made and server responded
				debug && console.log(err.response.data);
				debug && console.log(err.response.status);
				debug && console.log(err.response.headers);
				if (err.response.status === 401) {
					resetForm();
					errorMessage = 'wachtwoord of email is niet juist'
				}
			} else if (err.request) {
				// The request was made but no response was received, happens in case of CORS issues (fe)
				debug && console.log(err.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				debug && console.log('Error', err.message);
			}

			setErrors({
				general: {
					error: true,
					caption: errorMessage
				},
			})

				;
		}
	},
	displayName: 'LoginBoxElement',
})(LoginBoxElement);

const LoginBox = withRouter(LoginWithFormik);

export const PortalLogin = () => {

	const style = cx(
		'template--login'
	);

	return (
		<div className={style}>
			<Wrapper header={false} footer={false} compact={true}>
				<Experience intro={<LoginIntro />} box={<LoginBox />} size="size-s" theme="" content={<LoginFooter />} />
			</Wrapper>
		</div>
	)
};

export const LoginFooter = () => {
	const buildNumber = dev() ? 'dev' : process.env.REACT_APP_BITBUCKET_BUILD_NUMBER
	const love = <svg xmlns="http://www.w3.org/2000/svg" fill='#D8393C' width="10" height="10" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
	return (
		<Fragment>
			<Paragraph size={PARAGRAPH_SIZE.xs} alignment={PARAGRAPH_ALIGNMENT.center}>
				<div>Made with {love} in Belgium - versie: 2.1.{buildNumber}</div>
				<a href="https://public.trive.io/contacteer-ons" className="fc--white">Heb je nog vragen?</a>
			</Paragraph>
		</Fragment>
	)
};