import React from 'react';
import './styles/_boxedSearchResult.scss';
import cx from "classnames";

export const BoxedSearchResult = ({ children }) => {

	const style = cx(
		'boxedSearchResult'
	);

	return (
		<div className={style}>
			<div className="boxedSearchResult__inner">

				{children}

			</div>
		</div>
	)
};