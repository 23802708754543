import React, { Fragment } from 'react'
import { SidePanelManager, ShowObject, ErrorBoundary, FetchData } from '../../index';
import { ContentContainer, TableLayout } from '../../index'
import { ActivityTable } from './ActivityTable'
import { dataservice } from '../../00_global/service/dataservice';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';

const TaskElement = () => {

    const debug = false;
    
    return (
        <Fragment>
            <ErrorBoundary>
                <FetchData method={dataservice.getNotifications}>
                    {({ loading, error, data }) => {
                        if (data) {
                            return (
                                <Fragment>{data && <TableLayout><ActivityTable activities={data}  /></TableLayout>}
                                {debug && <ShowObject value={{ loading, error, data }} background={'#BFDADE'} />}
                                </Fragment>
                            )
                        }
                        if (error) {
                            return (
                                <Bug error={error} />
                            )
                        }
                    }}
                </FetchData>
            </ErrorBoundary>
        </Fragment>
    )
}

export const Tasks = () => {
    const main = <TaskElement />

    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}