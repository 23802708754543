import React from 'react';
import cx from 'classnames';
import './styles/_limitContent.scss';

export const LimitContent = ({ width = 'none', align, children, ...props }) => {

    // define classes
    const elementStyles = cx(
        'limitContent',
        { 'align-left': align === 'left'}
    );

    // render html
    return (
        <div className={elementStyles} style={{ maxWidth: width }}>
            {children}
        </div>
    );

};