import React, { Fragment, useContext } from 'react';
import './styles/_sidePanel.scss';
import cx from "classnames";

import { CONTROL_SIZE } from "../../constants";
import { Control, SidePanelContext, SIDEPANEL_VISIBILITY } from "../../";
import { notNull } from '../../06_features/reconciliation';
import { ShowObject } from '../../00_global';

const getArrowName = (isOpen) => {
	return isOpen ? 'chevron_right' : 'chevron_left'
}

const showIcons = false;

export const SidePanel = () => {

	const sidePanelContext = useContext(SidePanelContext);

	notNull(sidePanelContext, 'sidepanel context');

	const { show, collapse, visibility, sidepanels, onClose, debug } = sidePanelContext;
	const isOpen = visibility === SIDEPANEL_VISIBILITY.open;

	const toggleIsOpen = () => {
		isOpen ? collapse() : show();
	}

	const togglePinned = () => {
	}

	const handleClose = () => {
		onClose();
	}

	return (
		<Fragment>
			<SidePanelElement
				sidepanels={sidepanels}
				isOpen={isOpen}
				toggleIsOpen={toggleIsOpen}
				togglePinned={togglePinned}
				onClose={handleClose}
				visibility={visibility}
				debug={debug}
			/>
		</Fragment>
	)
}

export const SidePanelElement = ({ sidepanels, isOpen, toggleIsOpen, togglePinned, onClose, debug, visibility }) => {

	notNull(visibility, 'visibility');
	notNull(sidepanels, 'sidepanels');

	const style = cx(
		'sidePanel',
		'bgc--white'
	);

	const activePanel = sidepanels.length > 0 ? sidepanels[0] : null
	let panelTitle = '';
	let panelContent = null;

	if (activePanel){
		debug && console.log('active panel', activePanel)

		const { children, title } = activePanel;
		panelTitle = title;
	
		notNull(children, 'children of the active panel');
		panelContent = children;
	}
	
	const details = {
		style: style,
		check: visibility === SIDEPANEL_VISIBILITY.open,
		sidePanelCount: sidepanels.length,
		theTitle: panelTitle,
		panelContent
	}

return (
	<div className={style}>
		<div className="sidePanel__controls">
			{
				showIcons && <ul>
					<li>
						<Control size={CONTROL_SIZE.large} onClick={toggleIsOpen}>
							<i className="material-icons">{getArrowName(isOpen)}</i>
						</Control>
					</li>
				</ul>
			}
		</div>
		<div className="sidePanel__content">
			<div className="sidePanel__inner">
				<div className="sidePanel__handles">
					<Control size={CONTROL_SIZE.xsmall} isPlain={true} onClick={togglePinned} alt={'pin'}>
						{/* <span className="sidePanel__pin is-open">
								<i className="material-icons">lock_open</i>
							</span> */}
						<span className="sidePanel__pin is-locked">
							<i className="material-icons is-locked">lock</i>
						</span>
					</Control>
					<Control size={CONTROL_SIZE.xsmall} isPlain={true} onClick={onClose} alt={'close'}>
						<i className="material-icons">close</i>
					</Control>
				</div>
				<div className="sidePanel__pane">
					<div>
						<div>{panelTitle}</div>
						{panelContent}
						{debug && <ShowObject value={details} />}
					</div>
					
				</div>

			</div>
		</div>
	</div>
)
};