import React, { useState, useContext } from 'react';
import { Box, BoxHeader, SidePanelContext, Bug } from '../../../index';
import { dataservice } from "../../../00_global/service/dataservice";
import { isInDebug } from '../../../00_global/isInDebug';
import { Formik } from 'formik';
import { Input, Field, FormField, Button } from "../../../";
import * as Yup from "yup";

const debug = isInDebug('Inbox') && isInDebug('SupplierField');

const formValidationSchema = Yup.object().shape({
    customerName: Yup.string()
        .required('Verplicht veld!'),
});

export const CreateCustomer = ({ userInput, onCustomerCreated }) => {

    const [error, setError] = useState('');
    
    const ctx = useContext(SidePanelContext);
    debug && console.log(ctx);

    const onSubmit = async (values, actions) => {
        const { customerName } = values;

        try {
            setError(null);
            const response = await dataservice.createCustomer(customerName);
            const {customerId} = response;
            onCustomerCreated && onCustomerCreated(customerId, customerName)
            ctx.close();
        }
        catch (err) {
            setError(err);
        }
        finally {
            actions.setSubmitting(false);
        }
    }

    const initialValues = { customerName: userInput || ''}

    return (
        <Box plain={true} header={<BoxHeader title={'Nieuwe klant'} />}>
            <Formik
                initialValues={initialValues}
                validationSchema={formValidationSchema}
                validateOnChange={true}
                isInitialValid={formValidationSchema.isValidSync(initialValues)}
                //validateOnBlur={true}
                //validate={handleValidate}
                onSubmit={async (values, actions) => {
                    await onSubmit(values, actions);
                    actions.setSubmitting(false);
                }}>
                {
                    (formikProps) => {
                        const { isSubmitting, handleSubmit, isValid, errors } = formikProps;
                        return (
                            <>
                                {!error && <form className="form" onSubmit={handleSubmit}>
                                    <FormField>
                                        <Field id={'customerName'} infoMessage={'Bedrijfsnaam van de klant'} header={'Naam'} errorMessage={errors && errors['customerName']}>
                                            <Input />
                                        </Field>
                                        <div style={{ 'marginTop': '16px' }}><Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button></div>
                                    </FormField>
                                </form>}
                                {error && <Bug error={error} />}
                            </>
                        )
                    }
                }
            </Formik>
        </Box>
    )
}