import React, { useState, useRef, useEffect, useCallback } from 'react';
import { SidePanelContext } from './SidePanelContext'
import { ErrorBoundary } from '../ErrorBoundary'
import { find } from 'lodash';

function S4() {
    return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
}

export const SIDEPANEL_VISIBILITY = {
    hidden: 'hidden',
    collapsed: 'collapsed',
    open: 'open'
};

const defaultCloseProps = {
    canClose: true,
    onClose: () => { return true; }
};

const defaultPinProps = {
    toolTip: '',
    canPin: true,
    pinned: true
};


export const SidePanelManager = ({children} ) => {
    const pinned = false;
    const defaultPanels = [];

    const savedSomething = useRef();

    const [sidepanels, updateSidepanels] = useState(defaultPanels);
    const [visibility, updateVisibility] = useState(SIDEPANEL_VISIBILITY.hidden);
    const [pauzed, setPauzed] = useState(false);
    const [context] = useState((S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase());

    const handleUserKeyPress = useCallback(event => {
        const { keyCode } = event;
		if (keyCode === 27 && savedSomething.current.visibility === SIDEPANEL_VISIBILITY.open) {
			handleClose();
		}
	}, []);

    // Remember the latest callback.
    useEffect(() => {
        savedSomething.current = { sidepanels, visibility };
        window.addEventListener('keydown', handleUserKeyPress);
		// cleanup event listeners
		return () => {
			window.removeEventListener('keydown', handleUserKeyPress);
		};
    }, [sidepanels, visibility, handleUserKeyPress]);

    const _addPanel = (panel, blocking = false, pinProps = defaultPinProps, closeProps = defaultCloseProps) => {
        _add({ panel, pinProps, closeProps });
        setPauzed(blocking);
    }

    const _add = ({panel = null, pinProps = defaultPinProps,closeProps = defaultCloseProps }) => {
        if (!panel) {
            throw new Error('no panel content specified. Please check your component!');
        }

        const wrappedPanel = <ErrorBoundary>{panel}</ErrorBoundary>

        const newItem = {
            children: wrappedPanel,
            closeProps: closeProps,
            pinProps: pinProps,
            isActive: true
        };

        const items = [];
        items.push(newItem);

        updateSidepanels(items);
        updateVisibility(SIDEPANEL_VISIBILITY.open);
    }

    const show = () => {
        updateVisibility(SIDEPANEL_VISIBILITY.open)
    }

    const hide = () => {
        updateVisibility(SIDEPANEL_VISIBILITY.hidden)
    }

    const collapse = () => {
        updateVisibility(SIDEPANEL_VISIBILITY.collapsed)
    }

    const _getActiveItem = () => {
        // if (sidepanels.length === 0) {
        //     console.log(`Why are you retrieving an active item from an empty list? (${stateCounter})`);
        // }

        if (savedSomething.current.sidepanels) {
            const currentSidePanels = savedSomething.current.sidepanels;
            const activeItem = find(currentSidePanels, { 'isActive': true });
            if  (!activeItem){
                console.warn('no active item found');
            }
            return activeItem;
        }
    }

    const handleClose = () => {
        const activeItem = _getActiveItem();

        if (activeItem) {
            let closeAllowed = true;

            if (activeItem.closeProps.onClose !== null) {
                // invoke the close function
                closeAllowed = activeItem.closeProps.onClose();
            }

            if (closeAllowed === true) {
                const items = sidepanels.filter((value) => value !== activeItem);
                const visibility = items.length > 0 ? SIDEPANEL_VISIBILITY.open : SIDEPANEL_VISIBILITY.hidden;

                updateSidepanels(items);
                updateVisibility(visibility);
            }
            else {
                console.warn('close not allowed')
            }
        }
        else {
            console.warn('handleClose was invoked but no active view was found!')
        }
    }

    // const handlePin = () => {
    //     const activeItem = _getActiveItem();
    //     console.log('item '+ activeItem.pinProps.toolTip + 'should be pinned');
    // }

    // const handleUnpin = () => {
    //     const activeItem = _getActiveItem();
    //     console.log('item '+ activeItem.pinProps.toolTip + 'should be un-pinned');
    // }

    const val = {
        visibility: visibility,
        sidepanels: sidepanels,
        add: _addPanel, // todo: rename
        show: show,
        hide: hide,
        collapse: collapse,
        onClose: handleClose,
        close: handleClose, // a bit verbose, but this seems a better name to close it from an external 'context'
        debug: false,
        blocked: pauzed,
        size: "size-m",
        pinned: pinned,
        context
    }

    return (
        <SidePanelContext.Provider value={val}>
            {children}
        </SidePanelContext.Provider>
    )
}