import React, { useCallback, useState, useContext, createRef } from 'react'
import Dropzone from 'react-dropzone'
import { SidePanelManager, ErrorBoundary, NotificationManager } from '../../index';
import { ContentContainer, Button } from '../../index'
import { Bug, VisualFeedback } from '../../03_molecules/visualFeedback/VisualFeedback';
import { BUTTON_SIZE } from '../../constants';
import InProgressImage from './uploading.png';
import { dataservice } from '../../00_global/service/dataservice';
import { isInDebug } from '../../00_global/isInDebug';
import { NotificationContext } from '../../00_global/context/NotificationContext';
import { modules } from '../../00_global/debugInfo';

const debug = isInDebug(modules.UPLOAD);

const UploadFilesDropZone = () => {

    const [pct, setProgress] = useState(0);
    const [err, setError] = useState(null);
    const { showNotificationToast, showErrorToast } = useContext(NotificationContext);

    const onDrop = useCallback(acceptedFiles => {

        debug && console.log('acceptedFiles', acceptedFiles);

        const sendFilesAsync = async () => {
            try {
                const apiResult = await dataservice.sendFiles(acceptedFiles, (val) => {
                    debug && console.log('progressed', val)
                    setProgress(Math.round(val * 100));
                })

                debug && console.log('file upload result', apiResult);
                if (apiResult.failedFiles.length > 0){
                    // show error
                        showErrorToast(`Volgende bestanden werden niet aanvaard: ${apiResult.failedFiles.join(', ')}`)
                }
                else {
                    showNotificationToast(apiResult.message);
                }


            }
            catch (err) {
                debug && console.log('error during upload!', err);
                setError(err);
            }

            setProgress(0);
        }
        sendFilesAsync();
    }, [])

    const inProgress = pct > 1 ? true : false;
    const message = inProgress ? '' : 'Sleep je .pdf bestanden hier naartoe of klik op de knop.';

    const getTitle = (isDragActive) => {
        return !inProgress ? (isDragActive ? 'Laat maar los!' : 'Bestanden doorsturen') : `Bezig met verzenden: ${pct} %`;
    }

    var divStyle = {
        display: 'flex',
        flex: '1 0 auto',
        height: '100%',
        outline: 'none'
    };

    const dropzoneRef = createRef();

    return (
        <Dropzone ref={dropzoneRef} onDrop={onDrop}>
            {
                ({ getRootProps, getInputProps, isDragActive }) => (
                    <div style={divStyle} {...getRootProps()}>
                        <input {...getInputProps()} />
                        {
                            <>
                                {!err &&
                                    <>
                                        <VisualFeedback
                                            image={InProgressImage}
                                            title={getTitle(isDragActive)}
                                            subTitle={message}
                                            ctaButton={<Button size={BUTTON_SIZE.medium} rounded={false} inverted={true} onClick={() => { dropzoneRef.current.open() }}>Klik hier</Button>}
                                        >{/* <div>Loader als child staat niet centered</div> */}
                                        </VisualFeedback>
                                    </>
                                }
                                {err && <Bug error={err} onResetError={() => setError(null)}></Bug>}
                            </>
                        }
                    </div>
                )
            }
        </Dropzone>
    )
}

const UploadMain = () => {
    return (
        <ErrorBoundary>
            <UploadFilesDropZone />
        </ErrorBoundary>
    )
}

export const Upload = () => {
    const main = <UploadMain />

    return (
        <SidePanelManager>
            <NotificationManager>
                <ContentContainer main={main} />
            </NotificationManager>
        </SidePanelManager>
    )
}