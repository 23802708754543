import React from 'react';
import cx from 'classnames';

export const BoxHeader = ({ title = '', subTitle = '', controls }) => {

	const headerStyle = cx(
		'heading',
		'weight-500',
		{ 'spacing-xxs': subTitle !== '' },
		{ 'spacing-0': subTitle === '' }

	);

	const wrappedControls = (
		<>
			{ controls }
		</>);

	return (
		<div className="box__header">
			<div className="box__inner">
				<div className="box__headerContent">
					<h3 className={headerStyle}>{title}</h3>
					<p className="fc--gray-3">{subTitle}</p>
				</div>
				<div className="box__headerControls">
					{controls && wrappedControls}
				</div>
			</div>
		</div>
	)
}