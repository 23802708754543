import React, { useState } from 'react';
import cx from 'classnames';

import { InvoiceListItemDto } from './models/dtos';
import { TableCell } from '../export/ExportOverview';
import { Label, Paragraph, DateTime, Amount, Control } from '../..';
import { LABEL_SIZE, PARAGRAPH_SIZE, CONTROL_SIZE, HEADING_SIZE, HEADING_WEIGHT, PARAGRAPH_SPACING, BUTTON_SIZE, COLOR } from '../../constants';
import { getColumnDefinition } from '../export/getColumnDefinition';
import { Confirmation } from '../../03_molecules/confirmation/Confirmation';
import { Box } from '../../03_molecules/box/Box';
import { Heading } from '../../02_atoms/headings/Heading';
import { Button } from '../../02_atoms/buttons/Button';
import { Ellipsis } from '../../02_atoms/ellipsis/Ellipsis';
import { ConfirmationV2 } from '../../03_molecules/confirmation/ConfirmationV2';
import { isInDebug } from '../../00_global/isInDebug';
import { InvoiceStatus } from './InvoiceHeader';

interface InvoiceRowProps {
    invoice: InvoiceListItemDto,
    onSendInvoice(item:InvoiceListItemDto): void,
    onEditInvoice(item:InvoiceListItemDto): void,
    onConfirmDelete(item:InvoiceListItemDto): void,
}

export const InvoiceRow = ({invoice, onSendInvoice, onEditInvoice, onConfirmDelete} : InvoiceRowProps) => {

    const rowStyle = cx('table__row',
        { 'is-clickable': false },
        { 'is-selected': false }
    )

    const canSend = isInDebug('invoices'); // feature toggle

    return (
        <div className={rowStyle}>
            <TableCell definition={getColumnDefinition('status', 'Status', 10)}>
                {(() => <InvoiceStatus invoiceStatusEnum={invoice.invoiceStatus} />)()}
            </TableCell>
            <TableCell definition={getColumnDefinition('invoiceId', 'ID', 16)}>
                <div style={{overflow: 'hidden'}}>
                    <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-4">
                        <Ellipsis>{invoice.number}</Ellipsis>
                    </Paragraph>
                </div>
            </TableCell>
            <TableCell definition={getColumnDefinition('toParty', 'Aan', 30)}>
                <Ellipsis>{invoice.toParty}</Ellipsis>
            </TableCell>
            <TableCell definition={getColumnDefinition('invoiceDate', 'Datum', 15)}>
                <DateTime dateValue={invoice.invoiceDate} />
            </TableCell>
            <TableCell definition={getColumnDefinition('totalAmount', 'Totaal', 15)}>
                <Amount value={invoice.totals.totalGross} />
            </TableCell>
            <TableCell definition={getColumnDefinition('status', 'Status', 14, 'right')}>
                {canSend && 
                <Control size={CONTROL_SIZE.small} isGhost disabled color="color-success" onClick={() => onSendInvoice(invoice)}>
                    <i key={'send_invoice'} className="material-icons">near_me</i>
                </Control>}
                &nbsp;
                <Control size={CONTROL_SIZE.small} isGhost onClick={() => onEditInvoice(invoice)}>
                    <i key={'edit_invoice'} className="material-icons">edit</i>
                </Control>
                &nbsp;
                {invoice.invoiceStatus === 1 &&
                    <ConfirmationV2 message={'Deze factuur wordt definitief verwijderd'} onDeleteConfirmed={() => onConfirmDelete(invoice)} ></ConfirmationV2>
                }
                
            </TableCell>
        </div>
    )
}

interface InvoiceDeleteConfirmationProps {
    handleAccept: any,
    handleDecline: any
}

// todo: remove 
const InvoiceDeleteConfirmationz = ({ handleAccept, handleDecline, ...props }: InvoiceDeleteConfirmationProps) => {
    return (
        <Box border={true}>
            <Heading size={HEADING_SIZE.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Ben je zeker?</Heading>
            <Paragraph size={PARAGRAPH_SIZE.small} spacing={PARAGRAPH_SPACING.xs}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit numquam possimus aliquam at, sed cupiditate.</Paragraph>
            <div className="row no-pad-v">
                <div className="col-md-6">
                    <Button size={BUTTON_SIZE.full} color={COLOR.secondary_2} onClick={handleAccept}>Ja</Button>
                </div>
                <div className="col-md-6">
                    <Button size={BUTTON_SIZE.full} color="color-error" onClick={handleDecline}>Nee</Button>
                </div>
            </div>
        </Box>
    )
}