import React from 'react';
import './styles/_bubble.scss';
import cx from "classnames";

export const Bubble = ({children, position}) => {

	const style = cx(
		'bubble',
		{'position-top': position === 'top'},
		{'position-right': position === 'right'},
		{'position-bottom': position === 'bottom'},
		{'position-left': position === 'left'}
	);

	return (
		<div className={style} data-position={position}>
			<div className="bubble__inner">
				{children}
			</div>
		</div>
	)
};

