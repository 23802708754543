import React, { Fragment } from 'react';
import { Select } from '../../../index';
import { notNull } from '../../../00_global/notNull';

export const SelectPaymentMethod = (props) => {

    const options = ['Nog niet betaald', 'Bank', 'Mastercard', 'Cash', 'Visa']
    const { value, onChange, onBlur, id } = props;

    notNull(value, 'value');
    notNull(onChange, 'onChange prop');
    notNull(onBlur, 'onBlur prop');
    notNull(id, 'id');

    return (
        <Fragment>
            <Select {...props} options={options} />
        </Fragment>
        
    )
}

