import React from 'react';
import { InvoiceTemplateDto } from '../models/invoiceSettings';
import { Box, Heading, Ellipsis, ShowObject, Button } from '../../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING } from '../../../constants';
import { Formik } from 'formik';
import { InputField } from '../InputField';
import { invoiceTemplateFormFields } from './CreateTemplateForm';
import { FormField } from '../../../04_organisms/form/Form';

interface InvoiceTemplateForm {
    template?: InvoiceTemplateDto,
    initialValues: any,
    onSubmit(item: InvoiceTemplateDto): void
}

export const InvoiceTemplateForm = (props: InvoiceTemplateForm) => {

    const { initialValues, onSubmit, template } = props;
    const fields = invoiceTemplateFormFields;

    const getValue = (input: any): string => {
        return input;
    }

    const mapValues = (values: any): InvoiceTemplateDto => {

        const item: InvoiceTemplateDto = {...template,
            name: getValue(values[fields.companyName]), // for now
            defaultPaymentTermInDays: +getValue(values[fields.defaultPaymentTermInDays]),
            footerMessage: getValue(values[fields.footerMessage]),
            from: {
                name: getValue(values[fields.companyName]),
                address: [
                    getValue(values[fields.addressLine1]),
                    getValue(values[fields.addressLine2])
                ],
                bankAccount: {
                    bic: '',
                    iban: getValue(values[fields.iban])
                },
                country: values[fields.country],
                emailAddress: values[fields.emailAddress],
                phoneNumber: values[fields.phoneNumber],
                taxIdentificationNumber: values[fields.taxIdentificationNumber],
                websiteAddress: values[fields.website]
            },
        }

        return item;
    }

    const submitForm = (values: any) => {
        const item = mapValues(values);
        onSubmit(item);
    }

    return (
        <Box id={'select_customer'} noBorder color="bgc--white">
            <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.small}>
                <Ellipsis>{'Standaard factuur instellingen'}</Ellipsis>
            </Heading>
            <Formik
                initialValues={initialValues}
                validationSchema={undefined}
                validateOnChange={true}
                validateOnBlur={true}
                // validate={handleValidate}
                onSubmit={submitForm}
            >
                {
                    // render func
                    (formikProps) => {
                        const { handleSubmit, errors, values, setFieldValue, isValid, isSubmitting } = formikProps

                        return (
                            <form className="form" onSubmit={handleSubmit}>
                                <InputField formik={formikProps} id={fields.companyName} header={'Bedrijfnaam'} infoMessage={'of handelsnaam'} />
                                <InputField formik={formikProps} id={fields.taxIdentificationNumber} header={'BTW nummer'} />
                                <InputField formik={formikProps} id={fields.addressLine1} header={'Straat + nr'} />
                                <InputField formik={formikProps} id={fields.addressLine2} header={'Postcode + stad'} />
                                <InputField formik={formikProps} id={fields.country} header={'Land'} />
                                <InputField formik={formikProps} id={fields.phoneNumber} header={'Telefoon'} infoMessage={'optioneel'} />
                                <InputField formik={formikProps} id={fields.emailAddress} header={'Email'} infoMessage={'optioneel'} />
                                <InputField formik={formikProps} id={fields.iban} header={'Bankrekening'} infoMessage={'Bij voorkeur IBAN'} />
                                <InputField formik={formikProps} id={fields.footerMessage} header={'Standaard notitie'} infoMessage={'bv. "Bedankt voor het vertrouwen"'} multiline={true} />
                                <FormField>
                                    <Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button>
                                </FormField>
                            </form>
                        )
                    }
                }
            </Formik>
        </Box>
    )
}
