import React from 'react';
import { SidePanelManager, ContentContainer } from '../../index';
import { ArchiveList } from '../archive/ArchiveList';

export const Archive = () => {

	return (
		<SidePanelManager>
            <ContentContainer
                main={ <ArchiveList  />}
            />
        </SidePanelManager>
	)
}
