import React, { useState, useContext } from 'react';
import cx from 'classnames';
import '../../03_molecules/moduleHeader/styles/_moduleHeader.scss';
import { SidePanelManager, ContentContainer, Heading, Redirectable, FetchDataState } from '../..';
import { HEADING_SIZE, HEADING_SPACING, COLOR } from '../../constants';
import { TableHeader } from '../export/TableHeader';
import { FetchData, notNull } from '../../00_global';
import { InvoiceListItemDto } from './models/dtos';
import { productService, getListResponse } from './invoiceService';
import { Button } from '../../02_atoms/buttons/Button';
import { InvoiceRow } from './InvoiceRow';
import { SearchInvoices } from './SearchInvoices';
import { InvoiceDataContext } from '.';
import { routes } from '../../../SluggedPrivateRoutes';

export const InvoiceOverviewElement = () => {

    const style = cx('table')
    const enableFilters = false;

    const [redirect, redirectTo] = useState('');
    const [version, updateVersion] = useState(1);

    const columnDefinitions = [,
        { property: 'status', headerText: 'status', sortable: false, large: 'size-large-10', hideOnMedium: false },
        { property: 'invoiceId', headerText: 'ID', sortable: false, large: 'size-large-16' },
        { property: 'toParty_name', headerText: 'Aan', sortable: false, large: 'size-large-30' },
        { property: 'invoiceDate', headerText: 'Datum', sortable: false, large: 'size-large-15' },
        { property: 'totalAmount', headerText: 'Totaal (incl.)', sortable: false, large: 'size-large-15' }
    ]

    const dataContext = useContext(InvoiceDataContext);
    notNull(dataContext, 'dataContext @ AddInvoiceForm');

    const createNewInvoice = async () => {

        const templates = await productService.getTemplatesAsync();
        if (templates.items && templates.items.length > 0){
            const createDraftResponse = await productService.createDraftAsync();
            if (createDraftResponse.status === 0) {
                redirectTo(`/invoices/edit/${createDraftResponse.draft.id}`);
            }
        }
        else {
            redirectTo(routes.invoices.templateOverview);
        }
    }

    const handleConfirmDelete = async (invoice: InvoiceListItemDto) => {
        // todo: toggle state on InvoiceRow
        var result = await productService.removeInvoice(invoice.id);
        if (result.status === 0)
            updateVersion(version + 1);
    }

    const handleEditInvoice = (invoice: InvoiceListItemDto) => {
        redirectTo(`edit/${invoice.id}`)
    }

    const handleSendInvoice = () => {
        //todo
        alert('not ready');
    }

    return <Redirectable to={redirect}>
            <div className="moduleHeader">
                <div className="row" style={{ alignItems: 'flex-end' }}>
                    <div className="col-md-7">
                        <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.small}>
                            Overzicht facturen
                        </Heading>
                        <Button inverted color={COLOR.secondary_2} onClick={createNewInvoice}>
                            <i className="material-icons">add</i>
                            Nieuwe factuur
                        </Button>
                    </div>
                    <div className="col-md-5">
                        <SearchInvoices enabled={enableFilters} />
                    </div>
                </div>
            </div>

            <FetchData method={productService.getInvoicesAsync} triggerValues={[version]}>
                {
                    ({ data }: FetchDataState<getListResponse<InvoiceListItemDto>>) => {
                        if (data && data.items.length === 0) {
                            return <>Er zijn nog geen facturen aangemaakt</>
                        }
                        if (data) {
                            return (
                                <div className={style}>
                                    <div className="table__inner">
                                        <TableHeader columnDefinitions={columnDefinitions} />
                                        <div className="table__body">
                                            {
                                                data.items.map(x => <InvoiceRow key={x.id} invoice={x}
                                                    onConfirmDelete={handleConfirmDelete}
                                                    onEditInvoice={handleEditInvoice}
                                                    onSendInvoice={handleSendInvoice}
                                                />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        return null;
                    }
                }
            </FetchData>
    </Redirectable>
}

export const InvoiceOverview = () => {
    return (
        <SidePanelManager >
            <ContentContainer main={<InvoiceOverviewElement />} />
        </SidePanelManager>
    )
}