import React from 'react';
import { Box, Paragraph, Heading, Ellipsis, FormField, Button } from '../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE, PARAGRAPH_SPACING } from '../../constants';
import { Formik } from "formik";
import { ShowObject, notNull } from '../../00_global';
import { ErrorBoundary } from '../../00_global/ErrorBoundary';
import { isInDebug } from '../../00_global/isInDebug';
import { InputField } from '../../06_features/invoice_module_v1/InputField.tsx';
import * as Yup from "yup";


const ERROR_REQUIRED_FIELD = 'Verplicht veld!';
const enableSyncValidation = Yup.object().shape({
    path: Yup.string().required(ERROR_REQUIRED_FIELD).nullable(),
    rootFolder: Yup.string().required(ERROR_REQUIRED_FIELD).nullable()
});

export const EnableSyncPanel = ({ accountSettings, onSubmit }) => {

    notNull(onSubmit, 'onSubmit event handler not specified');
    notNull(accountSettings, 'accountSettings')

    const { rootFolder, externalReference, accountId, folderConfigs } = accountSettings;

    const handleValidate = (values) => {
        const { rootFolder, path } = values;
        return rootFolder !== '' && path !== '';
    }

    const dtoToForm = () => {
        return {
            rootFolder: rootFolder ? rootFolder : "",
            path: externalReference || ""
        }
    }

    const formToDto = (values) => {

        notNull(accountId, 'accountId')

        return {
            ...values,
            accountId
        }
    }

    const handleSubmit = async (values, actions) => {
        actions.isSubmitting = true;
        const updatedItem = formToDto(values);

        await onSubmit(updatedItem);
        actions.isSubmitting = false;

    }

    function pathJoin(parts, sep) {
        var separator = sep || '\\';
        var replace = new RegExp(separator + '{1,}', 'g');
        return parts.join(separator).replace(replace, separator);
    }

    const getExamples = (values) => {
        var root = values['rootFolder'];
        var customerFolder = values['path']

        var cleanedRoot = (root && root.endsWith('\\')) ? root.substring(0, root.length - 1) : root;

        var a = cleanedRoot && customerFolder && pathJoin([cleanedRoot, customerFolder, 'AANKOOP']);
        var b = cleanedRoot && customerFolder && pathJoin([cleanedRoot, customerFolder, 'VERWERKT', 'VERKOOP']);

        return a ? [a, b] : [];
    }

    const debug = isInDebug('dev');
    const formValues = dtoToForm(accountSettings)
    
    return (
        <>
            <ErrorBoundary>
                <Box noBorder color="bgc--white">
                    <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.normal} spacing={HEADING_SPACING.xxs}>
                        <Ellipsis text={'Synchronisatie - instellingen'}></Ellipsis>
                    </Heading>
                    <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.zero} color="fc--gray-4">
                        <div>Controleer de gegevens hieronder en vul aan waar nodig</div>
                    </Paragraph>
                    <Formik initialValues={formValues}
                        //isInitialValid={true}
                        validationSchema={enableSyncValidation}
                        validateOnChange={true}
                        validateOnBlur={true}
                        validate={handleValidate}
                        onSubmit={(values, actions) => {
                            handleSubmit(values, actions);
                        }}>
                        {
                            (formik) => {
                                const { handleSubmit, errors, values, isValid, isSubmitting } = formik;
                                const samples = getExamples(values);
                                const isMultiline = values['rootFolder'] && values['rootFolder'].length > 40

                                return (
                                    <>
                                        <form className="form" onSubmit={handleSubmit}>
                                            <InputField formik={formik} header={'Hoofdmap'} id={'rootFolder'} placeholder={''} infoMessage={'Hoofdmap voor gekoppelde dossiers'} multiline={isMultiline} />
                                            <InputField formik={formik} header={'Tussenmap'} id={'path'} placeholder={''} infoMessage={'bv. dossier code, btw nummer, ...'} multiline={false} />
                                            {samples.length > 0 &&
                                                <Paragraph>
                                                    Voorbeelden:
                                                    <ul>
                                                        {samples.map(s => <li key={s}>{s}</li>)}
                                                    </ul>
                                                </Paragraph>
                                            }{folderConfigs.length > 0 &&
                                                <Paragraph color={'fc--warning'} size={PARAGRAPH_SIZE.xs}>Opgelet, de huidige configuratie wordt overgeschreven!</Paragraph>
                                            }  
                                            <FormField>
                                                <Button type={'submit'} disabled={!isValid || isSubmitting}>Opslaan</Button>
                                            </FormField>
                                        </form>
                                        {debug && <ShowObject value={{ values, errors, isValid: isValid, accountSettings }} />}
                                    </>

                                )
                            }
                        }
                    </Formik>
                </Box>
            </ErrorBoundary>
        </>
    )
}