import React from 'react';
import './styles/_experience.scss';
import cx from "classnames";

export const Experience = ({intro=false, icon=false, box=false, theme, content=false, footer=false, size=''}) => {

	const style = cx(
		'experience',
		[theme]
	);

	const innerStyle = cx(
		'experience__inner',
		[size]
	);

	return (
		<div className={style}>
			<div className={innerStyle}>
				{intro &&
				<div className="experience__intro">
					{intro}
				</div>
				}
				{icon &&
				<div className="experience__icon">
					{icon}
				</div>
				}
				{box &&
				<div className="experience__box">
					{box}
				</div>
				}
				{content &&
				<div className="experience__content">
					{content}
				</div>
				}
				{footer &&
				<div className="experience__footer">
					{footer}
				</div>
				}
			</div>
		</div>
	)
};