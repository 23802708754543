import React, { useState } from 'react'
import { withRouter } from "react-router-dom";
import { SidePanelManager, FetchData, ControlBar } from '../../index';
import { ContentContainer } from '../../index'
import { dataservice } from '../../00_global/service/dataservice';
//import { WorkInProgress } from '../../03_molecules/visualFeedback/VisualFeedback';
import { notNull } from '../../00_global';
import { download } from "../../helpers";
import PdfViewer from '../inbox/_amo_/PdfViewer';
import './_DocumentViewer.scss';

export const PdfWrapper = ({ title = 'PDF', content, onDelete }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const onPDFLoaded = (totalPages) => {
        setTotalPages(totalPages);
    };

    const onPreviousPage = () => {

        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const onNextPage = () => {

        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onDownload = () => {
        download(content, title, 'application/pdf');
    };

    const onOpen = () => {
        console.log('open pdf in new tab');
    };

    return (
        <>
            <div className="documentViewer">
                <div className="documentViewer__inner">
                    <div className="documentViewer__controlbar">
                        <ControlBar
                            title={title}
                            spacing={true}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePrevious={onPreviousPage}
                            handleNext={onNextPage}
                            handleSave={onDownload}
                            handleOpen={onOpen}
                            handleDelete={onDelete}
                        />
                    </div>
                    <div className="documentViewer__main">
                        <PdfViewer content={content} page={currentPage} fillWidth fillHeight scale={1} onDocumentComplete={onPDFLoaded} />
                    </div>
                </div>
            </div>
        </>
    )

}


const PdfContentElement = ({match}) => {

   const { documentId } = match.params
   notNull(documentId, 'documentId');

    return (
        <FetchData method={() => dataservice.getContent(documentId)} >
        {
             ({data, error, loading}) => {
                 if (data) {
                    const { content, documentName } = data
                    return <>
                    <PdfWrapper content={content} title={documentName} />
                    </>
                 }
            }
        }
        </FetchData>
    )
}

const PdfContent = withRouter(PdfContentElement)

export const DocumentViewer = () => {

   const main = <PdfContent />

    return (
        <SidePanelManager>
            <ContentContainer
                main={main}
            />
        </SidePanelManager>
    )
}