import React, { useState } from 'react';
import cx from "classnames";
import { Box, Button, Experience, FormField, Paragraph, Wrapper, ShowObject } from "../../";
import { BUTTON_SIZE, COLOR, PARAGRAPH_SIZE, PARAGRAPH_ALIGNMENT, PARAGRAPH_WEIGHT } from "../../constants";

import { LogoPlain } from "../../01_fundaments/logo";
import * as Yup from "yup";
import { dev, isInDebug } from '../../00_global/isInDebug';
import { Formik } from "formik";
import mailbox from './undraw_mail_box_kd5i.png'
import { VisualFeedback } from '../../03_molecules/visualFeedback/VisualFeedback';
import { dataservice } from '../../00_global/service/dataservice';
import { InputField } from '../../06_features/invoice_module_v1/InputField.tsx';

const ERROR_REQUIRED_FIELD = 'Verplicht veld!';
const ERROR_INVALID_EMAIL = 'Geef een geldig mailadres op'

const debug = isInDebug('login');

const resetPwdSchema = Yup.object().shape({
	email: Yup.string()
		.email(ERROR_INVALID_EMAIL)
		.required(ERROR_REQUIRED_FIELD)
});

const ResetPwdForm = () => {

	const [submitted, setSubmitted] = useState(false);

	const initialValues = {
		email: ''
	}

	const handleSubmit = (values, actions) => {
		try {
			dataservice.reset_pwd(values['email']);
			
		} catch (error) {
			
		}
		
		setSubmitted(true);
	}

	const title = submitted ? 'Check je mailbox' : 'Wachtwoord vergeten?'

	return (
		<Formik
			initialValues={initialValues}
			//isInitialValid={true}
			validationSchema={resetPwdSchema}
			validateOnChange={true}
			validateOnBlur={true}
			//validate={handleValidate}
			onSubmit={(values, actions) => {
				handleSubmit(values, actions);
			}}>
			{
				(formikProps) => {

					const { handleSubmit, errors, values, isValid, isSubmitting } = formikProps;

					// const samples = getExamples(values);
					// const isMultiline = values['rootFolder'] && values['rootFolder'].length > 40

					return (
						<>
							<Box header={false} >
								<form className="form" onSubmit={handleSubmit}>
									<Paragraph  alignment={PARAGRAPH_ALIGNMENT.left} size={PARAGRAPH_SIZE.large} weight={PARAGRAPH_WEIGHT.bold} color={'color-secondary-2'}>
										<div>{title.toUpperCase()}</div>
									</Paragraph>
									{!submitted &&
										<>
											<Paragraph size={PARAGRAPH_SIZE.small} alignment={PARAGRAPH_ALIGNMENT.left}>
												<div>Vul hieronder je mailadres in en wij sturen een link waarmee je het wachtwoord opnieuw kan instellen</div>
											</Paragraph>
											<InputField formik={formikProps}
												header={'e-mail'} 
												id={'email'} placeholder={''} infoMessage={''} color={'color-secondary-2'} />

											<FormField>
												<Button
													type="submit"
													color={COLOR.secondary_2}
													size={BUTTON_SIZE.full}
													disabled={isSubmitting || !isValid}
													loading={isSubmitting}
												>Stuur link</Button>
											</FormField>
										</>
										}
										{submitted &&
										<Paragraph size={PARAGRAPH_SIZE.small} alignment={PARAGRAPH_ALIGNMENT.left}>
											<div>Als je email adres gekend is in ons systeem is je mailtje onderweg!</div>
											<VisualFeedback image={mailbox} plain={true} />
											<div>Niets ontvangen? Check je spam folder of stuur ons een mailtje op <a href={'mailto:support@trive.io'}>support@trive.io</a></div>
									</Paragraph>
										}
								</form>
							</Box>
							{debug && <ShowObject value={{ values, errors, isSubmitting }} />}
						</>

					)
				}
			}
		</Formik>
	)
}

export const ResetPwd = () => {

	const style = cx(
		'template--login'
	);

	return (
		<div className={style}>
			<Wrapper header={false} footer={false} compact={true}>
				<Experience
					intro={<LogoPlain />}
					box={<ResetPwdForm />}
					size="size-s" theme=""
					content={<LoginFooter />} />
			</Wrapper>
		</div>
	)
};

export const LoginFooter = () => {
	const buildNumber = dev() ? 'dev' : process.env.REACT_APP_BITBUCKET_BUILD_NUMBER
	const love = <svg xmlns="http://www.w3.org/2000/svg" fill='#D8393C' width="10" height="10" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" /></svg>
	return (
		<Paragraph size={PARAGRAPH_SIZE.xs} alignment={PARAGRAPH_ALIGNMENT.center}>
			<div>Made with {love} in Belgium - versie: 2.1.{buildNumber}</div>
			<a href="https://public.trive.io/contacteer-ons" className="fc--white">Heb je nog vragen?</a>
		</Paragraph>
	)
};