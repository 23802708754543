import React from 'react';
import { Wrapper } from "../../";
import { dataservice } from '../../00_global/service/dataservice';
import { AccountancyHeader } from '../../04_organisms/header/AccountancyHeader';
import cx from "classnames";
import { Footer } from "../../";

export class TriveApp extends React.Component {

    // main entry point for the app. 

    render() {
        const { children } = this.props;
        return (
            <Wrapper dataservice={dataservice}>
                {children}
            </Wrapper>
        )
    }
}

const AccountancyWrapper = ({children}) => {

    // copy to make sure I don't break stuff. Should be refactored someday (actually, Wrapper should be refactored to get rid of the dataservice)

    const header = true;
    const footer = true;

    const style = cx(
		'wrapper',
		{'has-header': header === true},
		{'has-footer': footer === true}
	);

	return (
		<div className={style}>
			<div className="wrapper__inner">
				{header && 
					<div className="wrapper__header section">
						<AccountancyHeader />
					</div>
				}

				<div className="wrapper__content content">
					{children}
				</div>

				{footer &&
					<div className="wrapper__footer footer section">
						<Footer />
					</div>
				}
			</div>
		</div>
	)
}

export const AccountancyPortal = ({ children }) => {
    return (
        <AccountancyWrapper>
            {children}
        </AccountancyWrapper>
    )
}