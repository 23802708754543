import React from 'react';
import * as Yup from 'yup';
import * as moment from 'moment';
import { Formik } from 'formik';
import { SupplierField, SelectPaymentMethod } from './_amo_/index'
import { Input, Field, FormField, Button } from "../..";
import { BUTTON_SIZE } from "../../constants";

import { DateField } from "../../03_molecules/date/DateField";
import { isInDebug } from '../../00_global/isInDebug';
import { ShowObject } from '../../00_global/ShowObject';
import { notNull } from '../..'

import { dataservice } from '../../00_global/service/dataservice';
import { withRouter } from "react-router-dom";
import { routeWithSlug } from '../../00_global/'
import { sleep } from '../../helpers';

const debug = isInDebug('Inbox');

const formValidationSchema = Yup.object().shape({
    supplier_searchQuery: Yup.string()
        .required('Verplicht veld!'),
    documentDate: Yup.string()
        .required('Verplicht veld!'),
});

const AnnotateExpenseElement = ({ documentId, history }) => {

    notNull(documentId, 'documentId');
    notNull(history, 'history')

    const onSubmit = async (values, actions) => {

        const request = {
            documentId: documentId,
            party: {
                partyId: values['supplierId'],
                name: values['supplier_searchQuery']
            },
            documentDate: values['documentDate'],
            paymentMethod: values['paymentMethod'],
            description: values['description'],
            documentType: 3
        }

        try {
            await dataservice.addMetadata(request);
            sleep(2000);
            history.push(routeWithSlug('inbox'));
        }
        finally {
            actions.setSubmitting(false);
        }        
    }

    const handleValidate = (values) => {
        const {supplierId} = values;
        
        let errors = {};

        if (!supplierId){
            errors['supplier_searchQuery'] = 'Kies een leverancier';
        }

        return errors;
    }

    const defaultState = {
        supplier_searchQuery: '',
        documentDate: '',
        description: '',
        paymentMethod: 'Nog niet betaald',
        supplierId: ''
      };

    return (
        <Formik
            initialValues={defaultState}
            validationSchema={formValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            validate={handleValidate}
            onSubmit={(values, actions) => {
                onSubmit(values, actions);
            }}>
            {
                (formikProps) => {

                    const { isSubmitting, setFieldValue, handleSubmit, errors, values } = formikProps;
                    const selectedSupplier = values['supplierId'];                    

                    const onDocumentDateSelected = (date) =>{
                        const fieldName = 'documentDate'
                        moment(date).isValid() 
                        ? setFieldValue(fieldName, moment(date).format())
                        : setFieldValue(fieldName, null);
                    };

                    return (
                        <form className="form" onSubmit={handleSubmit}>
                        
                            <FormField>
                                <Field id={'supplier_searchQuery'} infoMessage={!selectedSupplier && 'Typ om te zoeken'} header={'Leverancier *'} errorMessage={errors['supplierId']}>
                                    <SupplierField propertyName={'supplier_searchQuery'} selectedValueId={'supplierId'} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'documentDate'} header={'Factuur datum *'} infoMessage={''}>
                                    <DateField placeholder={'factuur datum'} propertyName={'documentDateFormValue'} onSelect={onDocumentDateSelected} maxDate={new Date()} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'paymentMethod'} header={'Betaalmethode'}>
                                    <SelectPaymentMethod id={'paymentMethodSelect'} />
                                </Field>
                            </FormField>
                            <FormField>
                                <Field id={'description'} header={'Info'} infoMessage={'eigen notities'}>
                                    <Input />
                                </Field>
                            </FormField>

                            <FormField>
                                <Button
                                    type="submit"
                                    size={BUTTON_SIZE.full}
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >Verzenden</Button>
                            </FormField>
                            {debug && <ShowObject value={formikProps} />}
                        </form>
                    )
                }
            }
        </Formik>
    )
};

export const AnnotateExpenseLtd = withRouter(AnnotateExpenseElement);