import React from 'react';
import { Button } from '../../02_atoms/buttons/Button';
import { Fill } from '../../02_atoms/fill/Fill';
import { Box } from '../../03_molecules/box/Box';
import { BoxHeader } from '../../03_molecules/box/BoxHeader';

const spaceStyle = {
    'height': '20px'
}

const DuplicateWarning = ({ duplicates }) => {

    if (duplicates.length === 0)
        return null;

    return (
        <>
            <div style={spaceStyle}></div>
            <Box header={<BoxHeader title={'Opgelet!'} subTitle={'Het lijkt er op dat dit document reeds in je administratie zit'} />}>
            </Box>
        </>
    );
}

export const SelectDocumentType = ({ onDocumentTypeSelected, duplicates }) => {

    const additionalStyles = {
        'width': '100%',
        'margin': '5px'
    }



    return (
        <>
            {duplicates && <DuplicateWarning duplicates={duplicates} />}
            <div style={spaceStyle}></div>
            <Box header={<BoxHeader title={'Kies het document type'} />}>
                <Fill><Button additionalStyles={additionalStyles} onClick={() => onDocumentTypeSelected(3)}>Aankoop</Button></Fill>
                <Fill><Button additionalStyles={additionalStyles} onClick={() => onDocumentTypeSelected(2)}>Verkoop</Button></Fill>
                <Fill><Button additionalStyles={additionalStyles} onClick={() => onDocumentTypeSelected(4)}>Divers</Button></Fill>
            </Box>
        </>
    )
}