// https://medium.com/@axelhadfeg/builder-pattern-using-javascript-and-es6-ec1539182e24

export class ChainItemData {
  constructor(builder) {
    this.id = builder._id;
    this.key = builder._id;
    this.incomplete = builder._incomplete;
    this.disabled = builder._disabled;
    this.heading = builder._heading;
    this.caption = builder._caption;
    this.material_icon = builder._material_icon;
    this.error = builder._error;
    this.inProgress = builder._inProgress;
    this.warning = builder._warning;
    this.done = builder._done;
  }
  static get Builder() {
    class Builder {
      constructor(id) {
        this._id = id;
        this._incomplete = false;
        this._disabled = false;
        this._heading = '';
        this._caption = '';
        this._material_icon = '';
        this._error = false;
        this._inProgress = false;
        this._warning = false;
        this._done = false;
      }
      build() {
        return new ChainItemData(this);
      }
      incomplete() {
        this._incomplete = true;
        return this;
      }
      disabled() {
        this._disabled = true;
        return this;
      }
      withHeading(value = '') {
        this._heading = value;
        return this;
      }
      withCaption(value = '') {
        this._caption = value;
        return this;
      }
      withIconName(value = '') {
        this._material_icon = value;
        return this;
      }
      inProgress() {
        this._inProgress = true;
        return this;
      }
      hasError() {
        this._error = true;
        return this;
      }
      hasWarning() {
        this._warning = true;
        return this;
      }
      isDone() {
        this._done = true;
        return this;
      }
    }
    return Builder;
  }
}
