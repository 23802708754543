// todo: move to helper class
export const getColumnDefinition = (property: string, headerText: string, columnWidth: number, align = 'left') => {
    return (
        {
            property: property,
            headerText: headerText,
            sortable: false,
            large: `size-large-${columnWidth}`,
            medium: `size-medium-${columnWidth}`,
            small: `small-medium-${columnWidth}`,
            align: align
        }
    )
}