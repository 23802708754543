export { MatchTransactionPanel } from './MatchTransactionPanel';
export { IsMatch } from './IsMatch';
export { MatchResults } from './MatchResults';
export { DocumentMatch } from './DocumentMatch';

export const notNull = (value, propertyName, warnOnly = false) => {
  if (!value) {
    if (!warnOnly){
      throw new Error('value for [' + propertyName + '] is missing');
    }
    else {
      console.warn('value for [' + propertyName + '] is missing');
    }
  }
};
