import React from 'react';
import { InvoiceTemplateDto } from '../models/invoiceSettings';
import { InvoiceTemplateForm } from './InvoiceTemplateForm';

interface CreateInvoiceTemplateProps {
    onSaveTemplate(item: InvoiceTemplateDto) : void
}

export const CreateInvoiceTemplate = (props: CreateInvoiceTemplateProps) => {

    const handleSubmit = (item: InvoiceTemplateDto) => {
        props.onSaveTemplate(item);
    }

    const fields = invoiceTemplateFormFields;

    const initialValues = {
        [fields.templateName]: '',
        [fields.companyName]: '',
        [fields.addressLine1]: '',
        [fields.addressLine2]: '',
        [fields.country]: '',
        [fields.phoneNumber]: '',
        [fields.emailAddress]: '',
        [fields.iban]: '',
        [fields.taxIdentificationNumber]: '',
        [fields.website]: '',
        [fields.footerMessage]: '',
        [fields.defaultPaymentTermInDays]: 30
    }

    return (<InvoiceTemplateForm initialValues={initialValues} onSubmit={handleSubmit} />)
}

export const invoiceTemplateFormFields = {
    templateName: 'templateName',
    companyName: 'companyName',
    addressLine1: 'addressLine1',
    addressLine2: 'addressLine2',
    country: 'country',
    phoneNumber: 'phoneNumber',
    emailAddress: 'emailAddress',
    iban: 'iban',
    taxIdentificationNumber: 'taxIdentificationNumber',
    website: 'website',
    footerMessage: 'footerMessage',
    defaultPaymentTermInDays: 'defaultPaymentTermInDays',
}