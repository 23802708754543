import React from 'react';
import './styles/_loader.scss';
import cx from "classnames";

import {LOADER_SIZE} from "../../constants";

export const Loader = ({size = LOADER_SIZE.default, color}) => {

    const style = cx(
    	'loader',
    	[color],
    	[size]
    );

    return (
        <div className={style}></div>
    )
};