import React from 'react';
import * as Yup from 'yup';
import { Box, Paragraph, Heading, Ellipsis, FormField, Button } from '../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE, PARAGRAPH_SPACING, BUTTON_SIZE, COLOR } from '../../constants';
import { Formik } from "formik";
import { Dropdown } from './Dropdown';
import { productService, invoiceApiResponse } from './invoiceService';
import { InvoiceLineItem, ProductOrTask } from './models/api';
import { SearchItem } from '../../03_molecules/search/SearchV2';
import { InputField } from './InputField';
import { ERROR_REQUIRED_FIELD, ERROR_INVALID_NUMBER } from './addInvoiceLineValidationSchema';

const _vatRegimes: SearchItem[] = [
    { id: '0', caption: '0%', note: '', value: 0 },
    { id: '1', caption: '6%', note: '', value: 0.06 },
    { id: '2', caption: '21%', note: '', value: 0.21 },
]


interface UpdateLinePanelProps {
    invoiceId: string;
    item: InvoiceLineItem;
    onInvoiceLineSaved(result: invoiceApiResponse): void;
    defaultVat: string;
}

export const UpdateLinePanel = (props: UpdateLinePanelProps) => {

    const { invoiceId, onInvoiceLineSaved, item } = props;

    const fields = {
        product: 'product',
        vatPct: 'vatPct',
        vatCaption: 'vatCaption',
        unitName: 'unit',
        description: 'description',
        quantity: 'quantity',
        unitPrice: 'unitPrice'
    }

    const invoiceLineValidationSchema = Yup.object().shape({
        [fields.product]: Yup.string().required(ERROR_REQUIRED_FIELD),
        [fields.quantity]: Yup.number().required(ERROR_REQUIRED_FIELD).typeError(ERROR_INVALID_NUMBER),
        [fields.unitPrice]: Yup.number().required(ERROR_REQUIRED_FIELD).typeError(ERROR_INVALID_NUMBER)
    });

    const l = item.productOrTask;
    const selectedVat = _vatRegimes.find(x => x.value === l.taxPercentage);

    const vatCaption = selectedVat ? selectedVat.caption : '';

    const initialValues = {
        [fields.product]: l.productName,
        [fields.description]: l.description,
        [fields.quantity]: l.quantity,
        [fields.unitPrice]: l.unitPrice,
        [fields.vatCaption]: vatCaption,
        [fields.vatPct]: l.taxPercentage,
        [fields.unitName]: l.unit
    }

    const valuesToInvoiceLine = (values: any): InvoiceLineItem => {

        const productOrTask: ProductOrTask = {
            lineItemId: item.productOrTask.lineItemId,
            description: values[fields.description],
            note: '',
            productName: values[fields.product],
            quantity: parseFloat(values[fields.quantity]),
            taxPercentage: parseFloat(values[fields.vatPct]),
            unit: values[fields.unitName],
            unitPrice: parseFloat(values[fields.unitPrice]),
        }

        return {
            productOrTask: productOrTask
        }
    }

    const onSubmit = async (values: any, actions: any) => {
        try {

            const line = valuesToInvoiceLine(values);
            const result = await productService.updateInvoiceLine(invoiceId, line);

            if (!result)
                throw Error('expected a result, got null or undefined');

            if (result.status === 0)
                onInvoiceLineSaved(result);
                else {
                    console.warn('Expected succes', result);
                }
        }
        finally {
            actions.setSubmitting(false);
        }
    }

    return (<Box id={'addInvoiceLinePanel'} noBorder color="bgc--white">
        <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.xxs}>
            <Ellipsis>{'Aanpassen'}</Ellipsis>
        </Heading>
        <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.small} color="fc--gray-4">
            <div>Geef de details van de geleverde producten of geleverde diensten in.</div>
        </Paragraph>
        <Formik
            initialValues={initialValues}
            validationSchema={invoiceLineValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            // validate={handleValidate}
            onSubmit={onSubmit}
        >
            {
                // render func
                (formikProps) => {
                    
                    const { handleSubmit, setFieldValue, isValid, isSubmitting } = formikProps
                    const onVatSelected = (item: SearchItem) => {
                        setFieldValue(fields.vatCaption, item.caption);
                        setFieldValue(fields.vatPct, item.value);
                    }

                    return (
                        <form className="form" onSubmit={handleSubmit}>
                            <InputField formik={formikProps} header={'Aantal'} id={fields.quantity} infoMessage={'numeriek'} />
                            <InputField formik={formikProps} header={'Product/dienst'} id={fields.product} infoMessage={''} />
                            <InputField formik={formikProps} header={'Omschrijving'} id={fields.description} />
                            <InputField formik={formikProps} header={'Eenheid'} id={fields.unitName} />
                            <InputField formik={formikProps} header={'Eenheidsprijs *'} id={fields.unitPrice}  infoMessage={'totaal excl. btw, in EUR'}  />
                            <Dropdown formik={formikProps} header={'BTW percentage'} id={fields.vatCaption} blockInput={true} results={_vatRegimes} onItemSelected={onVatSelected} />
                            <FormField>
                                <Button type={'submit'} size={BUTTON_SIZE.full} color={COLOR.secondary_2} disabled={!isValid || isSubmitting}>Opslaan</Button>
                            </FormField>
                        </form>
                    )
                }
            }
        </Formik>
    </Box>
    )
}