import React from 'react';
import { InboxFormViewer } from './InboxFormViewer';
import { SidePanelManager, NotificationManager } from "../..";
import { withRouter } from "react-router-dom";
import { ContentContainer } from '../../index'
import { dataservice } from "../../00_global/service/dataservice";
import { FetchData } from '../../00_global/FetchData';
import { Bug, VisualFeedback } from '../../03_molecules/visualFeedback/VisualFeedback';
import PleasWaitImage from './download-undraw.png';

const AnnotateDocumentView = ({ documentId }) => (
	<FetchData method={() => dataservice.getInboxDetail(documentId)}>
		{({ loading, error, data }) => {
			return (
				<>
					{data && <InboxFormViewer detail={data} />}
					{error && error.response && error.response.status === 404}
					{error && <Bug error={error}  />}
					{loading && <VisualFeedback title={'Even geduld'} subTitle={'Je bestand wordt gedownload'} image={PleasWaitImage} delay={500}></VisualFeedback>}
				</>
			)
		}}
	</FetchData>
);

const GetDocumentIdFromRoute = (props) => {
	const { documentId } = props.match.params;
	const main = <AnnotateDocumentView documentId={documentId} />

	return (
		<NotificationManager>
			<SidePanelManager>
				<ContentContainer main={main} />
			</SidePanelManager>
		</NotificationManager>
	)
}

export const Inbox = withRouter(GetDocumentIdFromRoute);