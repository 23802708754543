import React, {Fragment} from 'react';
import './styles/_field.scss';
import cx from 'classnames';
import { connect, getIn } from 'formik';
import { notNull } from '../../00_global/notNull';
import { isInDebug } from '../../00_global/isInDebug';

const debug = isInDebug('Field');
const dev = false;

const FieldElement = ({
	children,
	placeholder = '',
	color = '',
	header = '',
	infoMessage = '',
	id,
	clone = true,
	errorMessage = '',
	onChange = null,
	...props
}) => {

	if (dev) {
		notNull(id, 'id', `[id] prop is required for <Field>`);
   }
   else {
	   !id && console.warn('<Field> is missing a required [id] property!');
   }


	const formikProps = getIn(props.formik);
	const { values, handleChange, handleBlur, touched, errors } = formikProps;

	debug && console.log('touched', touched[id]);
	debug && console.log('error', errors[id]);
		
	const formikError = touched[id] && errors[id]
	const hasError = (errorMessage || formikError);
	const invalid= touched[id] && hasError

	debug && console.log('formikError', formikError);
	debug && console.log('hasError', hasError);
	debug && console.log('isInvalid', invalid);

	notNull(handleChange, 'handleChange');
	
	const fieldStyle = cx(
		'field',
		{'is-invalid': invalid}
	);

	const elementStyle = cx(
		'field__element'
	);

	const labelStyle = cx(
		'field__label',
		color
	);

	
	const infoOrError =  hasError ? formikError || errorMessage : infoMessage;

	const value = values[id];

	const cwp = React.Children.map(children, child => {
		
				const cloned = React.cloneElement(child, {
					onChange: onChange ? onChange : handleChange, // use onChange handler if specified, or default Formik 
					onBlur: handleBlur,
					value,
					placeholder,
					id
					});

					//console.log('cloned', cloned);
					return cloned;
		
		
	  });

	  const elements = clone ? cwp : children;

	return (
		<Fragment>
			<div className={fieldStyle}>
				{(header || infoOrError) &&
					<div className="field__intro">
					<label className={labelStyle} htmlFor="fld3_1">{header}</label>
					<div className="field__note">{infoOrError}</div>
				</div>
				}
				<div className={elementStyle}>
					{elements}
				</div>
			</div>
		</Fragment>
	)
};

export const Field = connect(FieldElement)