
import React from 'react';
import './_invoice-v2.scss';
import { Amount } from '../../02_atoms/amount/Amount';
import { LineTotals } from './models/dtos';

export interface LineTotalsProps {
	totals: LineTotals,
}
export const VatAmount = (props: LineTotalsProps) => {

	return (
		<>
			<Amount value={props.totals.totalVat} />
			<span className={'invoice__lineNote'}>{'btw'}</span>
		</>
	)
}

export const TotalAmount = (props: LineTotalsProps) => {
	return (
		<>
			<Amount value={props.totals.totalGross} />
			<span className={'invoice__lineNote'}>{'totaal incl. BTW'}</span>
		</>
	)
}