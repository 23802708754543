import React from 'react';
import './styles/_inputs.scss';
import cx from 'classnames';
import { notUndefined } from '../../00_global';
import { dev } from '../../00_global/isInDebug';
import { notNull } from '../../06_features/reconciliation';

export const Input = ({id, control=null, type="text", invalid=false, placeholder='', ...props}) => {

	const {value, onChange} = props; // don't destruct in props, as typescript expects them there and they are injected by Formik

	if (dev) {
		 notUndefined(value, 'value', `Input should always receive a value. Check initialValues for ${id}`, true);
	}
	else {
		value === undefined && console.warn('Input should always receive a value. Check initialValues');
	}

	const style = cx('input',
		{'is-invalid': invalid === true},
		{'has-control': control !== null}
	);

	return (
		<div className={style}>
			<div className="input__element">
				<input id={id}
					 type={type}
					 value={value}
					 onChange={onChange}
					 autoComplete={'off'}
					 autoCorrect={'off'}
					 placeholder={placeholder}
					 {...props}
				/>
			</div>
			{control !== null &&
			<div className="input__control">
				{control}
			</div>
			}
		</div>
	)

};

export const TextArea = ({id, control=null, type="text", invalid=false, placeholder='', ...props}) => {

	const {value, onChange} = props;

	const style = cx('input',
		{'is-invalid': invalid === true},
	);

	notNull(id, 'id');
	notUndefined(value, 'value');

	return (
		<div className={style}>
			<textarea id={id} onChange={onChange} value={value} {...props}></textarea>
		</div>
	)

};