import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Label, Paragraph, Heading, Ellipsis, Chain, ControlBar2, Amount, ConfirmDelete  } from '../../';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, LABEL_SIZE, PARAGRAPH_SIZE, PARAGRAPH_SPACING, PARAGRAPH_WEIGHT } from '../../../components/constants';
import { ChainItemData } from '../../03_molecules/chain/helpers/ChainItemData'
import { notNull, FetchData, ShowObject, routeWithSlug } from '../../00_global';
import { dataservice } from '../../00_global/service/dataservice';
import { DateTime } from '../../02_atoms/date/DateTime';
import { Bug } from '../../03_molecules/visualFeedback/VisualFeedback';
import { download, sleep } from '../../helpers';
import { RegisterPayment } from './RegisterPayment';

// placeholder, vervangen door nieuw component (zie Trello)
const DisplayFields = ({ children }) => {
    return children;
}

const DisplayField = ({ label, children }) => {
    return (
        <>
            <Paragraph size={PARAGRAPH_SIZE.large} spacing={PARAGRAPH_SPACING.zero} weight={PARAGRAPH_WEIGHT.bold} narrow={true} color="fc--gray-1">{label}</Paragraph>
            <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.small} color="fc--gray-4">{children}</Paragraph>
        </>
    )
}
const mapLabelTypeToColor = (typeId) => {
    if (typeId === 1)
        return 'success';
    if (typeId === 2)
        return 'warning';
    if (typeId === 3)
        return 'error';

    return 'primary-1';
}

const mapHistory = (history) => {
    const chainData = history.map(h =>
        new ChainItemData.Builder(h)
            .withHeading(h.timestamp ? <DateTime dateValue={h.timestamp} /> : '')
            .withCaption(h.eventName)
            .withIconName(h.iconName).build());
    return chainData;
}

const fetchAndDownload = async (documentId) => {
    const { content, documentName } = await dataservice.getContent(documentId);

    download(content, `${documentName}.pdf`, 'application/pdf');
}

const SearchDocumentDetailElement = ({ documentId, history, onDocumentDeleted, readonly }) => {

    notNull(documentId, 'documentId');
    if (!readonly)
        notNull(onDocumentDeleted, 'onDocumentDeleted');

    const [stateMachine, setStateMachine] = useState(0); // default
    const [fetchCounter, setFetchCounter] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const debug = false;

    const handleDeleteConfirmed = async (documentId) => {

        setIsSubmitting(true);

        debug && console.log('confirmed!', documentId)
        try {
            const response = await dataservice.deleteDocument(documentId);
            debug && console.log('delete response', response);
            sleep(2000); // wait until BE finished processing
            setIsSubmitting(false);
            onDocumentDeleted && onDocumentDeleted();
        }
        catch(err){
            console.error(err)
        }
    }

    const canDelete = true;
    const handleDocumentPaid = () => {
        setStateMachine(0); //default
        setFetchCounter(fetchCounter + 1);
    }

    return (
        <div className='searchDocumentDetail'>
            <FetchData method={() => dataservice.getDocumentDetail(documentId)} triggerValues={[fetchCounter]}>
                {
                    ({ data, error, loading }) => {
                        if (data) {

                            const { counterPartName, documentName, documentId, documentType } = data; //partnerReference
                            const caption = counterPartName || documentName;
                            const inInbox = documentType === 1;
                            const canOpen = true;

                            const documentActions = [
                                { materialIconName: 'save_alt', title:'Download', onClick: () => fetchAndDownload(documentId) }
                            ];

                            if (!readonly) {
                                if (inInbox) {
                                    notNull(documentId, 'documentId');
                                    documentActions.push({ materialIconName: 'inbox', title:'Verwerken in Inbox', onClick: () => history.push(`${routeWithSlug(`inbox/${documentId}`)}`) })
                                }
                                if (!inInbox) {
                                    documentActions.push({ materialIconName: 'payment', title:'Registreer betaling', onClick: () => stateMachine === 20 ? setStateMachine(0) : setStateMachine(20) });
                                }
                                if (canDelete) {
                                    documentActions.push({ materialIconName: 'delete_outline', title:'Verwijder dit document', onClick: () => stateMachine === 10 ? setStateMachine(0) : setStateMachine(10) });
                                }
                            }
                            if (canOpen) {
                                documentActions.push({ materialIconName: 'pageview', title:'Toon PDF', onClick: () => history.push(`${routeWithSlug(`document/${documentId}`)}`) })
                            }

                            const labels = data.labels.map(l => <Fragment key={l.labelValue}>&nbsp;<Label size={LABEL_SIZE.small} text={l.labelValue} color={mapLabelTypeToColor(l.labelTypeEnum)} /></Fragment>);
                            labels.push(<Fragment key={'documentType'}>&nbsp;<Label size={LABEL_SIZE.small} text={data.documentType === 2 ? 'verkoop' : data.documentType === 3 ? 'aankoop' : 'inbox'} /></Fragment>)
                            
                            return (
                                <>
                                    <Box noBorder color="bgc--white">
                                        <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.normal} spacing={HEADING_SPACING.xxs}><Ellipsis text={caption}></Ellipsis></Heading>
                                        <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.xs} color="fc--gray-4">
                                            {labels}
                                        </Paragraph>
                                        <ControlBar2 controls={documentActions} onlyActions />
                                    </Box>
                                    {stateMachine === 0 && <DocumentInfo document={data} />}
                                    {stateMachine === 10 && <ConfirmDelete detail={data}
                                        onConfirmed={() => handleDeleteConfirmed(documentId)}
                                        onCancelled={() => setStateMachine(0)}
                                        isSubmitting={isSubmitting}
                                    />}
                                    {stateMachine === 20 && <RegisterPayment document={data} onDocumentPaid={handleDocumentPaid} />}

                                    {debug && <ShowObject value={data} />}
                                </>)
                        }
                        if (error) {
                            return (<Bug error={error} />)
                        }
                        if (loading) {
                            // return (<div>Even geduld...</div>)
                            // TODO: Create aesthetically pleasing loading component
                            return null;
                        }
                    }
                }
            </FetchData>
        </div>
    )
}

export const SearchDocumentDetail = withRouter(SearchDocumentDetailElement);

export const DocumentInfo = ({ document }) => {

    notNull(document, 'document');

    const { history, paidOn, paymentReference, shortId, tags, totalAmount, vatAmount, dueDate, description } = document; //partnerReference
    const chainData = mapHistory(history)

    return (
        <>
            <Box noBorder color="bgc--gray-10">
                <Heading size={HEADING_SIZE.xs} spacing={HEADING_SPACING.xs} weight={HEADING_WEIGHT.bold} color="fc--primary-1">Document informatie</Heading>

                <DisplayFields>
                    {description && <DisplayField label={'Omschrijving'}>{description}</DisplayField>}
                    {shortId && <DisplayField label={'Trive code'} info={'Referentie code naar dit document'}>{shortId}</DisplayField>}
                    {dueDate && <DisplayField label={'Verval datum'}>{<DateTime dateValue={dueDate} />}</DisplayField>}
                    {paymentReference && <DisplayField label={'Mededeling'}>{paymentReference}</DisplayField>}
                    {paidOn && <DisplayField label={'Betaald op'}>{<DateTime dateValue={paidOn} />}</DisplayField>}
                    {totalAmount && <DisplayField label={'Totaal bedrag (inc. BTW)'}><Amount value={totalAmount} /></DisplayField>}
                    {vatAmount && <DisplayField label={'BTW bedrag'}><Amount value={vatAmount} /></DisplayField>}
                    {tags && tags.length > 0 && <DisplayField label={'Tags'}>
                        {tags.map(t => <Fragment key={t}><Label size={LABEL_SIZE.small} text={t} />&nbsp;</Fragment>)}
                    </DisplayField>}
                </DisplayFields>
            </Box>

            <Box noBorder color="bgc--white">
                <Heading>Historiek</Heading>
                <Chain items={chainData} />
            </Box>
        </>
    )
}