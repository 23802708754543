import React from 'react';
import './styles/_toast.scss';

import { Notification } from "../../";

export const Toast = ({items, onDismiss}) => {

    const notifications = items.map((item, i) => {
        return (
            <div key={`toast-${i}`} className="toast__element is-active is-visible">
                <Notification
                    caption={item.caption}
                    error={item.error}
                    success={item.success}
                    onClose={() => onDismiss(item)}
                />
            </div>
        )
    });

    return (
        <div className="toast">
            <div className="toast__inner">
                {notifications}
            </div>
        </div>)
}

