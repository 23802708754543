import React from 'react';
import cx from 'classnames';
import './styles/_message.scss';

export const Message = ({ type = 'warning', size = '', color = '', compact = false, icon = 'notification_important', orientation = 'horizontal', children, ...props }) => {

    // define classes
    const styles = cx(
        'message',
        { 'type-vertical' : orientation === 'vertical' },
        { 'size-compact' : compact },
        [color]
    );

    // render html
    return (
        <div className={styles}>
            <div className="message__inner">
                <div className="message__visual">
                    <div className="message__icon">
                        <i className="material-icons">{icon}</i>
                    </div>
                </div>
                <div className="message__content">
                    {children}
                </div>
            </div>
        </div>
    );

}