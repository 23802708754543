import React, { useState, useContext } from 'react';
import { Box, BoxHeader, SidePanelContext, Bug } from '../../../index';
import { dataservice } from "../../../00_global/service/dataservice";
import { isInDebug } from '../../../00_global/isInDebug';
import { Formik } from 'formik';
import { Input, Field, FormField, Button } from "../../../";
import * as Yup from "yup";
import { notNull } from '../../../00_global';

const debug = isInDebug('Inbox') && isInDebug('SupplierField');

const formValidationSchema = Yup.object().shape({
    supplierName: Yup.string()
        .required('Verplicht veld!'),
});

export const CreateSupplier = ({ userInput, onSupplierCreated }) => {

    notNull(onSupplierCreated, 'onSupplierCreated');
    notNull(userInput, 'userInput');
    
    const [error, setError] = useState('');
    const ctx = useContext(SidePanelContext);
    debug && console.log(ctx);

    const onSubmit = async (values, actions) => {
        const { supplierName } = values;

        try {
            setError(null);
            const response = await dataservice.createSupplier(supplierName);
            const {supplierId} = response;
            onSupplierCreated && onSupplierCreated(supplierId, supplierName)
            ctx.close();
        }
        catch (err) {
            setError(err);
        }
        finally {
            actions.setSubmitting(false);
        }
    }

    return (
        <Box plain={true} header={<BoxHeader title={'Nieuwe leverancier'} />}>
            <Formik
                initialValues={{ supplierName: userInput }}
                validationSchema={formValidationSchema}
                validateOnChange={true}
                //validateOnBlur={true}
                //validate={handleValidate}
                onSubmit={async (values, actions) => {
                    await onSubmit(values, actions);
                    actions.setSubmitting(false);
                }}>
                {
                    (formikProps) => {
                        const { isSubmitting, handleSubmit } = formikProps;
                        return (
                            <>
                                {!error && <form className="form" onSubmit={handleSubmit}>
                                    <FormField>
                                        <Field id={'supplierName'} infoMessage={'Een naam die voor jou handig is'} header={'Naam'}>
                                            <Input />
                                        </Field>
                                        <div style={{ 'marginTop': '16px' }}><Button type={'submit'} disabled={isSubmitting}>Opslaan</Button></div>
                                    </FormField>
                                </form>}
                                {error && <Bug error={error} />}
                            </>
                        )
                    }
                }
            </Formik>
        </Box>
    )
}