import React, { useState, useContext } from 'react';
import cx from 'classnames';
import { SidePanelManager, FetchData, Control, Button, Paragraph, ErrorBoundary, Grid, useInterval } from '../../index';
import { ContentContainer } from '../../index';
import { Ellipsis, Heading, TableLayout, VisualFeedback, NotificationManager, NotificationContext, SidePanelContext } from "../../../components/index";
import { CONTROL_SIZE, HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_WEIGHT, PARAGRAPH_SIZE, STATUS_COLOR, CONTROL_STATUS, LOADER_SIZE, GENERAL_ERROR } from "../../constants";
import { SortToggle } from '../../01_fundaments/icons';
import PleasWaitImage from '../../06_features/inbox/download-undraw.png';
import { Link } from "react-router-dom";
import { ShowObject, notNull } from '../../00_global';
import { getPortalService } from '../'
import { isInDebug } from '../../00_global/isInDebug';
import { GridRow } from '../../01_fundaments/grid/GridRow';
import { GridColumn } from '../../01_fundaments/grid/GridColumn';
import { CreateAccountPanel } from '../createAccount/CreateAccountPanel';
import { Loader } from '../../02_atoms/loader/Loader';
import { sleep } from '../../helpers';

const debug = isInDebug('dev');

var date_diff_indays = function (date2) {

    try {
        if (date2) {
            const dt1 = new Date();
            const dt2 = new Date(date2);
            const value = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));        
            const message = Math.abs(value) === 0 ? 'vandaag' : `${Math.abs(value)} dagen geleden`;

            return <Paragraph>{message}</Paragraph>
        }
        return 'nog nooit';
    } catch (error) {
        return ''
    }
}

const ListAccountsMain = () => {

    const svc = getPortalService();

    const header = [
        {
            value: 'Naam',
            widths: 'size-medium-28 size-large-28',
            sortable: true
        },
        {
            value: 'Inbox',
            widths: 'size-medium-12 size-large-12',
            sortable: false
        },
        {
            value: 'Aankoop',
            widths: 'size-medium-12 size-large-12',
            sortable: false
        },
        {
            value: 'Verkoop',
            widths: 'size-medium-12 size-large-12',
            sortable: false
        },
        {
            value: 'Divers',
            widths: 'size-medium-12 size-large-12',
            sortable: false
        },
        {
            value: 'Laatste sync',
            widths: 'size-medium-12 size-large-12',
            sortable: true
        },
        {
            value: '',
            widths: 'size-medium-12 size-large-12'
        }
    ]

    const [version, setVersion] = useState(0);
    const [accounts, setAccounts] = useState(null);
    const { showNotificationToast, showErrorToast } = useContext(NotificationContext);
    const { add, close } = useContext(SidePanelContext);

    useInterval(() => {
		setVersion(version + 1);
    }, 10000);
    
    const handleSyncClicked = async (accountId) => {

        notNull(accountId, 'accountId');
        const account = accounts.find(a => a.accountId === accountId);

        notNull(account, 'account');

        console.log(account);

        const { purchaseInvoices, salesInvoices, referenceDocuments } = account;
        const documentIds = purchaseInvoices.documentIds.concat(salesInvoices.documentIds).concat(referenceDocuments.documentIds);

        try {
            
            const response = await svc.portal_startSync(accountId, documentIds);
            const { status, message } = response;
    
            if (status === 1) {
                debug && console.log(message, status);
                showNotificationToast(message);
            }
            else {
                showErrorToast(message)
            }  
            
            sleep(500);
            setVersion(version + 1);
        } catch (error) {
            showErrorToast(error)
        }        
    }

    const handleCreateAccount = async (data) => {

        try {
            const response = await svc.portal_createAccount(data);
            setVersion(version + 1);

            close();

            if (debug) {
                console.log('account created', response);
            }

            const { accountName } = data;
            showNotificationToast(`Dossier ${accountName} is aangemaakt`);

        } catch (error) {
            showErrorToast(GENERAL_ERROR)
        }


    }

    const showCreateAccountPanel = () => {
        const panel = <CreateAccountPanel onSubmit={handleCreateAccount} />
        add(panel, true);
    }

    return (
        <FetchData method={svc.portal_getOverview} triggerValues={[version]}>
            {
                ({ loading, error, data }) => {

                    if (data) {
                        setAccounts(data); // keep data in local state so we can find it
                    }

                    return (
                        <>
                            <ErrorBoundary>
                                {loading && !accounts && <VisualFeedback title={'Even geduld'} subTitle={'De gegevens worden opgehaald'} image={PleasWaitImage} delay={600}></VisualFeedback>}
                                {data &&
                                    <>
                                        <Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.small} color="fc--primary-1" weight={HEADING_WEIGHT.bold}>
                                            Gekoppelde dossiers
                                        </Heading>
                                        <ListAccountsTable header={header} data={data} onSyncClicked={handleSyncClicked} />
                                        <Grid>
                                            <GridRow>
                                                <GridColumn><Button key={'createAccount'} onClick={showCreateAccountPanel}>Nieuw...</Button></GridColumn>
                                            </GridRow>
                                        </Grid>
                                    </>}
                                {error && <ShowObject value={error} />}
                            </ErrorBoundary>
                        </>
                    )
                }
            }
        </FetchData>
    )
}

export const ListAccounts = () => {
    const main = <ListAccountsMain />

    return (
        <NotificationManager>
            <SidePanelManager>
                <ContentContainer
                    main={main}
                />
            </SidePanelManager>
        </NotificationManager>
    )
}

const LinkToQueue = ({ to, children, disabled }) => {
    if (disabled) {
        return <>{children}</>;
    }
    else {
        return (
            <Link to={to} className="reset-default">
                {children}
            </Link>
        )

    }

}

const ListAccountsTable = ({ header, data, onSyncClicked }) => (
    <TableLayout>
        <div className="table">
            <div className="table__inner">
                <div className="table__head">
                    <ListAccountsHead data={header} />
                </div>

                <div className="table__body">
                    <ListAccountsBody data={data} head={header} onSyncClicked={onSyncClicked} />
                </div>
            </div>
        </div>
    </TableLayout>
)

const ListAccountsHead = ({ data }) => (
    <div className="table__row">
        {data.map((item, key) => {

            const headColStyle = cx(
                'table__cell',
                item.widths,
                { 'is-sortable': item.sortable }
            );

            return (
                <div className={headColStyle} key={key}>
                    <Ellipsis text={item.value} />
                    {item.sortable &&
                        <SortToggle />
                    }
                </div>
            )
        })}
    </div>
);



const ListAccountsBody = ({ data, head, onSyncClicked }) => {

    // data contains all connected accounts
    const getInboxColor = (counter) => {
        return (counter === 0)
            ? STATUS_COLOR.disabled
            : STATUS_COLOR.default;
    }

    const getStatusColor = (counter) => {
        return (counter === 0)
            ? STATUS_COLOR.disabled
            : STATUS_COLOR.success;
    }

    const handleLoginAsAccountant = (accountId) => {

        notNull(accountId, 'accountId');

        const url = `/portal/account/${accountId}/sso`
        window.open(url, '_blank');
    }

    const actionStyle = {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'flex-end',
        'flexWrap': 'wrap'
    }

    if (!data)
        return (<div>no data</div>)

    return (
        <>
            {data.map((dto) => (
                <div className="table__row" key={dto.accountId}>
                    <div className={`table__cell ${head[0].widths}`}>
                        <div>
                            <Paragraph weight={PARAGRAPH_WEIGHT.bold} color="fc--primary-1" narrow={true}>{dto.accountName}</Paragraph>
                            <Paragraph size={PARAGRAPH_SIZE.xs}>{dto.primaryContact}</Paragraph>
                        </div>

                    </div>
                    <div className={`table__cell size-medium-12 size-large-12`}>
                        <LinkToQueue to={`/portal/account/${dto.accountId}/queue/1`} disabled={dto.itemsInInbox.counter === 0}>
                            <Control size={CONTROL_SIZE.large} color={getInboxColor(dto.itemsInInbox.counter)}>{dto.itemsInInbox.counter}</Control>
                        </LinkToQueue>
                    </div>
                    <div className={`table__cell size-medium-12 size-large-12`}>
                        <LinkToQueue to={`/portal/account/${dto.accountId}/queue/3`} disabled={dto.purchaseInvoices.counter === 0}>
                            <Control size={CONTROL_SIZE.large} color={getStatusColor(dto.purchaseInvoices.counter)}>{dto.purchaseInvoices.counter}</Control>
                        </LinkToQueue>
                    </div>
                    <div className={`table__cell size-medium-12 size-large-12`}>
                        <LinkToQueue to={`/portal/account/${dto.accountId}/queue/2`} disabled={dto.salesInvoices.counter === 0}>
                            <Control size={CONTROL_SIZE.large} color={getStatusColor(dto.salesInvoices.counter)}>{dto.salesInvoices.counter}</Control>
                        </LinkToQueue>
                    </div>
                    <div className={`table__cell size-medium-12 size-large-12`}>
                        <LinkToQueue to={`/portal/account/${dto.accountId}/queue/4`} disabled={dto.referenceDocuments.counter === 0}>
                            <Control size={CONTROL_SIZE.large} color={getStatusColor(dto.referenceDocuments.counter)}>{dto.referenceDocuments.counter}</Control>
                        </LinkToQueue>
                    </div>
                    <div className={`table__cell size-medium-12 size-large-12`}>
                        <Paragraph>{date_diff_indays(dto.lastSyncedOn)}</Paragraph>
                    </div>

                    <div className={`table__cell ${head[6].widths}`}>
                        <div style={actionStyle}>
                            <Control isPlain={true} size={CONTROL_SIZE.small} status={!dto.syncEnabled && CONTROL_STATUS.error}>
                                <Link to={`/portal/account/${dto.accountId}/settings`} className="reset-default"> <i className="material-icons">settings</i></Link>
                            </Control>

                            {dto.syncInProgress &&
                                <Control isPlain={true} size={CONTROL_SIZE.small}>
                                    <Loader size={LOADER_SIZE.xsmall} />
                                </Control>
                            }

                            {!dto.syncInProgress && <Control isPlain={true} size={CONTROL_SIZE.small} onClick={() => onSyncClicked(dto.accountId)} disabled={!dto.syncEnabled}  >
                                <i className="material-icons">cloud_download</i>
                            </Control>}
                            <Control isPlain={true} size={CONTROL_SIZE.small} onClick={() => handleLoginAsAccountant(dto.accountId)}>
                                <i className="material-icons">open_in_new</i>
                            </Control>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}