import React, { Fragment, useState } from 'react';
import { ControlBar, ShowObject } from '../../../index';
import PdfViewer from "./PdfViewer";
import { download } from "../../../helpers";
import { notNull } from '../../reconciliation';
import { routeWithSlug } from '../../../00_global';
import './_InboxDocumentViewer.scss';

export const InboxDocumentViewer = ({ title = '', documentId, content, onDelete }) => {

    notNull(documentId, 'documentId');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const onPDFLoaded = (totalPages) => {
        setTotalPages(totalPages);
    };

    const onPreviousPage = () => {

        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const onNextPage = () => {

        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onDownload = () => {
        download(content, title, 'application/pdf');
    };

    const onOpen = () => {
        const url = `${routeWithSlug(`document/${documentId}`)}`
        window.open(url, '_blank');

    };

    const debug = false;
    const pdfTitle = documentId;

    return (
        <Fragment>
            <div className="inboxDocumentViewer">
                <div className="inboxDocumentViewer__inner">
                    <div className="inboxDocumentViewer__controlbar">
                        <ControlBar
                            title={pdfTitle}
                            spacing={true}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePrevious={onPreviousPage}
                            handleNext={onNextPage}
                            handleSave={onDownload}
                            handleOpen={onOpen}
                            handleDelete={onDelete}
                        />
                    </div>
                    <div className="inboxDocumentViewer__main">
                        {debug && <ShowObject value={{title, currentPage }} />}
                        {!debug && <PdfViewer content={content} page={currentPage} fillWidth fillHeight scale={1} onDocumentComplete={onPDFLoaded} />}
                    </div>
                </div>
            </div>
        </Fragment>
    )

}