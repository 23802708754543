import React, { useState } from 'react';
import { FetchData, ShowObject, Box, Heading, Ellipsis, FormField, Button, FetchDataState } from '../..';
import { CustomerDetailDto } from '../invoice_module_v1/models/api';
import { productService, getListResponse } from '../invoice_module_v1/invoiceService';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, COLOR, BUTTON_SIZE } from '../../constants';
import { Formik, FormikProps } from 'formik';
import { SearchItem } from '../../03_molecules/search/SearchV2';
import { Dropdown } from '../invoice_module_v1/Dropdown';
import { isInDebug } from '../../00_global/isInDebug';
import { CustomerDetails } from '../invoice_module_v1';

interface SelectCustomerProps {
    onPartySelected(customer: CustomerDetailDto): void,
    onCreatePartyClicked(): void
}

export const SelectCustomer = (props: SelectCustomerProps) => {
    // remark: context not available, not created/rendered in a context!
    const { onPartySelected, onCreatePartyClicked } = props;

    const initialValues = {
        customerId: '',
        customer: ''
    }

    const onSubmit = () => {
        if (selectedCustomer) {
            onPartySelected(selectedCustomer);
        }
    }

    const debugInfo = isInDebug('InvoiceModule');

    const [selectedCustomer, setSelectedCustomer] = useState<CustomerDetailDto | null>(null);

    const mapCustomers = (customers: CustomerDetailDto[]): SearchItem[] => {

        if (!customers)
            return [];

        const mapped = customers.map(p => {
            const m = {
                id: p.id || '',
                caption: p.party.business.name,
                value: p.id,
                note: p.party.business.taxIdentificationNumber,
            }
            return m;
        });

        return mapped;
    }

    const loadCustomerDetail = async (partyId: string) => {
        const details: CustomerDetailDto = (await productService.getCustomerDetail(partyId)).item;
        if (details !== undefined) {
            setSelectedCustomer(details);
        }
    }



    const createNewCustomer = () => {
        if (onCreatePartyClicked) {
            console.log('yes')
            onCreatePartyClicked();
        }
        else {
            console.log('no')
        }
    }


    return (
        <>
            <FetchData method={productService.getCustomersAsync}>
                {
                    ({ data }: FetchDataState<getListResponse<CustomerDetailDto>>) => {
                        if (data) {
                            const customers = data.items

                            return (
                                <Box id={'select_customer'} noBorder color="bgc--white">
                                    <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.default} spacing={HEADING_SPACING.small}>
                                        <Ellipsis>{'Kies klant'}</Ellipsis>
                                    </Heading>

                                    <Formik initialValues={initialValues}
                                        validationSchema={undefined}
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        // validate={handleValidate}
                                        onSubmit={() => {
                                            onSubmit();
                                        }}>
                                        {
                                            (formikProps: FormikProps<any>) => {
                                                const { handleSubmit, errors, values, setFieldValue, isValid, isSubmitting } = formikProps

                                                const onCustomerSelected = (item: SearchItem) => {

                                                    setFieldValue('customerId', item.id);
                                                    setFieldValue('customer', item.caption);

                                                    loadCustomerDetail(item.id);
                                                }

                                                const _customers = mapCustomers(data.items);

                                                return (
                                                    <form className="form" onSubmit={handleSubmit}>
                                                        <Dropdown header={''} id={'customer'} results={_customers} onSearch={() => { }} onItemSelected={onCustomerSelected} key={'customerSearch'} formik={formikProps} blockInput={false} />

                                                        {selectedCustomer &&
                                                            <FormField>
                                                                <CustomerDetails inSidebar={true} party={selectedCustomer.party} validation={selectedCustomer.validation} />
                                                            </FormField>
                                                        }

                                                        <FormField>
                                                            <Button type={'submit'} size={BUTTON_SIZE.full} color={COLOR.secondary_2} disabled={!isValid || isSubmitting}>
                                                                Opslaan
                                                            </Button>
                                                        </FormField>

                                                        <FormField smallGap>
                                                            <Button type={'submit'} size={BUTTON_SIZE.full} inverted onClick={() => { createNewCustomer(); }}>
                                                                Beheer klanten
                                                            </Button>
                                                        </FormField>
                                                        {debugInfo && <ShowObject value={{ isValid, isSubmitting, customers: customers, values, errors, selectedCustomer: selectedCustomer }} />}
                                                    </form>
                                                )
                                            }
                                        }
                                    </Formik>
                                </Box>
                            )
                        }
                    }
                }
            </FetchData>
        </>
    )
}