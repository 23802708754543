import { modulesInDebug } from './debugInfo'

export const dev = () => {
    return process.env.NODE_ENV === 'development';
}

export const isInDebug = (module) => {
    const isDev = process.env.NODE_ENV === 'development';
    const moduleFound = modulesInDebug.indexOf(module) > -1;
    return isDev && moduleFound;
}