import React from 'react'
import * as moment from 'moment'

export const DateTime = ({ dateValue , format='DD/MM/YYYY'}) => {
    const momentValue = moment(dateValue);
    const displayValue = momentValue.isValid() ? momentValue.format(format): `${dateValue}` ;
  
    return (
        <>
            {dateValue ? <span>{displayValue}</span> : null}
        </>
    )
}

export const FromNow = ({ dateValue = ''}) => {
    const momentValue = moment(dateValue);
    const displayValue = momentValue.isValid() ? momentValue.fromNow(): `${dateValue}` ;
    return (
        <span>{displayValue}</span>
    )
}