import React from 'react';
import './styles/_boxedSearchResultItem.scss';
import cx from 'classnames';

export const BoxedSearchResultItem = ({ children, id, border = false, hover = true, isActive = false, onClick = () => null }) => {

	const style = cx(
		'boxedSearchResultItem',
		{ 'has-border': border === true },
		{ 'can-hover': hover === true },
		{ 'is-active': isActive === true }
	);

	return (
		<div className={style} onClick={() => onClick(id)}>
			{children &&
				<div className="boxedSearchResultItem__inner">{children}</div>}
		</div>
	)
};

