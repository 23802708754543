// debugInfo allow to use console.log or debug components (like ShowObject) without risking to leak something to production

export const modules = {
    SEARCH: 'Search',
    UPLOAD: 'upload',
    DATASERVICE: 'dataservice',
    GENERAL: 'dev',
    INVOICE_SERVICE: 'invoiceService',
    TRACKER: 'tracker',
}

const allModules = [
//'FetchData',
//'ExportDocuments',
//'Login',
//'Inbox',
modules.SEARCH,
//'Field',
'Upload',
'Button',
'ft_Reconciliation',
'ft_Invoicing',
'Dropdown',
//'App',
//'Dashboard'
//'CreateSupplier',
modules.GENERAL
]

const muteAllDebug = false;

export const modulesInDebug = muteAllDebug ? [] : allModules;
