import React, {Fragment} from 'react';
import './_search.scss';
import { notNull } from '../../00_global/notNull';

// container component with render function to expose properties
export const SearchResultContainer = ({ children, onSearchResultClicked, isHighlighted }) => {
    
    notNull(children, 'children')
    notNull(onSearchResultClicked, 'onSearchResultClicked');

    return (
        <Fragment>
            {children({ handleClick: onSearchResultClicked, isHighlighted })}
        </Fragment>
    )
}