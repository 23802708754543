import { dataservice } from '../00_global/service/dataservice';

export { AddAccount }  from './settings/AddAccount';
export { ListAccounts } from './overview/ListAccounts';
export { UpdateAccount } from './settings/UpdateAccount';
export { AccountantInbox } from './inbox/AccountantInbox';
export { SingleSignOn } from './sso/SingleSignOn';

export const getPortalService = () => {
    return dataservice
}