import React from 'react';
import './_invoice-v2.scss';
import { Paragraph } from '../../index';
import {  PARAGRAPH_SPACING, HEADING_SIZE, HEADING_SPACING, HEADING_WEIGHT } from "../../constants";
import ReactMarkDown from 'react-markdown';
import { Heading } from '../../02_atoms/headings/Heading';

export const InvoiceRemarks = ({ remarks }) => {

	return (
		<div className="invoice__intro">
			<div className="row">
				<div className="col-md-12">
					<Heading size={HEADING_SIZE.small} spacing={HEADING_SPACING.small} color="fc--primary-1" weight={HEADING_WEIGHT.bold}>
						Opmerkingen
					</Heading>
					<Paragraph spacing={PARAGRAPH_SPACING.zero}>
						<ReactMarkDown source={remarks}></ReactMarkDown>
					</Paragraph>
				</div>
			</div>
		</div>)
}