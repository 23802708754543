import * as Yup from "yup";

export const ERROR_REQUIRED_FIELD = 'Verplicht veld!';
export const ERROR_INVALID_NUMBER = 'Dit lijkt geen geldig getal';

export const fields = {
    productId: 'productId',
    product: 'product',
    vatPct: 'vatPct',
    vatCaption: 'vatCaption',
    unitId: 'unitId',
    unit: 'unit',
    description: 'description',
    quantity: 'quantity',
    unitPrice: 'unitPrice'
}

export const addInvoiceLineValidation = Yup.object().shape({
    [fields.productId]: Yup.string().required(ERROR_REQUIRED_FIELD),
    [fields.product]: Yup.string().required(ERROR_REQUIRED_FIELD),
    [fields.quantity]: Yup.number().required(ERROR_REQUIRED_FIELD).typeError(ERROR_INVALID_NUMBER),
    [fields.unit]: Yup.string().required(ERROR_REQUIRED_FIELD),
    [fields.unitPrice]: Yup.number().required(ERROR_REQUIRED_FIELD).typeError(ERROR_INVALID_NUMBER),
    [fields.vatPct]: Yup.string().required(ERROR_REQUIRED_FIELD)
});