import React from 'react';
import './styles/_wrapper.scss';
import cx from "classnames";
import {Header, Footer} from "../../";
import { dataservice as fallback } from '../../00_global/service/dataservice';

export const Wrapper = ({children, dataservice, header=true, footer=true, compact=false}) => {

	const style = cx(
		'wrapper',
		{'is-compact': compact === true},
		{'has-header': header === true},
		{'has-footer': footer === true}
	);

	if (!dataservice)
	{
		dataservice = fallback;
		//notNull(dataservice, 'dataservice', 'Inject a dataservice into Wrapper');
	}
	
	return (
		<div className={style}>
			<div className="wrapper__inner">
				{header && 
					<div className="wrapper__header section">
						<Header dataservice={dataservice} />
					</div>
				}

				<div className="wrapper__content content">
					{children}
				</div>

				{footer &&
					<div className="wrapper__footer footer section">
						<Footer />
					</div>
				}
			</div>
		</div>
	)
};