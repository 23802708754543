import React, { Fragment, useState, useEffect } from 'react';
import './styles/_visualFeedback.scss';
import cx from "classnames";
import { BUTTON_SIZE } from "../../constants";
import { Button } from "../../";
import inboxEmpty from './graphics/undraw_enjoy.png';
import image2 from './graphics/empty.png';
import image3 from './graphics/empty2.png';
import bugImage from './graphics/undraw_bug_fixing_oc7a.png';
import noDataImage1 from './graphics/no_data_1.png';
import noDataImage2 from './graphics/no_data_2.png';
import message_send from './graphics/message_send.png';
import { routeWithSlug, FetchData } from '../../00_global/';
import { NavLink } from "react-router-dom";
import { isInDebug } from '../../00_global/isInDebug';
import { dataservice } from '../../00_global/service/dataservice';

export const InboxEmpty = () => {

	return (
		<FetchData method={dataservice.getUserProfile}>
			{
				({ data }) => {
					if (data) {
						const { mailboxAddress } = data;
						return (
						<VisualFeedback
						image={inboxEmpty}
						title='Je inbox is leeg'
						subTitle={<Fragment>Om documenten toe te voegen kan je .pdf bestanden uploaden of mailen naar je persoonlijk mailadres op Trive: <a href={`mailto:${mailboxAddress}`}>{mailboxAddress}</a></Fragment>}
						>
							<NavLink to={routeWithSlug('upload')}><Button>Uploaden</Button></NavLink>
						</VisualFeedback>)
					}
				}
			}
		</FetchData>

	)
}

export const ArchiveEmpty = () => {

	return (
		<VisualFeedback
			image={noDataImage2}
			title='Er zijn geen recente archieven beschikbaar.'
			subTitle={<Fragment>Je kan documenten exporteren om ze te delen met medewerkers of je accountant</Fragment>}
		>

		</VisualFeedback>
	)
}

export const Bug = ({ error, onResetError = null }) => {
	const debug = isInDebug('App');

	if (debug)
		throw error;

	return (
		<VisualFeedback
			image={bugImage}
			title='Oeps... er gaat iets mis'
			subTitle={<Fragment>{error && <div>technische info: {error.message}</div>}</Fragment>}
			ctaButton={onResetError && <Button size={BUTTON_SIZE.medium} rounded={false} inverted={true} onClick={onResetError}>Opnieuw proberen</Button>}
		>
			<a href='https://public.trive.io/rapporteer-een-probleem' target='_blank' rel="noopener noreferrer">Rapporteer een probleem</a>
		</VisualFeedback>
	)
}

export const NoData1 = ({ ctaButton, title, subTitle }) => {
	return (
		<VisualFeedback
			image={noDataImage1}
			title={title}
			subTitle={subTitle}
			ctaButton={ctaButton}
		>
		</VisualFeedback>
	)
}

export const NoData2 = ({ title, subTitle }) => {

	const cta = (
		<NavLink to={routeWithSlug('upload')} activeClassName={`is-active`}>
			<Button size={BUTTON_SIZE.medium} rounded={false} inverted={true}>Bestanden doorsturen...</Button>
		</NavLink>
	)

	return (
		<VisualFeedback
			image={noDataImage2}
			title={title}
			subTitle={subTitle}
			ctaButton={cta}
		>
		</VisualFeedback>
	)
}

export const NotFound = ({ error, onNavigateBack }) => {

	return (
		<VisualFeedback
			image={bugImage}
			title='Deze pagina is niet gevonden'
			plain={true}
			subTitle={<Fragment>{error && <div>technische info: {error.message}</div>}</Fragment>}
			ctaButton={onNavigateBack && <Button size={BUTTON_SIZE.medium} rounded={false} inverted={true} onClick={onNavigateBack}>Vorige</Button>}
		>
			<div><a href='https://public.trive.io/rapporteer-een-probleem' target='_blank' rel="noopener noreferrer">Rapporteer een probleem</a></div>
		</VisualFeedback>
	)
}

export const MessageSend = ({title, subTitle, ctaButton}) => {
	return <VisualFeedback title={title} image={message_send} subTitle={subTitle} ctaButton={ctaButton} />
}

export const WorkInProgress = () => {

	return (
		<VisualFeedback
			image={image2}
			plain={true}
			title='Under construction'
			subTitle={<Fragment>Hier zijn we nog even mee bezig...</Fragment>}
		>

		</VisualFeedback>
	)

}

export const VisualFeedback = ({ image, title = '', subTitle, horizontal = false, imagePos = false, ctaButton, plain = false, children = null, delay = 0 }) => {

	//const [timerInstance, setTimerInstance] = useState();
	const [isDelayed, setDelayed] = useState(false);

	useEffect(() => {
		let didCancel = false;
		if (delay) {
			!didCancel && setDelayed(true);
			setTimeout(() => {
				!didCancel && setDelayed(false);
			}, delay)
		}

		return () => {
			didCancel = true; // cleanup function to prevent state updates if the component doesn't need to render (default case)
		};

	}, [])

	const style = cx(
		'visualFeedback',
		{ 'is-plain': plain === true },
		{ 'type-horizontal' : horizontal === true },
		{ 'type-image-right' : horizontal === true && imagePos === 'right' }
	);

	return (
		!isDelayed && <div className={style}>
			<div className="visualFeedback__inner">
				<div className="visualFeedback__visual">
					<img src={image} alt='' />
				</div>
				<div className="visualFeedback__content">
					<h1 className="visualFeedback__title">{title}</h1>
					<div>{subTitle}</div>
					{ctaButton && <div className="visualFeedback__ctas">
						{/* <div className="visualFeedback__cta">
						<Button size={BUTTON_SIZE.medium} rounded={false} inverted={true}>Export lists</Button>
					</div>
					<div className="visualFeedback__cta">
						<Button size={BUTTON_SIZE.medium} rounded={false} inverted={true}>Update profile</Button>
					</div> */}
						<div className="visualFeedback__cta">
							{ctaButton}
						</div>
					</div>}
					{children}
				</div>
			</div>
		</div>
	)
};

export const VisualFeedbackStorybook = ({ variant = "1", horizontal = false, imagePos = false }) => {

	const style = cx(
		'visualFeedback',
		{ 'type-horizontal' : horizontal === true },
		{ 'type-image-right' : horizontal === true && imagePos === 'right' }
	);

	return (
		<div className={style}>
			<div className="visualFeedback__inner">
				<div className="visualFeedback__visual">
					{variant === '1' &&
						<img src={inboxEmpty} alt={'1'} />
					}
					{variant === '2' &&
						<img src={image2} alt={'2'} />
					}
					{variant === '3' &&
						<img src={image3} alt={'3'} />
					}
				</div>
				<div className="visualFeedback__content">
					<h1 className="visualFeedback__title">You have no exports</h1>
					<p>To export a list or a segment of a list, refer to <a href="/fake">our knowledge base article.</a></p>
					<div className="visualFeedback__ctas">
						<div className="visualFeedback__cta">
							<Button size={BUTTON_SIZE.large} rounded={true} inverted={true}>Export lists</Button>
						</div>
						<div className="visualFeedback__cta">
							<Button size={BUTTON_SIZE.large} rounded={true} inverted={true}>Update profile</Button>
						</div>
						<div className="visualFeedback__cta">
							<Button size={BUTTON_SIZE.large} rounded={true} inverted={true}>Knowledge base</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};