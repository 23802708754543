import React from 'react';
// import PdfJsLib from 'pdfjs-dist';
import { makeCancelable, binaryToBase64, base64ToArrayBuffer } from "../../../helpers";

const PdfJsLib = window.pdfjsLib;

// TODO enable web worker when CRA supports loading web workers: https://github.com/facebook/create-react-app/issues/3660
// PdfJsLib.GlobalWorkerOptions.workerSrc = 'https://dvj00bwq6p6ga.cloudfront.net/pdfjs-dist@2.0.943/pdf.worker.min.js';

const calculateScale = (scale, fillWidth, fillHeight, view, parentElement) => {
    if (fillWidth) {
        const pageWidth = view[2] - view[0];
        return parentElement.clientWidth / pageWidth;
    }
    if (fillHeight) {
        const pageHeight = view[3] - view[1];
        return parentElement.clientHeight / pageHeight;
    }
    return scale;
};
const defaultBinaryToBase64 = binaryToBase64;

class PdfViewer extends React.Component {
    documentPromise;

    constructor(props) {
        super(props);

        this.state = {
            pdf: null,
            page: props.page,
            loading: true,
        };

        this.loadPDFDocument(props);
    }

    componentDidMount() {
        window.addEventListener('resize', this.renderPdf);
        window.addEventListener('orientationchange', this.renderPdf);
    }

    componentWillReceiveProps(nextProps) {
        const { pdf, page } = this.state;

        if (nextProps.page !== page) {
            this.setState({
                page: nextProps.page,
            }, () => {
                pdf.getPage(nextProps.page).then(this.onPageComplete);
            });
        }
    }

    componentWillUnmount() {
        const { pdf } = this.state;
        if (pdf) {
            pdf.destroy();
        }
        if (this.documentPromise) {
            this.documentPromise.cancel();
        }

        window.removeEventListener('resize', this.renderPdf);
        window.removeEventListener('orientationchange', this.renderPdf);
    }

    getDocument = (val) => {
        if (this.documentPromise) {
            this.documentPromise.cancel();
        }

        this.documentPromise = makeCancelable(PdfJsLib.getDocument(val).promise);
        this.documentPromise.promise
            .then(this.onDocumentComplete)
            .catch(this.onDocumentError);

        return this.documentPromise;
    };

    onDocumentComplete = (pdf) => {
        this.setState({ pdf });

        const { onDocumentComplete, onContentAvailable, onBinaryContentAvailable } = this.props;

        if (typeof onDocumentComplete === 'function') {
            onDocumentComplete(pdf.numPages);
        }
        if (typeof onContentAvailable === 'function' || typeof onBinaryContentAvailable === 'function') {
            pdf.getData().then(this.onGetPdfRaw);
        }

        pdf.getPage(this.state.page).then(this.onPageComplete);
    };

    onDocumentError = (err) => {
        if (err.isCanceled && err.pdf) {
            err.pdf.destroy();
        }
        if (typeof this.props.onDocumentError === 'function') {
            this.props.onDocumentError(err);
        }
    };

    onGetPdfRaw = (pdfRaw) => {
        const { onContentAvailable, onBinaryContentAvailable, binaryToBase64 } = this.props;
        if (typeof onBinaryContentAvailable === 'function') {
            onBinaryContentAvailable(pdfRaw);
        }
        if (typeof onContentAvailable === 'function') {
            let convertBinaryToBase64 = defaultBinaryToBase64;
            if (typeof binaryToBase64 === 'function') {
                convertBinaryToBase64 = binaryToBase64;
            }
            onContentAvailable(convertBinaryToBase64(pdfRaw));
        }
    };

    onPageComplete = (page) => {
        this.setState({ page, loading: false });

        this.renderPdf();

        const { onPageComplete } = this.props;

        if (typeof onPageComplete === 'function') {
            onPageComplete(page.pageIndex + 1);
        }
    };

    loadByteArray = (result) => {
        this.getDocument(result);
    };

    loadPDFDocument = ({ file, binaryContent, content, documentInitParameters }) => {
        if (file) {
            if (typeof file === 'string') {
                // url
                return this.getDocument(file);
            }

            // document
            const reader = new FileReader();
            reader.onloadend = () => this.loadByteArray(new Uint8Array(reader.result));
            reader.readAsArrayBuffer(file);
        } else if (binaryContent) {
            this.loadByteArray(binaryContent);
        } else if (content) {
            const byteArray = base64ToArrayBuffer(content);
            this.loadByteArray(byteArray);
        } else if (documentInitParameters) {
            this.getDocument(documentInitParameters);
        } else {
            throw new Error('react-pdf-js works with a file(URL) or (base64)content. At least one needs to be provided!');
        }
    };

    renderPdf = () => {
        const { page } = this.state;

        if (!page) { return; }

        const {
            fillWidth,
            fillHeight,
            rotate,
            scale: pScale,
            className,
            style,
        } = this.props;

        // We need to create a new canvas every time in order to avoid concurrent rendering
        // in the same canvas, which can lead to distorted or upside-down views.
        const canvas = document.createElement('canvas');
        canvas.style = style;
        canvas.className = className;

        // Replace or add the new canvas to the placehloder div set up in the render method.
        const parentElement = this.canvasParent;
        if (!parentElement) { return; }
        const previousCanvas = parentElement.firstChild;
        if (previousCanvas) {
            parentElement.replaceChild(canvas, previousCanvas);
        } else {
            parentElement.appendChild(canvas);
        }

        const canvasContext = canvas.getContext('2d');
        const dpiScale = window.devicePixelRatio || 1;
        const scale = calculateScale(pScale, fillWidth, fillHeight, page.view, parentElement);
        const adjustedScale = scale * dpiScale;
        const viewport = page.getViewport(adjustedScale, rotate);
        canvas.style.width = `${viewport.width / dpiScale}px`;
        canvas.style.height = `${viewport.height / dpiScale}px`;
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render({ canvasContext, viewport });

        page.getTextContent().then((textContent) => {
            //console.log(textContent);
        });
    };

    render() {
        const { page, loading } = this.state;
        return page ?
            <div ref={(parentDiv) => { this.canvasParent = parentDiv; }} />
            :
            loading || <div>PDF wordt geladen...</div>;
    }
}

export default PdfViewer;