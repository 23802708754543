import React from 'react';
import './styles/_control.scss';
import cx from "classnames";

import { CONTROL_MODE, CONTROL_SIZE, CONTROL_COLOR, CONTROL_STATUS } from "../../constants"; //CONTROL_ICONSIZE (no unused vars allow for production builds)

export const Control = ({children = null, isPlain = false, onClick = null, size = CONTROL_SIZE.default, color = CONTROL_COLOR.default, count = -1, status = CONTROL_STATUS.default, skipTab = false, iconSize = false, isGhost = false, disabled = false}) => {

    const style = cx('control',
        {[CONTROL_MODE.isPlain]: isPlain === true},
        [size],
        {[status]: status !== ''},
        {[color]: color !== ''},
        {[iconSize]: iconSize !== false},
        {'type-ghost': isGhost !== false},
        {'is-disabled': disabled }
    );

    return (
        <button type='button' disabled={skipTab} className={style} onClick={disabled ? null : onClick} tabIndex={skipTab ? -1 : undefined}>
        	<span className="control__content">
                {children}
            </span>
        	<span className="control__status"></span>
            {count > 0 && <span className="control__count">{ count }</span>}
        </button>
    )
};