
import React, { useState } from 'react';
import { ShowSlug } from '../../content/ShowContent';
import { ContentContainer, SidePanelManager, FetchData, FetchDataState, isInDebug } from '../../..';
import { productService, invoiceApiResponse, getDetailResponse } from '../invoiceService';
import { Redirect, NavLink, Link } from 'react-router-dom';
import { Paragraph } from '../../../02_atoms/paragraph/Paragraph';
import { Bug } from '../../../03_molecules/visualFeedback/VisualFeedback';
import { InvoiceSettingsDto } from '../models/api';
import { routeWithSlug } from '../../../00_global';
import { routes } from '../../../../SluggedPrivateRoutes';

const Content = () => {
    return (<ShowSlug slug={'20200630_invoice_module'} />)
}

const InvoiceModuleNotActivated = () => {
    return (<ul>
        <li>
            <Link to={'/invoices/onboarding'}>Activeer deze module...</Link>
        </li>
    </ul>)
}

export const InvoiceModuleHeader = () => {

    const [invoiceModuleEnabled, setinvoiceModuleEnabled] = useState(-1)

    const checkStatus = async () => {
        const response = await productService.getInvoiceModuleSettingsAsync();
        if (response.status === 0 && response.item) {
            console.log('enabled', response.item.invoiceModuleEnabled);
            setinvoiceModuleEnabled(response.item.invoiceModuleEnabled ? 1 : 0);
        }
    }

    return (
        <li>
            {<NavLink to={invoiceModuleEnabled === 1 ? routeWithSlug('invoices/overview') : routeWithSlug('invoices/onboarding')} activeClassName={`is-active`}>
                <span>Facturen</span>
                <i className="material-icons">expand_more</i>
                {/* <span className="header__superscript">nieuw!</span> */}
            </NavLink>}
            {invoiceModuleEnabled === 1 &&
                <ul>
                    {isInDebug('dev') &&
                        <li>
                            <Link to={'/invoices/create'}>Nieuwe factuur</Link>
                        </li>
                    }
                    <li>
                        <Link to={routes.invoices.invoiceOverview}>Overzicht facturen</Link>
                    </li>
                    <li>
                        <Link to={routes.invoices.templateOverview}>Instellingen</Link>
                    </li>

                    <li className="divider"></li>
                    <li>
                        <Link to={'/customers/overview'}>Overzicht klanten</Link>
                    </li>
                    {isInDebug('products') &&
                        <li>
                            <Link to={'/products/overview'}>Overzicht producten</Link>
                        </li>
                    }
                </ul>
            }
            {(invoiceModuleEnabled === -1) &&
                <FetchData method={() => checkStatus}>
                    {
                        ({ data, loading, error }: FetchDataState<getDetailResponse<InvoiceSettingsDto>>) => {

                            if (error) {
                                return <Bug error={error} />
                            }

                        }
                    }
                </FetchData>
            }
            {(invoiceModuleEnabled === 0) &&
                <InvoiceModuleNotActivated />
            }
        </li>
    )
}
export const ModuleActivated = () => {

    const [redirect, setRedirect] = useState(false);

    const reload = () => {
        const shouldReload = sessionStorage.getItem('reload');
        if (shouldReload) {
            sessionStorage.removeItem('reload');
            window.location.reload();
        }
        else {
            setRedirect(true);
        }
    }

    return (
        <>
            <FetchData method={reload}>
                {
                    () => {
                        if (redirect)
                            return <Redirect to={'/invoices/templates/overview'} />
                        else {
                            return <></>
                        }
                    }
                }
            </FetchData>
        </>
    )
}


export const ActivateInvoiceModule = () => {

    const [isActivated, setIsActivated] = useState(false);


    const activate = async () => {
        const result = await productService.activateInvoiceModuleAsync();
        if (result.status === 0) {
            sessionStorage.setItem('reload', "now");
            setIsActivated(true);
        }
    }

    return (

        <SidePanelManager >
            <ContentContainer main={
                <FetchData method={activate}>
                    {
                        ({ data, loading, error }: FetchDataState<invoiceApiResponse>) => {

                            return (
                                <>
                                    {isActivated ? <Redirect to={'/invoices/activated'} /> :
                                        error ? <Bug error={error}></Bug> : <Paragraph>Even geduld...</Paragraph>
                                    }
                                </>
                            )
                        }
                    }
                </FetchData>
            } />
        </SidePanelManager>

    )
}

export const EnableInvoiceModule = () => {

    return (
        <>
            <SidePanelManager >
                <ContentContainer main={<Content />} />
            </SidePanelManager>
        </>
    )

}
