import React from 'react';
import { Field, FormField, Input } from '../..';
import { FormikProps } from "formik";
import { TextArea } from '../../02_atoms/inputs/Input';

interface InputFieldProps {
    header: string,
    id: string,
    placeholder?: string,
    infoMessage?: string,
    multiline?: boolean,
    inputControl?: React.ReactNode,
    formik: FormikProps<any>
}

export const InputField = ({ header, id, placeholder = '', infoMessage = '', multiline = false, inputControl = false, formik }: InputFieldProps) => {

    if (!formik)
        throw Error(`formik cannot be null or undefined. Check props of ${id}`);

    const value = formik.values[id];
    const onChange = formik.handleChange;

    return (
        <FormField>
            <Field header={header} id={id} placeholder={placeholder} infoMessage={infoMessage} onChange={onChange}>
                {multiline 
                ? <TextArea id={`${id}_textarea`} value={value} /> 
                : <Input id={`${id}_input`} value={value} control={inputControl} />}
            </Field>
        </FormField>
    )
}