import React, { Fragment, useState } from 'react';
import { Toast } from '../../index'
//import cx from "classnames";
import { isInDebug } from '../isInDebug';

export const NotificationContext = React.createContext();
export const NotificationManager = ({ children }) => {

    const debug = isInDebug('NotificationContext');

    const [toastCollection, setToastCollection] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [modalContent, setModalContent] = useState();

    const showErrorToast = (message) => {
        const clone = toastCollection.slice(0);
        debug && console.log('before add', clone);
        
        const item = {
            caption: message,
            error: true,
            onClose: removeItem
        };

        clone.push(item);
        debug && console.log('after add', clone);

        setToastCollection(clone);
    }

    const showNotificationToast = (message, duration = 3000) => {

        const clone = toastCollection.slice(0);
        const item = {
            caption: message,
            onClose: removeItem
        };

        clone.push(item);
        setToastCollection(clone);

        setTimeout(() => {
            removeItem(item);
        }, duration);
    }

    const removeItem = (itemToRemove) => {
        debug && console.log('removing item', itemToRemove);
        const filtered = toastCollection.filter(item => item !== itemToRemove);
        setToastCollection(filtered);
    }

    const openModal = (content) => {
        setModalIsOpen(true);
        setModalContent(content)
      }
    
      const closeModal = () => {
        setModalIsOpen(false);
      }

    const val = {
        toastCollection,
        showNotificationToast,
        showErrorToast,
        openModal,
        closeModal
    }

    // todo: wrap children in a container that can get an overlay
    const wrappedChildren = children

    return (
        <NotificationContext.Provider value={val}>
            <Fragment>
                <Toast
                    onDismiss={removeItem}
                    items={toastCollection}
                />
                {wrappedChildren}
                {modalIsOpen && modalContent}
                
            </Fragment>

        </NotificationContext.Provider>
    )
}

