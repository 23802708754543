import React from 'react';

import './styles/_header.scss';
import cx from "classnames";

//import { STATUS_TYPE } from "../../constants";
import { User } from "../../";
import { LogoTextPlain } from "../../01_fundaments/logo";
import { NavLink } from "react-router-dom";
import { notNull } from '../../00_global';

export const AccountancyHeader = ({ alt }) => {

	const style = cx(
		'header',
		{ 'theme-primary': alt }
	);

	//const notificationBulletType = STATUS_TYPE.default;
	const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
	const {userName, companyName} = userInfo;

	notNull(userName, 'userName');

	//const messageCounter = <span className="header__status"><Status value={1} type={notificationBulletType} /></span>

	return (
		<div className={style}>
			<div className="section">
				<div className="header__inner section__inner">
					<div className="header__primary">
						<div className="header__brand">
							<LogoTextPlain />
						</div>
						<div className="header__navigation">
							<ul>
								{/* {!limited &&
									<li>
										<NavLink to={routeWithSlug('dashboard')} activeClassName={`is-active`}>
											Dashboard
												</NavLink>
									</li>
								}
								<li>
									<NavLink to={routeWithSlug('inbox')} activeClassName={`is-active`}>
										Inbox
													{inboxCounter > 0 && <span className="header__status">
											<Status value={inboxCounter} type={inboxBulletType} />
										</span>}
									</NavLink>
								</li>
								<li>
									{!limited &&
										<NavLink to={routeWithSlug('tasks')} activeClassName={`is-active`}>
											Opvolgen
													{notificationCounter > 0 && 
											}
										</NavLink>
									}
								</li> */}

								<li><NavLink to={'/portal/overview'} activeClassName={`is-active`}>Dossiers</NavLink></li>
								{/* <li><NavLink to={'/portal/messages'} activeClassName={`is-active`}>Berichten {messageCounter}<span className="header__superscript">soon</span></NavLink></li> */}
								<li><a href={'https://ohdear.app/status-page/trive-status-page'} target={'_blank'}>Status</a></li>
							</ul>
						</div>
					</div>
					<div className="header__user">
						{userInfo && <User userName={userName} companyName={companyName} />}
					</div>
				</div>
			</div>
		</div>
	)
}