import React, { useState, Fragment, useContext } from 'react';
import { Search, SidePanelContext, notNull, Bug, Heading, Paragraph } from '../../index'
import {HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE} from "../../constants";
import { FetchData } from '../../00_global/FetchData';
import { isInDebug } from '../../00_global/isInDebug';
import { connect, getIn } from 'formik';
import { DateTime } from '../../index';
import { noResultsItemBuilder, searchResultItemBuilder } from '../../03_molecules/search/helpers';

const debug = isInDebug('Inbox') //&& isInDebug('SearchParty');

let searchTimer;

const SearchPartyElement = ({ id, selectedValueId, formik, onSearch, onNoResultsFound, onPartySelected }) => {

    notNull(id, 'id is a required prop');

    const [searchQuery, setSearchQuery] = useState('');

    const handleChange = (e) => {
        // clear first
        clearTimeout(searchTimer);
        
        const search = e.target.value;

        setFieldValue(id, search);
        setFieldValue(selectedValueId, '');

        debug && notNull(setFieldError, 'setFieldError');
        setFieldError(id, 'Maak een keuze uit de lijst');; // => push errors on selectedValueId to id so they get displayed on the Field

        searchTimer = setTimeout(() => {
            setSearchQuery(search);
        }, 200)
    };

    const formikProps = getIn(formik);
    const { values, setFieldValue, handleBlur, setFieldError } = formikProps;

    const handleSelect = (selectedValue) => {
        if (!selectedValue) {
            console.error('selectedValue null!');
            return; // 2019-04-06 bug while navigating + enter with keyboard
        }
        setFieldValue(id, selectedValue.displayValue);
        setFieldValue(selectedValueId, selectedValue.key);
        setFieldError(id, ''); // => reset errors

        onPartySelected && onPartySelected(selectedValue.key);
    };

    const { sidepanels } = useContext(SidePanelContext)
    const textboxValue = values[id];
    const selectedSupplierId = values[selectedValueId]
    const sidepanelOpen = sidepanels && sidepanels.length > 0;

    return (
        <FetchData method={() => onSearch(searchQuery)} triggerValues={[searchQuery]}>
            {
                ({ data, error, loading }) => {
                    if (data) {
                        const searchResults = data;

                        const mappedResults = searchResults.map((sr) => {
                            const subTitle = sr.lastUsedOn && <span>Laatst gebruikt op <DateTime dateValue={sr.lastUsedOn} /></span>;
                            const children = (
                            <>
                                <Heading size={HEADING_SIZE.xxs} weight={HEADING_WEIGHT.bold} spacing={HEADING_SPACING.zero} color="fc--primary-1">{sr.displayValue}</Heading>
                                <Paragraph size={PARAGRAPH_SIZE.xs} color="fc--gray-4">{subTitle}</Paragraph>
                            </>)
                            return searchResultItemBuilder(sr.key, () => handleSelect(sr), children);
                        })

                        const showNoResults = !sidepanelOpen // user is entering text
                        && textboxValue.length > 1 // at least 2 chars to use as input for the search
                        && !selectedSupplierId // supplier is not selected

                        if (showNoResults) {
                            const message = searchResults.length > 0 ? 'Nieuwe klant?' : `'${searchQuery}' niet gevonden`
                            const noResultControl = noResultsItemBuilder(() => onNoResultsFound(searchQuery), message, 'Klik hier om een nieuwe klant aan te maken');
                            mappedResults.push(noResultControl)
                        }

                        return (
                            <Fragment>
                                <Search
                                    onSearch={handleChange}
                                    onBlur={handleBlur}
                                    searchQuery={searchQuery}
                                    results={mappedResults}
                                    onSelect={handleSelect}
                                    loading={loading}
                                    value={textboxValue}
                                />
                            </Fragment>
                        )
                    }
                    else if (error) {
                        return <Bug error={error} />
                    }
                }
            }
        </FetchData>
    )
}

export const SearchParty = connect(SearchPartyElement);