import React from 'react';
import { Box, Paragraph, Heading, Ellipsis, Button, Checkbox, Field } from '../..';
import { HEADING_SIZE, HEADING_WEIGHT, HEADING_SPACING, PARAGRAPH_SIZE, PARAGRAPH_SPACING } from '../../constants';
import { Formik } from "formik";
import { ShowObject, notNull } from '../../00_global';
import { ErrorBoundary } from '../../00_global/ErrorBoundary';
import { isInDebug } from '../../00_global/isInDebug';
import * as Yup from "yup";
import { FormField } from '../../04_organisms/form/Form';
import { InputField } from '../../06_features/invoice_module_v1/InputField.tsx';

const ERROR_REQUIRED_FIELD = 'Verplicht veld!';
const ERROR_INVALID_EMAIL = 'Geef een geldig mailadres op'
const validateNewAccountSchema = Yup.object().shape({
    companyName: Yup.string().required(ERROR_REQUIRED_FIELD).nullable(),
    firstName: Yup.string().required(ERROR_REQUIRED_FIELD).nullable(),
    name: Yup.string().required(ERROR_REQUIRED_FIELD).nullable(),
    email: Yup.string().required(ERROR_REQUIRED_FIELD).nullable().email(ERROR_INVALID_EMAIL),
    approved: Yup.boolean().test("test", "aanvaarden!", val => val === true)

});

export const CreateAccountPanel = ({ onSubmit }) => {

    const debug = isInDebug('dev');

    const dtoToForm = () => {
        return {
            companyName: '',
            reference: '',
            firstName: '',
            name: '',
            email: '',
            isLimited: false,
            approved: false,
            startOnboarding: true
        }
    }

    const formToDto = (values) => {

        const { companyName, reference, firstName, name, email, approved, startOnboarding } = values;
        notNull(companyName, 'companyName');
        notNull(firstName, 'firstName');
        notNull(name, 'name');
        notNull(email, 'email');

        return {
            accountName: companyName,
            externalId: reference,
            firstName: firstName,
            lastName: name,
            emailAddress: email,
            userConstent: approved,
            startOnboarding: startOnboarding
        }
    }

    const handleValidate = (values) => {
        return true;
    }

    const _handleSubmit = (values) => {
        onSubmit(formToDto(values));
    }

    const initialValues = dtoToForm();

    return (
        <ErrorBoundary>
            <Box noBorder color="bgc--white">
                <Heading size={HEADING_SIZE.small} weight={HEADING_WEIGHT.normal} spacing={HEADING_SPACING.xxs}>
                    <Ellipsis text={'Nieuwe account'}></Ellipsis>
                </Heading>
                <Paragraph size={PARAGRAPH_SIZE.xs} spacing={PARAGRAPH_SPACING.zero} color="fc--gray-4">
                    <div></div>
                </Paragraph>
                <Formik initialValues={initialValues}
                    //isInitialValid={true}
                    validationSchema={validateNewAccountSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validate={handleValidate}
                    onSubmit={(values, actions) => {
                        _handleSubmit(values, actions);
                    }}>
                    {
                        ( formik) => {

                            const {handleSubmit, errors, values, isValid, isSubmitting} = formik;
                            // const samples = getExamples(values);
                            // const isMultiline = values['rootFolder'] && values['rootFolder'].length > 40

                            return (
                                <>
                                    <form className="form" onSubmit={handleSubmit}>
                                        <InputField formik={formik} header={'Naam'} id={'companyName'} placeholder={''} infoMessage={'Naam van het bedrijf of dossier (zonder rechtsvorm)'} />
                                        <InputField formik={formik} header={'Code'} id={'reference'} placeholder={''} infoMessage={'Code of referentie van het dossier binnen het kantoor.'} />
                                        <InputField formik={formik} header={'Voornaam'} id={'firstName'} placeholder={''} infoMessage={'Voornaam van de klant'} />
                                        <InputField formik={formik} header={'Naam'} id={'name'} placeholder={''} infoMessage={'Naam van de klant'} />
                                        <InputField formik={formik} header={'Email klant'} id={'email'} placeholder={''} infoMessage={'Opgelet, dit is ook de login en kan niet aangepast worden!'} />
                                        {/* <FormField><Checkbox id={'isLimited'} label={'Beperkt dossier?'}></Checkbox></FormField> */}
                                        <FormField>
                                            <Field id={'approved'} header={'GDPR'} infoMessage={'Dat moet ook in orde zijn...'}>
                                                <Checkbox label={'Ik heb de toestemming van deze persoon om een account in zijn naam aan te maken'}></Checkbox>
                                            </Field>
                                        </FormField>
                                        <FormField>
                                            <Field id={'startOnboarding'} header={'Informatie mailen'} infoMessage={'Info en logingegevens'}>
                                                <Checkbox label={'Stuur info over Trive en logingegevens? (Vink dit uit als u de klant zelf wil contacteren)'}></Checkbox>
                                            </Field>
                                        </FormField>
                                        <FormField><Button type={'submit'} disabled={!isValid || isSubmitting}>Aanmaken</Button></FormField>
                                    </form>
                                    {debug && <ShowObject value={{ values, errors, isValid: isValid }} />}
                                </>
                            )
                        }
                    }
                </Formik>
            </Box>
        </ErrorBoundary>
    )
}